<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<app-modal-stepper
  [title]="stepTitle"
  linear 
  [finalAction]="'Finish'"
  [scan_action]="false"
  [step]="step"
  [hasDraft]="true"
  [isEdit]="isEdit"
  [isDraft]="isDraft"
  [drawer]="drawer"
  [stepTitle]="!isEdit ? ['Create Post'] : ['Edit Post']"
  [nextButtonLabels]="nextButtonLabels"
  [disabledStep]="disabledNextStep"
  [cancelText]="cancelText"
  [cancelButton]="'Leave'"
  [closeLabel]="'Close'"
  [isSlider]="true"
  [isSliderOpened]="isSliderOpened"
  [closeDrawerAfterComplete]="false"
  [dataIsBeingVerified]="dataIsBeingVerified"
  [verifiedDataLabel]="'Post'"
  (onStepChange)="onStepChange($event)"
  (backStep)="backStep($event)"
  (completed)="completeSteeper()"
  (postDraftEvent)="completeSteeper(true)"
  (destroyData)="ngOnDestroy()"
>
  <cdk-step [completed]="completeStep1">
    <div *ngIf="!isBulk" class="single-location-data">
      <div class="box box--padding-xxxs box--rounded bg--lightblue d-flex justify-content-between align-items-center">
        <h6 class="fw--medium txt--blue m--0">{{location[0]?.accountName}}</h6>
      </div>

      <div class="dialog-row pr--15 pl--15 mt--10 d-flex justify-content-between align-items-center single-location-data-location">
        <div class="company">
          <div class="table__icon table__icon--lg">
            <svg-icon src="/assets/images/icons/pin.svg"></svg-icon>
          </div>
          <div class="company__info">
            <h3 class="company__title m--0"><strong>{{location[0]?.locations[0]?.locationName}}</strong></h3>
            <gmb-address [address]="location[0]?.locations[0]?.address" [serviceAreas]="location[0]?.locations[0]?.serviceArea?.places?.placeInfos"></gmb-address>
          </div>
        </div>
      </div>
    </div>

    <app-toggle-locations-in-account
      *ngIf="isBulk"
      class="locations-no-padding"
      [isSlider]="true"
      [hasGoToLocation]="true"
      [txtInfoNotification]="'Select at least 2 locations to create a bulk post. To create a post for a single location, go to Post Management in the Location view.'"
      [isSliderOpened]="isSliderOpened"
      [reportEdit]="isEdit ? postToEdit?.accounts : !isBulk ? location : null"
      (selected)="selectedLocations($event)"
    ></app-toggle-locations-in-account>
  </cdk-step>

  <cdk-step [completed]="completeStep2">
    <form [formGroup]="form">

      <section #postTypeSection class="bg--off--white p--20">
        <div class="dialog-row p--0">
          <mat-label class="d-block mb--20 txt--md fw--700">Select Post Type</mat-label>
          <mat-radio-group formControlName="postType" (change)="addValidationsStepTwo()" [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
            <mat-radio-button [value]="'STANDARD'" class="txt--14 p--0 pr--50">Update</mat-radio-button>
            <mat-radio-button [value]="'EVENT'" class="txt--14 p--0 pr--50">Event</mat-radio-button>
            <mat-radio-button [value]="'OFFER'" class="txt--14 p--0 pr--50">Offer</mat-radio-button>
          </mat-radio-group>
          <mat-error class="mt--5" *ngIf="postType?.errors && form?.touched">The post type is required</mat-error>
        </div>
      </section>

      <mat-divider></mat-divider>

      <section #postFrequencySection class="bg--off--white p--20">
        <div class="dialog-row p--0">
          <mat-label class="d-block mb--5 txt--md fw--700">Post Schedule and Settings</mat-label>
          <p class="txt--14 m--0 mb--20">Schedule your post as one-time or recurring content, with or without an expiration date.</p>
          
          <p class="mb--10 txt--14 fw--700">Cadence:</p>
          <mat-select formControlName="cadence" class="input-group__field p--10" placeholder="Select Cadence" (selectionChange)="addValidationsStepTwo()" 
          [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
            <mat-option [value]="'INSTANT'">Does not repeat</mat-option>
            <mat-option [value]="'DAILY'">Daily</mat-option>
            <mat-option [value]="'WEEKLY'">Weekly (Day of Week)</mat-option>
            <mat-option [value]="'MONTHLY'">Monthly on (Day of Month)</mat-option>
          </mat-select>
          <mat-error class="mt--5" *ngIf="cadence?.errors && form?.touched">Cadence is required</mat-error>

          <!-- Post type is 'Event' -->
          <section *ngIf="cadence?.value" class="mt--20">
            <!-- Cadence is 'Not Repeat' -->
            <ng-container #schedulePublishSection>
              
              <ng-container *ngIf="cadence?.value == 'INSTANT'" class="w--auto d--inl-block">
                <p class="mb--10 txt--14 fw--700">Schedule Publish:</p>
                <mat-radio-group class="w--auto d--inl-block" formControlName="schedulePublish" (change)="addValidationsStepTwo()" [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
                  <mat-radio-button [value]="'publish_immediately'" class="txt--14 d--block p--0 mb--20">Publish the post immediately (~10 mins)</mat-radio-button>
                  <mat-radio-button [value]="'custom'" class="txt--14 d--inl-block vertical--align--top p--0 mr--5 mt--10">Custom</mat-radio-button>
                  <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block w--70">
                    <input matInput class="input-group__field input--calendar input-icon--right w--70 p--10" [min]="getMinDate('schedulePublish')" formControlName="postStartDate" [matDatepicker]="myDatepickerStart" 
                    (click)="myDatepickerStart.open()" [disabled]="schedulePublish?.value != 'custom' || (isEdit && !isDraft)">
                    <mat-datepicker #myDatepickerStart ></mat-datepicker>
                  </mat-form-field>
                  <mat-error class="position-error-label" *ngIf="postStartDate?.errors && form?.touched">Custom date is required</mat-error>
                </mat-radio-group>
                <mat-error class="position-error-label" *ngIf="schedulePublish?.errors && form?.touched">Schedule publish is required</mat-error>
              </ng-container>

              <ng-container *ngIf="cadence?.value == 'MONTHLY' || cadence?.value == 'WEEKLY'" class="w--auto d--inl-block">
                <p class="mb--10 mt--20 txt--14 fw--700">
                  Repeats on:
                  <app-info-tooltip *ngIf="cadence?.value == 'MONTHLY'" [tooltip]="'Schedule your post to be published on a specific day of the month. It will then be unpublished and republished on the same day of the following month.'"></app-info-tooltip>
                  <app-info-tooltip *ngIf="cadence?.value == 'WEEKLY'" [tooltip]="'Schedule your post to be published on a specific day of the week. It will then be unpublished and republished on the same day of the following week.'"></app-info-tooltip>
                </p>
                <ng-container *ngIf="cadence?.value == 'MONTHLY'">
                  <mat-radio-group class="w--auto d--inl-block" formControlName="repeatsOnMonthly" (change)="addValidationsStepTwo()" [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
                    <mat-radio-button [value]="1" class="txt--14 d--block p--0 mb--20">On the first day of the month</mat-radio-button>
                    <mat-radio-button [value]="15" class="txt--14 d--block p--0 mb--20">On the 15th day of the month</mat-radio-button>
                    <mat-radio-button [value]="-1" class="txt--14 d--block p--0 mb--20">On the last day of the month</mat-radio-button>
                    <mat-radio-button [value]="'custom'" class="txt--14 d--block p--0 mb--20">
                      On the
                      <mat-select class="w--40 input-group__field p--10" formControlName="repeatsOnDay" [disabled]="repeatsOnMonthly?.value !== 'custom' || (isEdit && !isDraft)">
                        <mat-option *ngFor="let day of daysList;" [value]="day"> {{day}} </mat-option>
                      </mat-select>
                      of the month
                    </mat-radio-button>
                  </mat-radio-group>
                  <mat-error class="position-error-label" *ngIf="repeatsOnDay?.errors && form?.touched">On the day is required</mat-error>
                  <mat-error class="position-error-label" *ngIf="repeatsOnMonthly?.errors && form?.touched">Repeats on is required</mat-error>
                </ng-container>

                <ng-container *ngIf="cadence?.value == 'WEEKLY'">
                  <mat-select class="input-group__field w--25 p--10" formControlName="repeatsOnWeekly" placeholder="Select day" 
                  [disabled]="(isEdit && !isDraft)" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
                    <mat-option *ngFor="let day of nameOfDaysOptions;" [value]="day?.value"> {{day?.displayName}} </mat-option>
                  </mat-select>
                  <mat-error class="mt--5" *ngIf="repeatsOnWeekly?.errors && form?.touched">Repeats on is required</mat-error>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="cadence?.value == 'DAILY'" class="w--auto d--inl-block">
                <p class="mb--10 mt--20 txt--14 fw--700">
                  Publishes every:
                  <app-info-tooltip [tooltip]="'Schedule your post to be published on specific days of the week. It will then be unpublished at 11:59 pm on the same day, and republished on the next selected day.'"></app-info-tooltip>
                </p>
                <p class="txt--14 m--0">Select days of week:</p>
                <div class="d-flex m-auto mt--10 mb--10">
                  <span class="frecuency-week-selector" (click)="selectDay(0)" [ngClass]="{'activate': publishDay?.value?.includes(0)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">SU</span>
                  <span class="frecuency-week-selector" (click)="selectDay(1)" [ngClass]="{'activate': publishDay?.value?.includes(1)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">MO</span>
                  <span class="frecuency-week-selector" (click)="selectDay(2)" [ngClass]="{'activate': publishDay?.value?.includes(2)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">TU</span>
                  <span class="frecuency-week-selector" (click)="selectDay(3)" [ngClass]="{'activate': publishDay?.value?.includes(3)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">WE</span>
                  <span class="frecuency-week-selector" (click)="selectDay(4)" [ngClass]="{'activate': publishDay?.value?.includes(4)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">TH</span>
                  <span class="frecuency-week-selector" (click)="selectDay(5)" [ngClass]="{'activate': publishDay?.value?.includes(5)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">FR</span>
                  <span class="frecuency-week-selector" (click)="selectDay(6)" [ngClass]="{'activate': publishDay?.value?.includes(6)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">SA</span>
                </div>
                <p *ngIf="publishDay?.value?.length > 0" class="m--0 txt--dark--gray">{{publishDay.value.length}} days have been selected</p>
                <mat-error class="mt--5" *ngIf="publishDay?.errors && form?.touched">Day of week is required</mat-error>
              </ng-container>

              <ng-container *ngIf="cadence?.value != 'INSTANT' || (cadence?.value == 'INSTANT' && schedulePublish?.value == 'custom')" class="w--auto d--inl-block">
                <div class="p--0 mb--10 mt--20 col-3 display-in-bl" [class.display-in-bl]="cadence?.value == 'DAILY'">
                  <p class="m--0 mb--10 txt--14 fw--700">
                    Time of Publish:
                  </p>
                  <mat-form-field class="input-group__field input-group--override-label time-autocomplete bg--white p--10">
                    <input 
                      [class.txt--disbled]="isEdit && !isDraft"
                      type="text"
                      aria-label="Start time"
                      matInput
                      formControlName="publishTime"
                      [matAutocomplete]="auto"
                      (keydown)="validateInput($event)"
                      [readonly]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">
                        {{ hour }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error class="mt--5" *ngIf="publishTime?.errors && form?.touched">Time of publish is required</mat-error>
                </div>

                <!-- <div *ngIf="cadence?.value == 'DAILY'" class="col-3 p--0 mb--10 mt--20 ml--10 display-in-bl vertical--align--top">
                  <mat-form-field class="input-group__field input-group--override-label time-autocomplete bg--white p--10">
                    <input 
                      [class.txt--disbled]="isEdit && !isDraft"
                      [placeholder]="'To (Optional)'"
                      type="text"
                      aria-label="Start time"
                      matInput
                      formControlName="unpublishTime"
                      [matAutocomplete]="auto"
                      (keydown)="validateInput($event)"
                      [readonly]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">
                        {{ hour }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div> -->
              </ng-container>

              <!-- <ng-container *ngIf="cadence?.value != 'INSTANT' && (cadence?.value == 'WEEKLY' || cadence?.value == 'MONTHLY')" class="w--auto d--inl-block">
                <p class="mb--10 mt--20 txt--14 fw--700">
                  Time of Unpublish:
                  <app-info-tooltip [tooltip]="'Your post will be unpublished at 11:59 PM on the same day before the next post by default.'"></app-info-tooltip>
                </p>
                <mat-form-field class="input-group__field input-group--override-label time-autocomplete bg--white w--25 p--10">
                  <input 
                    [class.txt--disbled]="isEdit && !isDraft"
                    type="text"
                    aria-label="Start time"
                    matInput
                    formControlName="unpublishTime"
                    [matAutocomplete]="auto"
                    (keydown)="validateInput($event)"
                    [readonly]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">
                      {{ hour }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </ng-container> -->

              <ng-container *ngIf="cadence?.value == 'INSTANT'" class="w--auto d--inl-block">
                <p class="mb--10 mt--20 txt--14 fw--700">
                  Scheduled Delete (Optional):
                  <app-info-tooltip [tooltip]="'Schedule your post to be deleted by end of day at 11:59 pm, on the specified date.'"></app-info-tooltip>
                </p>
                <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                  <input matInput class="input-group__field input--calendar input-icon--right w--25 p--10" [min]="getMinDate('deleteDate')" formControlName="deleteDate" [matDatepicker]="myDatepickerEnd" (click)="myDatepickerEnd.open()" 
                  [readonly]="isEdit && !isDraft" [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
                  <mat-datepicker #myDatepickerEnd></mat-datepicker>
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="cadence?.value == 'MONTHLY' || cadence?.value == 'WEEKLY' || cadence?.value == 'DAILY'">
                <p class="mb--10 mt--20 txt--14 fw--700">Ends on:
                  <app-info-tooltip [tooltip]="'Choose whether this recurring post will continue indefinitely or stop after a specific date or number of occurences.'"></app-info-tooltip>
                </p>
                <mat-radio-group class="w--auto d--inl-block" formControlName="endsOn" (change)="addValidationsStepTwo()" [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
                  <mat-radio-button [value]="'never'" class="txt--14 d--block p--0 mb--20">Never</mat-radio-button>
                  
                  <mat-radio-button [value]="'custom_date'" class="txt--14 d--inl-block vertical--align--top p--0 mr--5 mt--10 mb--20">On</mat-radio-button>
                 
                  <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block w--70">
                    <input matInput class="input-group__field input--calendar input-icon--right p--10" [min]="getMinDate('endsOnCustomDate')" formControlName="endsOnCustomDate" [matDatepicker]="endsOnCalendar" (click)="endsOnCalendar.open()" [class.txt--disbled]="isEdit && !isDraft"
                    [disabled]="endsOn?.value !== 'custom_date' || (isEdit && !isDraft)">
                    <mat-datepicker #endsOnCalendar></mat-datepicker>
                  </mat-form-field>
                  <mat-error class="position-error-label" *ngIf="endsOnCustomDate?.errors && form?.touched">On date is required</mat-error>
                  
                  <section class="d--block">
                    <mat-radio-button [value]="'custom_occurrences'" class="txt--14 d--inl-block vertical--align--top p--0 mb--20 mt--10 mr--5">After</mat-radio-button>
                    <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block w--70">
                      <input matInput type="number" class="w--40 fw--regular p--10" [class.txt--disbled]="endsOn?.value != 'custom_occurrences' || (isEdit && !isDraft)" formControlName="endsOnAfterOcurrences"
                      [readonly]="endsOn?.value != 'custom_occurrences' || (isEdit && !isDraft)">
                      <p class="vertical--align--top d--inl-block m--0 mt--10 ml--5">Occurences</p>
                    </mat-form-field>
                    <mat-error class="position-error-label" *ngIf="endsOnAfterOcurrences?.errors && form?.touched">Occurences is required</mat-error>
                  </section>
                </mat-radio-group>
                <mat-error class="position-error-label" *ngIf="endsOn?.errors && form?.touched">Ends on is required</mat-error>
              </ng-container>

              <ng-container *ngIf="cadence?.value == 'MONTHLY' || cadence?.value == 'WEEKLY' || cadence?.value == 'DAILY'">
                <p class="mb--10 mt--10 txt--14 fw--700">First Publish:</p>
                <mat-radio-group class="w--auto d--inl-block" formControlName="firstPublish" (change)="addValidationsStepTwo()" [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
                  <mat-radio-button [value]="'post_immediately'" class="txt--14 d--block p--0 mb--20">
                    Publish the first post immediately (~10 mins)
                    <app-info-tooltip [tooltip]="'Publish the first post right away. Future posts will be published at the next scheduled date and time.'"></app-info-tooltip>
                  
                  </mat-radio-button>
                  <mat-radio-button [value]="'next_occurrence'" class="txt--14 d--block p--0 mb--20">
                    Wait for the next occurence
                    <app-info-tooltip [tooltip]="'Publish the first post at the next scheduled date and time'"></app-info-tooltip>
                  </mat-radio-button>
                  <mat-radio-button [value]="'custom'" class="txt--14 d--inl-block vertical--align--top p--0 mb--20 mt--10 mr--5">Custom</mat-radio-button>
                  <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block w--70">
                    <input matInput class="input-group__field input--calendar input-icon--right p--10" [min]="getMinDate('firstPublishDate')" formControlName="firstPublishDate" [matDatepicker]="firstPublishDate" (click)="firstPublishDate.open()"
                    [class.txt--disbled]="firstPublish?.value !== 'custom' || (isEdit && !isDraft)" [disabled]="firstPublish?.value !== 'custom' || (isEdit && !isDraft)">
                    <mat-datepicker #firstPublishDate></mat-datepicker>
                  </mat-form-field>
                  <mat-error class="position-error-label" *ngIf="form?.controls?.firstPublishDate?.errors && form?.touched">Custom date is required</mat-error>
                </mat-radio-group>
                <mat-error class="position-error-label" *ngIf="firstPublish?.errors && form?.touched">First publish is required</mat-error>
              </ng-container>
              
            </ng-container>
          </section>


        </div>
      </section>

    </form>
  </cdk-step>

  <cdk-step [completed]="completeStep3">
    <div class="dialog-row mb--20 border-bottom p--20 pt--0 pb--0">
      <p class="d-block mb--20 txt--md fw--700">{{postTypeLabel | titlecase}} Details</p>
    </div>
    
    <form [formGroup]="form">  
        
      <section class="col mb--20 pr--20 pl--20">
        
        <div class="col-12 p--0">
          <div class="dialog-row p-tb--0 w--96" *ngIf="postType?.value != 'STANDARD' && step == 2">
            <p class="mb--10 txt--14 fw--700">Title</p>
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <input matInput class="input-group__field" maxLength="58" formControlName="title" placeholder="Title"/>
              <mat-hint align="end">{{title?.value?.length ? title?.value?.length : 0}} / 58</mat-hint>
            </mat-form-field>
            <mat-error class="position-error-label" *ngIf="title?.errors && form?.touched">Title is required</mat-error>
          </div>
          
          <div class="dialog-row p-tb--0 mt--20 w--96">
            <p class="mb--10 txt--14 fw--700">Description</p>
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label w--100">
              <textarea matInput class="input-group__field" formControlName="summary" maxlength="1500" placeholder="Add a description" [rows]="postType == 'STANDARD' ? 10 : 6"></textarea>
              <mat-hint align="end">{{summary?.value?.length ? summary?.value?.length : 0}} / 1500</mat-hint>
            </mat-form-field>
            <mat-error class="position-error-label" *ngIf="summary?.errors && form?.touched">Description is required</mat-error>
          </div>
        </div>
        <div>
          <p class="mb--10 txt--14 fw--700 mt--20">
            Media (Optional)
          </p>
          <div class="row d-flex pr--20 pl--12">
            <!-- start input -->
            <app-form-input-upload-multiple
              [multiple]="false"
              [maxNumberPhotos]="1"
              [urlInput]="imageSelectedUrl"
              [requirements]="imageRequirements"
              (ref)="handleRef($event)"
              >
            </app-form-input-upload-multiple>
            <!-- end input -->
            <!-- start swipper -->
            <div class="col-9 pl--30">
              <ng-container *ngIf="mediaUrlArray?.length >= 1">
                <div class="w--100">
                  <swiper [config]="swiperConfig" class="carousel carousel--thumbs preview-photos">
                    <div class="thumb-wrapper thumb-wrapper-4" *ngFor="let file of mediaUrlArray">
                      <!-- delete icon on top right for every thumbnail -->
                      <button
                        (click)="openDelete(file)"
                        matTooltip="Delete"
                        matTooltipClass="tooltip tooltip--red"
                        matTooltipPosition="above"
                        class="btn btn-delete btn-delete--gallery ">
                        <i class="fas fa-trash-alt delete-file"></i>
                      </button>
                      <img [src]="file.category === 'VIDEO' ? file.preview : file.url" [ngClass]="file.fileName ? '' : 'new-image'" [alt]="file.url">
                    </div>
                  </swiper>
                </div>
              </ng-container>
            </div>
            <!-- end swipper -->
          </div>
          <div class="mt--10">
            <p class="m--0 txt--sm txt--medium-gray"> Add Image: Minimum Size: 400 x 300 </p>
            <p class="m--0 txt--sm txt--medium-gray"> Maximum Size: 10240 x 5000000 </p>
            <p class="m--0 txt--sm txt--medium-gray"> Formats: PNG, JPG, JPEG </p>
            <p class="m--0 txt--sm txt--medium-gray"> Aspect ratio: 4:3 </p>
          </div>
        </div>
      </section>
      
      <div class="bg--off--white mt--20 p--20">
        <section *ngIf="postType?.value != 'OFFER' && step == 2">
          <p class="mb--10 txt--14 fw--700">
            Add button (Optional)
            <app-info-tooltip [tooltip]="'Add a call to action button to your post.'"></app-info-tooltip>
          </p>
          <mat-select formControlName="actionType" class="input-group__field w--25 p--10 w--100" (selectionChange)="getActionLinks()">
            <mat-option *ngFor="let option of actionOptions;" [value]="option?.value"> {{option?.displayName}} </mat-option>
          </mat-select>

          <div class="mt--10" *ngIf="actionType && actionType?.value !== 'NONE' && actionType?.value !== 'CALL'">
            <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block p--0 btn-action">
              <input matInput class="input-group__field pb--10" formControlName="actionUrl" [placeholder]="isLoading ? '' : actionsUrl?.length > 0 ? 'Insert button link or select placeholder from below' : 'Insert button link'"/>
              <button mat-icon-button color="primary" class="btn btn-icon btn--rounded ml--10 mb--20 icon-delete-action" (click)="clearActionUrl()">X</button>
            </mat-form-field>

            <section *ngIf="actionsUrl?.length > 0" class="input-group__field bg--white mt--10">
              <button mat-flat-button color="primary" style="margin: 5px;" *ngFor="let url of actionsUrl" (click)="addActionUrl(url)">{{url.value}}</button>
              <p style="margin-top: 10px; color: #8c91a2;"><em class="fas fa-info-circle mr--5"></em>Select an above placeholder to insert data from your business profile into your button link.</p>
            </section>
          </div>

          <div class="mt--10" *ngIf="actionType?.value === 'CALL'">
            <section class="input-group__field bg--white mt--10">
              <p class="m--0" style="color: #8c91a2;">
                <em class="fas fa-info-circle mr--5"></em>The primary phone number for each location will be used for customer to call.
              </p>
            </section>
          </div>

        </section>

        <mat-divider class="mt--20 mb--20" *ngIf="postType?.value != 'STANDARD'"></mat-divider>

        <p class="d-block mb--20 txt--md fw--700" *ngIf="postType?.value != 'STANDARD'">{{postTypeLabel | titlecase}} Schedule</p>

        <p class="mb--10 mt--20 txt--14" *ngIf="postType?.value != 'STANDARD' && cadence?.value == 'DAILY'">
          <span class="fw--700">{{postType?.value | titlecase}} dates match publish dates:</span> Daily on {{unifiedPublishDayValues}}
        </p>

        <section *ngIf="(postType?.value != 'STANDARD' && cadence?.value != 'DAILY') && step == 2" class="mb--20">
          <p class="mb--10 mt--20 txt--14 fw--700">
            {{postType?.value | titlecase}} Start and End Dates
          </p>

          <div *ngIf="cadence?.value == 'INSTANT'" class="row col-12">
            <section class="col-4 p--0 mr--20">
              <span class="txt--14 mr--5 d--inl-block vertical--align--top mt--10">From:</span>
              <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block">
                <input matInput class="input-group__field input--calendar input-icon--right p--10" [min]="getMinDate('instantEventDatesStart')" formControlName="instantEventDatesStart" [matDatepicker]="instantEventDatesStart" (click)="instantEventDatesStart.open()">
                <mat-datepicker #instantEventDatesStart></mat-datepicker>
              </mat-form-field>
              <mat-error class="position-error-label" *ngIf="form?.controls?.instantEventDatesStart?.errors && form?.touched">From is required</mat-error>
            </section>

            <section class="col-4 p--0">
              <span class="txt--14 mr--5 d--inl-block vertical--align--top mt--10">To:</span>
              <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block">
                <input matInput class="input-group__field input--calendar input-icon--right p--10" [min]="getMinDate('instantEventDatesEnd')" formControlName="instantEventDatesEnd" [matDatepicker]="instantEventDatesEnd" (click)="instantEventDatesEnd.open()">
                <mat-datepicker #instantEventDatesEnd></mat-datepicker>
              </mat-form-field>
              <mat-error class="position-error-label" *ngIf="form?.controls?.instantEventDatesEnd?.errors && form?.touched">To is required</mat-error>
            </section>
          </div>

          <div class="col-12 row" *ngIf="cadence?.value == 'WEEKLY'">
            <section class="col-4 p--0 mr--20">
              <p class="m--0 txt--dark--gray">Select days of week:</p>
              <mat-select formControlName="eventDatesStartWeekly" class="input-group__field p--10 mr--10 bg--white">
                <mat-option *ngFor="let day of nameOfDaysOptions;" [value]="day?.value"> {{day?.displayName}} </mat-option>
              </mat-select>
              <mat-error class="mt--5" *ngIf="eventDatesStartWeekly?.errors && form?.touched">Days of week is required</mat-error>
            </section>

            <section class="col-4 p--0">
              <mat-select formControlName="eventDatesEndWeekly" class="input-group__field p--10 mt--20 bg--white">
                <mat-option *ngFor="let day of nameOfDaysOptions;" [value]="day?.value"> {{day?.displayName}} </mat-option>
              </mat-select>
              <mat-error class="mt--5" *ngIf="eventDatesEndWeekly?.errors && form?.touched">Days of week is required</mat-error>
            </section>
          </div>

          <div *ngIf="cadence?.value == 'MONTHLY'">
            <mat-radio-group formControlName="weeklyEventsDates" (change)="addFormFieldsStepThree()">
              <mat-radio-button [value]="'corresponds_publish_date'" class="txt--14 d--block p--0 mb--20">Corresponds with publish dates</mat-radio-button>
              <mat-radio-button [value]="'custom'" class="txt--14 d--block vertical--align--top p--0 mr--5 mt--10">Select {{postType?.value | lowercase}} dates</mat-radio-button>
              
              <div class="col-12 row">
                <section class="col-4 p--0 mr--20">
                  <mat-select class="input-group__field p--10 mt--10 mr--10" formControlName="eventDatesStartMonthly" [disabled]="weeklyEventsDates?.value == 'corresponds_publish_date'">
                    <mat-option *ngFor="let day of daysList;" [value]="day"> {{day}} </mat-option>
                  </mat-select>
                  <mat-error class="mt--5" *ngIf="eventDatesStartMonthly?.errors && form?.touched">Start date is required</mat-error>
                </section>

                <section class="col-4 p--0 mt--10">
                  <mat-select class="input-group__field p--10" formControlName="eventDatesEndMonthly" [disabled]="weeklyEventsDates?.value == 'corresponds_publish_date'">
                    <mat-option *ngFor="let day of daysList;" [value]="day"> {{day}} </mat-option>
                  </mat-select>
                  <mat-error class="mt--5" *ngIf="eventDatesEndMonthly?.errors && form?.touched">End date is required</mat-error>
                </section>
              </div>
            </mat-radio-group>
            <mat-error class="mt--5" *ngIf="weeklyEventsDates?.errors && form?.touched">Dates are required</mat-error>
          </div>
        </section>

        <section *ngIf="postType?.value != 'STANDARD' && step == 2" class="mb--20">
          <p class="mb--10 mt--20 txt--14 fw--700">
            {{postType?.value | titlecase}} Start and End Times (Local Business Time)
          </p>

          <div class="col-12 row">
            <section class="col-4 p--0 mr--20">
              <mat-form-field class="input-group__field input-group--override-label time-autocomplete bg--white p--10 mr--10">
                <input type="text"
                  aria-label="Start time"
                  matInput
                  formControlName="evenTimeStart"
                  [matAutocomplete]="auto"
                  (keydown)="validateInput($event)">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">
                    {{ hour }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </section>

            <section class="col-4 p--0">
              <mat-form-field class="input-group__field input-group--override-label time-autocomplete bg--white p--10 mr--10">
                <input type="text"
                  aria-label="End time"
                  matInput
                  formControlName="evenTimeEnd"
                  [matAutocomplete]="auto"
                  (keydown)="validateInput($event)">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">{{ hour }}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-error class="mt--5" *ngIf="evenTimeEnd?.errors && form?.touched">End time should be higher than the current time</mat-error>
            </section>
          </div>

          <div *ngIf="postType?.value !== 'STANDARD' && cadence?.value !== 'INSTANT'" class="alert alert-notification-dark w--100 mt--40">
            <span class="icon-info-message col-1 vertical--align--top">
              <em class="fas fa-info"></em>
            </span>
            <p class="col-11 display-in-bl">
              {{infoAboutDates}}
            </p>
          </div>

        </section>

        <section *ngIf="postType?.value == 'OFFER' && step == 2" class="additional-info mb--20">
          <p class="mb--10 mt--20 txt--14 fw--700">
            Additional Info
            <app-info-tooltip [tooltip]="'Add any additional details like coupon code or terms to your offer.'"></app-info-tooltip>
          </p>

          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <input matInput class="input-group__field pb--10" formControlName="couponCode" placeholder="Coupon code name (optional)"/>
          </mat-form-field>

          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <input matInput class="input-group__field pb--10" formControlName="linkToRedeem" placeholder="Link to Redeem Offer (optional)"/>
          </mat-form-field>

          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <input matInput class="input-group__field" formControlName="termsAndCondition" placeholder="Terms and Conditions (optional)"/>
          </mat-form-field>

        </section>
      </div>
      
    </form>
  </cdk-step>

  <cdk-step [completed]="true">
    <ng-container *ngIf="step == 3">

      <div class="alert alert-danger w--100" role="alert" *ngIf="isCorrectData">
        <em class="fas fa-exclamation-triangle mr--10"></em>
        
        <span>There are some errors with your post, please review the <span *ngIf="fieldsWithError?.length">following</span> fields and try again.</span>
        <ul class="mt--5 errors-list">
          <li *ngFor="let error of fieldsWithError" class="mb--0">{{error?.field | titlecase}} - {{error?.error}}</li>
        </ul>
      </div>


      <div class="dialog-row p--0 mb--10 border-bottom">
        <p class="d-block mt--20 mb--20 txt--md fw--700">{{dataForLastStep?.postType | titlecase}}</p>
      </div>

      <div class="row bg--off--white p--20 pb--0">
        <div class="selected-thumb col-5">
          <ng-container *ngIf="mediaUrlArray?.length >= 1">
              <div class="custom-swiper-cont">
                <div class="custom-swiper-prev" (click)="customSwiperPrev()" *ngIf="selectedFileIndex > 0">
                  <i class="far fa-chevron-left"></i>
                </div>
                <div class="custom-swiper-thumb">
                  <img 
                  [src]="mediaUrlArray[selectedFileIndex]?.category === 'VIDEO' 
                  ? mediaUrlArray[selectedFileIndex]?.preview 
                  : mediaUrlArray[selectedFileIndex]?.url" 
                  [alt]="mediaUrlArray[selectedFileIndex]?.url">
                </div>
                <div class="custom-swiper-next" (click)="customSwiperNext()" *ngIf="mediaUrlArray.length -1 > selectedFileIndex">
                  <i class="far fa-chevron-right"></i>
                </div>
              </div>
          </ng-container>
          <ng-container *ngIf="mediaUrlArray?.length === 0">
            <div class="custom-swiper-cont">
              <div class="custom-swiper-thumb no-media-attached">
                <div class="no-files-cont">
                  <img src="/assets/images/no_files_attached.svg" alt="">
                  <p>No image added</p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        
        <div class="col-7  p--0">
          <div class="summary-field">
            <p *ngIf="postType?.value !== 'STANDARD'" class="d-block mb--20 txt--md fw--700">{{title?.value | titlecase}}</p>
            <read-more class="mt--10" [text]="formattedSummary" [showToggleButton]="true" [maxLength]="300"></read-more>
          </div>
          <p *ngIf="actionType?.value && actionType?.value !== 'NONE'" class="txt--14 m--0 txt--gray txt-actionType">{{getActionType(actionType?.value) | titlecase}}</p>
        </div>
      </div>

      <div class="row ml--0">
        <div class="dialog-row bg--off--white p--20 pl--5 col-6 display-in-bl">
          <div class="dialog-row p--0 mb--20">
            <p class="d-block mt--20 mb--20 txt--md fw--700">Post Settings:</p>
          </div>

          <div>
            <section>
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Cadence:</p>
              <p class="fw--normal">{{dataForLastStep?.cadence | titlecase}}</p>
            </section>

            <section *ngIf="dataForLastStep?.repeatsOn">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Repeats On:</p>
              <p class="fw--normal">{{dataForLastStep.repeatsOn}}</p>
            </section>

              <section *ngIf="dataForLastStep?.schedulePublish">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Scheduled Publish:</p>
                <p class="fw--normal">{{dataForLastStep.schedulePublish}}</p>
              </section>

              <section *ngIf="dataForLastStep?.publishesEvery">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Publishes every:</p>
                <p class="fw--normal">{{dataForLastStep.publishesEvery}}</p>
              </section>

              <section *ngIf="dataForLastStep?.timeOfPublish">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Time of Publish:</p>
                <p class="fw--normal">{{dataForLastStep.timeOfPublish}}</p>
              </section>

              <!-- <section *ngIf="dataForLastStep?.timeOfUnpublish">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Time of Unpublish:</p>
                <p class="fw--normal">{{dataForLastStep.timeOfUnpublish}}</p> 
              </section>-->

              <section *ngIf="dataForLastStep?.scheduledDetele">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Scheduled Delete:</p>
                <p class="fw--normal">{{dataForLastStep.scheduledDetele}}</p>
              </section>

              <section *ngIf="dataForLastStep?.endsOn">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Ends On:</p>
                <p class="fw--normal">{{dataForLastStep.endsOn}}</p>
              </section>

              <section *ngIf="dataForLastStep?.firstPublish">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">First Publish:</p>
                <p class="fw--normal">{{dataForLastStep.firstPublish}}</p>
              </section>

          </div>
        </div>

        <div class="dialog-row bg--off--white p--20 pl--5 col-6 display-in-bl" *ngIf="postType?.value !== 'STANDARD'">
          <div class="dialog-row p--0 mb--20">
            <p class="d-block mt--20 mb--20 txt--md fw--700">{{postTypeLabel | titlecase}} Details:</p>
          </div>
          <div>
            <section *ngIf="cadence?.value === 'DAILY' || (cadence?.value === 'MONTHLY' && weeklyEventsDates?.value === 'corresponds_publish_date')" >
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">{{postType?.value | titlecase}} Dates:</p>
              <p class="fw--normal">{{dataForLastStep?.dailyDate}}</p>
            </section>

            <section *ngIf="cadence?.value !== 'DAILY' && weeklyEventsDates?.value !== 'corresponds_publish_date'">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">{{postType?.value | titlecase}} Dates:</p>
              <p class="fw--normal">{{dataForLastStep?.startDate}} - {{dataForLastStep?.endDate}}</p>
            </section>

            <section *ngIf="dataForLastStep?.startTime && dataForLastStep?.endTime">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">{{postType?.value | titlecase}} Time:</p>
              <p class="fw--normal">{{dataForLastStep?.startTime}} - {{dataForLastStep?.endTime}}</p>
            </section>

            <section *ngIf="dataForLastStep?.startTime && !dataForLastStep?.endTime">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">{{postType?.value | titlecase}} Start Time:</p>
              <p class="fw--normal">{{dataForLastStep?.startTime}}</p>
            </section>

            <section *ngIf="!dataForLastStep?.startTime && dataForLastStep?.endTime">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">{{postType?.value | titlecase}} End Time:</p>
              <p class="fw--normal">{{dataForLastStep?.endTime}}</p>
            </section>

            <section *ngIf="dataForLastStep?.couponCode">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Coupon Code Name:</p>
              <p class="fw--normal">{{dataForLastStep?.couponCode}}</p>
            </section>

            <section *ngIf="dataForLastStep?.linkToRedeem">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Link to Redeem Offer:</p>
              <p class="fw--normal">{{dataForLastStep?.linkToRedeem}}</p>
            </section>
              
            <section *ngIf="dataForLastStep?.termsAndCondition">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Terms and Conditions:</p>
              <p class="fw--normal">{{dataForLastStep?.termsAndCondition}}</p>
            </section>

          </div>
        </div>
      </div>

      <div class="dialog-row mt--20">
        <mat-accordion displayMode="flat" class="no-shadow no-hover">
          <mat-expansion-panel>
            <mat-expansion-panel-header class="p--0 mt--5" style="height: 50px!important;">
              <mat-panel-title>
                <p class="txt--md txt--blue m--0 fw--700 mb--25 pl--0">Locations</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let loc of locationsSelected">
              <p class="fw--medium txt--14">{{loc?.locationName}} | 
              <gmb-address class="txt--dark--gray vertical--align--middle display-in-bl" [address]="loc?.address" [serviceAreas]="loc?.serviceArea?.places?.placeInfos">
              </gmb-address></p>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </ng-container>
  </cdk-step>
</app-modal-stepper>