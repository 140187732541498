/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart-stats.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../chart-line/chart-line.component.ngfactory";
import * as i3 from "../chart-line/chart-line.component";
import * as i4 from "../chart-donut/chart-donut.component.ngfactory";
import * as i5 from "../chart-donut/chart-donut.component";
import * as i6 from "../chart-progress/chart-progress.component.ngfactory";
import * as i7 from "../chart-progress/chart-progress.component";
import * as i8 from "@angular/common";
import * as i9 from "./chart-stats.component";
var styles_ChartStatsComponent = [i0.styles];
var RenderType_ChartStatsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartStatsComponent, data: {} });
export { RenderType_ChartStatsComponent as RenderType_ChartStatsComponent };
function View_ChartStatsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "box__header d-flex align-items-center justify-content-start"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "txt--capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stats.title; _ck(_v, 3, 0, currVal_0); }); }
function View_ChartStatsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chart-line", [], null, null, null, i2.View_ChartLineComponent_0, i2.RenderType_ChartLineComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChartLineComponent, [], { dataset: [0, "dataset"], labels: [1, "labels"], commaNumbers: [2, "commaNumbers"], dataPicker: [3, "dataPicker"], title: [4, "title"], noDecimals: [5, "noDecimals"], colors: [6, "colors"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataset; var currVal_1 = _co.labels; var currVal_2 = true; var currVal_3 = _co.dataPicker; var currVal_4 = _co.stats.title; var currVal_5 = true; var currVal_6 = _co.colors; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_ChartStatsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chart-donut", [], null, null, null, i4.View_ChartDonutsComponent_0, i4.RenderType_ChartDonutsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ChartDonutsComponent, [], { dataset: [0, "dataset"], colorChange: [1, "colorChange"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataset; var currVal_1 = _co.type; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ChartStatsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chart-progress", [], null, null, null, i6.View_ChartProgressComponent_0, i6.RenderType_ChartProgressComponent)), i1.ɵdid(1, 4833280, null, 0, i7.ChartProgressComponent, [i1.ChangeDetectorRef], { total: [0, "total"], label: [1, "label"], bgColor: [2, "bgColor"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.total); var currVal_1 = _v.context.$implicit.label; var currVal_2 = _v.context.$implicit.backgroundColor; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ChartStatsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-7"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatsComponent_7)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataset; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChartStatsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "col-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatsComponent_5)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "col-lg-12 txt--center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "txt--xxxl-sm txt--lightblue fw--medium m--0"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 2), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "txt--md txt--lightgray fw--medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatsComponent_6)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataset.length > 1); _ck(_v, 3, 0, currVal_0); var currVal_2 = (((_co.stats.title != "Photo views") && (_co.stats.title != "Photo quantity")) && (_co.dataset.length > 1)); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), ((_co.stats == null) ? null : _co.stats.totals), "1.0-0")); _ck(_v, 6, 0, currVal_1); }); }
function View_ChartStatsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatsComponent_3)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatsComponent_4)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.line && _co.labels); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.stats.title != "Photo views") && (_co.stats.title != "Photo quantity")); _ck(_v, 5, 0, currVal_1); }, null); }
function View_ChartStatsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-chart-progress", [["type", "revenue"]], null, null, null, i6.View_ChartProgressComponent_0, i6.RenderType_ChartProgressComponent)), i1.ɵdid(2, 4833280, null, 0, i7.ChartProgressComponent, [i1.ChangeDetectorRef], { total: [0, "total"], label: [1, "label"], bgColor: [2, "bgColor"], type: [3, "type"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.total); var currVal_1 = _v.context.$implicit.label; var currVal_2 = _v.context.$implicit.backgroundColor; var currVal_3 = "revenue"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ChartStatsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatsComponent_9)), i1.ɵdid(1, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col-12 txt--center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "txt--xxxl-sm txt--lightblue fw--medium m--0"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "txt--md txt--lightgray fw--medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataset; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), ((_co.stats == null) ? null : _co.stats.totals), "1.0-0")); _ck(_v, 4, 0, currVal_1); }); }
export function View_ChartStatsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row box--height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "col-12 mb--30"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "box box--rounded box--shadow bg--white"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatsComponent_1)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "box__content box--padding-xxs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatsComponent_2)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["vertical", 2]], null, 0, null, View_ChartStatsComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleIcon; _ck(_v, 5, 0, currVal_0); var currVal_2 = (_co.type != "vertical"); var currVal_3 = i1.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "row ", ((_co.type == "vertical") ? "m--0" : ""), ""); _ck(_v, 7, 0, currVal_1); }); }
export function View_ChartStatsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chart-stats", [], null, null, null, View_ChartStatsComponent_0, RenderType_ChartStatsComponent)), i1.ɵdid(1, 4308992, null, 0, i9.ChartStatsComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartStatsComponentNgFactory = i1.ɵccf("app-chart-stats", i9.ChartStatsComponent, View_ChartStatsComponent_Host_0, { labels: "labels", stats: "stats", line: "line", type: "type", dataPicker: "dataPicker", colors: "colors", reportType: "reportType" }, {}, []);
export { ChartStatsComponentNgFactory as ChartStatsComponentNgFactory };
