export const MULTIKEY_DATA = [
  { name: 'Direction Requests', status: 'success'},
  { name: 'Mobile Phone Calls', status: 'info'},
  { name: 'Website Visits', status: 'fail'},
  { name: 'Local Post Actions', status: 'info'},
  { name: 'Local Post Views', status: 'info'},
  { name: 'Merchant Photo Views', status: 'fail'},
  { name: 'Merchant Photos Count', status: 'fail'},
  { name: 'Customer Photo Views', status: 'info'},
  { name: 'Customer Photos Count', status: 'info'},
  { name: 'Direct Searches', status: 'fail'},
  { name: 'Discovery Searches', status: 'fail'},
  { name: 'Search Views', status: 'fail'},
  { name: 'Map Views', status: 'info'},
  { name: 'Average Star Rating', status: 'success'},
  { name: 'Number of New Reviews', status: 'info'}
];

export const MULTIKEY_METRIC = [
  { id: 1, name: 'Direction Requests', checked: false },
  { id: 2, name: 'Website Visits', checked: false },
  { id: 3, name: 'Mobile Phone Calls', checked: false },
  { id: 4, name: 'Local Post Actions', checked: false },
  { id: 5, name: 'Local Post Views', checked: false },
  { id: 6, name: 'Merchant Photo Views', checked: false },
  { id: 7, name: 'Merchant Photos Count', checked: false },
  { id: 8, name: 'Customer Photo Views', checked: false },
  { id: 9, name: 'Customer Photos Count', checked: false },
  { id: 10, name: 'Direct Searches', checked: false },
  { id: 11, name: 'Discovery Searches', checked: false },
  { id: 12, name: 'Search Views', checked: false },
  { id: 13, name: 'Map Views', checked: false },
  { id: 14, name: 'Average Star Rating', checked: false },
  { id: 15, name: 'Number of New Reviews', checked: false },
];

export const MULTIKEY_METRIC_V2 = [
  { id: 1, name: 'Direction Requests', checked: false },
  { id: 2, name: 'Website Visits', checked: false },
  { id: 3, name: 'Mobile Phone Calls', checked: false },
  { id: 4, name: 'Search Views', checked: false },
  { id: 5, name: 'Map Views', checked: false },
  { id: 6, name: 'Average Star Rating', checked: false },
  { id: 7, name: 'Number of New Reviews', checked: false },
];

export const MULTIKEY_METRIC_V2_NEW = [
  { id: 1, name: 'Direction Requests', checked: false, isSecondary:false },
  { id: 2, name: 'Website Visits', checked: false, isSecondary:false },
  { id: 3, name: 'Mobile Phone Calls', checked: false, isSecondary:false },
  { id: 4, name: 'Search Views', checked: false, isSecondary:false },
  { id: 5, name: 'Map Views', checked: false, isSecondary:false },
  { id: 6, name: 'Average Star Rating', checked: false, isSecondary:false },
  { id: 7, name: 'Number of New Reviews', checked: false, isSecondary:false },
  { id: 8, name: 'Bookings', checked: false, isSecondary: true },
  { id: 9, name: 'Messages', checked: false, isSecondary: true },
  { id: 10, name: 'Menu Views', checked: false, isSecondary: true },
  { id: 11, name: 'Food Orders', checked: false, isSecondary: true },
];

export const MULTIKEY_METRIC_INSIGHTS = [
  { id: 2, name: 'Direction Requests', key: 'DIRECTIONS', checked: false },
  { id: 1, name: 'Mobile Phone Calls', key: 'CALL', checked: false },
  { id: 3, name: 'Website Visits', key: 'WEBSITES', checked: false },
  { id: 2, name: 'Bookings', key: 'BOOKINGS', checked: false },
  { id: 1, name: 'Messages', key: 'CONVERSATION', checked: false },
  { id: 3, name: 'Food Orders', key: 'FOOD_ORDERING', checked: false },
  { id: 3, name: 'Menu Views', key: 'MENU_CLICKS', checked: false },
  { id: 1, name: 'Google Mobile - Search', key: 'GOOGLEMOBILESEARCH', checked: false },
  { id: 1, name: 'Google Mobile - Maps', key: 'GOOGLEMOBILEMAPS', checked: false },
  { id: 1, name: 'Google Desktop - Search', key: 'GOOGLEDESKTOPSEARCH', checked: false },
  { id: 1, name: 'Google Desktop - Maps', key: 'GOOGLEDESKTOPMAPS', checked: false },
  { id: 14, name: 'Average Star Rating', checked: false },
  { id: 15, name: 'Number of New Reviews', checked: false }
];

export const ORDER_KEYS = {
  QUERIES_DIRECT: {},
  QUERIES_INDIRECT: {},
  VIEWS_SEARCH: {},
  VIEWS_MAPS: {},
  ACTIONS_DRIVING_DIRECTIONS: {},
  ACTIONS_PHONE: {},
  ACTIONS_WEBSITE: {},
  LOCAL_POST_ACTIONS_CALL_TO_ACTION: {},
  LOCAL_POST_VIEWS_SEARCH: {},
  PHOTOS_VIEWS_MERCHANT: {},
  PHOTOS_COUNT_MERCHANT: {},
  PHOTOS_VIEWS_CUSTOMERS: {},
  PHOTOS_COUNT_CUSTOMERS: {},
  AVERAGE_STAR_RATING: {},
  NEW_REVIEWS: {},
  BOOKINGS: {},
  FOOD_ORDERS: {},
  FOOD_ORDERING: {},
  FOOD_MENU_CLICKS: {},
  CONVERSATION: {},
};

export const GRADE_METRICS = [
  {
    name: 'Basic Business Information',
    id: 'basicInfo',
    max: {
      RESTAURANTS: 10,
      HOTELS: 6,
      SERVICES: 7,
      OTHER: 7,
    },
    items: {
      RESTAURANTS: [
        { name : 'Phone Number', active: false, points: 0 },
        { name: 'Website URL', active: false, points: 0 },
        { name: 'Hours of Operation', active: false, points: 0 },
        { name: 'Business Description', active: false, points: 0 },
        { name: 'Opening Date', active: false, points: 0 },
        { name: 'Additional Categories', active: false, points: 0 },
        { name: 'Attributes selected', active: false, points: 0 },
        { name: 'Menu URL', active: false, points: 0 },
        { name: 'Order Ahead URL', active: false, points: 0 },
        { name: 'Reservation URL', active: false, points: 0 },
      ],
      HOTELS: [
        { name : 'Phone Number', active: false, points: 0 },
        { name: 'Website URL', active: false, points: 0 },
        { name: 'Hours of Operation', active: false, points: 0 },
        { name: 'Opening Date', active: false, points: 0 },
        { name: 'Additional Categories', active: false, points: 0 },
        { name: 'Attributes selected', active: false, points: 0 },
      ],
      SERVICES: [
        { name : 'Phone Number', active: false, points: 0 },
        { name: 'Website URL', active: false, points: 0 },
        { name: 'Hours of Operation', active: false, points: 0 },
        { name: 'Business Description', active: false, points: 0 },
        { name: 'Opening Date', active: false, points: 0 },
        { name: 'Additional Categories', active: false, points: 0 },
        { name: 'Attributes selected', active: false, points: 0 },
      ],
      OTHER: [
        { name : 'Phone Number', active: false, points: 0 },
        { name: 'Website URL', active: false, points: 0 },
        { name: 'Hours of Operation', active: false, points: 0 },
        { name: 'Business Description', active: false, points: 0 },
        { name: 'Opening Date', active: false, points: 0 },
        { name: 'Additional Categories', active: false, points: 0 },
        { name: 'Attributes selected', active: false, points: 0 },
      ],
    },
  }, // restaurant + 3
  {
    name: 'Listing Status',
    id: 'listingStatus',
    max: {
      RESTAURANTS: 3,
      HOTELS: 3,
      SERVICES: 3,
      OTHER: 3,
    },
    items: {
      RESTAURANTS: [
        { name : 'Verified', active: false, points: 0 },
        { name: 'Updates pending', active: false, points: 0 },
        { name: 'Duplicate listing', active: false, points: 0 },
      ],
      HOTELS: [
        { name : 'Verified', active: false, points: 0 },
        { name: 'Updates pending', active: false, points: 0 },
        { name: 'Duplicate listing', active: false, points: 0 },
      ],
      SERVICES: [
        { name : 'Verified', active: false, points: 0 },
        { name: 'Updates pending', active: false, points: 0 },
        { name: 'Duplicate listing', active: false, points: 0 },
      ],
      OTHER: [
        { name : 'Verified', active: false, points: 0 },
        { name: 'Updates pending', active: false, points: 0 },
        { name: 'Duplicate listing', active: false, points: 0 },
      ],
    }
  },
  {
    name: 'Photos',
    id: 'media',
    max: {
      RESTAURANTS: 13,
      HOTELS: 15,
      SERVICES: 11,
      OTHER: 11
    },
    items: {
      RESTAURANTS: [
        { name : 'Cover', active: false, points: 0 },
        { name: 'Profile', active: false, points: 0 },
        { name: 'Interior', active: false, points: 0 },
        { name: 'Exterior', active: false, points: 0 },
        { name: 'Videos', active: false, points: 0 },
        { name: 'New Image By Owner Last 30 Days', active: false, points: 0 },
        { name: 'Total Quantity', active: false, points: 0 },
        { name: 'Food And Drinks', active: false, points: 0 },
        { name: 'Menu', active: false, points: 0 },
      ],
      HOTELS: [
        { name : 'Cover', active: false, points: 0 },
        { name: 'Profile', active: false, points: 0 },
        { name: 'Interior', active: false, points: 0 },
        { name: 'Exterior', active: false, points: 0 },
        { name: 'Videos', active: false, points: 0 },
        { name: 'New Image By Owner Last 30 Days', active: false, points: 0 },
        { name: 'Total Quantity', active: false, points: 0 },
        { name: 'Food And Drinks', active: false, points: 0 },
        { name: 'Menu', active: false, points: 0 },
        { name: 'Common Area', active: false, points: 0 },
        { name: 'Room', active: false, points: 0 },
      ],
      SERVICES: [
        { name : 'Cover', active: false, points: 0 },
        { name: 'Profile', active: false, points: 0 },
        { name: 'Interior', active: false, points: 0 },
        { name: 'Exterior', active: false, points: 0 },
        { name: 'Videos', active: false, points: 0 },
        { name: 'New Image By Owner Last 30 Days', active: false, points: 0 },
        { name: 'Total Quantity', active: false, points: 0 },
      ],
      OTHER: [
        { name : 'Cover', active: false, points: 0 },
        { name: 'Profile', active: false, points: 0 },
        { name: 'Interior', active: false, points: 0 },
        { name: 'Exterior', active: false, points: 0 },
        { name: 'Videos', active: false, points: 0 },
        { name: 'New Image By Owner Last 30 Days', active: false, points: 0 },
        { name: 'Total Quantity', active: false, points: 0 },
      ],
    }
  }, // restaurant + 2, hotel + 4
  {
    name: 'Content',
    id: 'post',
    max: {
      RESTAURANTS: 8,
      HOTELS: 7,
      SERVICES: 9,
      OTHER: 9
    },
    items: {
      RESTAURANTS: [
        { name : 'Posted in Last 30 days?', active: false, points: 0 },
        { name: 'Used images or video?', active: false, points: 0 },
        { name: 'Used action button?', active: false, points: 0 },
        { name: 'Published an “Offer”?', active: false, points: 0 },
        { name: 'Published an “Event”?', active: false, points: 0 },
        { name: 'Menu added?', active: false, points: 0 },
      ],
      HOTELS: [
        { name : 'Posted in Last 30 days?', active: false, points: 0 },
        { name: 'Used images or video?', active: false, points: 0 },
        { name: 'Used action button?', active: false, points: 0 },
        { name: 'Published an “Offer”?', active: false, points: 0 },
        { name: 'Published an “Event”?', active: false, points: 0 },
      ],
      SERVICES: [
        { name : 'Posted in Last 30 days?', active: false, points: 0 },
        { name: 'Used images or video?', active: false, points: 0 },
        { name: 'Used action button?', active: false, points: 0 },
        { name: 'Published an “Offer”?', active: false, points: 0 },
        { name: 'Published an “Event”?', active: false, points: 0 },
        { name: 'Services Added?', active: false, points: 0 },
        { name: 'Products Added?', active: false, points: 0 },
      ],
      OTHER: [
        { name : 'Posted in Last 30 days?', active: false, points: 0 },
        { name: 'Used images or video?', active: false, points: 0 },
        { name: 'Used action button?', active: false, points: 0 },
        { name: 'Published an “Offer”?', active: false, points: 0 },
        { name: 'Published an “Event”?', active: false, points: 0 },
        { name: 'Services Added?', active: false, points: 0 },
        { name: 'Products Added?', active: false, points: 0 },
      ],
    }
  }, // restaurant + 1, services and other + 2
  {
    name: 'Reputation and Reviews',
    id: 'reputation',
    max: {
      RESTAURANTS: 20,
      HOTELS: 20,
      SERVICES: 20,
      OTHER: 20
    },
    items: {
      RESTAURANTS: [
        { name : 'Average rating', active: false, points: 0 },
        { name: 'Total Number of Reviews', active: false, points: 0 },
        { name: 'Reputation Management', active: false, points: 0 },
        { name: 'New Review Frequency', active: false, points: 0 },
      ],
      HOTELS: [
        { name : 'Average rating', active: false, points: 0 },
        { name: 'Total Number of Reviews', active: false, points: 0 },
        { name: 'Reputation Management', active: false, points: 0 },
        { name: 'New Review Frequency', active: false, points: 0 },
      ],
      SERVICES: [
        { name : 'Average rating', active: false, points: 0 },
        { name: 'Total Number of Reviews', active: false, points: 0 },
        { name: 'Reputation Management', active: false, points: 0 },
        { name: 'New Review Frequency', active: false, points: 0 },
      ],
      OTHER: [
        { name : 'Average rating', active: false, points: 0 },
        { name: 'Total Number of Reviews', active: false, points: 0 },
        { name: 'Reputation Management', active: false, points: 0 },
        { name: 'New Review Frequency', active: false, points: 0 },
      ],
    }
  },
];

export const ORDER_LOCATION_INFO = [
  'locationname',
  'primarycategory',
  'additionalcategories',
  'address',
  'servicearea',
  'regularhours',
  'morehours',
  'specialhours',
  'primaryphone',
  'additionalphones',
  'websiteurl',
  'attributes',
  'profile',
  'openinfo',
  'placeactionlinks',
  'locationstate',
  'updatehaspendings',
  'storecode',
  'labels',
  'adwordslocationextensions'
];
