import { __awaiter, __generator, __values } from "tslib";
// dep
import { AfterViewChecked, ChangeDetectorRef, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, ReplaySubject } from 'rxjs';
import moment from 'moment';
// app
import { ReviewsService } from '../../services/reviews.service';
import { LocationService } from '../../services/location.service';
import { ReportService } from '../../services/report.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from 'src/app/services/auth.service';
import { EventMessageService } from 'src/app/services/event-message.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { LoadingService } from '../../services/loading.service';
import { LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";
var DashboardReviewsComponent = /** @class */ (function () {
    function DashboardReviewsComponent(route, reviewsS, locationS, auth, reportS, cdRef, snackS, em, modalService, subscriptionService, loadingService) {
        var _this = this;
        this.route = route;
        this.reviewsS = reviewsS;
        this.locationS = locationS;
        this.auth = auth;
        this.reportS = reportS;
        this.cdRef = cdRef;
        this.snackS = snackS;
        this.em = em;
        this.modalService = modalService;
        this.subscriptionService = subscriptionService;
        this.loadingService = loadingService;
        this.isShared = false;
        this.lockDates = false;
        this.reportType = 'review';
        this.viewModeChecked = 'legacy';
        this.minDate = null;
        this.maxDate = null;
        this.exporting = false;
        this.reviewsStats = null;
        this.isReportListAccordionOpen = false;
        this.refreshFilter = new ReplaySubject(1);
        this.resume = new Subject();
        // progress events
        this.isProgressResume = true;
        this.isProgressDistribution = true;
        // isProgressResponses = true;
        this.loadingMuchData = false;
        this.loadingAvereageAndReview = false;
        this.rating = null;
        this.first = true;
        this.reviewsData = {};
        this.averageData = {};
        this.locationId = this.route.snapshot.parent.params.locationId;
        this.accountId = this.route.parent.snapshot.params.accountId;
        this.locations = [{ accountId: this.accountId, locationId: this.locationId }];
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    DashboardReviewsComponent.prototype.ngOnInit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var dateValidations, dates, locationData_1;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.reportDataPicker) return [3 /*break*/, 1];
                        this.gid = this.route.snapshot.params.gid;
                        this.isReport = true;
                        this.locations = this.reportDataPicker.locations;
                        this.reportDataPicker.aggregation = this.reportS.correctDateAggregate(this.reportDataPicker);
                        this.dataPicker = this.reportDataPicker;
                        this.selectDate = this.reportS.selectDatesFromDataPicker(this.dataPicker);
                        return [3 /*break*/, 4];
                    case 1:
                        this.gid = (_b = (_a = this.auth) === null || _a === void 0 ? void 0 : _a.session) === null || _b === void 0 ? void 0 : _b.gid;
                        return [4 /*yield*/, this.locationS.getDateValidations('review', [this.accountId], [this.gid], [this.locationId]).toPromise()];
                    case 2:
                        dateValidations = _c.sent();
                        dates = this.locationS.dateValidation(dateValidations);
                        this.minDate = dates.minDate;
                        this.maxDate = dates.maxDate;
                        locationData_1 = {};
                        return [4 /*yield*/, this.locationS.getRef(this.gid, this.accountId, this.locationId).toPromise().then(function (location) { return locationData_1 = location; })];
                    case 3:
                        _c.sent();
                        ;
                        this.selectDate = (this.minDate && this.maxDate && (locationData_1.subscriptionType != LOCATION_SUBSCRIPTION_TYPE.FREE &&
                            locationData_1.subscriptionType != LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) ?
                            { start: this.minDate, end: this.maxDate } :
                            this.locationS.buildDatepickerDate(this.reportType, this.maxDate));
                        _c.label = 4;
                    case 4:
                        this.em.getEmitter().subscribe(function (res) {
                            if (res.name != "DATEPICKER SUBSCRIPTION") {
                                return;
                            }
                            var dateDismiss = null;
                            if (_this.subscription.dismissModalDatePicker != undefined) {
                                if (_this.subscription.dismissModalDatePicker instanceof moment) {
                                    dateDismiss = _this.subscription.dismissModalDatePicker.toDate();
                                }
                                else {
                                    dateDismiss = new Date(_this.subscription.dismissModalDatePicker);
                                }
                            }
                            else {
                                dateDismiss = new Date();
                            }
                            if (new Date() >= dateDismiss) {
                                _this.modalService.openModal(FeaturedDatePickerComponent, {
                                    accountId: _this.accountId,
                                    locationId: _this.locationId
                                }, { onClose: function (res) {
                                        if (!res)
                                            return;
                                        console.log('DashboardReviewsComponent upgrade suscription');
                                        _this.subscriptionService.flowChangeLocationsPlan(_this.subscription, [_this.locations[0]]);
                                    } });
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * this method handle event change in date picker and get data
     */
    DashboardReviewsComponent.prototype.handleChange = function ($event) {
        if (this.isReport && this.first) {
            this.getReviewData(this.dataPicker);
            this.first = false;
        }
        else {
            this.dataPicker = $event;
            this.getReviewData(this.dataPicker);
        }
        this.refreshFilter.next(this.dataPicker);
    };
    /**
     * this method get all data of charts when change date picker
     */
    DashboardReviewsComponent.prototype.getReviewData = function (dataPicker, refresh) {
        var _this = this;
        this.isProgressResume = true;
        this.isProgressDistribution = true;
        // this.isProgressResponses = true;
        if (!this.locations || !this.dataPicker) {
            return;
        }
        this.reviewResume$ = this.locationS.review_summary(this.gid, this.locations).take(2).subscribe(function (resume) {
            var _a;
            if (resume) {
                // this.isFailedResume = false;
                _this.isProgressResume = false;
                if (((_a = resume) === null || _a === void 0 ? void 0 : _a.length) > 1) {
                    _this.resumeManyLocations(resume);
                }
                else {
                    _this.reviewsStats = resume;
                    _this.differenceAverage = resume.difference.average;
                    _this.differenceTotal = resume.difference.total;
                }
                _this.resume.next(_this.reviewsStats);
            }
            else {
                _this.isProgressResume = false;
                // this.isFailedResume = true;
            }
            _this.loadingService.reportAdvanced(1, 'Review Summary');
            refresh && _this.snackS.openSuccess('Finished refreshing! ', 2000);
        });
        this.ratingDistribution$ = this.reviewsS.ratingDistribution(this.locations).take(2).subscribe(function (result) {
            if (result.data) {
                _this.isProgressDistribution = false;
                _this.rating = result.data;
                _this.loadingService.reportAdvanced(1, 'Rating Distribution');
            }
        }, function () {
            _this.isProgressDistribution = false;
        });
        this.getAverageAndReviews();
    };
    DashboardReviewsComponent.prototype.getAverageAndReviews = function () {
        var _this = this;
        var _a, _b, _c;
        this.loadingAvereageAndReview = true;
        this.dataPicker.aggregation = ((_a = this.report) === null || _a === void 0 ? void 0 : _a.aggregation) ? (_b = this.report) === null || _b === void 0 ? void 0 : _b.aggregation : (_c = this.dataPicker) === null || _c === void 0 ? void 0 : _c.aggregation;
        var url = this.isReport ? this.reviewsS.getRatingsAndReviewsReportData(this.reportId, this.dataPicker).take(2) : this.reviewsS.getRatingsAndReviewsData(this.accountId, this.gid, this.locationId, this.dataPicker).take(2);
        url.subscribe(function (res) {
            _this.reviewsData = {
                data: res.reviews,
                labels: res.dates,
                total: res.total_reviews
            };
            _this.averageData = {
                data: res.avg_rating,
                labels: res.dates,
                total: res.total_avg
            };
            _this.loadingAvereageAndReview = false;
        }, function (err) {
            _this.loadingAvereageAndReview = false;
        });
    };
    // TODO: Refactor against protocol-report.component.ts / resumeManyLocations
    DashboardReviewsComponent.prototype.resumeManyLocations = function (resumes) {
        var e_1, _a;
        var _b;
        var stats = { averageRating: 0,
            totalReviewCount: 0,
            answered: 0,
            notAnswered: 0,
            googleResume: { totalReviewCount: 0 }
        };
        var difference = { average: 0,
            total: 0 };
        var length = resumes.length;
        try {
            for (var resumes_1 = __values(resumes), resumes_1_1 = resumes_1.next(); !resumes_1_1.done; resumes_1_1 = resumes_1.next()) {
                var r = resumes_1_1.value;
                if (!r || r.totalReviewCount === 0) {
                    if (length > 0)
                        length--;
                    continue;
                }
                difference.total += r.difference.total;
                difference.average += r.difference.average;
                stats.averageRating += r.averageRating;
                stats.totalReviewCount += r.totalReviewCount;
                stats.answered += r.answered;
                stats.notAnswered += r.notAnswered;
                stats.googleResume.totalReviewCount += ((_b = r.googleResume) === null || _b === void 0 ? void 0 : _b.totalReviewCount) || 0;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (resumes_1_1 && !resumes_1_1.done && (_a = resumes_1.return)) _a.call(resumes_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        stats.averageRating /= (length || 1);
        difference.average /= (length || 1);
        this.differenceTotal = difference.total;
        this.differenceAverage = difference.average;
        this.reviewsStats = stats;
        this.isProgressResume = false;
    };
    DashboardReviewsComponent.prototype.ngAfterViewChecked = function () {
        this.detectChanges();
    };
    DashboardReviewsComponent.prototype.detectChanges = function () {
        if (this.cdRef !== null &&
            this.cdRef !== undefined &&
            !this.cdRef.destroyed) {
            this.cdRef.detectChanges();
        }
    };
    DashboardReviewsComponent.prototype.ngOnDestroy = function () {
        var _a, _b, _c, _d;
        (_a = this.ratingDistribution$) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        // this.aggregationResponses$?.unsubscribe();
        (_b = this.keywordsByStat$) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        (_c = this.googleReviewsWithLastMonth$) === null || _c === void 0 ? void 0 : _c.unsubscribe();
        (_d = this.reviewResume$) === null || _d === void 0 ? void 0 : _d.unsubscribe();
        this.resume.unsubscribe();
    };
    DashboardReviewsComponent.prototype.handleRefresh = function () {
        this.getReviewData(this.dataPicker, true);
    };
    DashboardReviewsComponent.prototype.isExporting = function (value) {
        this.exporting = value;
    };
    DashboardReviewsComponent.prototype.exportCSV = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var reportId, accountId, locationId, type;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (event != 'csv' && event != 'csv-review')
                            return [2 /*return*/];
                        reportId = this.isReport ? this.reportId : null;
                        accountId = !this.isReport ? this.accountId : null;
                        locationId = !this.isReport ? this.locationId : null;
                        this.isProgressCSV = true;
                        type = event === 'csv' ? 'review' : 'review_list';
                        return [4 /*yield*/, this.reportS.handleExportCsvMongo(reportId, this.gid, accountId, locationId, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation, type)];
                    case 1:
                        _a.sent();
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return DashboardReviewsComponent;
}());
export { DashboardReviewsComponent };
