import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ModalUrlComponent } from "../../modal-url/modal-url.component";

@Component({
  selector: 'app-location-url',
  templateUrl: './location-url.component.html',
  styleUrls: ['./location-url.component.scss']
})
export class LocationUrlComponent implements OnInit {
  @Input() url: any;
  @Input() isEdit = true;
  @Input() isUnderReview = false;
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();

  companyUrl: any;


  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
  }


  // edit website
  openUrlDialog() {
    const dialogRef = this.dialog.open(ModalUrlComponent, {
      width: '680px',
      data: {
        companyUrl: this.url
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != '') {
        this.companyUrl = result?.companyUrl;
        this.url = this.companyUrl?.website;
        this.onUpdate.emit(true);
      }
    });

    dialogRef.disableClose = true;
  }

}
