// dep
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { Observable, of, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// app
import { ModalOpenDateComponent } from '../modal-date/modal-open-date.component';
import { ModalStoreCodeComponent } from '../modal-store-code/modal-store-code.component';
import { ModalLabelsComponent } from '../modal-labels/modal-labels.component';
import { ModalGoogleAdsComponent } from '../modal-google-ads/modal-google-ads.component';
import { ModalPhotosComponent } from '../modal-photos/modal-photos.component';
import { LocationService } from '../../services/location.service';
import { GoogleService } from '../../services/google.service';
import { LocationEditService } from '../../services/location-edit.service';
import { DatesService } from '../../services/dates.service';
import { ModalFetchComponent } from '../modal-fetch/modal-fetch.component';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import { ModalService } from '../../services/modal.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from '../../services/auth.service';
import { ServiceList } from 'src/app/constants/google/service-list';
import SavedLocation from 'src/app/constants/firestore/saved-location';
import { WhiteLabelService } from 'src/app/services/white-label.service';

export interface LockHistory {
  date: any;
  action: string;
  status: string;
}

@Component({
  selector: 'app-dashboard-location',
  templateUrl: './dashboard-location.component.html',
  styleUrls:  ['./dashboard-location.component.scss'],

})
export class DashboardLocationComponent implements OnInit, OnDestroy {
  // custom pagination with page numbers
  @Input() page: number;
  @Input() totalPages: number;
  // events for custom pagination
  @Output() goPrev = new EventEmitter<boolean>();
  @Output() goNext = new EventEmitter<boolean>();
  @Output() goPage = new EventEmitter<boolean>();

  @ViewChild(MatPaginator, { read: MatPaginator, static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { read: MatSort, static: true }) sort: MatSort;

  public locationInfoTabs = ['General'];
  public isProgressPush: boolean = false;
  public name: string;
  public value: string;
  public locationName: string;
  public primaryCategory: any;
  public additionalCategories: any;
  public address: any;
  public regularHours: any[];
  public specialHours: any[];
  public moreHours: any[];
  public moreHoursLabels: any[];
  public primaryPhone: string;
  public additionalPhones: any;
  public serviceArea: {};
  public companyUrl: any;
  public url: string;
  public countSpecialHours = 0;
  public countRegularHours = 0;
  public countMoreHours = 0;
  public openingDate: any;
  public storeCode: string;
  public labels: any;
  public attributes: any;
  public socialMediaAttributes = []
  public attributesEnabled: boolean;
  public actionLinks = [];
  // data for table
  public displayedColumns: string[];
  public dataSource: any;
  // custom pagination elements
  public manualPage: number;
  public errorMessage: boolean;
  public priceList: any;
  public serviceList: ServiceList[] = [];
  public selected = new FormControl(0);
  public readonly locationId: any;
  public products: any[];
  public location: any;
  public noData: boolean;
  public dateConvert: boolean;
  public locked: boolean;
  public pageable: Pageable = { page: 1, size: 10 };
  public pagination: Pagination;
  public lockProgress: boolean;
  public adWordsPhone: any;
  public progress = true;
  public showPendingChangesMsg = false;
  public location$: Observable<SavedLocation> = of(null);
  public regionCode = 'US';
  public unexpectedError = "Unexpected Error. Please try again or contact Map Labs for support."
  public fetchProgress: boolean;
  public urlAttributes: any;
  public readonly accountId: any;
  public locationType;
  public companyName;
  public pendingMask = [];
  public diffMask = [];
  public locationDiffMask = {};

  private _getByPlaceId$: Subscription;
  private _push$: Subscription;
  private _fetch$: Subscription;
  private _listLockHistory$: Subscription;
  private _media: any;

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _locationS: LocationService,
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _googleS: GoogleService,
    private _locationEditS: LocationEditService,
    private _dateS: DatesService,
    private _modal: ModalService,
    private _snack: SnackbarService,
    private _changeDetector: ChangeDetectorRef,
    private _WhiteLabelS: WhiteLabelService
  ) {
    this.locationId = this._route.snapshot.parent.params.locationId;
    this.accountId = this._route.snapshot.parent.params.accountId;
  }

  ngOnInit() {
    this.initData();
  }

  async initData() {
    this._WhiteLabelS.branding.then(res => this.companyName = res['company_name']);
    this.location$ = this._locationS.location;
    this._locationS.get(this._auth.session.gid, this.accountId, this.locationId);
    await this.getPendingMask();
    this.getData();
    this._updateTable();
  }

   getData(): void {
    this._getByPlaceId$ = this._locationS.getRef(this._auth.session.gid, this.accountId, this.locationId).subscribe(result => {
      if (result === undefined) {
        this.noData = true;
        this.progress = false;
        return;
      }

      if ('serviceList' in result) {
        this.serviceList = result.serviceList;
      }
      if (!result.location.priceLists) 
        result.location.priceLists = []
      if (!Object.keys(result).includes('locationEdit')) {
        this._locationS.initLocationEdit(this._auth.session.gid, this.accountId, this.locationId, result.location).subscribe(resultEdit => {
          this._setLocationProperties(resultEdit);
        });
      } else {
        this._setLocationProperties(result);
      }
      this.progress = false;
    }, error => {
      this.progress = false;
      if (error.status === 0) {
        this.noData = true;
      }
    });
  }

  private _setLocationProperties(result):void {
    this.progress = false;
    if (!result) {
      return;
    }
    this.regionCode = this._locationS.getRegionCode(result);
    this.location = result.locationEdit;
    this.location.search_url = result?.search_url;
    this.location = { ...this.location, locationState: result.location.locationState ? result.location.locationState : {} }
    this.primaryCategory = this.location.primaryCategory;

    if (!this.locationInfoTabs.some(l => l === 'Media')) {
      this.locationInfoTabs.push('Media');
    }

    this._locationS.getByPrimaryCategory(this._auth.session.gid, this.accountId, this.locationId).subscribe(res => {
      this.locationType = res;
    });
    this._locationEditS.setLocationEdit(result.locationEdit, this._auth.session.gid, this.accountId, this.locationId);


    this.locked = result.lockedOn;
    this.noData = false;
    this.locationName = this.location.locationName;

    this.additionalCategories = this.location.additionalCategories;
    this.address = this.location.address ? this.location.address : null;
    this.serviceArea = this.location.serviceArea ? this.location.serviceArea : {};
    this.regularHours = this.location.regularHours;
    this.dateConvert = false;
    this.specialHours = this.location.specialHours;
    this.primaryPhone = this.location.primaryPhone;
    this.additionalPhones = this.location.additionalPhones;
    this.storeCode = this.location.storeCode;
    this.url = this.location.websiteUrl;
    this.buildAttributes();
    this.actionLinks = this.location.placeActionLinks;
    this.moreHours = this.location.moreHours || [];

    this.countSpecialHours = this.specialHours ? this.specialHours['specialHourPeriods'].length : 0;
    this.countRegularHours = this.regularHours ? this.regularHours['periods'].length : 0;
    this.countMoreHours = this.moreHours ? this.moreHours.length : 0;

    this.getMoreHoursLabels();


    if (this.location.openInfo) {
      if (this.location.openInfo.openingDate) {
        this.openingDate = this._dateS.dateJsonToDate(this.location.openInfo.openingDate);
      }
    }
    if (typeof this.location.priceLists === 'undefined') {
      this._locationEditS.update(this._auth.session.gid, this.accountId, this.locationId, { ...this.location, priceLists: [] })
    }
    this.priceList = this.location.priceLists ? this.location.priceLists : [];
    this.labels = this.location.labels;

    if (this.location.adWordsLocationExtensions) {
      this.adWordsPhone = this.location.adWordsLocationExtensions.adPhone;
    }
  }

  buildAttributes() {
    if (!this.location || !this.location.attributes) {
      this.attributes = [];
      this.socialMediaAttributes = [];
      return;
    }
    const clonedAttributes = [...this.location.attributes];
    this.attributes = clonedAttributes.filter(el => !this._locationEditS.isSocialMedia(el));
    this.socialMediaAttributes = clonedAttributes.filter(el => this._locationEditS.isSocialMedia(el));
  }

  async getPendingMask(): Promise<void> {
    await this._locationS.getPendingMask(this._auth.session.gid, this.accountId, this.locationId).then(res => {      
      if (res?.pendingMask) {
        const pendingMaskData = typeof(res.pendingMask) != 'string' ? [] : res.pendingMask.split(',');
        pendingMaskData?.forEach(el => {
          const field = this.getFields(el);
          this.pendingMask.push(field);
        })
      }

      if (res?.diffMask) {        
        const diffMaskData = res.diffMask?.split(',');
        diffMaskData?.forEach(el => {
          el = el.replaceAll(' ','');
          const levels = el.includes('.') ? el.split('.') : [el];
          const displayName = el == 'title' ? 'location Name' : levels[levels?.length - 1].replace(/([a-z])([A-Z])/g, '$1 $2');
          
          let value = res.location;

          levels?.forEach(n => {
            value = value?.[n] ? value[n] : null;
          });

          const fields = {
            key: el,
            displayName:  displayName,
            value: value || null
          };

          this.diffMask.push(fields);
        })
        this.locationDiffMask = res.location;
      }
    });
  }

  showDiffMaskChanges(): void {
    /*
    const differences = [];
    let existInLocation = false;

    this.diffMask?.forEach(el => {

      const key = el.key;
      const levels = key.includes('.') ? key.split('.') : [key];
      let newData = this.locationDiffMask;
      let dataLoc = this.location;

      let data = {
        key: this.getKey(el.key),
        googleLocation: null,
        location: null
      };

      levels?.forEach(n => {
        existInLocation = dataLoc[n] && newData[n] ? true : false;
        dataLoc = dataLoc[n];
        newData = newData[n];
      });

      if (existInLocation) {
        data.googleLocation = newData;
        data.location = dataLoc;
        differences.push(data);
      }
    });
     */

    const result = {
      difference: [],
      action: 'Review Updates from Google',
      isDiffMask: true
    }

    this.openFetchDialog(result, false);
   
  }

  /*getKey(level):string {
    let key;
    
    switch(level) {
      case 'phoneNumbers.primaryPhone':
        key = 'primaryPhone';
        break;
      case 'phoneNumbers.additionalPhones':
        key = 'additionalPhones';
        break;
      case 'openInfo.openingDate':
        key = 'openInfo';
        break;
      case 'storefrontAddress':
        key = 'address';
        break;
      default:
        key = key;
        break;
    }
    return key;
  }*/

  getFields(field): string {
    let value;
    
    switch(field) {
      case 'title':
        value = 'location name';
        break;
      case 'storefrontAddress':
        value = 'address';
        break;
      case 'regularHours':
        value = 'regular hours';
        break;
      case 'moreHours':
        value = 'more hours';
        break;
      case 'specialHours':
        value = 'special hours';
        break;
      case 'phoneNumbers':
        value = 'phone numbers';
        break;
      case 'websiteUri':
        value = 'Website';
        break;
      case 'openInfo.openingDate':
        value = 'opening date & status';
        break;
      case 'storeCode':
        value = 'store code';
        break;
      case 'adWordsLocationExtensions':
        value = 'google ads location extensions phone';
        break;
      case 'labels':
        value = 'labels';
        break;
      case 'categories':
        value = 'categories';
        break;
    }
    return value;
  }

  getPartialError(row): string {
    var msg: string
    const errorType = row?.error?.error
    if (errorType && typeof errorType === 'object'){
      if ("STALE_DATA" in errorType){
        const field = this.getFields(errorType?.STALE_DATA);
        msg = `We removed the field '${field}' from the update because Google is temporarily blocking the push. This happens from time to time due to Google's API limitations. Based on our experience and metrics, this issue will resolve itself within 48 hours. If you require this data on your Google Business Profile sooner, please update it natively and then Fetch it into Map Labs.`
      } else if ("LAT_LNG" in errorType){
        msg = "We removed the field 'address' because Google rejected it. Google said that the location (pin location) has changed. You can try updating the pin location in the native Google Business Profile or contact Map Labs for support."
      } else if ("THROTTLED" in errorType){
        const field = this.getFields(errorType?.THROTTLED);
        msg = `We removed the field '${field}' from the update because Google is temporarily blocking the push due to making too many updates. This happens from time to time due to Google's API limitations. Based on our experience and metrics, this issue will resolve itself within 48 hours. If you require this data on your Google Business Profile sooner, please update it natively and then Fetch it into Map Labs.`
      } else if ("PLACE_ACTION_LINKS" in errorType){
        msg = `Some links couldn’t be updated due to similarities or incorrect formatting, which resulted in an error. This issue occurs because the links may be too similar to each other or may contain errors. We recommend trying to fetch the links again. Please note that this limitation is due to constraints within Google’s API, which may prevent certain updates from being processed successfully. If you require this data on your Google Business Profile sooner, please update it natively and then Fetch it into Map Labs.`
      }
    }
      
    return msg
  }

  getMoreHoursLabels(): void {
    this.moreHoursLabels = this.location.primaryCategory?.moreHoursTypes || [];
    this.location?.additionalCategories?.map(el => {
      el?.moreHoursTypes?.forEach(h => {
        const hasLabel =  this.moreHoursLabels.find(l => l.hoursTypeId == h.hoursTypeId) ? true : false;
        if (!hasLabel) { this.moreHoursLabels.push(h) }
      });
    });
  }

  updateLocation(event): void {
    if (event) {
      this.progress=true;
      this.getData();
      this._updateTable();
    }
  }

  GoBack(): void {
    this._router.navigate(['/accounts', this.accountId, 'locations'])
  }

  // edit opening date
  openDateDialog(): void {
    const dialogRef = this._dialog.open(ModalOpenDateComponent, {
      width: '680px',
      data: {
        status: this.location.openInfo.status,
        openingDate: this.location.openInfo.openingDate
      }
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(res => {
      if (res != '') this.updateLocation(true);
    });
  }

  // edit photos
  openPhotosDialog(): void {
    const dialogRef = this._dialog.open(ModalPhotosComponent, {
      width: '60%',
      data: {
        locationId: this.locationId,
        accountId: this.accountId,
        media: this._media,
        typeLocation: this.locationType
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.updateLocation(true);
    });
  }

  // edit store code
  openStoreCodeDialog(): void {
    const dialogRef = this._dialog.open(ModalStoreCodeComponent, {
      width: '680px',
      data: {
        storeCode: this.storeCode
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res != '') this.updateLocation(true);
    });
  }

  // edit labels
  openLabelsDialog(): void {
    const dialogRef = this._dialog.open(ModalLabelsComponent, {
      width: '680px',
      data: {
        list: this.labels
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result != '') {
        this.labels = result?.labelList;
        this.updateLocation(true);
      }
    });
  }

  // edit google ads phone
  openGoogleAdsDialog(): void {
    const dialogRef = this._dialog.open(ModalGoogleAdsComponent, {
      width: '680px',
      panelClass: 'overflow--visible',
      data: {
        googleAds: this.adWordsPhone
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res != '') this.updateLocation(true);
    });
  }

  // TABLE
  _updateTable(): void {
    // data for table
    this.lockProgress = true;
    this.dataSource = null;
    this.displayedColumns = ['date', 'action', 'changes', 'status'];
    this._listLockHistory$ = this._googleS.listLockHistory(this.accountId, this.locationId, this.pageable).subscribe(
      result => {
        this.lockProgress = false;
        this.pagination = result;
        this.dataSource = new MatTableDataSource<LockHistory>(result.items);
      }, error => {
        this.lockProgress = false;
      }
    );
  }

  fetchDifference(history?: boolean): void {
    this.fetchProgress = true;
    this._fetch$ = this._googleS.fetchDifference(this.accountId, this.locationId).subscribe(result => {
      this.fetchProgress = false;
      this.openFetchDialog(result, history);
    }, error => {
      console.log(error);
      this.fetchProgress = false;
      this._snack.openError('failed to fetch', 4000);
    });
  }

  openFetchDialog(result, history, notifyErrors = false): void {
    const dialogRef = this._dialog.open(ModalFetchComponent, {
      width: '1000px',
      data: {
        pendingMask: result?.isDiffMask ? [] : this.pendingMask,
        diffMask: result?.isDiffMask ? this.diffMask : [],
        isDiffMask: result?.isDiffMask ? result?.isDiffMask : false,
        title: result?.action == 'pull' ? 'fetch' : result.action,
        difference: result?.difference,
        placeId: this.locationId,
        history,
        notifyErrors,
        moreHoursLabels: this.moreHoursLabels
      }
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(refresh => {
      if (refresh) {
        this.updateLocation(true);
        this._updateTable();
      }
    });
  }


  lock(): void {
    this.progress = true;
    this._modal.openConfirmModal('Are you sure you want to lock this location?',
      'Locking a location will periodically update GBP with the locked data. Any changes you make directly in GBP will be overwritten.',
      res => {
        if (!res) {
          return;
        }

        this._locationS.update(this._auth.session.gid, this.accountId, this.locationId, { lockedOn: new Date() }).toPromise().then(loc => {
          this._googleS.saveLockHistory(this.accountId, this.locationId, 'locked', 'success').subscribe(result => {
            this.initData();
          });
          this._snack.openSuccess('Location successfully locked!', 2000);
        }, err => {
          this._snack.openError('There was a problem locking this location. Please try again later or contact support.', 2000);
          console.error('Error locking a location', err);
        });
      }, 2);
  }

  unlock(): void {
    this.progress = true;
    this._locationS.update(this._auth.session.gid, this.accountId, this.locationId, { lockedOn: null }).toPromise().then(loc => {
      this._snack.openSuccess('Location successfully unlocked!', 2000);
      this._googleS.saveLockHistory(this.accountId, this.locationId, 'unlock', 'success').subscribe(result => {
        this.initData();
      });
    }, err => {
      this._snack.openError('There was a problem unlocking this location. Please try again later or contact support.', 2000);
      console.error('Error unlocking a location', err);
    });
  }

  reloadPaginator($event: any): void {
    this.pageable = $event;
    this._updateTable();
  }

  ngOnDestroy(): void {
    this._getByPlaceId$?.unsubscribe();
    this._push$?.unsubscribe();
    this._fetch$?.unsubscribe();
    this._listLockHistory$?.unsubscribe();
  }

  pushGoogle(): void {
    this.isProgressPush = true;
    this._push$ = this._googleS.push(this.accountId, this.locationId).pipe(take(1)).subscribe(
      (data) => {
        if (!data) 
          return
        else if (data.data.success == 200) {
          this.isProgressPush = false;
          if (data.message) this._snack.openSuccess(data.message, 4000);
          else this._snack.openSuccess(data.data.message, 4000);
        } else if (data.data.success == 400 && data.message === 'attributes') {
          this.fetchProgress = true;
          this._fetch$ = this._googleS.fetchDifference(this.accountId, this.locationId).take(1).subscribe(result => {
            this.isProgressPush = false;
            this.fetchProgress = false;
            this.openFetchDialog(result, undefined, true);
          }, error => {
            this.isProgressPush = false;
            this.fetchProgress = false;
            const msg = error?.data?.message || this.unexpectedError
            this._snack.openError(msg, data?.data?.timeout);
          });
        } else {
          this.isProgressPush = false;
          const msg = data?.data?.message ? data.data.message : this.unexpectedError
          this._snack.openError(msg, data?.data?.timeout);
        }
        this.initData();
      }, error => {
        this.isProgressPush = false;
        const msg = error?.data?.message ? error.data.message : this.unexpectedError
        this._snack.openError(msg, error?.data?.timeout);
        console.error(error);
      }
    );
  }

  handleUrls($event: any): void {
    this.urlAttributes = $event;
  }

  showChanges(element: any): void {
    this.openFetchDialog(element, true);
  }

  dispatchAction(action): void {
    switch (action) {
      case 'lock':
        this.lock();
        break;
      case 'unlock':
        this.unlock();
        break;
      case 'pushGoogle':
        this.pushGoogle();
        break;
      case 'fetchDifference':
        this.fetchDifference()
        break;
      default:
        break;
    }
  }

  hasPendingMask(section): boolean {
    if(this.pendingMask.length == 0) { return false }

    return this.pendingMask.find(el => el == section) ? true : false;
  }

  ngAfterViewChecked(): void {
    this._changeDetector.detectChanges();
  }

  formatStatus(status): string {
    if (status != 'OPEN') {
      status = status.split('_');
      status = status.join(' ');
    }
    return status;
  }

  hasOpeningData(): boolean {
    return (Object.keys(this.location.openInfo.openingDate).length > 0)
  }
}
