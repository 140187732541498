<div class="row box--height mt--50">
  <!-- Total Keywords -->
  <div class="col-12 col-sm-6 col-lg-4 mb--30">
    <div class="box box--shadow box--rounded bg--white pb--20 pt--20">
      <section class="pl--20 pr--20 mb--25">
        <h2 class="txt--md"><strong>Total Keywords</strong></h2>
        <ngx-skeleton-loader count="1" [theme]="{ width: '136px', height: '48px'}"></ngx-skeleton-loader>
      </section>
      <mat-divider></mat-divider>
      <div class="pl--20 pr--20 pt--40">
        <div class="txt--center mb--40">
          <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '150px', height: '150px'}"></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader class="pt--40" count="2" [theme]="{ width: '490px', height: '20px', 'margin-top': '10px', display:'block'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  
  <!-- Est. Total impressions -->
  <div class="col-12 col-sm-6 col-lg-8 mb--30">
    <div class="box box--shadow box--rounded bg--white pb--20 pt--20">
      <section class="pl--20 pr--20 mb--25">
        <h2 class="txt--md"><strong>Est. Total impressions</strong></h2>
        <ngx-skeleton-loader count="1" [theme]="{ width: '340px', height: '48px'}"></ngx-skeleton-loader>
      </section>
      <mat-divider></mat-divider>
      <div class="pl--20 pr--20 pt--40">
        <ngx-skeleton-loader class="pt--40" count="1" [theme]="{ width: '100%', height: '200px', 'margin-top': '10px', display:'block'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <!-- Keywords Table -->
  <div class="col-4 mb--30">
    <div class="box box--shadow box--rounded bg--white">
      <section class="p--20">
        <h2 class="m--0 mb--10"><strong>Direct vs. Discovery</strong></h2>
        <div class="txt--align--center">
          <ngx-skeleton-loader class="mr--90" count="1" [theme]="{ width: '4%', height: '150px'}"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '4%', height: '150px'}"></ngx-skeleton-loader>
        </div>
      </section>
      <mat-divider></mat-divider>
      <section class="p--20">
        <h2 class="m--0 mb--10"><strong>Keyword Trends</strong></h2>
      </section>
      <table class="table table--sm table--last-row-border mt--10 p--20" aria-describedby="keywords">
        <thead>
          <tr>
            <th class="mat-header-cell txt--left w--10"><strong>No.</strong></th>
            <th class="mat-header-cell txt--center"><strong>Keywords Details</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr class="mat-row" *ngFor="let i of [0,1,2]">
            <td class="mat-cell cdk-column-subscription">
              <ngx-skeleton-loader count="1" [theme]="{ width: '50px', height: '20px'}"></ngx-skeleton-loader>
            </td>
            <td class="mat-cell cdk-column-subscription txt--center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <div class="col-8 mb--30">
    <div class="box box--shadow box--rounded bg--white">
      <section class="p--20">
        <h2 class="m--0 mb--10"><strong>Keywords</strong></h2>
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '58px'}"></ngx-skeleton-loader>
      </section>
      <mat-divider></mat-divider>
      <table class="table table--sm table--last-row-border mt--10 p--20" aria-describedby="keywords">
        <thead>
          <tr>
            <th class="mat-header-cell txt--left"><strong>Keywords</strong></th>
            <th class="mat-header-cell txt--center"><strong>Number of Impressions</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr class="mat-row" *ngFor="let i of [0,1,2]">
            <td class="mat-cell cdk-column-subscription">
              <ngx-skeleton-loader count="1" [theme]="{ width: '800px', height: '20px'}"></ngx-skeleton-loader>
            </td>
            <td class="mat-cell cdk-column-subscription txt--center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Multi Locations Table -->
  <div class="col-12 mb--30">
    <div class="box box--shadow box--rounded bg--white p--20">
      <h2 class="m--0 mb--10"><strong>Multi Locations Table</strong></h2>
      <table class="table table--sm table--last-row-border mt--20" aria-describedby="keywords-multiloc">
        <thead>
          <tr>
            <th class="mat-header-cell txt--left"><strong>Location</strong></th>
            <th class="mat-header-cell txt--center"><strong>Total Impressions</strong></th>
            <th class="mat-header-cell txt--center"><strong>Total Keywords</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr class="mat-row" *ngFor="let i of [0,1,2]">
            <td class="mat-cell cdk-column-subscription">
              <ngx-skeleton-loader count="1" [theme]="{ width: '850px', height: '20px'}"></ngx-skeleton-loader>
            </td>
            <td class="mat-cell cdk-column-subscription txt--center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
            </td>
            <td class="mat-cell cdk-column-subscription txt--center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>


  
