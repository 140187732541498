import { OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ModalUrlComponent } from "../../modal-url/modal-url.component";
var LocationUrlComponent = /** @class */ (function () {
    function LocationUrlComponent(dialog) {
        this.dialog = dialog;
        this.isEdit = true;
        this.isUnderReview = false;
        this.onUpdate = new EventEmitter();
    }
    LocationUrlComponent.prototype.ngOnInit = function () {
    };
    // edit website
    LocationUrlComponent.prototype.openUrlDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalUrlComponent, {
            width: '680px',
            data: {
                companyUrl: this.url
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            var _a, _b;
            if (result != '') {
                _this.companyUrl = (_a = result) === null || _a === void 0 ? void 0 : _a.companyUrl;
                _this.url = (_b = _this.companyUrl) === null || _b === void 0 ? void 0 : _b.website;
                _this.onUpdate.emit(true);
            }
        });
        dialogRef.disableClose = true;
    };
    return LocationUrlComponent;
}());
export { LocationUrlComponent };
