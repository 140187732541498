
<div id="charts-container">
  <!--filter-->
  <app-reports-filter
    [reportName]="reportName"
    [locations]="locations"
    [selectedDateFromDashboard]="selectDate"
    [aggregation]="report?.aggregation"
    (dataPicker)="handleChange($event)"
    (refresh)="handleRefresh()"
    [type]="reportType"
    [isShared]="isShared"
    [lockDates]="lockDates"
    [accountId]="accountId"
    (export)="exportCSV($event)"
    (isExporting)="isExporting($event)"
    [minDate]="minDate"
    [maxDate]="maxDate">
  </app-reports-filter>

    <mat-progress-bar mode="indeterminate" *ngIf="isProgressCSV"></mat-progress-bar><!-- //NOSONAR -->

  <div class="container--full container--padding-lg" >
    <h2 *ngIf='reportName'><strong>{{reportName|uppercase}}</strong></h2>
    <!--review insight content-->
    <div class="box box--rounded box--shadow bg--white mb--30" *ngIf="noData">
      <div class="box__header d-flex align-items-center justify-content-center">
        <h2 class="txt--capitalize"><strong>No reviews data</strong></h2>
      </div>
    </div>

    <div class="row box--height">
      <!--overall star rating-->
      <div class="col-12 col-sm-6 col-lg-3 mb--30">
        <div class="box box--padding-xxs box--shadow box--rounded bg--white">
          <h2 class="txt--capitalize">
            <strong>overall star rating</strong>
          </h2>

          <app-skeleton-reviews [flagHarderLoad]="loadingMuchData" type="OVERALL-RATING"
                                *ngIf="isProgressResume && !reviewsStats && loadingAvereageAndReview">
          </app-skeleton-reviews>

          <div class="d-flex justify-content-between flex-column"
               *ngIf="reviewsStats">

            <div class="d-flex align-items-center justify-content-start mb--25">
              <p class="txt--xxxl txt--lightblue fw--medium m--0">
                {{reviewsStats.averageRating| number : '1.1-1'}}
              </p>
              <app-starts type="average" [starts]="reviewsStats.averageRating">
              </app-starts>

            </div>
          </div>
          <p class="txt--capitalize txt--lightgray"
             *ngIf="!reviewsStats && !isProgressResume">
            <strong>No data</strong>
          </p>
        </div>
      </div>

      <!--total reviews-->
      <div class="col-12 col-sm-6 col-lg-3 mb--30">
        <div class="box box--padding-xxs box--shadow box--rounded bg--white">
          <h2 class="txt--capitalize">
            <strong>total reviews</strong>

              <span class="txt--red"
              *ngIf="reviewsStats && reviewsStats.googleResume && reviewsStats.totalReviewCount !== reviewsStats.googleResume.totalReviewCount"
              matTooltip="Total Reviews is slightly different from your public Google profile. Differences can be for various reasons but are typically due to latency from the Google Business Profile API. Since we store a local copy of your review data, it is possible that Google or a user removed a review from your Google Business Profile, and our database hasn’t reflected this change yet. Please contact our support team for advanced troubleshooting if this number is significantly different from what you see on your public Google profile."> * </span>
          </h2>

          <app-skeleton-reviews [flagHarderLoad]="loadingMuchData" type="TOTAL-REVIEWS"
                                *ngIf="isProgressResume && !reviewsStats">
          </app-skeleton-reviews>


          <div class="d-flex justify-content-between flex-column"
               *ngIf="reviewsStats">
            <div class="d-flex align-items-center justify-content-start mb--25">
              <p
                class="txt--xxxl txt--lightblue fw--medium m--0">
                {{reviewsStats.totalReviewCount | number: '1.0-0'}}
              </p>

            </div>

          </div>
          <p class="txt--capitalize txt--lightgray"
             *ngIf="!reviewsStats && !isProgressResume">
            <strong>No data</strong>
          </p>
        </div>
      </div>

      <!--rating distribution-->
      <div class="col-12 col-sm-6 col-lg-3 mb--30">
        <div class="box box--padding-xxs box--shadow box--rounded bg--white">
          <h2 class="txt--capitalize mb--10"><strong>rating distribution</strong></h2>

          <app-skeleton-reviews [flagHarderLoad]="loadingMuchData" type="RATING-DISTRIBUTION"
                                *ngIf="isProgressDistribution && !rating">
          </app-skeleton-reviews>

          <div class="rating-distribution"
               *ngIf="rating">
            <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
              <span class="txt--md">5</span>
              <span class="single-star" *ngIf="rating.FIVE.count == 0"></span>
              <span class="single-star single-star--full" *ngIf="rating.FIVE.count > 0"></span>
              <mat-progress-bar mode="determinate" [value]="rating.FIVE.percent" color="primary"></mat-progress-bar>
              <span class="rating--percentage txt--md">
                {{rating.FIVE.percent | number:'1.0-0'}}%
                <sub *ngIf="rating.FIVE.count != ''" matTooltipPosition="above" [matTooltip]="rating.FIVE.count| number: '1.0-0'" class="txt--lightgray"> ({{rating.FIVE.count <= 99999 ? (rating.FIVE.count| shortNumber: "1.0-0" ) : '+9.9K' }})</sub>
                <sub *ngIf="rating.FIVE.count == ''" class="txt--lightgray"> (0)</sub>
              </span>
            </div>
            <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
              <span class="txt--md">4</span>
              <span class="single-star" *ngIf="rating.FOUR.count == 0"></span>
              <span class="single-star single-star--full" *ngIf="rating.FOUR.count > 0"></span>
              <mat-progress-bar mode="determinate" [value]="rating.FOUR.percent"
                                color="primary"></mat-progress-bar>
              <span class="rating--percentage txt--md">
                {{rating.FOUR.percent| number:'1.0-0'}}%
                <sub *ngIf="rating.FOUR.count != ''" matTooltipPosition="above" [matTooltip]="rating.FOUR.count| number: '1.0-0'"  class="txt--lightgray">({{rating.FOUR.count <= 99999 ? (rating.FOUR.count | shortNumber:'1.0-0') : '+9.9K'}})</sub>
                <sub *ngIf="rating.FOUR.count == ''" class="txt--lightgray"> (0)</sub>
              </span>
            </div>
            <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
              <span class="txt--md">3</span>
              <span class="single-star" *ngIf="rating.THREE.count == 0"></span>
              <span class="single-star single-star--full" *ngIf="rating.THREE.count > 0"></span>
              <mat-progress-bar mode="determinate" [value]="rating.THREE.percent"
                                color="primary"></mat-progress-bar>
              <span class="rating--percentage txt--md">
                {{rating.THREE.percent| number:'1.0-0'}}%
                <sub *ngIf="rating.THREE.count != ''" matTooltipPosition="above" [matTooltip]="rating.THREE.count| number: '1.0-0'"  class="txt--lightgray">({{rating.THREE.count <= 99999 ? (rating.THREE.count | shortNumber:'1.0-0') : '+9.9K'}})</sub>
                <sub *ngIf="rating.THREE.count == ''" class="txt--lightgray"> (0)</sub>
              </span>
            </div>

            <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
              <span class="txt--md">2</span>
              <span class="single-star" *ngIf="rating.TWO.count == 0"></span>
              <span class="single-star single-star--full" *ngIf="rating.TWO.count > 0"></span>
              <mat-progress-bar mode="determinate" [value]="rating.TWO.percent"
                                color="primary"></mat-progress-bar>
              <span class="rating--percentage txt--md">
                {{rating.TWO.percent| number:'1.0-0'}}%
                <sub *ngIf="rating.TWO.count != ''" matTooltipPosition="above" [matTooltip]="rating.TWO.count| number: '1.0-0'" class="txt--lightgray">({{rating.TWO.count <= 99999 ? (rating.TWO.count | shortNumber:'1.0-0') : '+9.9K'}})</sub>
                <sub *ngIf="rating.TWO.count == ''" class="txt--lightgray"> (0)</sub>
              </span>
            </div>

            <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
              <span class="txt--md">1</span>
              <span class="single-star" *ngIf="rating.ONE.count == 0"></span>
              <span class="single-star single-star--full" *ngIf="rating.ONE.count > 0"></span>
              <mat-progress-bar mode="determinate" [value]="rating.ONE.percent"
                                color="primary"></mat-progress-bar>
              <span class="rating--percentage txt--md">
                {{rating.ONE.percent| number:'1.0-0'}}%
                <sub *ngIf="rating.ONE.count != ''"  matTooltipPosition="above" [matTooltip]="rating.ONE.count| number: '1.0-0'" class="txt--lightgray">({{rating.ONE.count <= 99999 ? (rating.ONE.count|shortNumber:'1.0-0') : '+9.9K'}})</sub>
                <sub *ngIf="rating.ONE.count == ''" class="txt--lightgray"> (0)</sub>
              </span>
            </div>
          </div>
          <p class="txt--capitalize txt--lightgray"
             *ngIf="!isProgressDistribution && !rating">
            <strong>No data</strong>
          </p>
        </div>
      </div>

      <!--review responses-->
      <div class="col-12 col-sm-6 col-lg-3 mb--30">
        <div class="box box--padding-xxs box--shadow box--rounded bg--white">
          <h2 class="txt--capitalize mb--15"><strong>review responses</strong></h2>

          <app-skeleton-reviews [flagHarderLoad]="loadingMuchData" type="REVIEW-RESPONSES"
                                *ngIf="!reviewsStats">
          </app-skeleton-reviews>

          <ng-container *ngIf="reviewsStats">
            <div class="review-response review-response--success mb--15">
            <span>
            <i  class="far fa-check-circle icon-respose" data-html2canvas-ignore="true"></i>
            </span>

              <p class="txt--md m--0">Responded</p>
              <span class="review-response--num">{{reviewsStats.answered|number:'1.0-0'}}</span>
            </div>
            <div class="review-response review-response--error">
              <i class="far fa-times-circle icon-respose" data-html2canvas-ignore="true"></i>
              <p class="txt--md m--0">Unresponded</p>
              <span class="review-response--num">{{reviewsStats.notAnswered|number:'1.0-0'}}</span>
            </div>
          </ng-container>
        </div>
      </div>

      <!--average star rating-->
      <div class="col-12 col-lg-6 mb--30">
        <app-skeleton-reviews [flagHarderLoad]="loadingMuchData" type="AVERAGE-STAR-RATING" *ngIf="loadingAvereageAndReview && !reviewsStats"></app-skeleton-reviews>

        <app-reviews-average-rating [dataPicker]="dataPicker"
                                    [refreshFilter]="refreshFilter.asObservable()"
                                    [locations]="locations"
                                    [averageData]="averageData"
                                    [tooltip]="'This number does not include removed reviews.'"
                                    *ngIf="locations && dataPicker && !loadingAvereageAndReview && reviewsStats">
        </app-reviews-average-rating>
      </div>

      <!--number of new reviews-->
      <div class="col-12 col-lg-6 mb--30">
        <app-skeleton-reviews [flagHarderLoad]="loadingMuchData" type="NUMBER-NEW-REVIEWS" *ngIf="loadingAvereageAndReview && !reviewsStats"></app-skeleton-reviews>
        <app-reviews-number [dataPicker]="dataPicker"
                            [refreshFilter]="refreshFilter.asObservable()"
                            [locations]="locations"
                            [reviewsData]="reviewsData"
                            [tooltip]="'This number does not include removed reviews.'"
                            *ngIf="locations && dataPicker && !loadingAvereageAndReview && reviewsStats">
        </app-reviews-number>
      </div>

      <div *ngIf='reportName && locations && locations.length > 1' class="col-12 mb--30">
        <app-review-report-table [reportName]="reportName" [locations]="locations" [dataPicker]="dataPicker" [isShared]="isShared" [refreshFilter]="refreshFilter.asObservable()"></app-review-report-table>
      </div>

      <!--filters-->
      <div class="col-12 mb--30">
        <app-reports-filter-table [isReport]="isReport" [reportName]="reportName" [isExporting]="exporting" [placeId]="locations" [isShared]="isShared" [refreshFilter]="refreshFilter.asObservable()"></app-reports-filter-table>
      </div>
    </div>
  </div>

</div>

