import { CanActivate, Router } from '@angular/router';
import { AuthService } from "./auth.service";
import { ModalTrialEnded } from '../modules/modal-trial-ended/modal-trial-ended.component';
import { ModalService } from './modal.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth.service";
import * as i3 from "./modal.service";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(_router, _auth, _modalService) {
        var _this = this;
        this._router = _router;
        this._auth = _auth;
        this._modalService = _modalService;
        this._modalTrialEndedOpened = false;
        this.subscription$ = this._auth.subscription$;
        this.subscription$.subscribe(function (subscription) {
            var _a, _b;
            _this.subscription = subscription;
            if (((_a = subscription) === null || _a === void 0 ? void 0 : _a.status) === 'BLOCKED') {
                var message = void 0;
                var buttons = void 0;
                switch ((_b = _this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) {
                    case 1:
                    case 2: {
                        message = 'Schedule a call with us to upgrade your account and continue accessing Map Labs!';
                        buttons = {
                            'accept_icon': 'far fa-calendar',
                            'accept_text': 'Schedule Call',
                            'accept_cta': 'https://calendly.com/maplabs/discovery-call',
                            'cancel_text': 'Logout',
                        };
                        _this._openModal(buttons, message);
                        break;
                    }
                    case 3: {
                        message = 'Reach out to our sales team to upgrade your account and continue accessing Map Labs!';
                        buttons = {
                            'accept_icon': 'fas fa-paper-plane',
                            'accept_text': 'Contact Sales',
                            'accept_cta': 'mailto:sales@maplabs.com',
                            'cancel_text': 'Logout',
                        };
                        _this._openModal(buttons, message);
                        break;
                    }
                }
            }
        });
    }
    AuthGuardService.prototype._openModal = function (buttons, message) {
        if (!this._modalTrialEndedOpened) {
            this._modalService.openModal(ModalTrialEnded, {
                title: 'Your Free Subscription Has Ended',
                message: message,
                buttons: buttons
            }, {
                config: {
                    width: '750px',
                    disableClose: true
                }
            });
            this._modalTrialEndedOpened = true;
        }
    };
    AuthGuardService.prototype.canActivate = function () {
        var _a, _b;
        if (((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.status) === "BLOCKED") {
            var message = void 0;
            var buttons = void 0;
            switch ((_b = this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) {
                case 1:
                case 2: {
                    message = 'Schedule a call with us to upgrade your account and continue accessing Map Labs!';
                    buttons = {
                        'accept_icon': 'far fa-calendar',
                        'accept_text': 'Schedule Call',
                        'accept_cta': 'https://calendly.com/maplabs/discovery-call',
                        'cancel_text': 'Logout',
                    };
                    this._openModal(buttons, message);
                    break;
                }
                case 3: {
                    message = 'Reach out to our sales team to upgrade your account and continue accessing Map Labs!';
                    buttons = {
                        'accept_icon': 'fas fa-paper-plane',
                        'accept_text': 'Contact Sales',
                        'accept_cta': 'mailto:sales@maplabs.com',
                        'cancel_text': 'Logout',
                    };
                    this._openModal(buttons, message);
                    break;
                }
            }
        }
        if ((!this._auth.authenticated && !this._auth.session))
            return false;
        return true;
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.ModalService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
