import { OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ReviewsService } from '../../services/reviews.service';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../services/loading.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
var ReviewsNumberComponent = /** @class */ (function () {
    function ReviewsNumberComponent(reviewsS, loadingService, router, auth) {
        this.reviewsS = reviewsS;
        this.loadingService = loadingService;
        this.router = router;
        this.auth = auth;
        this.tooltip = null;
        this.isProgressNumber = true;
        this.destroySubs$ = new Subject();
    }
    ReviewsNumberComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        (_a = this.refreshFilter) === null || _a === void 0 ? void 0 : _a.pipe(takeUntil(this.destroySubs$)).subscribe(function (result) {
            _this.isProgressNumber = true;
            _this.dataPicker = result;
            _this.getData();
        });
        this.getData();
    };
    ReviewsNumberComponent.prototype.ngOnChanges = function (changes) {
        if (changes.reviewsData) {
            this.getData();
        }
    };
    ReviewsNumberComponent.prototype.getData = function () {
        var _this = this;
        var isReport = this.router.url.includes('report');
        if (this.reviewsData) {
            this.buildData(this.reviewsData);
        }
        else {
            if (isReport) {
                this.aggregationNumber$ = this.reviewsS.aggregationNumber(this.locations, this.dataPicker)
                    .pipe(takeUntil(this.destroySubs$))
                    .subscribe(function (result) {
                    _this.buildData(result);
                });
            }
            else {
                this.aggregationNumber$ = this.reviewsS.getRatingsAndReviewsData(this.locations[0].accountId, this.auth.session.gid, this.locations[0].locationId, this.dataPicker)
                    .pipe(takeUntil(this.destroySubs$)).subscribe(function (result) {
                    _this.buildData({
                        data: result.reviews,
                        labels: result.dates,
                        total: result.total_reviews
                    });
                });
            }
        }
    };
    ReviewsNumberComponent.prototype.buildData = function (result) {
        var _a, _b;
        // Remove extra days
        if (this.dataPicker.aggregation === 'Day') {
            var start = moment(this.dataPicker.range.start);
            var end = moment(this.dataPicker.range.end);
            var days = end.diff(start, 'days') + 1;
            var dropValues = ((_a = result.labels) === null || _a === void 0 ? void 0 : _a.length) - days;
            result['data'] = _.dropRight(result.data, dropValues);
            result['labels'] = _.dropRight(result.labels, dropValues);
        }
        this.datasetBarNumber = [{ data: result.data, label: 'Number' }];
        this.labelsBarRating = result.labels;
        if ((_b = result) === null || _b === void 0 ? void 0 : _b.total) {
            this.total = result.total;
        }
        else {
            this.total = this.getTotal(this.datasetBarNumber[0].data);
        }
        this.isProgressNumber = false;
        this.loadingService.reportAdvanced(1, 'Number Reviews');
    };
    /**
     * this method get total sum of a array
     */
    ReviewsNumberComponent.prototype.getTotal = function (array) {
        var _a;
        var total = 0;
        (_a = array) === null || _a === void 0 ? void 0 : _a.forEach(function (x) {
            total += x;
        });
        return total;
    };
    ReviewsNumberComponent.prototype.ngOnDestroy = function () {
        var _a;
        this.destroySubs$.next(true);
        (_a = this.destroySubs$) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    };
    return ReviewsNumberComponent;
}());
export { ReviewsNumberComponent };
