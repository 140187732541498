import { PipeTransform } from '@angular/core';
var BuildPercentageFormatPipe = /** @class */ (function () {
    function BuildPercentageFormatPipe() {
    }
    BuildPercentageFormatPipe.prototype.transform = function (value, round) {
        if (round === void 0) { round = true; }
        var _a;
        if (value) {
            value = (_a = value) === null || _a === void 0 ? void 0 : _a.toString().split(',').join('');
            value = Number(value);
        }
        else {
            value = 0;
        }
        if (round) {
            value = Math.round(value);
        }
        value = value < 0 ? Math.abs(value) : value;
        if (value) {
            value = Number(value).toLocaleString();
        }
        return value + "%";
    };
    return BuildPercentageFormatPipe;
}());
export { BuildPercentageFormatPipe };
