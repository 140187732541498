<!--PROTOCOL TABLE-->
<app-skeleton-protocol *ngIf="(progress | async)"></app-skeleton-protocol>

<ng-container *ngIf="!(progress | async)">
    <div class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center">
        <div class="input-field input-field--search">
            <div class="table__icon table__icon--lg">
                <i class="fas fa-search"></i>
            </div>
            <input (input)="applyFilter($event.target.value, $event)" [(ngModel)]="searchText" placeholder="Type to search">
        </div>
    </div>

    <ng-container *ngIf="protocolsExist; else protocolsEmpty">
        <table mat-table matSort [dataSource]="dataSource" class="table table--lg table--last-row-border">

            <ng-container matColumnDef="id" *ngIf="!isScan">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggleProtocol() : null"
                                [checked]="selectionProtocol.hasValue() && isAllSelectedProtocol()"
                                [indeterminate]="selectionProtocol.hasValue() && !isAllSelectedProtocol()"
                                [aria-label]="checkboxLabelProtocol()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" [style.width]="getSizeColumn('id')">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selectionProtocol.toggle(row) : null"
                                [checked]="selectionProtocol.isSelected(row)"
                                [aria-label]="checkboxLabelProtocol(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="name" *ngIf="isDisplayField('name')">
                <th mat-header-cell *matHeaderCellDef><strong>Name</strong></th>
                <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('name')">
                    <div class="cell__content flex-column align-items-start justify-content-center">
                        <p class="txt--darkgray m--0">{{ element?.name }}</p>
                        <button mat-flat-button color="accent" [matMenuTriggerFor]="locationMenu"
                                class="d-flex align-items-center btn btn--badge btn--badge--sm txt--uppercase txt--xs">
                            {{ countLocations(element)  || 0 }} locations <i class="fas fa-angle-down ml--10 mr--0"></i>
                        </button>
                        <mat-menu #locationMenu="matMenu" class="mat-menu--lg">
                            <ng-container *ngFor="let account of element?.accounts">
                            <button class="d-flex align-items-center" mat-menu-item *ngFor="let location of account.locations">
                                <span class="mr--5" *ngIf="location?.address || location?.location?.address || location?.serviceArea?.places?.placeInfos">{{location?.locationName}} -</span>
                                    <gmb-address 
                                        [address]="location?.address || location?.location?.address" 
                                        [serviceAreas]="location?.serviceArea?.places?.placeInfos">
                                    </gmb-address>
                            </button>
                            </ng-container>
                        </mat-menu>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="status" *ngIf="isDisplayField('status')">
                <th mat-header-cell *matHeaderCellDef><strong>Replied/Unreplied</strong></th>
                <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('status')">
                    <div class="cell__content">
                        <div class="progress-label">
                            <mat-progress-bar
                            color="primary"
                            mode="determinate"
                            [value]="progressValue(element)">
                            </mat-progress-bar>
                            <p
                            class="progress-label__txt {{ (element?.status.replied / element?.status.total) < 0.6 ? 'txt--black' : 'txt--white' }}"> {{ element?.status.replied}}
                            /{{element?.status.total }} </p>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="triggers" *ngIf="isDisplayField('triggers')">
                <th mat-header-cell *matHeaderCellDef><strong>Triggers</strong></th>
                <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('triggers')">
                    <div class="cell__content flex-column align-items-start justify-content-center">
                        <app-starts [starts]="star" class="stars--xs" [showEmpty]="false"
                                    *ngFor="let star of element?.stars"></app-starts>
                        <p class="txt--darkgray m--0" *ngIf="element?.withComment">With comments</p>
                        <p class="txt--darkgray m--0" *ngIf="element?.withOutComment">Without comments</p>
                        <p class="txt--darkgray m--0" *ngIf="element?.triggers.delay > 0">Delay: {{ element?.triggers.delay }}</p>
                        <p class="txt--darkgray m--0" *ngIf="element?.triggers.keywords.length > 0">
                            Keywords: {{ element?.triggers.keywords.join(' ') }}
                        </p>
                        <p class="txt--darkgray m--0" *ngIf="element?.triggers.excludeKeywords && element?.triggers.excludeKeywords.length > 0">
                          Exclude Keywords: {{ element?.triggers.excludeKeywords.join(', ') }}
                        </p>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="enable" *ngIf="isDisplayField('enable')">
                <th mat-header-cell *matHeaderCellDef><strong>Enable</strong></th>
                <td mat-cell *matCellDef="let element;index as i" [style.width]="getSizeColumn('enable')">
                    <div class="cell__content">
                    <mat-slide-toggle (change)="toggleEnable($event, i, element)"
                                        color="accent"
                                        [checked]="element?.enabled">
                    </mat-slide-toggle>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="retroactive" *ngIf="isDisplayField('retroactive')" >
                <th mat-header-cell *matHeaderCellDef><strong>Retroactive</strong></th>
                <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('retroactive')">
                    <div class="cell__content">
                        <button (click)="toggleValue(element)" mat-flat-button
                                [disabled]="loadingEnabled"
                                [color]="!element?.retroactive ? 'primary' : 'accent'"
                                class="btn btn--xs btn--width-sm txt--uppercase">
                            {{ !element?.retroactive ? 'run' : 'stop' }} retroactive <i class="ml--10 fas fa-{{ !element?.retroactive ? 'play' : 'pause' }}"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="action-btn" *ngIf="isDisplayField('action-btn')">
                <th mat-header-cell *matHeaderCellDef class="txt--right"><strong>Actions</strong></th>
                <td mat-cell *matCellDef="let element" class="txt--right column--sm" [style.width]="getSizeColumn('action-btn')">
                    <div class="cell__content">
                        <button mat-flat-button color="primary" (click)="newProtocol(true, element)" class="btn btn-icon mr--10">
                            <i class="fas fa-pen"></i>
                        </button>
                        <span class="alternate-theme d-flex">
                            <button mat-flat-button color="primary" class="btn btn-icon" (click)="delete(element)">
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="with-padding no-hover"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="with-padding no-hover"></tr>

        </table>
        <app-paginator [pagination]="pagination" (reload)="handleReload($event)"></app-paginator>
    </ng-container>
    <ng-template #protocolsEmpty>
        <app-no-data message="No available {{selectedLogs == 0 ? 'protocols' : 'scans'}}"></app-no-data>
    </ng-template>
</ng-container>
