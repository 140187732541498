import { __assign, __awaiter, __generator } from "tslib";
// dep
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
// app
import { InsightsService } from '../../services/insights.service';
import { ChartService } from '../../services/chart.service';
import { DatesService } from '../../services/dates.service';
import { ReportService } from '../../services/report.service';
import { SnackbarService } from '../../services/snackbar.service';
import { EventMessageService } from "../../services/event-message.service";
import { ModalService } from "../../services/modal.service";
import { SubscriptionService } from "../../services/subscription.service";
import { AuthService } from '../../services/auth.service';
import { LocationService } from '../../services/location.service';
import { LoadingService } from '../../services/loading.service';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";
import { CHART_GREEN_COLOR, CHART_RED_COLOR } from '../../constants/chart-color';
var DashboardInsightsComponent = /** @class */ (function () {
    function DashboardInsightsComponent(insightS, route, router, chartS, dateS, auth, reportS, snackS, em, modalService, subscriptionService, locationService, loadingService) {
        var _this = this;
        this.insightS = insightS;
        this.route = route;
        this.router = router;
        this.chartS = chartS;
        this.dateS = dateS;
        this.auth = auth;
        this.reportS = reportS;
        this.snackS = snackS;
        this.em = em;
        this.modalService = modalService;
        this.subscriptionService = subscriptionService;
        this.locationService = locationService;
        this.loadingService = loadingService;
        this.shared = false;
        this.lockDates = false;
        this.changeDataPicker = new EventEmitter();
        this.stats = [];
        this.isProgress = true;
        this.multiChartDataSet = [];
        this.typeReport = null;
        this.colorsViewChart = [
            CHART_GREEN_COLOR,
            CHART_RED_COLOR,
        ];
        this.first = true;
        this.isProgressCSV = false;
        this.modalSubscription = new Subject();
        this.viewModeOptions = [];
        this.viewModeChecked = 'legacy';
        this.accountId = this.route.parent.snapshot.params.accountId;
        this.locationId = this.route.parent.snapshot.params.locationId;
        this.locations = [{ accountId: this.accountId, locationId: this.locationId }];
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
        this.localRoute = route;
    }
    DashboardInsightsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.reportDataPicker) {
            this.typeReport = 'rollup';
            this.isReport = true;
            this.locationId = this.reportDataPicker.locations;
            this.reportDataPicker.aggregation = this.reportS.correctDateAggregate(this.reportDataPicker);
            this.dataPicker = this.reportDataPicker;
            this.selectDate = this.reportS.selectDatesFromDataPicker(this.dataPicker);
            this.locations = this.dataPicker.locations;
            return;
        }
        else {
            this.typeReport = 'rollup';
            this.selectDate = {
                start: moment('12-31-2022', 'MM-DD-YYYY').subtract(3, 'month'),
                end: moment('12-31-2022', 'MM-DD-YYYY')
            };
        }
        this.em.getEmitter().pipe(takeUntil(this.modalSubscription)).subscribe(function (res) {
            var _a;
            if (res.name != "DATEPICKER SUBSCRIPTION")
                return;
            var dateDismiss = ((_a = _this.subscription.dismissModalDatePicker) === null || _a === void 0 ? void 0 : _a.toDate()) || new Date();
            if (new Date() >= dateDismiss) {
                _this.modalService.openModal(FeaturedDatePickerComponent, {
                    accountId: _this.accountId,
                    locationId: _this.locationId
                }, { onClose: function (res) {
                        if (!res)
                            return;
                        console.log('DashboardInsightsComponent upgrade suscription');
                        _this.subscriptionService.flowChangeLocationsPlan(_this.subscription, [_this.locationUpgraded[0]]);
                    } });
            }
        });
        // TODO: Here locationId is always a single element array? Note `${[1]}` == "1"
        this.locationService.getRef(this.auth.session.gid, this.accountId, "" + this.locationId).toPromise().then(function (ref) { return _this.locationUpgraded = [{ accountId: _this.accountId, locationId: ref }]; });
    };
    DashboardInsightsComponent.prototype.getInsightData = function (dataPicker, refresh) {
        var _this = this;
        this.resetData();
        this.insights$ = this.insightS.getInsights(this.locations, dataPicker).take(2).subscribe(function (res) {
            var _a, _b;
            _this.popularStats = res.popularTime;
            _this.isProgress = false;
            if (((_b = (_a = res) === null || _a === void 0 ? void 0 : _a.labels) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                _this.labels = res.labels;
                _this.stats = _this.orderingStats(res.stats);
                _this.multiChartDataSet = _this.handleMultiChart(_this.dataPicker);
            }
            else {
                _this.isNoData = true;
            }
            _this.loadingService.reportAdvanced(1, 'Insights');
            refresh && _this.snackS.openSuccess('Finished refreshing! ', 2000);
        }, function () {
            _this.isNoData = true;
            _this.isProgress = false;
        });
    };
    DashboardInsightsComponent.prototype.exportCSV = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isProgressCSV = true;
                        return [4 /*yield*/, this.reportS.handleExportCsvFire(this.locations, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation.toLowerCase(), this.reportName)];
                    case 1:
                        _a.sent();
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardInsightsComponent.prototype.handleExport = function ($event) {
        if ($event === 'csv') {
            this.exportCSV();
        }
    };
    DashboardInsightsComponent.prototype.handleDataPicker = function ($event) {
        // Init get data
        if (this.isReport && this.first) {
            this.first = false;
            this.getInsightData(this.dataPicker);
            return;
        }
        if (!this.dataPicker) {
            this.dataPicker = __assign({}, $event);
            this.getInsightData(this.dataPicker);
            return;
        }
        // when multiChart Change
        this.dataPicker.multiChart = $event.multiChart;
        this.multiChartDataSet = this.handleMultiChart(this.dataPicker);
        // ranges or aggregation changes
        if (this.dataPicker.range !== $event.range || this.dataPicker.aggregation !== $event.aggregation) {
            if (this.dataPicker.aggregation === 'month') {
                var endMonth = this.dateS.stringToDate(this.dataPicker.range.end).getMonth();
                var startMonth = this.dateS.stringToDate(this.dataPicker.range.start).getMonth();
                if (startMonth === endMonth) {
                    this.dataPicker.aggregation = 'day';
                }
            }
            this.dataPicker = __assign({}, $event);
            this.getInsightData(this.dataPicker);
            // Calendar change event here
        }
        this.dataPicker.locations = this.locations;
        this.changeDataPicker.emit(this.dataPicker);
    };
    DashboardInsightsComponent.prototype.handleMultiChart = function (dataPicker) {
        return this.chartS.multiCharset(this.stats, dataPicker);
    };
    DashboardInsightsComponent.prototype.resetData = function () {
        this.labels = [];
        this.stats = [];
        this.isProgress = true;
    };
    DashboardInsightsComponent.prototype.clearChecked = function (event) {
        this.multiChartDataSet = [];
        this.checks = event;
    };
    DashboardInsightsComponent.prototype.statusChecks = function (event) {
        this.checks = event;
    };
    DashboardInsightsComponent.prototype.ngOnDestroy = function () {
        var _a;
        (_a = this.insights$) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.modalSubscription.next(true);
        this.modalSubscription.unsubscribe();
    };
    DashboardInsightsComponent.prototype.handleRefresh = function () {
        this.getInsightData(this.dataPicker, true);
    };
    DashboardInsightsComponent.prototype.orderingStats = function (stats) {
        var orderedData = [];
        var order = [
            "Actions on your Google Business Profile",
            "Search impressions",
            "total views on search",
            "Google Posts Activity",
            "Photo views",
            "Photo quantity"
        ];
        order.forEach(function (el) {
            var item = stats.find(function (s) { var _a; return ((_a = s) === null || _a === void 0 ? void 0 : _a.title) == el; });
            if (item) {
                orderedData.push(item);
            }
        });
        return orderedData;
    };
    DashboardInsightsComponent.prototype.hasNoDate = function () {
        var _a, _b, _c;
        var createdData = this.locationUpgraded ? moment((_c = (_b = (_a = this.locationUpgraded[0]) === null || _a === void 0 ? void 0 : _a.locationId) === null || _b === void 0 ? void 0 : _b.createdAt) === null || _c === void 0 ? void 0 : _c.$date) : null;
        var hasMoreThan24hs = createdData ? this.insightS.hasMoreThan24hs(createdData) : true;
        return hasMoreThan24hs ? 'No insights data' : 'The performance insights for this business profile are still being imported, please refresh and check again in 10 minutes';
    };
    DashboardInsightsComponent.prototype.changedViewMode = function (value) {
        this.router.navigateByUrl("/account/" + this.accountId + "/location/" + this.locationId + "/insights");
    };
    return DashboardInsightsComponent;
}());
export { DashboardInsightsComponent };
