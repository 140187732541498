      <!--number of new reviews-->

        <div class="box box--rounded box--shadow bg--white">
          <div class="box__header">
            <h2 class="txt--capitalize d-flex align-items-center">
              <strong>number of new reviews:
                <ngx-skeleton-loader count="1" [theme]="{ width: '25px',height: '25px','margin-bottom': '0', 'transform':'translateY(5px)'}"
                                     *ngIf="isProgressNumber"> </ngx-skeleton-loader>
                <span class="txt--green" *ngIf="!isProgressNumber">{{total | number: '1.0-2'}}</span>
              </strong>
              <span *ngIf="tooltip"
                  [matTooltip]="tooltip"
                  matTooltipPosition="above"
                  matTooltipClass="mat-tooltip--override"
                  class="mat-tooltip tooltip tooltip--info">
                  <em class="fas fa-info"></em>
              </span>
            </h2>
          </div>
          <div class="box__content box--padding-xxs">

           <app-skeleton-reviews type="NUMBER-NEW-REVIEWS" *ngIf="isProgressNumber"></app-skeleton-reviews>

          <app-chart-line
            type="COLORS-FULL"
            *ngIf="!isProgressNumber"
            [showLegend]="false"
            [labels]="labelsBarRating"
            [dataset]="datasetBarNumber"
            [dataPicker]="dataPicker"
            chartType="bar">

          </app-chart-line>

          </div>
        </div>
