<div class="row box--height">
  <!--Actions on your Google Business Profile & total Photo Views on your Google Business Profile-->
  <div class="col-12 mb--30">
    <div class="box box--rounded box--shadow bg--white">
      <div class="box__header d-flex align-items-center justify-content-start" *ngIf='titleIcon'>
        <h2 class="txt--capitalize"><strong>{{stats.title}}</strong></h2>
      </div>
      <div class="box__content box--padding-xxs">
        <div class="row {{ type == 'vertical' ? 'm--0' : '' }}">
          <ng-container *ngIf="type!='vertical'; else vertical">
            <div class="col-12">
              <app-chart-line [commaNumbers]="true" [dataset]="dataset" [labels]="labels" [dataPicker]="dataPicker"
                              *ngIf='line && labels' [title]="stats.title" [colors]="colors" [noDecimals]="true"></app-chart-line>
            </div>

            <ng-container *ngIf="stats.title !='Photo views' && stats.title !='Photo quantity'">
              <div class="col-md-5">
                <app-chart-donut *ngIf='dataset.length > 1' [dataset]="dataset" [colorChange]="type"></app-chart-donut>
                <div class="col-lg-12 txt--center">
                  <p class="txt--xxxl-sm txt--lightblue fw--medium m--0">{{stats?.totals |  number: '1.0-0' }}</p>
                  <p class="txt--md txt--lightgray fw--medium">Total</p>
                </div>
              </div>
              <div class="col-md-7" *ngIf="stats.title !='Photo views' && stats.title !='Photo quantity' && dataset.length > 1">
                <app-chart-progress [total]="data?.total" [label]="data.label" [bgColor]="data.backgroundColor"
                                    *ngFor="let data of dataset"></app-chart-progress>
              </div>
            </ng-container>

          </ng-container>
          <ng-template #vertical>

            <div class="col-6" *ngFor="let data of dataset">
              <app-chart-progress [total]="data?.total" [label]="data.label" [bgColor]="data.backgroundColor"
                                  type="revenue"></app-chart-progress>
            </div>
            <div class="col-12 txt--center">
              <p class="txt--xxxl-sm txt--lightblue fw--medium m--0">{{stats?.totals |  number: '1.0-0' }}</p>
              <p class="txt--md txt--lightgray fw--medium">Total</p>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>
