import { __awaiter, __generator } from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import moment from 'moment';
import { Pagination } from 'src/app/constants/api-response';
import { AuthService } from 'src/app/services/auth.service';
import { InsightsService } from 'src/app/services/insights.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PdfService } from 'src/app/services/pdf.service';
import { ReportService } from 'src/app/services/report.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
var MultilocationsInfoTableComponent = /** @class */ (function () {
    function MultilocationsInfoTableComponent(router, auth, loadingService, pdfS, reportS, afsStorage, snack, insightS) {
        var _this = this;
        this.router = router;
        this.auth = auth;
        this.loadingService = loadingService;
        this.pdfS = pdfS;
        this.reportS = reportS;
        this.afsStorage = afsStorage;
        this.snack = snack;
        this.insightS = insightS;
        this.isEmpty = true;
        this.isProgressCSV = false;
        this.isLoading = true;
        this.dataSource = new MatTableDataSource([]);
        this.pagination = Pagination;
        this.title = 'multi locations table';
        this.hasExportPDF = false;
        this.hasExportCSV = false;
        this.hasScroll = false;
        this.isSticky = false;
        this.isShared = false;
        this.isLegacy = false;
        this.reportId = null;
        this.reportName = null;
        this.handleMultilocSort = new EventEmitter();
        this.handleMultilocPaginate = new EventEmitter();
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    MultilocationsInfoTableComponent.prototype.ngOnInit = function () {
        var _a;
        if (!((_a = this.displayedColumns) === null || _a === void 0 ? void 0 : _a.length)) {
            this.setDisplayColumns();
        }
        this.loadingService.reportAdvanced(1, 'Insights Resume');
        this.isLoading = false;
    };
    MultilocationsInfoTableComponent.prototype.setDisplayColumns = function () {
        if (this.router.url.includes('reviews')) {
            this.displayedColumns = ['rating', 'date', 'status', 'reviewer', 'actions'];
            this.isSingleReview = false;
        }
        else {
            if (this.isShared) {
                this.displayedColumns = ['company', 'rating', 'date', 'status', 'reviewer'];
            }
            else {
                this.displayedColumns = ['company', 'rating', 'date', 'status', 'reviewer', 'actions'];
            }
            this.isSingleReview = true;
        }
    };
    MultilocationsInfoTableComponent.prototype.locationUrl = function (accountId, locationId, destiny) {
        return "/account/" + accountId + "/location/" + locationId + "/" + destiny;
    };
    MultilocationsInfoTableComponent.prototype.sortBy = function ($event) {
        this.sort = {
            sortBy: $event.active,
            direction: $event.direction
        };
    };
    MultilocationsInfoTableComponent.prototype.handleSort = function (event) {
        this.handleMultilocSort.emit(event);
    };
    MultilocationsInfoTableComponent.prototype.handleReload = function (event) {
        this.handleMultilocPaginate.emit(event);
    };
    MultilocationsInfoTableComponent.prototype.buildName = function (col) {
        return col.replaceAll('_', ' ');
    };
    MultilocationsInfoTableComponent.prototype.exportPDF = function () {
        this.pdfS.exportTableToPDF('#table-location-container', 'Multilocations-PDF', 'Multi Locations Table');
    };
    MultilocationsInfoTableComponent.prototype.exportCSV = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var date;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                        this.isProgressCSV = true;
                        if (!!this.isLegacy) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.reportS.resumeExportCsv((_b = (_a = this.auth) === null || _a === void 0 ? void 0 : _a.session) === null || _b === void 0 ? void 0 : _b.gid, this.reportType, this.sort, this.dataPicker, this.reportName).subscribe(function (result) {
                                _this.afsStorage.ref(result['data']).getDownloadURL().toPromise().then(function (filename) {
                                    _this.insightS.downloadFromGS(filename);
                                    _this.isProgressCSV = false;
                                    _this.snack.openSuccess("The file exported successfully", 2000);
                                });
                            })];
                    case 1:
                        _c.sent(),
                            function (err) {
                                _this.isProgressCSV = false;
                                console.error(err);
                                _this.snack.openError("The file export failed. Please try again later.", 2000);
                            };
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    MultilocationsInfoTableComponent.prototype.typeElement = function (element) {
        return typeof (element);
    };
    return MultilocationsInfoTableComponent;
}());
export { MultilocationsInfoTableComponent };
