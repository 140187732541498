import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Pagination } from 'src/app/constants/api-response';
import { COLORS_CHARTS } from 'src/app/constants/chart-color';
import { forkJoin, Subject } from 'rxjs';
import { Pageable } from 'src/app/constants/pageable';
import { SearchKeywordsService } from 'src/app/services/search-keywords.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReportService } from 'src/app/services/report.service';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PaginatorComponent } from '../../charts/paginator/paginator.component';
import { ModalService } from 'src/app/services/modal.service';
import { AlertType } from 'src/app/components/alert.component';
import { LocationService } from 'src/app/services/location.service';
import { DataPicker } from 'src/app/constants/data-picker';
@Component({
  selector: 'app-search-keywords',
  templateUrl: './search-keywords.component.html',
  styleUrls: ['./search-keywords.component.scss']
})
export class SearchKeywordsComponent implements OnInit {
  @ViewChild(PaginatorComponent, {static: false}) paginatorChild:PaginatorComponent;
  @Input() dataPicker: DataPicker;
  @Input() isReport: boolean = false;
  @Input() report;
  @Input() isShared = false;
  @Input() minDate = null;
  @Input() maxDate = null;

  public loading = true;
  public updatingData = false
  public gid: string;
  public reportId: string;
  public accountId: string;
  public locationId: string;
  public location: { accountId: string; locationId: string }[];
  public locations = [];
  public dataSourceMultiLoc = new MatTableDataSource([]);
  public selectDate: any = {};
  public searchKeywordsData: any = {};
  public totalKeywordsData: any = {};
  public totalImpressionsData: any = {};
  public searchImpressionsData: any = {};
  public keywordsDatasource = new MatTableDataSource([]);
  public displayedColumnsKeywords = ['keywords', 'impressions'];
  public paginate: Pageable = {size: 25, page: 1};
  public pagination; paginationMultiloc: Pagination = {
    items: [],
    per_page: this.paginate.size,
    page: 1,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public dataRange = { value: 'prevYearComparison', displayName: 'Previous Year'};
  public colors = COLORS_CHARTS;
  public labels = [];
  public sort = {
    sortBy: 'impressions',
    sortOrder: -1,
    sortDirection: 'desc'
  };
  public sortMultiloc = {
    sortBy: 'locationName',
    direction: 'asc'
  };
  public displayedColumnsMultLoc = ['location', 'Unique Keywords', 'Keywords with > 15 Impressions', 'Keywords with < 15 Impressions', 'Est. Total impressions'];
  public fieldsColumnsMultiLoc = [
    { displayName:'locationName', fieldSort: 'locationName' },
    { displayName:'totalKeywords', fieldSort: 'totalKeywords' },
    { displayName:'more15Keywords', fieldSort: 'more15Keywords' },
    { displayName:'less15Keywords', fieldSort: 'less15Keywords' },
    { displayName:'estTotalimpressions', fieldSort: 'totalImpressionsLow' }
  ];
  public paginateMultiloc: Pageable = {size: 10, page: 1};
  public isComparisonVisible = false
  public keywordSearchInput = new FormControl();
  public locationIds = [];
  public keywordSubject = new Subject();
  public keywordCurrentValue: string;
  public isProgressCSV = false;
  public topicsGraph: any = [];
  public fieldsColumnsTopics = ['no', 'details'];
  public dataSourceTopics = new MatTableDataSource([]);
  public topicsPaginate: Pageable = {size: 25, page: 1};
  public topicPagination  = {
    items: [],
    per_page: this.paginate.size,
    page: 1,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public tableLoading = true;

  constructor(
    public route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private SearchesKeywordsServ: SearchKeywordsService,
    public auth: AuthService,
    private reportS: ReportService,
    private modalS: ModalService,
    private locationS: LocationService
  ) {}

  ngOnInit() {
    if (!this.isReport) {
      this.accountId = this.route.parent.snapshot.params.accountId;
      this.locationId = this.route.parent.snapshot.params.locationId;
    } else {
      this.reportId = this.route.snapshot.params.id;
      if (this.report?.accounts?.length > 0) {
        this.locations = this.report?.accounts[0]?.locations;
      }
      this.dataRange = (this.report?.compareToValue && Object.keys(this.report?.compareToValue).length) ? this.report?.compareToValue : this.dataRange;
      this.report.showComparison = this.report.showComparison || false;
      if(this.report.showComparison) {
        this.isComparisonVisible = true;
        this.displayedColumnsKeywords = ['keywords', 'impressions', 'differencePercentage'];
        this.sort = {
          sortBy: this.dataRange?.value,
          sortOrder: -1,
          sortDirection: 'desc'
        }
      };
    }
    this.minDate = this.minDate?.startOf('month');
    this.maxDate = this.maxDate?.endOf('month');
    this.gid = this.auth.session.gid;
    this.location = [{ accountId: this.accountId, locationId: this.locationId }];
    this.selectDate = this.isReport ? { start: this.report.startDatetime, end: this.report.endDatetime } : this.locationS.buildDatepickerDate('keyword', this.maxDate);

    this.keywordSearchInput.valueChanges
    .subscribe(keyword => {
      this.keywordSubject.next(keyword?  keyword: '');
    })

    this.keywordSubject.pipe(
      debounceTime(650),
      switchMap( (keyword: string) => {

        if(this.keywordCurrentValue !== keyword){
          this.paginate.page = 1;
          this.keywordCurrentValue = keyword;
          this.paginatorChild.reset();
        }

        this.updatingData = true;
        const startDate = this.getDays().startDate;
        const endDate = this.getDays().endDate;

        if(keyword && !this.isReport){
          return this.SearchesKeywordsServ.getSearchKeywordsAtlas(keyword, this.paginate, startDate, endDate,
            this.dataRange?.value, [this.locationId], this.sort)
        } 
        else if(keyword && this.isReport){
          return this.SearchesKeywordsServ.getSearchKeywordsAtlas(keyword, this.paginate, startDate, endDate,
            this.dataRange?.value, this.locationIds, this.sort)
        }
        else if (!keyword && this.isReport) {
          const period = this.dataRange.value.replace('Comparison', '');
          return this.reportS.getSearchKeywordsReports(this.auth.session.gid, this.reportId, period, this.paginate, this.sort, startDate, endDate)
        }
        else if (!keyword && !this.isReport){
          const period = this.dataRange.value.replace('Comparison', '');
          return this.SearchesKeywordsServ.getSearchKeywords(this.locationId, this.gid, this.accountId, period, startDate, endDate, this.sort, this.paginate)
        }
      }),
    )
     .subscribe(result => {
      this.updatingData = false;
        if(this.keywordSearchInput.value){
        this.keywordsDatasource = new MatTableDataSource(result?.data);
        this.pagination.per_page = this.paginate.size;
        this.pagination.page= this.paginate.page;
        this.pagination.hasNext= result?.hasNext;
        this.pagination.hasPrev= result?.hasPrev;
        this.pagination.items= result?.data;
        this.pagination.pages = Math.ceil(result?.total / this.pagination.per_page)
        this.pagination.total = result?.total;
      } else {
        this.keywordsDatasource = new MatTableDataSource(result?.items);
        this.pagination = {
          per_page: result?.per_page,
          page: result?.page,
          hasNext: result?.hasNext,
          hasPrev: result?.hasPrev,
          pages: result?.pages,
          total: result?.keywordsCount,
          items: result?.items
        }
      } 
    },
    err => {
      this.updatingData = false;
      this.keywordsDatasource = new MatTableDataSource([]);
    });

    if (this.isReport || this.isShared) {
      this.getReportData();
    } else  {
      this.getData();
    }
  }

  getDays() {
   return {
    startDate: this.selectDate?.start ? moment(this.selectDate?.start).format('YYYY-MM-DD') : null,
    endDate: this.selectDate?.end ? moment(this.selectDate?.end).format('YYYY-MM-DD') : null
   }
  }

  getPercentage(value) {
   return value > 1000 ? 1000 : value;
  }

  getData() : void {
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;
    const period = this.dataRange.value.replace('Comparison', '');

    forkJoin([
      this.SearchesKeywordsServ.getSearchKeywords(this.locationId, this.gid, this.accountId, period, startDate, endDate, this.sort, this.paginate),
      this.SearchesKeywordsServ.getSearchImpressions(this.locationId, this.gid, this.accountId, startDate, endDate),
      this.SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, null, this.locationId, this.accountId)
    ]).subscribe(
      async result => {
        const dateValidations = await this.locationS.getDateValidations('keyword', [this.accountId], [this.auth.session.gid], [this.locationId]).toPromise();
        const dates = this.locationS.dateValidation(dateValidations);
        this.minDate = dates.minDate?.startOf('month');
        this.maxDate = dates.maxDate?.endOf('month');
        this.buildSearchData(result[0]);
        this.buildCardsData(result[0], result[1]?.dateFrom, result[1]?.dateTo);
        this.buildImpressionsData(result[1]);
        this.buildTopics(result[2]);
        this.loading = false;
        this.updatingData = false;
        this.detectChanges();
      },
      err => {
        this.loading = false;
        this.updatingData = false;
        this.modalS.openConfirmModal(
          'Loading error',
          'There was an error while loading the data. Please try again or contact support (error code 3)',
        (() => {
          this.auth.signOut(true, true)
        }),
        AlertType.ERROR,
        'Retry');
      }
    )
  }

  detectChanges(): void{
    if ( this.cdRef !== null && this.cdRef !== undefined && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }

  getKeywordsTable() : void {
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;
    const period = this.dataRange.value.replace('Comparison', '');
    const url = this.isReport ? 
      this.reportS.getSearchKeywordsReports(this.auth.session.gid, this.reportId, period, this.paginate, this.sort, startDate, endDate) :
      this.SearchesKeywordsServ.getSearchKeywords(this.locationId, this.gid, this.accountId, period, startDate, endDate, this.sort, this.paginate)

      url.subscribe(
        result => {
          this.buildSearchData(result);
          this.updatingData = false;
          this.detectChanges();
        },
        err => {
          this.updatingData = false;
        }
      )
  }

  getReportData() : void {
    this.tableLoading = true;
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;
    this.dataPicker.range = {
      start: startDate,
      end: endDate
    }
    const period = this.dataRange?.value?.replace('Comparison', '');
    this.dataSourceMultiLoc = new MatTableDataSource([]);
   
    forkJoin([
      this.reportS.getSearchKeywordsReports(this.auth.session.gid, this.reportId, period, this.paginate, this.sort, startDate, endDate),
      this.reportS.getReportsByType(this.auth.session.gid, this.reportId, 'search-impressions', startDate, endDate),
      this.reportS.getTableData(this.gid, 'keywords', this.paginateMultiloc ,this.sortMultiloc, this.dataPicker),
      this.SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, this.reportId, null, null)
    ]).subscribe(
      result => {
        this.locationIds = result[0]?.locationIds;
        this.buildSearchData(result[0]);
        this.buildCardsData(result[0], result[1]?.dateFrom, result[1]?.dateTo);
        this.buildImpressionsData(result[1]);
        this.buildMultiLocTable(result[2]?.data);
        this.buildTopics(result[3]);
        this.loading = false;
        this.updatingData = false;
        this.tableLoading = false;
        this.detectChanges();
      },
      err => {
        this.loading = false;
        this.updatingData = false;
        this.modalS.openConfirmModal(
          'Loading error',
          'There was an error while loading the data. Please try again or contact support (error code 4)',
        (() => {
          this.auth.signOut(true, true)
        }),
        AlertType.ERROR,
        'Retry');
      }
    )
  }

  getMultilocTable() : void {
    this.updatingData = true;
    this.tableLoading = true;
    this.dataSourceMultiLoc = new MatTableDataSource([]);
    this.dataPicker.range = {
      start: this.getDays().startDate,
      end:this.getDays().endDate
    }

      this.reportS.getTableData(this.gid, 'keywords', this.paginateMultiloc ,this.sortMultiloc, this.dataPicker).subscribe(
      res => {
        this.buildMultiLocTable(res.data);
        this.tableLoading = false;
        this.updatingData = false;
      },
      err => this.updatingData = false
    );
  }

  getTopicsTable() : void {
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;

    this.SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, this.reportId, this.locationId, this.accountId).subscribe(
      res => {
        this.updatingData = false;
        this.buildTopics(res);
      },
      err => {
        this.updatingData = false;
    }
    )
  }

  buildSearchData(data) {
    this.searchKeywordsData = data;
    this.keywordsDatasource = new MatTableDataSource(this.searchKeywordsData?.items || this.searchKeywordsData?.data);
    this.pagination = {
      per_page: data.per_page,
      page: data.page,
      hasNext: data.hasNext,
      hasPrev: data.hasPrev,
      pages: data.pages,
      total: data.keywordsCount,
      items: data?.items
    };
  }

  buildCardsData(data, start, end) {
    this.selectDate = {
      start: moment(start),
      end: moment(end)
    }
    this.totalKeywordsData = data?.counts;
    this.totalKeywordsData?.items.forEach((item, i) => { 
      item.backgroundColor = this.colors[i].backgroundColor,
      item.borderColor = this.colors[i].borderColor
      item.tooltip = i == 0 ? 
        'Google gives exact keyword impression data for keywords with more than (>) 15 impressions. This is the total number of unique keywords with more than (>) 15 impressions.' :
        'For keywords with fewer than (<) 15 impressions, Google does not provide impression data. This is the total number of unique keywords with less than (<) 15 impressions.'
    });
    this.totalImpressionsData = data?.estimates;
    this.totalImpressionsData?.items.forEach((item, i) => { 
      item.backgroundColor = this.colors[i].backgroundColor,
      item.borderColor = this.colors[i].borderColor
    });
  }

  buildImpressionsData(data) : void {
    this.searchImpressionsData = data;
    this.labels = this.searchImpressionsData?.labels;
  }

  buildMultiLocTable(data) {
    data.items.forEach(i => i.estTotalimpressions = `${new Intl.NumberFormat().format(i?.totalImpressionsLow || 0)} - ${new Intl.NumberFormat().format(i?.totalImpressionsHigh || 0)}`);
    this.dataSourceMultiLoc = new MatTableDataSource(data.items);
    this.paginationMultiloc = {
      items: data['items'],
      page: data['page'],
      pages: data['totalPages'],
      per_page: data['pageSize'],
      total: data['total'],
      hasNext: data['hasNext'],
      hasPrev: data['hasPrev']
    };
  }

  buildTopics(data) {
    this.topicsGraph = data?.searches;
    if(this.topicsGraph?.total > 0) {
      this.topicsGraph.items.forEach(
        (el, i) => {
          el.progressBgColorClass = this.getGraphColors(i);
        }
      )
    }
    this.dataSourceTopics = new MatTableDataSource(data?.keyword_trends?.items);
    this.topicPagination = {
      per_page: data?.keyword_trends?.per_page,
      page: data?.keyword_trends?.page,
      hasNext: data?.keyword_trends?.hasNext,
      hasPrev: data?.keyword_trends?.hasPrev,
      pages: data?.keyword_trends?.pages,
      total: data?.keyword_trends?.total,
      items: data?.keyword_trends?.items
    }
  }

  getGraphColors(index) {
    const colors = ['progress--yellow','progress--green'];
    return colors[index]
  }

  topicsHandleReload(event) {
    this.updatingData = true;
    this.topicsPaginate = event;
    this.getTopicsTable();
  }

  async handleExportCsvTopic(item) {
    this.isProgressCSV = true;
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;
    
    await this.SearchesKeywordsServ.handleExportCsvTopic(this.gid,  this.reportId, this.accountId, this.locationId, startDate, endDate, item?.topics);
    this.isProgressCSV = false;
  }

  async handleExportCsvKeywordsSearch(){
    this.isProgressCSV = true;
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate
    let locations
    if (this.locationIds.length > 0){
      locations = this.locationIds
    } else {
      locations = [this.locationId]
    }
    await this.SearchesKeywordsServ.handleExportCsvKeywordsSearch(
      startDate, endDate, locations, this.keywordSearchInput.value , this.dataRange?.value, this.isComparisonVisible, this.sort
      );
    this.isProgressCSV = false;
  }

  handleReload(event) {
    this.paginate = event;

    if(this.keywordSearchInput.value){
      this.keywordSearchInput.setValue(this.keywordSearchInput.value)
    } else {
      this.updatingData = true;
      if(this.isReport || this.isShared){
        this.getReportData();
      }
      else{
        this.getData();
      }
    }
  }

  sortChanged(event, onlyTable = false) {
    this.updatingData = true;
    this.sort = {
      sortBy: event.active === 'impressions' ? event.active : this.dataRange.value,
      sortOrder: event.direction === 'asc' ? 1 : -1,
      sortDirection: event.direction
    }
    
    this.paginate = {
      page: 1,
      size: this.paginate.size
    }
    
    if(this.keywordSearchInput.value){
      this.keywordSearchInput.setValue(this.keywordSearchInput.value)
    } else {
      this.updatingData = true;
      if(this.isReport || this.isShared){
        this.getReportData();
      }
      else{
        this.getData();
      }
    }
  }

  handleDataPicker(event) {
    this.updatingData = true;
    this.selectDate.start = event.range.start;
    this.selectDate.end = event.range.end;
    
    if(this.isReport || this.isShared){
      this.getReportData();
    }
    else{ // not report
      this.getData();
    }
  }

  handleSelectRange(event) {
    if (this.dataRange.value == event.value) { return; }
    this.dataRange = event;
    const sort = {
      active: this.isComparisonVisible ? 'differencePercentage' :'impressions', 
      direction: 'desc'
    }
    this.sortChanged(sort);
  }

  handleMultilocSort(event) {
    this.updatingData = true;
    this.paginateMultiloc = {size: this.paginateMultiloc.size, page: 1}
    this.sortMultiloc = {
      sortBy: event.active.replace('_', ''),
      direction: event.direction
    };
    this.getMultilocTable();
  }

  handleMultilocPaginate(event) {
    this.updatingData = true;
    this.paginateMultiloc = event;
    this.getMultilocTable();
  }

  async handleExport($event) {
    this.isProgressCSV = true;
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;
    const period = !this.isComparisonVisible ? null : this.dataRange?.displayName?.split(' ')[1];

    await this.reportS.handleExportCsvMongo(this.reportId, this.gid, this.accountId, this.locationId, startDate, endDate, '', "keywords", period);

    this.isProgressCSV = false;
  }

  getImpressionsValue(numberOfImpressions) {
    return numberOfImpressions > 0 ? numberOfImpressions.toLocaleString("en-US") : '<15';
  }

  changedViewComparison(event) {
    this.isComparisonVisible = event;
    this.displayedColumnsKeywords = this.isComparisonVisible ? ['keywords', 'impressions', 'differencePercentage'] : ['keywords', 'impressions'];
    const sort = {
      active: this.isComparisonVisible ? 'differencePercentage' :'impressions', 
      direction: 'desc'
    }
    this.sortChanged(sort, true);
  }

  getTooltip(graph) {
    this.modalS.openInfoModal(
      graph,
      this.getTooltipText(graph)
    );
  }

  getTooltipText(section) {
    switch(section) {
      case 'Impressions':
        return `<div class='txt--left'>Google gives exact keyword impression data for keywords with more than (>) 15 impressions. Exact data for keywords with less than (<)15 impressions is unavailable, but Google provides a range of 1-15 impressions for each of those keywords.
          <br><br>We calculate total impressions by combining the exact count from keywords with more than (>) 15 impressions and adding the following estimates for keywords with less than (<) 15 impressions.
          </div>
          <span>
            <br>- High Estimate = 15 impressions per <15 keyword
            <br>- Medium Estimate = 8 impressions per <15 keyword
            <br>- Low Estimate = 1 impression per <15 keyword"
          </span>`;
      case 'Total Page One Keywords': 
        return `This total represents the unique keyword count your business was found for on maps and search during the report's time frame. While exact search engine results page placement can't be determined, your business must appear on the first page or as a highlighted 'pin' on maps to generate any Google impressions.`
      case 'Keywords':
        return `Keywords shown below represent the unique search terms that your business profile appeared in search results on Google Maps and Search on desktop and mobile devices. Impressions are displayed for reference to determine the number of times your business profile appeared in the search results for a given keyword. Multiple impressions by a unique user within a single day are counted as a single impression. You can use this report to track changes in impressions over long periods of time to track changes in overall visibility for your business on Maps and Search on desktop and mobile devices.`
      case 'Direct vs. Discovery':
        return `Direct searches represent keywords that include or are related to the name or address of your business. Discovery searches represent keywords related to the category, product or services your business offers.`
      case 'Keyword topics':
        return `The keyword topics listed below represent the most commonly used search terms that generated impressions for your business profile. Total impression and keyword counts are shown below the search term topic. You can export these keywords by clicking the .csv button to the right of the keyword.`
    }
  }
}
