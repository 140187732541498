import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LocationEditService } from 'src/app/services/location-edit.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { REGEX_URL } from '../../constants/regex';

@Component({
  selector: 'app-modal-action-links',
  templateUrl: './modal-action-links.component.html',
  styleUrls: ['./modal-action-links.component.scss']
})
export class ModalActionLinksComponent implements OnInit {
  public title = 'Links';
  public actionLinks = {
    displayName: null,
    placeActionLinks: [],
    placeActionType: null
  };
  public placeActionLinks = [];
  public actionLinksData: FormArray;
  public actionLinksAttributes = [];

  @Input() isEdit = true;
  @Input() bulk = false;
  @Input() bulkActionLinks = [];
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    public dialogRef: MatDialogRef<ModalActionLinksComponent>,
    private _locationEditS: LocationEditService,
    private _snack: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.title = this.data?.title;
    this.actionLinks = this.data?.actionLinks;
    this.placeActionLinks = this.actionLinks?.placeActionLinks;
    
    this.initForm();
  }

  initForm(): void {
    this.actionLinksData = new FormArray([]);

    this.placeActionLinks?.forEach(el => {
      this.addActionLinks(el)
    });
  }

  addActionLinks(element?): void {
    this.actionLinksData.push(new FormGroup({
      url: new FormControl(element?.uri, [Validators.required, Validators.pattern(REGEX_URL)]),
      isPreferred: new FormControl(element?.isPreferred || false),
      name: new FormControl(element?.name)
    }));
  }

  removeActionLinks(index: number): void {
    this.actionLinksData.removeAt(index);
  }

  handleCheckPreferred(event: MatCheckboxChange, index: number): void {
    this.actionLinksData.controls[index].get('isPreferred').setValue(event.checked);

    if(event.checked){
      this.actionLinksData.controls.forEach((control, i) => {
        if (i !== index) {
          control.get('isPreferred').setValue(false);
        }
      });
    }
  }

  hasError(index: number, field: string, error: string):boolean {
    const control = this.actionLinksData?.at(index);
    return control?.get(field)?.hasError(error) && control.touched;
  }

  apply(): void {
    if(!this.actionLinksData.valid) { 
      this.actionLinksData.markAllAsTouched();
      return
    }

    const index = this._locationEditS.locationEdit.placeActionLinks.findIndex(el => el.placeActionType === this.actionLinks.placeActionType);

    this._locationEditS.locationEdit.placeActionLinks[index].placeActionLinks = this.builData();
    this._locationEditS.setAndUpdate().toPromise()
    .then(()=>{
      this.dialogRef.close(true);
    })
    .catch(() => {
      this._snack.openError('There was an error while saving the data. Please try again or contact support')
      this.dialogRef.close(false);
    });
  }

  builData(): {} {
    const data = [];
    let newElement;

    this.actionLinksData.controls.forEach(control => {
      newElement = {};
      const index = this.actionLinks?.placeActionLinks?.findIndex(el => el.name === control.get('name')?.value);

      if(index > -1) {
        newElement = {
          ...this.actionLinks.placeActionLinks[index],
          uri: control.get('url').value,
          isPreferred: control.get('isPreferred').value
        };
      } else {
        newElement ={
          uri: control.get('url').value,
          isPreferred: control.get('isPreferred').value,
          name: null
        }
      }
      data.push(newElement);
    });

    return data;
  }

  getResult(): {} {
    this.actionLinksData.markAllAsTouched();

    if (this.actionLinksData.valid) {
      this.validResult.emit(true);
      return this.builData();
    }
  }

}
