import { __assign, __awaiter, __generator } from "tslib";
import { MatDialog } from '@angular/material';
// app
// IMPORTANT: Only add simple components here that doesn't depend on auth.service or
// other services, to avoid circular deps. In doubt, don't modify this file and call openModal()
// from an outer module importing the component on that outer module. Or inject the data needed
// from the services to the component from outside.
import { AlertComponent, AlertType } from '../components/alert.component';
import { ConfirmComponent } from '../components/confirm.component';
import { ModalConfirmData } from "../classes/modal-confirm-data";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var ModalService = /** @class */ (function () {
    function ModalService(dialog) {
        this.dialog = dialog;
    }
    /**
    * Generic awaitable openModal method
    */
    ModalService.prototype.openModal = function (component, dialogData, opts) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return __awaiter(this, void 0, void 0, function () {
            var d, p_resolve, p;
            return __generator(this, function (_o) {
                opts = __assign(__assign(__assign({}, (_a = component) === null || _a === void 0 ? void 0 : _a.OPTS_DEFAULT), opts), (((_c = (_b = component) === null || _b === void 0 ? void 0 : _b.OPTS_DEFAULT) === null || _c === void 0 ? void 0 : _c.config) || ((_d = opts) === null || _d === void 0 ? void 0 : _d.config) ?
                    { config: __assign(__assign({}, (_f = (_e = component) === null || _e === void 0 ? void 0 : _e.OPTS_DEFAULT) === null || _f === void 0 ? void 0 : _f.config), (_g = opts) === null || _g === void 0 ? void 0 : _g.config) } : null));
                d = this.dialog.open(component, __assign(__assign({}, (_h = opts) === null || _h === void 0 ? void 0 : _h.config), { data: dialogData }));
                if (((_j = opts) === null || _j === void 0 ? void 0 : _j.autoCloseMS) !== undefined && ((_k = opts) === null || _k === void 0 ? void 0 : _k.autoCloseMS) !== null)
                    setTimeout(function () { d.close(); }, opts.autoCloseMS);
                p = new Promise(function (resolve) { p_resolve = resolve; });
                if (((_l = opts) === null || _l === void 0 ? void 0 : _l.disableClose) !== undefined)
                    d.disableClose = (_m = opts) === null || _m === void 0 ? void 0 : _m.disableClose;
                d.afterClosed().subscribe(function (res) {
                    var _a;
                    if ((_a = opts) === null || _a === void 0 ? void 0 : _a.onClose)
                        opts.onClose(res);
                    p_resolve(res);
                });
                return [2 /*return*/, p];
            });
        });
    };
    // openLoadingModal(title: string, message: string, dismiss: number = null) : Promise<boolean> {
    //   return this.openModal(LoadComponent,                           
    //     {
    //       title, 
    //       content: message
    //     }, 
    //     {
    //       config: { width: '680px' }, 
    //       autoCloseMS : dismiss
    //     }
    //   )
    // }
    ModalService.prototype.openAlertModal = function (title, message, alertType, dismiss, closeButton, callBackFunction, colorCloseBtn, hideCancel) {
        if (dismiss === void 0) { dismiss = null; }
        if (closeButton === void 0) { closeButton = 'Ok'; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.openModal(AlertComponent, {
                        title: title,
                        alertType: alertType,
                        content: message,
                        closeButtonLabel: closeButton,
                        colorCloseBtn: colorCloseBtn ? colorCloseBtn : 'primary',
                        hideCancel: hideCancel
                    }, {
                        onClose: callBackFunction,
                        autoCloseMS: dismiss
                    })];
            });
        });
    };
    // TODO: Replace calls to this with openModal and remove it
    ModalService.prototype.openGenericModal = function (modalComponentRef, data, callBackFunction, modalWidth) {
        var dialogRef = this.dialog.open(modalComponentRef, {
            width: modalWidth ? modalWidth + 'px' : '680px',
            data: new ModalConfirmData({
                data: data,
                title: null,
                content: null,
                confirmButtonLabel: 'Save',
                closeButtonLabel: 'Cancel'
            })
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) { return callBackFunction(result); });
        return dialogRef;
    };
    ModalService.prototype.openInfoModal = function (title, message, cancelButton, hideCancel) {
        if (cancelButton === void 0) { cancelButton = 'Ok'; }
        if (hideCancel === void 0) { hideCancel = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.openAlertModal(title, message, AlertType.INFO, null, cancelButton, null, null, hideCancel)];
            });
        });
    };
    ModalService.prototype.openWarningModal = function (title, message) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.openAlertModal(title, message, AlertType.WARNING)];
            });
        });
    };
    ModalService.prototype.openErrorModal = function (title, message) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.openAlertModal(title, message, AlertType.ERROR)];
            });
        });
    };
    ModalService.prototype.openConfirmModal = function (title, message, callBackFunction, alertClass, button, modalPayment, description, typePaid) {
        return this.openModal(ConfirmComponent, new ModalConfirmData({ title: title,
            content: message,
            confirmButtonLabel: button || 'Confirm',
            closeButtonLabel: 'Cancel',
            alertType: alertClass || null,
            modalPayment: modalPayment || false,
            description: description || null,
            typePaid: typePaid || false }), { config: { width: '680px' },
            onClose: callBackFunction,
            disableClose: true
        });
    };
    ModalService.prototype.openInfoProfiles = function (title) {
        return this.openInfoModal(title, "Performance data from Google Business Profile is latent up to 7 days from today\u2019s date. " +
            "Performance data from the last week is unavailable. " +
            "Please refresh this report at a later date for more recent insights.");
    };
    ModalService.prototype.openDatepickerdates = function () {
        return this.openInfoProfiles('Date range');
    };
    ModalService.prototype.openDynamicReportReminderModal = function () {
        return this.openInfoProfiles('Dynamic report');
    };
    ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.ɵɵinject(i1.MatDialog)); }, token: ModalService, providedIn: "root" });
    return ModalService;
}());
export { ModalService };
