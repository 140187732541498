// dep
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {HttpClient} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import moment from 'moment';
import firebase from 'firebase/app';

// appp
import {AuthService} from './auth.service';
import Report, {REPORT_TYPE} from '../constants/firestore/report';
import {DataPicker} from '../constants/data-picker';
import {FirestoreService} from './firestore.service';
import {Pageable} from '../constants/pageable';

import {ZuluToDatePipe} from '../pipes/zulu-to-date.pipe';
import { FIRESTORE_AUTOMATED_REPORTS, GROUPS, REPORTS } from '../constants/firestore/collections';
import {environment} from "@environment";
import { InsightsService } from './insights.service';
import { SnackbarService } from './snackbar.service';
import { DatesService } from './dates.service';
import { LocationService } from './location.service';



@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private auth: AuthService,
    private firestoreS: FirestoreService,
    private insightS: InsightsService,
    private afsStorage: AngularFireStorage,
    private snackS: SnackbarService,
    private dateS: DatesService,
    private locationS: LocationService
  ) {
  }

  // api operations
  getRef(gid) {
    return this.afs.collection(GROUPS).doc(gid).collection(REPORTS);
  }

  getAutomatedRef(gid) {
    return this.afs.collection(GROUPS).doc(gid).collection(FIRESTORE_AUTOMATED_REPORTS);
  }

  /**
   * this method get hash and token for public report
   * @return result.hash and result.token
   */
  getHash() {
    if (!this.auth.session) {
      return;
    }
    return this.http.get<{ hash: string, token: string }>(`${environment.apiUrl}/users/hash`);
  }

  // CRUD firestore operations
  /**
   * this method get doc by docId
   */
  get(gid, docId: string): Observable<Report> {
    return this.afs.collection(`${GROUPS}/${gid}/${REPORTS}`).doc<Report>(docId).valueChanges();
  }


  getAutomated(gid, docId: string): Observable<Report> {
    return this.afs.collection(GROUPS).doc(gid).collection(FIRESTORE_AUTOMATED_REPORTS).doc<Report>(docId).valueChanges();
  }

  /**
   * this method get doc by docId
   */
  getById(gid, docId: string) {
    return this.afs.collection(GROUPS).doc(gid).collection(REPORTS).doc<Report>(docId);
  }

  /**
   * search by uid and gid
   */
  getByGIdAndType(gid: string, type: string,
                  pageable: Pageable = {size: 10, page: 1},
                  next, prev, searchKeywords, order = 'createdAt',
                  direction = 'desc'): Observable<any> {
    return this.firestoreS.paginateValuesReports(`${GROUPS}/${gid}/${REPORTS}`,
      order, direction, gid, type, pageable, next, prev, searchKeywords);
  }


  getByGIdAndTypeCount(gid, reportType){
    const collectionRef = this.afs.collection(`${GROUPS}/${gid}/${REPORTS}`,
      query => query.where('gid', '==', gid)
                  .where('reportType', '==', reportType));
                  
    return collectionRef.get()
  }
    
  async getLegacy(gid: string, type: string) {
    const res = await this.afs.collection(`${GROUPS}/${gid}/${REPORTS}`, ref => 
      ref.where('gid', '==', gid)
      .where('reportType', '==', type)
    ).get().toPromise();

   // console.log(res.forEach(doc => console.log(doc.data())))
   return res.docs.length;
  }

  getReportsFromMongo(gid, reportType, paginate, sortBy, sortOrder, reportName = null) {
    const data = {
      gid: gid,
      reportType: reportType,
      size: paginate.size,
      page: paginate.page,
      sortBy: sortBy,
      sortOrder: sortOrder,
      reportName: reportName
    }
    return this.http.post(`${environment.apiUrl}/v3/reports/list`, data)
  }
  
  saveReportInMongo(report: Report): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v3/reports/create`, report)
  }

  updateReportsInMongo(reportId, report): Observable<any> {
    const data = { updatedData: report };
    report.accounts = this.locationS.deleteServiceArea(report.accounts);
    return this.http.post(`${environment.apiUrl}/v3/reports/${reportId}/edit`, data)
  }

  deleteFromMongo(reportId): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/v3/reports/${reportId}/delete`)
  }

  getSearchKeywordsReports(gid, reportId, period, paginate, sort, startDate = null, endDate = null): Observable<any> {
    const data = {
      gid: gid,
      reportId: reportId,
      size: paginate.size,
      page: paginate.page,
      sortOrder: sort.sortOrder,
      sortBy: sort.sortBy,
      startDate: startDate,
      endDate: endDate,
      period: period
    }
    
    return this.http.post(`${environment.apiUrl}/v3/reports/keywords`, data);
  }

  getPerformanceRollupReports(reportId, viewBy = 'month', startDate = null, endDate = null): Observable<any> {
    const data = {
      reportId: reportId,
      viewBy: viewBy.toLocaleLowerCase(),
      startDate: startDate,
      endDate: endDate,
    }
    
    return this.http.post(`${environment.apiUrl}/v3/reports/insights`, data);
  }

  validateDateFormat(date: string): string {
    const dateParts = date?.split('-') || [];
    const year = dateParts[0].padStart(4, '0');
    const month = dateParts[1].padStart(2, '0');
    const day = dateParts[2].padStart(2, '0');

    return `${year}-${month}-${day} 00:00:00`;
  }

  resumeExportCsv(gid, reportType, sort, dataPicker, filename): Observable<any> {
    const startDatetimeMoment = moment(dataPicker.range.start);
    const endDatetimeMoment = moment(dataPicker.range.end);
    
    let startDatetime = !startDatetimeMoment.isValid() ? null : startDatetimeMoment['_i'].includes('T') ? (startDatetimeMoment['_i'].split('T')[0]).replaceAll('/', '-') : (startDatetimeMoment['_i'].split(' ')[0]).replaceAll('/', '-');
    let endDatetime = !endDatetimeMoment.isValid() ? null :  endDatetimeMoment['_i'].includes('T') ? (endDatetimeMoment['_i'].split('T')[0]).replaceAll('/', '-') : (endDatetimeMoment['_i'].split(' ')[0]).replaceAll('/', '-');



    if (!!startDatetime) { startDatetime = this.validateDateFormat(startDatetime); }
    if (!!endDatetime) { endDatetime = this.validateDateFormat(endDatetime); }

    let accounts = dataPicker?.locations?.map(l => l?.accountId);
    accounts = [...new Set(accounts)];
    const locations = dataPicker?.locations?.map(l => l?.locationId);

    const data =
    {
      "gids": [gid],
      "accountIds": accounts,
      "locationIds": locations,
      "filter": {
        "startDate": startDatetime,
        "endDate": endDatetime
      },
      "type": reportType,
      "filename": filename
    }

    if (sort) {
      data['sortDesc'] = sort?.direction == 'asc' ? false : true;
      data['sortKey'] = sort?.sortBy;
    }
    return this.http.post(`${environment.coreApiUrl}/export/multilocation`, data);
  }

  getTableData(gid, reportType, pageable, sort, dataPicker): Observable<any> {
    let startDatetime, endDatetime, endDatetimeMoment, startDatetimeMoment = null
    
    if(dataPicker.range.start) {
        startDatetimeMoment = moment(dataPicker.range.start);
        startDatetime = !startDatetimeMoment.isValid() ? null : startDatetimeMoment['_i']?.includes('T') ? (startDatetimeMoment['_i']?.split('T')[0])?.replaceAll('/', '-') : (startDatetimeMoment['_i']?.split(' ')[0])?.replaceAll('/', '-');
        startDatetime = `${startDatetime} 00:00:00`;
    }
    if(dataPicker.range.end) {
      endDatetimeMoment = moment(dataPicker.range.end);
      endDatetime = !endDatetimeMoment.isValid() ? null :  endDatetimeMoment['_i']?.includes('T') ? (endDatetimeMoment['_i']?.split('T')[0])?.replaceAll('/', '-') : (endDatetimeMoment['_i']?.split(' ')[0])?.replaceAll('/', '-');
      endDatetime = `${endDatetime} 00:00:00`;
    }

    let accounts = dataPicker?.locations?.map(l => l?.accountId);
    accounts = [...new Set(accounts)];
    const locations = dataPicker?.locations?.map(l => l?.locationId);

    const data =
    {
      "gids": [gid],
      "accountIds": accounts,
      "locationIds": locations,
      "filter": {
        "startDate": startDatetime,
        "endDate": endDatetime
      },
      "type": reportType,
      "page": pageable.page,
      "pageSize": pageable.size,
    }

    if (sort) {
      data['sortDesc'] = sort?.direction == 'asc' ? false : true;
      data['sortKey'] = sort?.sortBy;
    }

    return this.http.post(`${environment.coreApiUrl}/reports/multilocation`, data);
  }

  getReportsByType(gid, reportId, reporType, startDate = null, endDate = null, viewBy = null): Observable<any> {
    const data = {
      gid: gid,
      reportId: reportId,
      startDate: startDate,
      endDate: endDate,
    }

    if (viewBy) {
      data['viewBy'] = viewBy.toLowerCase()
    }
    
    return this.http.post(`${environment.apiUrl}/v3/reports/${reporType}`, data);
  }

  getReportDetails(reportId): Observable<any> {
    const data = {
      reportId: reportId,
    }
    return this.http.post(`${environment.apiUrl}/v3/reports/accounts`, data);
  }
  
  getReportById(reportId, isAutomatedReport = false): Observable<any> {
    const type = isAutomatedReport ? 'automated' : 'default';
    return this.http.get(`${environment.apiUrl}/v3/reports/${reportId}?type=${type}`);
  }

  async handleExportCsvFire(locations, startDate, endDate, viewBy, reportName) {
    // let hasExport;
    await this.insightS.getExportFile(locations, startDate, endDate, viewBy, 'csv', reportName).toPromise().then(async response => {
        const filename = response.data;

        await this.afsStorage.ref(filename).getDownloadURL().toPromise().then(
          filename => {
            this.insightS.downloadFromGS(filename);
            this.snackS.openSuccess("The file exported successfully", 2000);
          },
          err => this.snackS.openError("There was an error in the export", 2000)
        );
      });
  }

  async handleExportCsvMongo(reportId, gid, accountId, locationId, startDate, endDate, viewBy, type = null, period = null)  {
    const startDatetime = this.locationS.formatDates(startDate);
    const endDatetime = this.locationS.formatDates(endDate);

    await this.downloadReportCsv(reportId, gid, accountId, locationId, startDatetime, endDatetime, viewBy, type, period).subscribe(
      res => {
        const filename = res;

        this.afsStorage.ref(filename).getDownloadURL().toPromise().then(filename => {
          this.insightS.downloadFromGS(filename);
          this.snackS.openSuccess("The file exported successfully", 2000);
        });
      },
      err => this.snackS.openError("There was an error in the export", 2000)
    )
  }

  downloadReportCsv(reportId = null, gid = null, accountId = null, locationId = null, startDate = null, endDate = null, viewBy = null, type = null, period = null): Observable<any> {
    const data = {
      reportId: reportId,
      gid: gid,
      accountId: accountId,
      locationId: locationId,
      startDate: startDate,
      endDate: endDate,
      viewBy: viewBy?.toLowerCase(),
      type: type,
      period: period
    };

    return this.http.post(`${environment.apiUrl}/v3/reports/export`, data);
  }

  downloadReportCsvReview(gid, reportId, startDate, endDate, type): Observable<any> {
    const data = {
      gid,
      reportId, 
      startDate, 
      endDate, 
      type
    };

    return this.http.post(`${environment.apiUrl}/v3/reports/export`, data);
  }

  save(gid, report: Report) {
    return this.getRef(gid).add(report);
  }

  delete(gid, docId) {
    return this.getRef(gid)
      .doc(docId)
      .delete();
  }

  update(automatedReport= false, gid, docId, report: Report) {
    if (automatedReport) {
      return this.getAutomatedRef(gid)
                    .doc(docId)
                    .update({...report, updateAt: firebase.firestore.Timestamp.now()});
    }

    return this.getRef(gid)
      .doc(docId)
      .update({...report, updateAt: firebase.firestore.Timestamp.now()});
  }

  setMerge(gid, docId, report: any) {
    return this.getRef(gid)
      .doc(docId)
      .set(report, {merge: true});
  }

  share(dataPicker: DataPicker, type: REPORT_TYPE, metrics?: any[], reportName?: string, showComparison?, questionsType?, periodDaysValue?, compareToValue?) {

    return this.getHash()
      .pipe(
        map(result => {
          const report: Report = {
            reportName: reportName || '',
            reportType: type,
            createdAt: firebase.firestore.Timestamp.now(),
            locations: dataPicker.locations,
            gid: this.auth.session.gid,
            hash: result.hash,
            token: result.token,
            aggregation: dataPicker.aggregation,
            lockDates: false,
            uid: this.auth.session.uid,
            shared: true,
            sharedOnly: true
          };

          if (dataPicker.multiChart) {
            report.multiChart = dataPicker.multiChart;
          }


          if (dataPicker.range) {
            report.startDatetime = dataPicker?.range?.start;
            report.endDatetime = dataPicker?.range?.end;
          }

          if (dataPicker.rangeB) {

            if (dataPicker.rangeB.start !== undefined && dataPicker.rangeB.end !== undefined) {
              report.startDatetimeB = dataPicker.rangeB.start;
              report.endDatetimeB = dataPicker.rangeB.end;
            }

          }

          if(metrics.length) {
            report.metrics = metrics;
          }

          if (!report.accounts) {
            report.accounts = this.buildAccounts(dataPicker.locations);
          }

          if (type.includes('keyword') && showComparison) {
            report.showComparison = showComparison;
          }
          if (type.includes('keyword') && compareToValue) {
            report.compareToValue = compareToValue;
          }

          if (type.includes('qanda') && questionsType) {
            report.questionsType = questionsType;
          }

          if (type.includes('qanda') && periodDaysValue) {
            report.dynamicRange = periodDaysValue;
          }

          return report;
        }),


        switchMap(report => type != 'performance-insights' && !type?.includes('-location') && !type?.includes('-report') ? this.save(report.gid, report) : this.saveReportInMongo(report))
      );
  }

  buildAccounts(locations) {
    const data = []
    locations.forEach(el =>{
      const indexAcc = data.findIndex(loc => loc.accountId == el?.accountId);
      if (indexAcc > -1) {
        data[indexAcc].locations.push(el)
      } else {
        data.push({
          accountId: el?.accountId,
          gid: this.auth.session.gid,
          locations: [el]
        });
      }
    });
    return data;
  }

  getByHash(hash: string): Observable<any> {
    return this.afs.collectionGroup(REPORTS, ref => ref.where('hash', '==', hash)
      .limit(1)).get()
      .pipe(map(report => report.docs.length > 0 ? {id: report.docs[0].id, ...report.docs[0].data()} : {}));
    // return this.firestoreS.colWithIds$<Report>(GROUPS + '/' + REPORTS, ref => ref.where('hash', '==', hash).limit(1))
  }


  reportSelectDatesFromReport(report) {
    const selectStart = report.startDatetime;
    const selectEnd = report.endDatetime;

    return {start: moment(selectStart), end: moment(selectEnd)};
  }

  converDynamicDateComparison(selector: string, reportType?: null): {
    startDatetime: string, endDatetime: string, startDatetimeB: string, endDatetimeB: string
    } {
    let dates = this.getDates(selector, reportType);
    let startDatetime =  dates?.startDatetime?.toISOString();
    let endDatetime =  dates?.endDatetime?.toISOString();
    let startDatetimeB =  dates?.startDatetimeB?.toISOString();
    let endDatetimeB =  dates?.endDatetimeB?.toISOString();


    return {startDatetime, endDatetime, startDatetimeB, endDatetimeB};
  }

  updateDynamicDates(report, idReport, automatedReport, viewMode = 'new') {
    if (!report?.dynamic || !report?.dynamicRange) return
    const dynamic = report?.dynamicRange;
    let dates = this.getDates(dynamic, report.reportType);

    report.startDatetime =  moment(dates?.startDatetime).utc().toISOString();
    report.endDatetime =  moment(dates?.endDatetime).utc().toISOString();

    if (report.reportType?.includes('comparison')) {
      report.startDatetimeB =  dates?.startDatetimeB;
      report.endDatetimeB   =  dates?.endDatetimeB;
    }
    this.updateReport(viewMode, idReport, report, automatedReport);
  }

  getDates(selector, reportType) {
    let startDatetime =  null;
    let endDatetime = null;
    let startDatetimeB =  null;
    let endDatetimeB = null;

    const date = moment().utc().toDate();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    switch (selector) {
      case 'LastMonth':
        startDatetime =  moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
        endDatetime =  moment(new Date(year, month, 0).setHours(0, 0, 0));
        
        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};
      case 'ThisMonth':
        startDatetime =  moment(new Date(year, month , 1).setHours(0, 0, 0));
        endDatetime =  moment(new Date(year, month, day).setHours(0, 0, 0));
        
        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};
      case 'Last7':
        startDatetime =  moment(new Date(year, month, day - 7).setHours(0, 0, 0));
        startDatetime = this.removeSevenDays(startDatetime, reportType);
        
        endDatetime =  moment(new Date(year, month, day).setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);
        
        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};
      case 'Last30':
        startDatetime =  moment(new Date(year, month, day - 30).setHours(0, 0, 0));
        startDatetime = this.removeSevenDays(startDatetime, reportType);
        
        endDatetime =  moment(new Date(year, month, day).setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);
        
        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};
      case 'Last90':
        startDatetime = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
        startDatetime = this.removeSevenDays(startDatetime, reportType);
        
        endDatetime = moment(new Date(year, month, day).setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);
        
        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};
      case 'LastYear':
        startDatetime =  moment(new Date(year - 1, 0, 1));
        endDatetime = moment(new Date(year - 1, 11, 31));

        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};
      case 'YTD':
        startDatetime =  moment(new Date(year, 0, 1).setHours(0, 0, 0));
        
        endDatetime =  moment(new Date(year, month, day).setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);
        
        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};

      case 'OneMonth':
        startDatetime = moment().subtract(1, 'month').startOf('month');
        endDatetime = moment(new Date().setDate(0));
        
        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};
      case 'Last3Month':
        startDatetime = moment().subtract(3, 'month').startOf('month');
        endDatetime = moment(new Date().setDate(0));
        
        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};
      case 'OneYear':
        startDatetime =  moment(new Date(year, 0, 1));
        endDatetime = moment(new Date().setDate(0));
        
        return {startDatetime, endDatetime, startDatetimeB: null, endDatetimeB: null};
    
      case 'LastMonth-PrevMonth':
        startDatetime =  moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
        endDatetime =  moment(new Date(year, month, 0).setHours(0, 0, 0));
        
        startDatetimeB =  moment(new Date(year, month - 2, 1).setHours(0, 0, 0));
        endDatetimeB =  moment(new Date(year, month - 1, 0).setHours(0, 0, 0));
        
        return {startDatetime, endDatetime, startDatetimeB, endDatetimeB};
      case 'LastMonth-YearAgoMonth':
        startDatetime =  moment(new Date(year, month - 1, 1).setHours(0, 0, 0));
        endDatetime =  moment(new Date(year, month, 0).setHours(0, 0, 0));
        
        startDatetimeB =  moment(new Date(year - 1, month - 1, 1).setHours(0, 0, 0));
        endDatetimeB =  moment(new Date(year - 1, month, 0).setHours(0, 0, 0));
        
        return {startDatetime, endDatetime, startDatetimeB, endDatetimeB};
      case 'Last90-PrevLast90':
        startDatetime = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
        startDatetime = this.removeSevenDays(startDatetime, reportType);

        endDatetime = moment(new Date().setHours(0, 0, 0));
        endDatetime = this.removeSevenDays(endDatetime, reportType);

        startDatetimeB = moment(new Date(year, month, day - 180).setHours(0, 0, 0));
        startDatetimeB = this.removeSevenDays(startDatetimeB, reportType);

        endDatetimeB = moment(new Date(year, month, day - 90).setHours(0, 0, 0));
        endDatetimeB = this.removeSevenDays(endDatetimeB, reportType);

        return {startDatetime, endDatetime, startDatetimeB, endDatetimeB};
    }
  }

  removeSevenDays(date, reportType) {
    if (reportType?.includes('rollup') || reportType?.includes('comparison')) {
      date = moment(date).subtract(7, 'days');
    }
    return date;
  }

  addSevenDays(date, reportType) {
    if (reportType?.includes('rollup') || reportType?.includes('comparison')) {
      date = moment(date).add(7, 'days');
    }
    return date;
  }

  updateReport(viewMode, idReport, report, automatedReport) {
    if (viewMode == 'new') {
      this.updateReportsInMongo(idReport, { ...report, startDatetime: report.startDatetime, endDatetime: report.endDatetime })
    } else {
      this.update(
        automatedReport,
        report.gid,
        idReport,
        { ...report, startDatetime: report.startDatetime, endDatetime: report.endDatetime })
    }


  }

  reportToDataPicker(report: Report, idReport: string, automatedReport= false, viewMode = 'new', refresh = false) {
    if(!refresh) {
      this.updateDynamicDates(report, idReport, automatedReport, viewMode)
    }
    
    const dataPicker: DataPicker = {
      range: {
        start: report.startDatetime,
        end: report.endDatetime
      },
      rangeB: null,
      aggregation: 'Month'
    };
    
    if (report.reportType.includes('comparison') || report.reportType === 'grade') {
      const startMoment = moment(report.startDatetimeB);
      const endMoment   = moment(report.endDatetimeB);

      dataPicker.rangeB = {
        start:  `${startMoment['year']()}-${startMoment['month']() +1}-${startMoment['date']()}`,
        end:  `${endMoment['year']()}-${endMoment['month']() +1}-${endMoment['date']()}`
      };
    }

    let locations = [];
    if (report.accounts) {
      report.accounts.forEach(account => {
        locations = locations.concat(account.locations);
      });
    } else if (report.locations) {
      locations = report.locations;
    }
    dataPicker.locations = locations;
    return dataPicker;
  }


  selectDatesFromDataPicker(dataPicker: DataPicker, rangeB = false) {
    const r = rangeB ? dataPicker.rangeB : dataPicker.range
    return {
        start: moment(r.start).utc(false).utcOffset(0),
        end:   moment(r.end)  .utc(false).utcOffset(0)
    }
  }


  convertReportDate(report: Report) {
    if (!report)
      return

    report.startDatetime = this.convertDate(report.startDatetime);
    report.endDatetime   = this.convertDate(report.endDatetime);

    if (report.reportType === 'comparison') {
      report.startDatetimeB = this.convertDate(report.startDatetimeB);
      report.endDatetimeB   = this.convertDate(report.endDatetimeB);
    }

    return report;
  }

  convertDate(date) {
    const zulu = new ZuluToDatePipe();
    return zulu.transform(date);
  }


  countLocation(element) {
    let counter = 0;
    element?.accounts?.forEach(account => {
      counter += account?.locations?.length;
    });
    return counter;
  }

  // TODO: Unused, remoe?
  // getReports(gid, reports) {
  //   const reports$ = [];
  //   reports.forEach(r => {
  //     reports$.push(this.afs.collection<any>(`${GROUPS}/${gid}/${REPORTS}`,
  //       ref => ref.orderBy('reportName')).doc(r.id).snapshotChanges().pipe(
  //         map(report => {
  //           const data = report.payload.data();
  //           if (!data) {
  //             return;
  //           }
  //           // tslint:disable-next-line: no-string-literal
  //           data['id'] = report.payload.id;
  //           return data;
  //         }),
  //     ));
  //   });
  //   return combineLatest(reports$);
  // }

  correctDateAggregate(reportDataPicker) {
    const start = moment(reportDataPicker.range.start).utc(true);
    const end   = moment(reportDataPicker.range.end).utc(true);
    const diff = end.diff(start, 'days') + 1;
    let value = 'Month'; 

    if (diff >= 90) {
      value = 'Month';
    } else if (diff >= 32) {
      value = 'Week';
    } else if (diff <= 31) {
      value = 'Day';
    } else {
      value = 'Month';
    }

    return value;
  }

  periodChange(period, startDateTime, endDateTime) {
    const startDate = moment(startDateTime).utc();
    const endDate = moment(endDateTime).utc();
    const data = {
      difference: null,
      startDateB: null,
      endDateB: null,
      rangeBDisabled: true
    }
    switch (period) {
      case 'period':
        data.difference = endDate.diff(startDate, 'days');
        data.endDateB = this.getFormattedMomentDate(startDate.subtract(1, 'days'));
        data.startDateB = this.getFormattedMomentDate(moment(data.endDateB).subtract(data.difference, 'days'));
        break;
      case 'year':
        data.startDateB = this.dateS.momentSubtractYearReport(startDate, 1);
        data.endDateB = this.dateS.momentSubtractYearReport(endDate, 1);
        break;
      case 'month':
        data.difference = endDate.diff(startDate, 'days');
        data.startDateB = this.getFormattedMomentDate(startDate.subtract(1, 'month').startOf('month'));
        data.endDateB = this.getFormattedMomentDate(moment(data.startDateB).endOf('month'));
        break;
      default:
       data.rangeBDisabled = false;
        break;
    }
    return data;
  }

  getFormattedMomentDate(date) {
    return `${date['year']()}-${date['month']() +1}-${date['date']()}`;
  }
  
  getFormattedStringDate(date) {
    return date?.includes('T') ? `${date.split('T')[0]}` : date;
  }

}
