<!-- <div class="row d-flex pr--20 pl--20"> -->
    <div class="col-2.5 input-field input-field--image bg--gray">
        <div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--sticky" *ngIf="isUploading">
            <mat-spinner></mat-spinner>
        </div>
        <label for="fileInput{{imageId}}" class="">
            <div class="preview-text txt--center p--6 border--dashed border--darkgray">
                <img src="/assets/images/add_media.svg" alt="Upload photos here." class="img--xs mb--10">
                <span class="d-block txt--sm txt--lightgray" *ngIf="actionText.toLowerCase() == 'post'">
                    <!-- Drag or Upload <br> Images or Videos -->
                    Upload Image
                </span>
            </div>
        </label>
        <input 
            #fileInput 
            type="file" 
            name="fileMedia" 
            id="fileInput{{imageId}}" 
            [multiple]="multiple" 
            hidden 
            [accept]="requirements.accepts"
            (change)="filesChanged($event.target)" 
        >
    </div>

<!-- <mat-progress-bar 
    mode="determinate" 
    [value]="percentUpload"
    *ngIf="percentUpload && percentUpload !=100"
></mat-progress-bar> -->