<div class="box__content box--padding-xxs" *ngIf="dataset">
  <div *ngIf="showLegend" >
    <!--checkbox-->
    <div class="p--lr-30 d-flex justify-content-start align-items-center flex-wrap mb--10" [class.justify-content-center]="centerLegend" *ngIf="!disabledLengend">
      <div class="d-flex align-items-center mb--10 mr--10" *ngFor="let item of dataset; let i = index">
        <div (click)="actionChartLine.hideDataset(i, !actionChartLine.isDatasetHidden(i))" class="mat-checkbox--square mr--10" [ngStyle]="{'border': '2px solid ' + item.borderColor}">
          <div *ngIf="!actionChartLine.isDatasetHidden(i)" class="mat-checkbox--square--check" [ngStyle]="{'border': 'solid ' + item.borderColor, 'border-width': '0 2px 2px 0'}">
          </div>
        </div>
        <p class="txt--sm mr--5 mb--0" >{{item.label}}</p>
      </div>
    </div>

    <div class="p--lr-30 d-flex justify-content-start align-items-center flex-wrap mb--10" [class.justify-content-center]="centerLegend" *ngIf="disabledLengend">
      <div class="d-flex align-items-center mb--10 mr--10" *ngFor="let item of dataset; let i = index">
        <div class="mat-checkbox--square mr--10" [ngStyle]="{'background': item.borderColor}"></div>
        <p class="txt--sm mr--5 mb--0" >{{item.label}}</p>
      </div>
    </div>
  </div>
  <div class="w--100" style="min-height: 300px" >
    <!-- adding ID with labels as the value for Cypress Tests -->
    <canvas [attr.id-ct]="labels"
            #actionChartLine="base-chart" baseChart
            [datasets]="dataset"
            [labels]="labels"
            [legend]="barChartLegend"
            [options]="barChartOptions"
            [colors]="colors"
            [chartType]="chartType">
    </canvas>
  </div>

</div>
