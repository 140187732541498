// dep
import { Injectable } from '@angular/core'
import { ActivatedRoute, CanActivate, Router } from '@angular/router'
import { Observable } from 'rxjs'

// app
import { ISubscription } from '../constants/subscription';
import {AuthService} from "./auth.service";
import { ModalTrialEnded } from '../modules/modal-trial-ended/modal-trial-ended.component'
import { ModalService } from './modal.service'
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  subscription$: Observable<ISubscription>;
  subscription: ISubscription;

  private _modalTrialEndedOpened = false;
  
  constructor(
    private _router: Router, 
    private _auth: AuthService, 
    private _modalService: ModalService,
  ) {
    this.subscription$ = this._auth.subscription$
    this.subscription$.subscribe(
      subscription => {
        this.subscription = subscription
        if (subscription?.status === 'BLOCKED') {
          let message
          let buttons
          switch (this.subscription?.pricingVersion) {
            case 1:
            case 2: {
              message = 'Schedule a call with us to upgrade your account and continue accessing Map Labs!'
              buttons = {
                'accept_icon': 'far fa-calendar',
                'accept_text': 'Schedule Call',
                'accept_cta': 'https://calendly.com/maplabs/discovery-call',
                'cancel_text': 'Logout',
              }
              this._openModal(buttons, message)
              break
            }
            case 3: {
              message = 'Reach out to our sales team to upgrade your account and continue accessing Map Labs!'
              buttons = {
                'accept_icon': 'fas fa-paper-plane',
                'accept_text': 'Contact Sales',
                'accept_cta': 'mailto:sales@maplabs.com',
                'cancel_text': 'Logout',
              }
              this._openModal(buttons, message)
              break
            }
          }
        }
      }
    )
  }

  private _openModal(buttons: any, message: string): void {
    if (!this._modalTrialEndedOpened) {
      this._modalService.openModal(ModalTrialEnded,
        {
          title: 'Your Free Subscription Has Ended',
          message: message,
          buttons: buttons
        }, 
        { 
          config: { 
            width: '750px',
            disableClose: true 
          }
        }
      )
      this._modalTrialEndedOpened = true
    }
  }

  canActivate(): boolean {
    if (this.subscription?.status === "BLOCKED") {
      let message
      let buttons
      switch (this.subscription?.pricingVersion) {
        case 1:
        case 2: {
          message = 'Schedule a call with us to upgrade your account and continue accessing Map Labs!'
          buttons = {
            'accept_icon': 'far fa-calendar',
            'accept_text': 'Schedule Call',
            'accept_cta': 'https://calendly.com/maplabs/discovery-call',
            'cancel_text': 'Logout',
          }
          this._openModal(buttons, message)
          break
        }
        case 3: {
          message = 'Reach out to our sales team to upgrade your account and continue accessing Map Labs!'
          buttons = {
            'accept_icon': 'fas fa-paper-plane',
            'accept_text': 'Contact Sales',
            'accept_cta': 'mailto:sales@maplabs.com',
            'cancel_text': 'Logout',
          }
          this._openModal(buttons, message)
          break
        }
      }
     }
    if ((!this._auth.authenticated && !this._auth.session)) return false
    return true
  }
}
