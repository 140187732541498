<ng-container *ngIf="!isNewPost">
  <table class="table table--sm table--last-row-border bg--white">
    <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell txt--left"><strong>Media</strong></th>
      <th class="mat-header-cell txt--left"><strong>Description</strong></th>
      <th class="mat-header-cell txt--left"><strong>{{ type === 'POSTED' ||  type === 'ARCHIVE' ? 'Date Published' : 'Created at' }}</strong></th>
      <th class="mat-header-cell txt--left" *ngIf="type === 'POSTED' ||  type === 'ARCHIVE'"><strong>Last Updated</strong></th>
      <th class="mat-header-cell txt--left" *ngIf="type === 'POSTED' ||  type === 'ARCHIVE'"><strong>Total Views</strong></th>
      <th class="mat-header-cell txt--left" *ngIf="type === 'POSTED' ||  type === 'ARCHIVE'"><strong>Total Actions</strong></th>
      <th class="mat-header-cell txt--center" *ngIf="type === 'PENDING' || type === 'DRAFTS'"><strong>Status</strong></th>
      <th class="mat-header-cell txt--center" *ngIf="type === 'PENDING'"><strong>Progress</strong></th>
      <th class="mat-header-cell txt--right"><strong>Actions</strong></th>
    </tr>
    </thead>
    <tbody>
      <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">

        <td class="mat-cell cdk-column-img">
          <div class="cell__content">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '100px', height: '100px', 'border-radius': '4px', margin: '0' }"></ngx-skeleton-loader>
          </div>
        </td>

        <td class="mat-cell" [style.width]="type === 'PENDING'? '680px' : '900px'">
          <ngx-skeleton-loader count="2" [theme]="{ width: '100px', height: '20px', 'display': 'block', 'margin-bottom': '10px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '90%', height: '40px', 'margin-top': '10px' }"></ngx-skeleton-loader>
        </td>

        <td class="mat-cell cdk-column-date">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>

        <td class="mat-cell cdk-column-date-updated" *ngIf="type === 'POSTED' ||  type === 'ARCHIVE'">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>

        <td class="mat-cell cdk-column-view" *ngIf="type === 'POSTED' ||  type === 'ARCHIVE'">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>

        <td class="mat-cell cdk-column-actionsT" *ngIf="type === 'POSTED' ||  type === 'ARCHIVE'">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px' }"></ngx-skeleton-loader>
        </td>

        <td class="mat-cell mat-column-status" *ngIf="type === 'PENDING' || type === 'DRAFTS'">
          <span class="d-flex justify-content-center align-items-center">
            <ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px'}"></ngx-skeleton-loader>
          </span>
        </td>

        <td class="mat-cell mat-column-status" *ngIf="type === 'PENDING'">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'border-radius': '25px',
              height: '20px',
              'background-color': '#992929',
              border: '1px solid white'
            }"
          ></ngx-skeleton-loader>
        </td>

        <td class="mat-cell mat-column-actions txt--right">
          <ngx-skeleton-loader count="{{ type === 'POSTED' || type === 'ARCHIVE' ? 1 : 2 }}" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0 10px 0 0', 'border-radius': '5px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader *ngIf="type !== 'ARCHIVE'" count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="isNewPost">
  <table class="table table--sm table--last-row-border bg--white">
    <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell txt--center row mt--20 mb--20" style="width: 435px;" *ngIf="dashboardType !== 'ARCHIVE'">
        <mat-checkbox class="mt--5" disabled></mat-checkbox>
                
        <button mat-flat-button color="warn" class="btn btn-icon buttons-actions mr--20" disabled>
          <em class="far fa-trash-alt"></em>
        </button>
  
        <button mat-flat-button color="accent" class="btn btn--icon mr--20 pt--10 pb--10"  ngDefaultControl disabled>
          Post Status <em class="fal fa-angle-down ml--10" data-html2canvas-ignore></em>
        </button>
  
        <button mat-flat-button color="accent" class="btn btn--icon pt--10 pb--10" ngDefaultControl disabled>
          Post Occurence <em class="fal fa-angle-down ml--10" data-html2canvas-ignore></em>
        </button>            
      </th>
      <th class="mat-header-cell" *ngIf="dashboardType === 'ARCHIVE'"><strong>Media</strong></th>
      <th class="mat-header-cell txt--center" style="width: 24%!important;"><strong></strong></th>
      <th class="mat-header-cell" *ngIf="dashboardType === 'ARCHIVE'" style="width: 20%;"><strong>Description</strong></th>
      <th class="mat-header-cell" style="width: 17%!important;"><strong>Post Time & Date (last updated)</strong></th>
      <th class="mat-header-cell txt--center"><strong>Status</strong></th>
      <th class="mat-header-cell txt--center" *ngIf="dashboardType !== 'ARCHIVE'"><strong>Post Actions</strong></th>
    </tr>
    </thead>
    <tbody>
      <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">
        <td class="mat-cell cdk-column-img">
          <div class="row mb--10 mt--10">
            <ngx-skeleton-loader count="1" appearance="circle" class="col-4 p--0" [theme]="{ width: '100px', height: '100px', 'border-radius': '4px', margin: '0' }"></ngx-skeleton-loader>
            <div class="col-6 p--0" *ngIf="dashboardType !== 'ARCHIVE'">
              <ngx-skeleton-loader count="2" [theme]="{ width: '100px', height: '20px', 'display': 'block', 'margin-bottom': '10px' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ width: '90%', height: '40px', 'margin-top': '10px' }"></ngx-skeleton-loader>
            </div>
          </div>
        </td>
  
        <td class="mat-cell" style="width: 900px;"></td>

        <td class="mat-cell cdk-column-date" *ngIf="dashboardType === 'ARCHIVE'">
          <ngx-skeleton-loader count="4" class="mb--10 mt--10" [theme]="{ width: '150px', height: '20px', 'display': 'block' }"></ngx-skeleton-loader>
        </td>
  
        <td class="mat-cell cdk-column-date">
          <ngx-skeleton-loader count="4" class="mb--10 mt--10" [theme]="{ width: '150px', height: '20px', 'display': 'block' }"></ngx-skeleton-loader>
        </td>
  
        <td class="mat-cell cdk-column-status txt--center">
          <ngx-skeleton-loader count="1" [theme]="{ width: '185px', height: '60px', 'border-radius': '100px' }"></ngx-skeleton-loader>
        </td>
  
        <td class="mat-cell mat-column-actions txt--center" *ngIf="dashboardType !== 'ARCHIVE'">
          <ngx-skeleton-loader count="2" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0 10px 0 0', 'border-radius': '5px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin': '0', 'border-radius': '5px' }"></ngx-skeleton-loader>
        </td>
        
      </tr>
    </tbody>
  </table>
</ng-container>
