import { EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
var FormCreateProtocolComponent = /** @class */ (function () {
    function FormCreateProtocolComponent(fb) {
        this.fb = fb;
        this.isValid = new EventEmitter();
        this.starsArray = [1, 2, 3, 4, 5];
        this.showKeywords = false;
        this.selectedStars = [];
    }
    FormCreateProtocolComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    FormCreateProtocolComponent.prototype.withoutsChange = function () {
        var _this = this;
        var keywords = this.form.controls['triggers'].get('keywords').value;
        var withOutComment = this.form.controls['withOutComment'].value;
        var withComment = this.form.controls['withComment'].value;
        if ((withComment && withOutComment) || (!withComment && !withOutComment)) {
            keywords.forEach(function (a, i) { return _this.keywords.removeAt(i); });
            this.showKeywords = false;
        }
        else if (withComment) {
            this.showKeywords = true;
        }
    };
    FormCreateProtocolComponent.prototype.initForm = function () {
        var _this = this;
        if (this.protocolEdit) {
            var keywords_1 = new FormArray([]);
            if (this.protocolEdit.withComment && !this.protocolEdit.withOutComment)
                this.showKeywords = true;
            this.protocolEdit.triggers.keywords.forEach(function (k) {
                keywords_1.push(new FormControl(k));
            });
            var excludeKeywords_1 = new FormArray([]);
            if (this.protocolEdit.triggers.excludeKeywords && this.protocolEdit.triggers.excludeKeywords.length > 0) {
                this.protocolEdit.triggers.excludeKeywords.forEach(function (k) {
                    excludeKeywords_1.push(new FormControl(k));
                });
            }
            this.form = this.fb.group({
                name: [this.protocolEdit.name, [Validators.required, Validators.minLength(3)]],
                withComment: [this.protocolEdit.withComment],
                withOutComment: [this.protocolEdit.withOutComment],
                triggers: this.fb.group({
                    delay: [this.protocolEdit.triggers.delay, Validators.required],
                    keywords: keywords_1,
                    excludeKeywords: excludeKeywords_1
                }),
                stars: [[], Validators.required]
            });
            this.protocolEdit.stars.forEach(function (star) {
                var event = new MatCheckboxChange();
                event.source = null;
                event.checked = true;
                _this.checkedStars(event, star - 1);
            });
        }
        else {
            this.form = this.fb.group({
                name: ['', [Validators.required, Validators.minLength(3)]],
                withComment: [false],
                withOutComment: [false],
                triggers: this.fb.group({
                    delay: [0, [Validators.min(0)]],
                    keywords: new FormArray([]),
                    excludeKeywords: new FormArray([]),
                }),
                stars: [[], Validators.required]
            });
        }
        this.form.valueChanges.subscribe(function () {
            if (_this.form.valid) {
                var keywords = _this.form.controls['triggers'].get('keywords').value;
                if (keywords.length > 0) {
                    var some_empty = keywords.some(function (k) { return k === ""; });
                    if (some_empty) {
                        _this.isValid.emit(false);
                    }
                }
                _this.isValid.emit(_this.form.value);
            }
            else {
                _this.isValid.emit(false);
            }
        });
    };
    FormCreateProtocolComponent.prototype.checkWord = function () {
        return function (control) {
            var word = control.value;
            if (word.split(' ').length > 1)
                return { 'notWord': true };
            return;
        };
    };
    FormCreateProtocolComponent.prototype.checkedStars = function ($event, i) {
        if ($event.checked) {
            this.selectedStars.push(i + 1);
            this.starsTouched = true;
        }
        else {
            var index = this.selectedStars.findIndex(function (s) { return s === i + 1; });
            this.selectedStars.splice(index, 1);
        }
        this.stars.setValue(this.selectedStars);
        if (this.form.valid && this.stars.value.length > 0) {
            this.isValid.emit(this.form.value);
        }
    };
    FormCreateProtocolComponent.prototype.triggerNumberUp = function ($event) {
        var targetParent = $event.target.closest('.input-number-wrapper');
        var input = targetParent.querySelector('input');
        input.stepUp();
        this.delay.setValue(input.value);
    };
    FormCreateProtocolComponent.prototype.triggerNumberDown = function ($event) {
        var targetParent = $event.target.closest('.input-number-wrapper');
        var input = targetParent.querySelector('input');
        input.stepDown();
        this.delay.setValue(input.value);
    };
    FormCreateProtocolComponent.prototype.pushKeyword = function () {
        this.keywords.push(new FormControl('', [Validators.required, this.validateCommaa()]));
    };
    FormCreateProtocolComponent.prototype.pushNegativeKeyword = function () {
        this.excludeKeywords.push(new FormControl('', [Validators.required, this.validateCommaa()]));
    };
    FormCreateProtocolComponent.prototype.validateCommaa = function () {
        return function (control) {
            var word = control.value;
            if (word.includes(',')) {
                return { hasComma: true };
            }
            return;
        };
    };
    FormCreateProtocolComponent.prototype.removeKeyword = function (index) {
        this.keywords.removeAt(index);
    };
    FormCreateProtocolComponent.prototype.removeNegativeKeyword = function (index) {
        this.excludeKeywords.removeAt(index);
    };
    Object.defineProperty(FormCreateProtocolComponent.prototype, "name", {
        get: function () {
            return this.form.get('name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormCreateProtocolComponent.prototype, "withComment", {
        get: function () {
            return this.form.get('withComment');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormCreateProtocolComponent.prototype, "withOutComment", {
        get: function () {
            return this.form.get('withOutComment');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormCreateProtocolComponent.prototype, "triggers", {
        get: function () {
            return this.form.get('triggers');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormCreateProtocolComponent.prototype, "delay", {
        get: function () {
            return this.triggers.get('delay');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormCreateProtocolComponent.prototype, "keywords", {
        get: function () {
            return this.triggers.get('keywords');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormCreateProtocolComponent.prototype, "excludeKeywords", {
        get: function () {
            return this.triggers.get('excludeKeywords');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormCreateProtocolComponent.prototype, "stars", {
        get: function () {
            return this.form.get('stars');
        },
        enumerable: true,
        configurable: true
    });
    FormCreateProtocolComponent.prototype.inputKeyword = function (i) {
        return this.keywords.at(i).errors;
    };
    FormCreateProtocolComponent.prototype.inputNegativeKeyword = function (i) {
        return this.excludeKeywords.at(i).errors;
    };
    return FormCreateProtocolComponent;
}());
export { FormCreateProtocolComponent };
