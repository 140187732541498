<ng-container *ngIf="!isSocialMedia">
  <div class="dialog__header" *ngIf="!bulk">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Attributes</strong></h2>
  </div>
  <div class="dialog__content" mat-dialog-content>
    <div class="input-field input-field--search">
      <div class="table__icon table__icon--lg">
        <em class="fas fa-search"></em>
      </div>
      <input (keyup)="applyFilter($event.target.value)" placeholder="Type to search">
    </div>
    <ng-container *ngFor="let group of groups; index as groupIndex;">
      <h3><strong *ngIf="!isBoolean(group)">{{group.groupName}}</strong></h3>
      <div class="dialog-row pb--0">
        <mat-chip-list class="mat-chip-list-stacked alternate-theme">

          <ng-container *ngFor="let item of group.items; index as itemIndex">
            <ng-container *ngIf="item.valueType === 'BOOL' && item.displayName">
              <span class="mat-chip--half mb--10">
              <mat-chip (click)="changeStatus(groupIndex, itemIndex)">
                <em class="far fa-check mr--15" *ngIf="item.active === true"></em>
                <em class="far fa-ban mr--15" *ngIf="item.active === false"></em>
                <em class="far fa-plus mr--15" *ngIf=" item.active==undefined"></em>
                {{item.displayName}}
              </mat-chip>
            </span>
            </ng-container>

            <ng-container *ngIf="item.valueType == 'ENUM' || item.valueType == 'REPEATED_ENUM' ">
              <p class="sub-category"><strong *ngIf="item.displayName">{{item.displayName}}</strong></p>

              <span class="mat-chip--half mb--10" style="width: auto" *ngFor="let metadata of item.valueMetadata; index as metaIndex">
                <mat-chip (click)="changeStatusEnum(groupIndex, itemIndex, metaIndex); changeStatus(groupIndex, itemIndex)" *ngIf="metadata?.displayName">
                  <em class="far fa-check mr--15" *ngIf="metadata.active === true"></em>
                  <em class="far fa-ban mr--15" *ngIf="metadata.active === false"></em>
                  <em class="far fa-plus mr--15" *ngIf=" metadata.active==undefined"></em>
                  {{metadata?.displayName}}
                </mat-chip>
              </span>

            </ng-container>
          </ng-container>
        </mat-chip-list>
      </div>
      <mat-divider class="m--10" *ngIf="!isBoolean(group) && isNotLastVisibleGroup(groupIndex)"></mat-divider>
    </ng-container>

  </div>
  <div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
      <span class="alternate-theme d-inline-block mr--10">
          <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
      </span>
    <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
  </div>
</ng-container>  

<ng-container *ngIf="isSocialMedia">
  <div class="dialog__header" *ngIf="!bulk">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Social Media Profiles</strong></h2>
  </div>
  <ng-container *ngIf="socialMediaData">
    <div class="dialog-row row justify-content-between align-items-center p--0 mt--10" *ngFor="let control of socialMediaData.controls; let i = index;" [formGroup]="control">
      <section class="col-4 pr--0">
        <mat-select class="input-group__field mr--10" formControlName="socialMedia" placeholder="Select Social Media">
          <mat-select-trigger>
            <ng-container *ngIf="getOption(control)">
              <svg-icon [src]="getOption(control)?.icon" class="menu__item__icon vertical--align--sub"></svg-icon>
              {{ getOption(control)?.label }}
            </ng-container>
          </mat-select-trigger>

          <mat-option *ngFor="let option of getAvailableOptions(control)" [value]="option.value">
            <svg-icon [src]="option.icon" class="menu__item__icon vertical--align--sub"></svg-icon>{{option.label}}
          </mat-option>
        </mat-select> 
        <mat-error *ngIf="hasError(i, 'socialMedia', 'required')">The social media profile is required</mat-error>
      </section>

      <section class="col-6 p--0">
        <mat-form-field class="input-group input-group--override input-group--override-label p--0">
          <input matInput class="input-group__field" formControlName="url" placeholder="Url"/>
        </mat-form-field>
        <mat-error *ngIf="hasError(i, 'url', 'pattern')">Incorrect format. Example: http://myurl.com or https://www.myurl.test</mat-error>
        <mat-error *ngIf="hasError(i, 'url', 'required')">The URL is required</mat-error>
      </section>

      <button mat-button class="btn btn-icon col-1 p--0" (click)="removeSocialMedia(i)"><em class="fas fa-times"></em></button>
    </div>
  </ng-container>

  <div class="pb--0 row mt--10">
    <button mat-button class="btn btn--md d-flex align-items-center" (click)="addSocialMedia()" [disabled]="socialMediaData.length === 7">
      <img src="/assets/images/add-btn.png" class="mr--10 img--xxs">Add social media profile</button>
  </div>

  <div mat-dialog-actions class="dialog__footer">
      <span class="alternate-theme d-inline-block mr--10">
          <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
      </span>
    <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
  </div>
</ng-container>
