import { __awaiter, __generator } from "tslib";
import { ChangeDetectorRef, OnInit, ViewRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { COLORS_CHARTS } from 'src/app/constants/chart-color';
import { forkJoin, Subject } from 'rxjs';
import { SearchKeywordsService } from 'src/app/services/search-keywords.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReportService } from 'src/app/services/report.service';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PaginatorComponent } from '../../charts/paginator/paginator.component';
import { ModalService } from 'src/app/services/modal.service';
import { AlertType } from 'src/app/components/alert.component';
import { LocationService } from 'src/app/services/location.service';
var SearchKeywordsComponent = /** @class */ (function () {
    function SearchKeywordsComponent(route, cdRef, SearchesKeywordsServ, auth, reportS, modalS, locationS) {
        this.route = route;
        this.cdRef = cdRef;
        this.SearchesKeywordsServ = SearchesKeywordsServ;
        this.auth = auth;
        this.reportS = reportS;
        this.modalS = modalS;
        this.locationS = locationS;
        this.isReport = false;
        this.isShared = false;
        this.minDate = null;
        this.maxDate = null;
        this.loading = true;
        this.updatingData = false;
        this.locations = [];
        this.dataSourceMultiLoc = new MatTableDataSource([]);
        this.selectDate = {};
        this.searchKeywordsData = {};
        this.totalKeywordsData = {};
        this.totalImpressionsData = {};
        this.searchImpressionsData = {};
        this.keywordsDatasource = new MatTableDataSource([]);
        this.displayedColumnsKeywords = ['keywords', 'impressions'];
        this.paginate = { size: 25, page: 1 };
        this.paginationMultiloc = {
            items: [],
            per_page: this.paginate.size,
            page: 1,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.dataRange = { value: 'prevYearComparison', displayName: 'Previous Year' };
        this.colors = COLORS_CHARTS;
        this.labels = [];
        this.sort = {
            sortBy: 'impressions',
            sortOrder: -1,
            sortDirection: 'desc'
        };
        this.sortMultiloc = {
            sortBy: 'locationName',
            direction: 'asc'
        };
        this.displayedColumnsMultLoc = ['location', 'Unique Keywords', 'Keywords with > 15 Impressions', 'Keywords with < 15 Impressions', 'Est. Total impressions'];
        this.fieldsColumnsMultiLoc = [
            { displayName: 'locationName', fieldSort: 'locationName' },
            { displayName: 'totalKeywords', fieldSort: 'totalKeywords' },
            { displayName: 'more15Keywords', fieldSort: 'more15Keywords' },
            { displayName: 'less15Keywords', fieldSort: 'less15Keywords' },
            { displayName: 'estTotalimpressions', fieldSort: 'totalImpressionsLow' }
        ];
        this.paginateMultiloc = { size: 10, page: 1 };
        this.isComparisonVisible = false;
        this.keywordSearchInput = new FormControl();
        this.locationIds = [];
        this.keywordSubject = new Subject();
        this.isProgressCSV = false;
        this.topicsGraph = [];
        this.fieldsColumnsTopics = ['no', 'details'];
        this.dataSourceTopics = new MatTableDataSource([]);
        this.topicsPaginate = { size: 25, page: 1 };
        this.topicPagination = {
            items: [],
            per_page: this.paginate.size,
            page: 1,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.tableLoading = true;
    }
    SearchKeywordsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (!this.isReport) {
            this.accountId = this.route.parent.snapshot.params.accountId;
            this.locationId = this.route.parent.snapshot.params.locationId;
        }
        else {
            this.reportId = this.route.snapshot.params.id;
            if (((_b = (_a = this.report) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                this.locations = (_d = (_c = this.report) === null || _c === void 0 ? void 0 : _c.accounts[0]) === null || _d === void 0 ? void 0 : _d.locations;
            }
            this.dataRange = (((_e = this.report) === null || _e === void 0 ? void 0 : _e.compareToValue) && Object.keys((_f = this.report) === null || _f === void 0 ? void 0 : _f.compareToValue).length) ? (_g = this.report) === null || _g === void 0 ? void 0 : _g.compareToValue : this.dataRange;
            this.report.showComparison = this.report.showComparison || false;
            if (this.report.showComparison) {
                this.isComparisonVisible = true;
                this.displayedColumnsKeywords = ['keywords', 'impressions', 'differencePercentage'];
                this.sort = {
                    sortBy: (_h = this.dataRange) === null || _h === void 0 ? void 0 : _h.value,
                    sortOrder: -1,
                    sortDirection: 'desc'
                };
            }
            ;
        }
        this.minDate = (_j = this.minDate) === null || _j === void 0 ? void 0 : _j.startOf('month');
        this.maxDate = (_k = this.maxDate) === null || _k === void 0 ? void 0 : _k.endOf('month');
        this.gid = this.auth.session.gid;
        this.location = [{ accountId: this.accountId, locationId: this.locationId }];
        this.selectDate = this.isReport ? { start: this.report.startDatetime, end: this.report.endDatetime } : this.locationS.buildDatepickerDate('keyword', this.maxDate);
        this.keywordSearchInput.valueChanges
            .subscribe(function (keyword) {
            _this.keywordSubject.next(keyword ? keyword : '');
        });
        this.keywordSubject.pipe(debounceTime(650), switchMap(function (keyword) {
            var _a, _b;
            if (_this.keywordCurrentValue !== keyword) {
                _this.paginate.page = 1;
                _this.keywordCurrentValue = keyword;
                _this.paginatorChild.reset();
            }
            _this.updatingData = true;
            var startDate = _this.getDays().startDate;
            var endDate = _this.getDays().endDate;
            if (keyword && !_this.isReport) {
                return _this.SearchesKeywordsServ.getSearchKeywordsAtlas(keyword, _this.paginate, startDate, endDate, (_a = _this.dataRange) === null || _a === void 0 ? void 0 : _a.value, [_this.locationId], _this.sort);
            }
            else if (keyword && _this.isReport) {
                return _this.SearchesKeywordsServ.getSearchKeywordsAtlas(keyword, _this.paginate, startDate, endDate, (_b = _this.dataRange) === null || _b === void 0 ? void 0 : _b.value, _this.locationIds, _this.sort);
            }
            else if (!keyword && _this.isReport) {
                var period = _this.dataRange.value.replace('Comparison', '');
                return _this.reportS.getSearchKeywordsReports(_this.auth.session.gid, _this.reportId, period, _this.paginate, _this.sort, startDate, endDate);
            }
            else if (!keyword && !_this.isReport) {
                var period = _this.dataRange.value.replace('Comparison', '');
                return _this.SearchesKeywordsServ.getSearchKeywords(_this.locationId, _this.gid, _this.accountId, period, startDate, endDate, _this.sort, _this.paginate);
            }
        }))
            .subscribe(function (result) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            _this.updatingData = false;
            if (_this.keywordSearchInput.value) {
                _this.keywordsDatasource = new MatTableDataSource((_a = result) === null || _a === void 0 ? void 0 : _a.data);
                _this.pagination.per_page = _this.paginate.size;
                _this.pagination.page = _this.paginate.page;
                _this.pagination.hasNext = (_b = result) === null || _b === void 0 ? void 0 : _b.hasNext;
                _this.pagination.hasPrev = (_c = result) === null || _c === void 0 ? void 0 : _c.hasPrev;
                _this.pagination.items = (_d = result) === null || _d === void 0 ? void 0 : _d.data;
                _this.pagination.pages = Math.ceil(((_e = result) === null || _e === void 0 ? void 0 : _e.total) / _this.pagination.per_page);
                _this.pagination.total = (_f = result) === null || _f === void 0 ? void 0 : _f.total;
            }
            else {
                _this.keywordsDatasource = new MatTableDataSource((_g = result) === null || _g === void 0 ? void 0 : _g.items);
                _this.pagination = {
                    per_page: (_h = result) === null || _h === void 0 ? void 0 : _h.per_page,
                    page: (_j = result) === null || _j === void 0 ? void 0 : _j.page,
                    hasNext: (_k = result) === null || _k === void 0 ? void 0 : _k.hasNext,
                    hasPrev: (_l = result) === null || _l === void 0 ? void 0 : _l.hasPrev,
                    pages: (_m = result) === null || _m === void 0 ? void 0 : _m.pages,
                    total: (_o = result) === null || _o === void 0 ? void 0 : _o.keywordsCount,
                    items: (_p = result) === null || _p === void 0 ? void 0 : _p.items
                };
            }
        }, function (err) {
            _this.updatingData = false;
            _this.keywordsDatasource = new MatTableDataSource([]);
        });
        if (this.isReport || this.isShared) {
            this.getReportData();
        }
        else {
            this.getData();
        }
    };
    SearchKeywordsComponent.prototype.getDays = function () {
        var _a, _b, _c, _d;
        return {
            startDate: ((_a = this.selectDate) === null || _a === void 0 ? void 0 : _a.start) ? moment((_b = this.selectDate) === null || _b === void 0 ? void 0 : _b.start).format('YYYY-MM-DD') : null,
            endDate: ((_c = this.selectDate) === null || _c === void 0 ? void 0 : _c.end) ? moment((_d = this.selectDate) === null || _d === void 0 ? void 0 : _d.end).format('YYYY-MM-DD') : null
        };
    };
    SearchKeywordsComponent.prototype.getPercentage = function (value) {
        return value > 1000 ? 1000 : value;
    };
    SearchKeywordsComponent.prototype.getData = function () {
        var _this = this;
        var startDate = this.getDays().startDate;
        var endDate = this.getDays().endDate;
        var period = this.dataRange.value.replace('Comparison', '');
        forkJoin([
            this.SearchesKeywordsServ.getSearchKeywords(this.locationId, this.gid, this.accountId, period, startDate, endDate, this.sort, this.paginate),
            this.SearchesKeywordsServ.getSearchImpressions(this.locationId, this.gid, this.accountId, startDate, endDate),
            this.SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, null, this.locationId, this.accountId)
        ]).subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
            var dateValidations, dates;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.locationS.getDateValidations('keyword', [this.accountId], [this.auth.session.gid], [this.locationId]).toPromise()];
                    case 1:
                        dateValidations = _e.sent();
                        dates = this.locationS.dateValidation(dateValidations);
                        this.minDate = (_a = dates.minDate) === null || _a === void 0 ? void 0 : _a.startOf('month');
                        this.maxDate = (_b = dates.maxDate) === null || _b === void 0 ? void 0 : _b.endOf('month');
                        this.buildSearchData(result[0]);
                        this.buildCardsData(result[0], (_c = result[1]) === null || _c === void 0 ? void 0 : _c.dateFrom, (_d = result[1]) === null || _d === void 0 ? void 0 : _d.dateTo);
                        this.buildImpressionsData(result[1]);
                        this.buildTopics(result[2]);
                        this.loading = false;
                        this.updatingData = false;
                        this.detectChanges();
                        return [2 /*return*/];
                }
            });
        }); }, function (err) {
            _this.loading = false;
            _this.updatingData = false;
            _this.modalS.openConfirmModal('Loading error', 'There was an error while loading the data. Please try again or contact support (error code 3)', (function () {
                _this.auth.signOut(true, true);
            }), AlertType.ERROR, 'Retry');
        });
    };
    SearchKeywordsComponent.prototype.detectChanges = function () {
        if (this.cdRef !== null && this.cdRef !== undefined && !this.cdRef.destroyed) {
            this.cdRef.detectChanges();
        }
    };
    SearchKeywordsComponent.prototype.getKeywordsTable = function () {
        var _this = this;
        var startDate = this.getDays().startDate;
        var endDate = this.getDays().endDate;
        var period = this.dataRange.value.replace('Comparison', '');
        var url = this.isReport ?
            this.reportS.getSearchKeywordsReports(this.auth.session.gid, this.reportId, period, this.paginate, this.sort, startDate, endDate) :
            this.SearchesKeywordsServ.getSearchKeywords(this.locationId, this.gid, this.accountId, period, startDate, endDate, this.sort, this.paginate);
        url.subscribe(function (result) {
            _this.buildSearchData(result);
            _this.updatingData = false;
            _this.detectChanges();
        }, function (err) {
            _this.updatingData = false;
        });
    };
    SearchKeywordsComponent.prototype.getReportData = function () {
        var _this = this;
        var _a, _b;
        this.tableLoading = true;
        var startDate = this.getDays().startDate;
        var endDate = this.getDays().endDate;
        this.dataPicker.range = {
            start: startDate,
            end: endDate
        };
        var period = (_b = (_a = this.dataRange) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.replace('Comparison', '');
        this.dataSourceMultiLoc = new MatTableDataSource([]);
        forkJoin([
            this.reportS.getSearchKeywordsReports(this.auth.session.gid, this.reportId, period, this.paginate, this.sort, startDate, endDate),
            this.reportS.getReportsByType(this.auth.session.gid, this.reportId, 'search-impressions', startDate, endDate),
            this.reportS.getTableData(this.gid, 'keywords', this.paginateMultiloc, this.sortMultiloc, this.dataPicker),
            this.SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, this.reportId, null, null)
        ]).subscribe(function (result) {
            var _a, _b, _c, _d;
            _this.locationIds = (_a = result[0]) === null || _a === void 0 ? void 0 : _a.locationIds;
            _this.buildSearchData(result[0]);
            _this.buildCardsData(result[0], (_b = result[1]) === null || _b === void 0 ? void 0 : _b.dateFrom, (_c = result[1]) === null || _c === void 0 ? void 0 : _c.dateTo);
            _this.buildImpressionsData(result[1]);
            _this.buildMultiLocTable((_d = result[2]) === null || _d === void 0 ? void 0 : _d.data);
            _this.buildTopics(result[3]);
            _this.loading = false;
            _this.updatingData = false;
            _this.tableLoading = false;
            _this.detectChanges();
        }, function (err) {
            _this.loading = false;
            _this.updatingData = false;
            _this.modalS.openConfirmModal('Loading error', 'There was an error while loading the data. Please try again or contact support (error code 4)', (function () {
                _this.auth.signOut(true, true);
            }), AlertType.ERROR, 'Retry');
        });
    };
    SearchKeywordsComponent.prototype.getMultilocTable = function () {
        var _this = this;
        this.updatingData = true;
        this.tableLoading = true;
        this.dataSourceMultiLoc = new MatTableDataSource([]);
        this.dataPicker.range = {
            start: this.getDays().startDate,
            end: this.getDays().endDate
        };
        this.reportS.getTableData(this.gid, 'keywords', this.paginateMultiloc, this.sortMultiloc, this.dataPicker).subscribe(function (res) {
            _this.buildMultiLocTable(res.data);
            _this.tableLoading = false;
            _this.updatingData = false;
        }, function (err) { return _this.updatingData = false; });
    };
    SearchKeywordsComponent.prototype.getTopicsTable = function () {
        var _this = this;
        var startDate = this.getDays().startDate;
        var endDate = this.getDays().endDate;
        this.SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, this.reportId, this.locationId, this.accountId).subscribe(function (res) {
            _this.updatingData = false;
            _this.buildTopics(res);
        }, function (err) {
            _this.updatingData = false;
        });
    };
    SearchKeywordsComponent.prototype.buildSearchData = function (data) {
        var _a, _b, _c;
        this.searchKeywordsData = data;
        this.keywordsDatasource = new MatTableDataSource(((_a = this.searchKeywordsData) === null || _a === void 0 ? void 0 : _a.items) || ((_b = this.searchKeywordsData) === null || _b === void 0 ? void 0 : _b.data));
        this.pagination = {
            per_page: data.per_page,
            page: data.page,
            hasNext: data.hasNext,
            hasPrev: data.hasPrev,
            pages: data.pages,
            total: data.keywordsCount,
            items: (_c = data) === null || _c === void 0 ? void 0 : _c.items
        };
    };
    SearchKeywordsComponent.prototype.buildCardsData = function (data, start, end) {
        var _this = this;
        var _a, _b, _c, _d;
        this.selectDate = {
            start: moment(start),
            end: moment(end)
        };
        this.totalKeywordsData = (_a = data) === null || _a === void 0 ? void 0 : _a.counts;
        (_b = this.totalKeywordsData) === null || _b === void 0 ? void 0 : _b.items.forEach(function (item, i) {
            item.backgroundColor = _this.colors[i].backgroundColor,
                item.borderColor = _this.colors[i].borderColor;
            item.tooltip = i == 0 ?
                'Google gives exact keyword impression data for keywords with more than (>) 15 impressions. This is the total number of unique keywords with more than (>) 15 impressions.' :
                'For keywords with fewer than (<) 15 impressions, Google does not provide impression data. This is the total number of unique keywords with less than (<) 15 impressions.';
        });
        this.totalImpressionsData = (_c = data) === null || _c === void 0 ? void 0 : _c.estimates;
        (_d = this.totalImpressionsData) === null || _d === void 0 ? void 0 : _d.items.forEach(function (item, i) {
            item.backgroundColor = _this.colors[i].backgroundColor,
                item.borderColor = _this.colors[i].borderColor;
        });
    };
    SearchKeywordsComponent.prototype.buildImpressionsData = function (data) {
        var _a;
        this.searchImpressionsData = data;
        this.labels = (_a = this.searchImpressionsData) === null || _a === void 0 ? void 0 : _a.labels;
    };
    SearchKeywordsComponent.prototype.buildMultiLocTable = function (data) {
        data.items.forEach(function (i) { var _a, _b; return i.estTotalimpressions = new Intl.NumberFormat().format(((_a = i) === null || _a === void 0 ? void 0 : _a.totalImpressionsLow) || 0) + " - " + new Intl.NumberFormat().format(((_b = i) === null || _b === void 0 ? void 0 : _b.totalImpressionsHigh) || 0); });
        this.dataSourceMultiLoc = new MatTableDataSource(data.items);
        this.paginationMultiloc = {
            items: data['items'],
            page: data['page'],
            pages: data['totalPages'],
            per_page: data['pageSize'],
            total: data['total'],
            hasNext: data['hasNext'],
            hasPrev: data['hasPrev']
        };
    };
    SearchKeywordsComponent.prototype.buildTopics = function (data) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        this.topicsGraph = (_a = data) === null || _a === void 0 ? void 0 : _a.searches;
        if (((_b = this.topicsGraph) === null || _b === void 0 ? void 0 : _b.total) > 0) {
            this.topicsGraph.items.forEach(function (el, i) {
                el.progressBgColorClass = _this.getGraphColors(i);
            });
        }
        this.dataSourceTopics = new MatTableDataSource((_d = (_c = data) === null || _c === void 0 ? void 0 : _c.keyword_trends) === null || _d === void 0 ? void 0 : _d.items);
        this.topicPagination = {
            per_page: (_f = (_e = data) === null || _e === void 0 ? void 0 : _e.keyword_trends) === null || _f === void 0 ? void 0 : _f.per_page,
            page: (_h = (_g = data) === null || _g === void 0 ? void 0 : _g.keyword_trends) === null || _h === void 0 ? void 0 : _h.page,
            hasNext: (_k = (_j = data) === null || _j === void 0 ? void 0 : _j.keyword_trends) === null || _k === void 0 ? void 0 : _k.hasNext,
            hasPrev: (_m = (_l = data) === null || _l === void 0 ? void 0 : _l.keyword_trends) === null || _m === void 0 ? void 0 : _m.hasPrev,
            pages: (_p = (_o = data) === null || _o === void 0 ? void 0 : _o.keyword_trends) === null || _p === void 0 ? void 0 : _p.pages,
            total: (_r = (_q = data) === null || _q === void 0 ? void 0 : _q.keyword_trends) === null || _r === void 0 ? void 0 : _r.total,
            items: (_t = (_s = data) === null || _s === void 0 ? void 0 : _s.keyword_trends) === null || _t === void 0 ? void 0 : _t.items
        };
    };
    SearchKeywordsComponent.prototype.getGraphColors = function (index) {
        var colors = ['progress--yellow', 'progress--green'];
        return colors[index];
    };
    SearchKeywordsComponent.prototype.topicsHandleReload = function (event) {
        this.updatingData = true;
        this.topicsPaginate = event;
        this.getTopicsTable();
    };
    SearchKeywordsComponent.prototype.handleExportCsvTopic = function (item) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var startDate, endDate;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isProgressCSV = true;
                        startDate = this.getDays().startDate;
                        endDate = this.getDays().endDate;
                        return [4 /*yield*/, this.SearchesKeywordsServ.handleExportCsvTopic(this.gid, this.reportId, this.accountId, this.locationId, startDate, endDate, (_a = item) === null || _a === void 0 ? void 0 : _a.topics)];
                    case 1:
                        _b.sent();
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsComponent.prototype.handleExportCsvKeywordsSearch = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var startDate, endDate, locations;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isProgressCSV = true;
                        startDate = this.getDays().startDate;
                        endDate = this.getDays().endDate;
                        if (this.locationIds.length > 0) {
                            locations = this.locationIds;
                        }
                        else {
                            locations = [this.locationId];
                        }
                        return [4 /*yield*/, this.SearchesKeywordsServ.handleExportCsvKeywordsSearch(startDate, endDate, locations, this.keywordSearchInput.value, (_a = this.dataRange) === null || _a === void 0 ? void 0 : _a.value, this.isComparisonVisible, this.sort)];
                    case 1:
                        _b.sent();
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsComponent.prototype.handleReload = function (event) {
        this.paginate = event;
        if (this.keywordSearchInput.value) {
            this.keywordSearchInput.setValue(this.keywordSearchInput.value);
        }
        else {
            this.updatingData = true;
            if (this.isReport || this.isShared) {
                this.getReportData();
            }
            else {
                this.getData();
            }
        }
    };
    SearchKeywordsComponent.prototype.sortChanged = function (event, onlyTable) {
        if (onlyTable === void 0) { onlyTable = false; }
        this.updatingData = true;
        this.sort = {
            sortBy: event.active === 'impressions' ? event.active : this.dataRange.value,
            sortOrder: event.direction === 'asc' ? 1 : -1,
            sortDirection: event.direction
        };
        this.paginate = {
            page: 1,
            size: this.paginate.size
        };
        if (this.keywordSearchInput.value) {
            this.keywordSearchInput.setValue(this.keywordSearchInput.value);
        }
        else {
            this.updatingData = true;
            if (this.isReport || this.isShared) {
                this.getReportData();
            }
            else {
                this.getData();
            }
        }
    };
    SearchKeywordsComponent.prototype.handleDataPicker = function (event) {
        this.updatingData = true;
        this.selectDate.start = event.range.start;
        this.selectDate.end = event.range.end;
        if (this.isReport || this.isShared) {
            this.getReportData();
        }
        else { // not report
            this.getData();
        }
    };
    SearchKeywordsComponent.prototype.handleSelectRange = function (event) {
        if (this.dataRange.value == event.value) {
            return;
        }
        this.dataRange = event;
        var sort = {
            active: this.isComparisonVisible ? 'differencePercentage' : 'impressions',
            direction: 'desc'
        };
        this.sortChanged(sort);
    };
    SearchKeywordsComponent.prototype.handleMultilocSort = function (event) {
        this.updatingData = true;
        this.paginateMultiloc = { size: this.paginateMultiloc.size, page: 1 };
        this.sortMultiloc = {
            sortBy: event.active.replace('_', ''),
            direction: event.direction
        };
        this.getMultilocTable();
    };
    SearchKeywordsComponent.prototype.handleMultilocPaginate = function (event) {
        this.updatingData = true;
        this.paginateMultiloc = event;
        this.getMultilocTable();
    };
    SearchKeywordsComponent.prototype.handleExport = function ($event) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var startDate, endDate, period;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.isProgressCSV = true;
                        startDate = this.getDays().startDate;
                        endDate = this.getDays().endDate;
                        period = !this.isComparisonVisible ? null : (_b = (_a = this.dataRange) === null || _a === void 0 ? void 0 : _a.displayName) === null || _b === void 0 ? void 0 : _b.split(' ')[1];
                        return [4 /*yield*/, this.reportS.handleExportCsvMongo(this.reportId, this.gid, this.accountId, this.locationId, startDate, endDate, '', "keywords", period)];
                    case 1:
                        _c.sent();
                        this.isProgressCSV = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsComponent.prototype.getImpressionsValue = function (numberOfImpressions) {
        return numberOfImpressions > 0 ? numberOfImpressions.toLocaleString("en-US") : '<15';
    };
    SearchKeywordsComponent.prototype.changedViewComparison = function (event) {
        this.isComparisonVisible = event;
        this.displayedColumnsKeywords = this.isComparisonVisible ? ['keywords', 'impressions', 'differencePercentage'] : ['keywords', 'impressions'];
        var sort = {
            active: this.isComparisonVisible ? 'differencePercentage' : 'impressions',
            direction: 'desc'
        };
        this.sortChanged(sort, true);
    };
    SearchKeywordsComponent.prototype.getTooltip = function (graph) {
        this.modalS.openInfoModal(graph, this.getTooltipText(graph));
    };
    SearchKeywordsComponent.prototype.getTooltipText = function (section) {
        switch (section) {
            case 'Impressions':
                return "<div class='txt--left'>Google gives exact keyword impression data for keywords with more than (>) 15 impressions. Exact data for keywords with less than (<)15 impressions is unavailable, but Google provides a range of 1-15 impressions for each of those keywords.\n          <br><br>We calculate total impressions by combining the exact count from keywords with more than (>) 15 impressions and adding the following estimates for keywords with less than (<) 15 impressions.\n          </div>\n          <span>\n            <br>- High Estimate = 15 impressions per <15 keyword\n            <br>- Medium Estimate = 8 impressions per <15 keyword\n            <br>- Low Estimate = 1 impression per <15 keyword\"\n          </span>";
            case 'Total Page One Keywords':
                return "This total represents the unique keyword count your business was found for on maps and search during the report's time frame. While exact search engine results page placement can't be determined, your business must appear on the first page or as a highlighted 'pin' on maps to generate any Google impressions.";
            case 'Keywords':
                return "Keywords shown below represent the unique search terms that your business profile appeared in search results on Google Maps and Search on desktop and mobile devices. Impressions are displayed for reference to determine the number of times your business profile appeared in the search results for a given keyword. Multiple impressions by a unique user within a single day are counted as a single impression. You can use this report to track changes in impressions over long periods of time to track changes in overall visibility for your business on Maps and Search on desktop and mobile devices.";
            case 'Direct vs. Discovery':
                return "Direct searches represent keywords that include or are related to the name or address of your business. Discovery searches represent keywords related to the category, product or services your business offers.";
            case 'Keyword topics':
                return "The keyword topics listed below represent the most commonly used search terms that generated impressions for your business profile. Total impression and keyword counts are shown below the search term topic. You can export these keywords by clicking the .csv button to the right of the keyword.";
        }
    };
    return SearchKeywordsComponent;
}());
export { SearchKeywordsComponent };
