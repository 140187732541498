import { __awaiter, __generator } from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../services/modal.service';
import { AuthService } from '../services/auth.service';
import { GROUP_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { SubscriptionService } from '../services/subscription.service';
import { FeaturedComponent } from '../components/featured.component';
import { ModalGetAddOn } from '../modules/modal-get-addon/modal-get-addon.component';
var GroupGatedPaidDirective = /** @class */ (function () {
    function GroupGatedPaidDirective(_modalService, _auth, _subscriptionService, _route, _element) {
        var _this = this;
        this._modalService = _modalService;
        this._auth = _auth;
        this._subscriptionService = _subscriptionService;
        this._route = _route;
        this._element = _element;
        this.gmbGGActive = function () { };
        this.subscription$ = this._auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    GroupGatedPaidDirective.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.subscription.status === GROUP_SUBSCRIPTION_TYPE.PAID) {
                    this._element.nativeElement.removeAllListeners('click');
                    this._element.nativeElement.addEventListener('click', this.handler.bind(this));
                }
                return [2 /*return*/];
            });
        });
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    GroupGatedPaidDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('GroupGatedPaidDirective handler');
                        return [4 /*yield*/, this._modalService.openModal(FeaturedComponent, null)];
                    case 1:
                        _a = (_b.sent());
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._subscriptionService.flowChangeLocationsPlan(this.subscription, [this._route.snapshot.parent.params])];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3:
                        if (_a)
                            console.log("upgrade successful - group-gated.directive");
                        return [2 /*return*/];
                }
            });
        });
    };
    return GroupGatedPaidDirective;
}());
export { GroupGatedPaidDirective };
var GroupGatedTrialDirective = /** @class */ (function () {
    function GroupGatedTrialDirective(_modalService, _auth, _subscriptionService, _route, _element) {
        var _this = this;
        this._modalService = _modalService;
        this._auth = _auth;
        this._subscriptionService = _subscriptionService;
        this._route = _route;
        this._element = _element;
        this.gmbSubscriptionInTrial = function () { };
        this.subscription$ = this._auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    GroupGatedTrialDirective.prototype.ngOnInit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                if ((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.status) {
                    if ((((_b = this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) < 3) &&
                        this.subscription.status === GROUP_SUBSCRIPTION_TYPE.TRIAL) {
                        this._element.nativeElement.removeAllListeners('click');
                        this._element.nativeElement.addEventListener('click', this.handler.bind(this));
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
    // code block. 
    GroupGatedTrialDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('GroupGatedTrialDirective handler');
                        return [4 /*yield*/, this._modalService.openModal(FeaturedComponent, null)];
                    case 1:
                        _a = (_b.sent());
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._subscriptionService.flowChangeLocationsPlan(this.subscription, [this._route.snapshot.parent.params])];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3:
                        if (_a)
                            console.log("upgrade successful - group-gated.directive");
                        return [2 /*return*/];
                }
            });
        });
    };
    return GroupGatedTrialDirective;
}());
export { GroupGatedTrialDirective };
var GroupGatedBulkUpdateAddOnDirective = /** @class */ (function () {
    function GroupGatedBulkUpdateAddOnDirective(_modalService, _auth, _element) {
        var _this = this;
        this._modalService = _modalService;
        this._auth = _auth;
        this._element = _element;
        this.gmbBulkUpdateAddOn = function () { };
        this.subscription$ = this._auth.subscription$;
        this.subscription$.subscribe(function (subscription) { return _this.subscription = subscription; });
    }
    GroupGatedBulkUpdateAddOnDirective.prototype.ngOnInit = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_e) {
                this._bulk_actions_enabled = ((((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.pricingVersion) < 3) || (((_b = this.subscription) === null || _b === void 0 ? void 0 : _b.pricingVersion) >= 3 && ((_d = (_c = this.subscription) === null || _c === void 0 ? void 0 : _c.packages) === null || _d === void 0 ? void 0 : _d.hasOwnProperty('pkg_bulk_actions'))));
                if (!this._bulk_actions_enabled) {
                    this._element.nativeElement.removeAllListeners('click');
                    this._element.nativeElement.addEventListener('click', this.handler.bind(this));
                }
                return [2 /*return*/];
            });
        });
    };
    GroupGatedBulkUpdateAddOnDirective.prototype.handler = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalService.openModal(ModalGetAddOn, {
                            title: 'Add-On: Bulk Actions',
                            message: '',
                            buttons: {
                                'cancel': 'Maybe Later',
                                'accept': 'Get Add-On'
                            }
                        }, {
                            config: {
                                width: '830px'
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return GroupGatedBulkUpdateAddOnDirective;
}());
export { GroupGatedBulkUpdateAddOnDirective };
