// TODO: Refactor against admin-frontend/auth.service.ts

import { ReplaySubject, BehaviorSubject, combineLatest } from 'rxjs';
import { Injectable, NgZone, isDevMode, OnDestroy} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import 'rxjs/add/observable/from';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { map, share } from 'rxjs/operators';
import User from '../constants/firestore/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';

// app
import { GroupService } from './group.service';
import { UserService } from './user.service';
import { WhiteLabelService } from './white-label.service';
import { SnackbarService } from './snackbar.service';
import { Messages, string_message } from '../constants/messages';
import { IS_IMPERSONATING, Session, SESSION, SESSION_EXTERNAL, STORAGE_ALL_KEYS } from '../constants/session';
import Group from '../constants/firestore/group';
import { ISubscription } from "../constants/subscription";
import { GROUP_SUBSCRIPTION_TYPE } from "../constants/firestore/account-location";
import { NOTIFICATION_GENERAL, TYPE_LOG_USER } from "../constants/notifications";
import { DomainService } from './domain.service';
import { NotificationService } from './notification.service';
import { VerificationEmailService } from '../services/verification-email.service';
import { AlertComponent, AlertType } from '../components/alert.component';
import { SpinnerService } from './spinner.service';
import { PaymentsService } from "./payments.service";
import { ModalService } from "./modal.service"
import { environment as ENV } from '@environment';

import { AuthProxyService } from './auth.proxy.service'
import { HEADERS_NO_AUTH, MAIL_EXTERNAL_GRADE, MAIL_ANONYMOUS } from '../constants/auth'
import { asPromisedObservable, isRunningEmbedded } from '../helpers/utils.helpers';
import { SessionTraceService } from './session-trace.service';
import { UserFeatures } from '../constants/user-features';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  // TODO: 'anonymous', 'gradeExternal', etc, should be coalesced to a "sessionType"
  anonymous: boolean;
  gradeExternal: boolean;
  gradeExternalUser: User;
  user$  = new BehaviorSubject<any>({});
  userIndex$;
  searchDomain$;
  private _featureResolve;
  private _processSubscriptionTimer: any;
  public userFeatures = new Promise<UserFeatures>((resolve, reject) => {
    this._featureResolve = resolve;
  });
  
  private _disableSessionCallbacks = false;
  private _accessToken : string | null = null 

  private _groupIn$ = new ReplaySubject<Group>(1)
  public groupOut$ = asPromisedObservable(this._groupIn$)
  
  private _subscriptionIn$ = new ReplaySubject<ISubscription>(1)
  public subscription$ = asPromisedObservable(this._subscriptionIn$)  // TODO: Rename to subscriptionOut$

  constructor(
    // dep
    public afAuth: AngularFireAuth,
    private _router: Router,
    private _http: HttpClient,
    private _ngZone: NgZone,
    // app
    private _groupService: GroupService,
    private _wl: WhiteLabelService,
    private _userService: UserService,
    private _snack: SnackbarService,
    private _domainService: DomainService,
    private _notificationService: NotificationService,
    private _verificationService: VerificationEmailService,
    private _authProxyService: AuthProxyService,
    private _spinnerService: SpinnerService,
    private _paymentsService: PaymentsService,
    private _modalService : ModalService,
    private _sessionTraceService : SessionTraceService
  ) {

    if (!isRunningEmbedded()) {
      this.initSession().then(); // TODO: Intention was to await?
    }

    if (!this._domainService.domain$.value) {
      this.searchDomain$ = this._domainService.searchDomain$;
      this.searchDomain$.subscribe(domain => {
          this._domainService.domain$.next(domain);
        });
    }

    //// Subscribe to Firestore Auth state changes
 
    // onAuthStateChanged():
    // Prior to 4.0.0, this triggered the observer when users were signed in, signed out, 
    // or when the user's ID token changed in situations such as token expiry or password change. 
    // After 4.0.0, the observer is only triggered on sign-in or sign-out.
    this.afAuth.auth.onAuthStateChanged(
      // this.afAuth.authState.pipe(map(
      user => {

        if(this._disableSessionCallbacks)
          return

        if (!user)
          return

        if (!this.session)
          this.setSession(user);
        else if (this.session?.gid) {
          this._checkUser(this.session?.gid, this.session?.uid);
        }
        // this.forceAuthRefresh()

        // user.getIdToken(true).then(token => {
        //   BAD: This changed epheremal session getter object with no real effect
        //   this.session?.authToken = token;
        // });
      }
    )

    // onIdTokenChanged():
    // Adds an observer for changes to the signed-in user's ID token, which includes sign-in, 
    // sign-out, and token refresh events. This method has the same behavior as 
    // firebase.auth.Auth.onAuthStateChanged had prior to 4.0.0.
    // Also check:
    // https://github.com/angular/angularfire/issues/2694#issuecomment-734052171
    this.afAuth.auth.onIdTokenChanged(async (user) => {
      if(this._disableSessionCallbacks)
        return

      // Update the accessToken for events not triggered by forceAuthRefresh
      // (e.g., when firebase-sdk refreshes it automatically in one of his non
      // angular intercepted queries)
      //
      // console.log('idTokenChanged changed handler', user)
      this._setAccessToken(user ? await user.getIdToken() : null, 'onIdTokenChanged')
    })

    // Firebase can start with a token already loaded (from cookies
    // or localStorage?)

    // console.log('token at start=', this.session?.authToken  )

    window.addEventListener('storage', (ev) => this._onStorageEventFromOtherTab(ev))
  }

  ngOnDestroy() : void {
    this.subscription$.destroy()    
    this.groupOut$.destroy()
  }
 
  /**
   * Returns the session as saved in LocalStorage
   */
  get session(): User | null {
    const session = localStorage.getItem(SESSION);
    if (!session) 
      return null
    const parseSession: User = JSON.parse(session);  // TODO: Validate
    if (parseSession.email === MAIL_EXTERNAL_GRADE) {
      this.signOut()
      return null
    }
    return parseSession
  }

  get authenticated(): boolean {
    return !!this.afAuth.auth.currentUser;
  }

  get isAdmin(): boolean {
    const role = this.session?.role?.toLowerCase()
    return role === 'admin' || role === 'master_admin';
  }

  get isMember(): boolean {
    return this.session?.role?.toLowerCase() === 'member'
  }

  get externalSession(): User {
    const session_ext = localStorage.getItem(SESSION_EXTERNAL);
    if (!session_ext) 
        return null
    const parseSession: User = JSON.parse(session_ext);  // TODO: validate
    return parseSession
  }

  get headers() : { headers : HttpHeaders } {
    let h
    if (this.anonymous) 
      h = {'gid' : this.session?.gid || '',
            // Older code sent an invalid token, bad:
            // 'Authorization' : `Bearer ....${this.accessToken}`
          }
    else if (this.gradeExternal) 
      h = {'uid' : this.gradeExternalUser.uid,
           'gid' : this.gradeExternalUser.gid}
    else 
      h = {'uid' : this.session?.uid || '', 
           'gid' : this.session?.gid || '', 
           'Authorization' : 'Bearer ' + this._accessToken,
           'domain' : this._wl.apiUrl || ''
          }

    return { headers : new HttpHeaders(h)}
  }

  async initSession(forceGid = null): Promise<void> {
    if (forceGid) {
      localStorage.setItem(SESSION, JSON.stringify({...this.session, gid: forceGid }));
    }
    // Anonymous
    if (this.session && Object.keys(this.session)?.length == 1)
      await this.processSubscription();

    // Non-anonymous
    if (!this.session)
      return

    const featuresP = this._userService.getUserFeature(this.session.uid).toPromise() 

    this.setSession(this.session); // JWT: Not present before merge
    this.datadogInit();

    const group = await this._groupService.getByDocId(this.session.gid).toPromise()    
    this.updateGroup(group)

    const f = await featuresP;
    this._featureResolve(f);
    
    // Reloading subcription to keep data fresh
    if (this._processSubscriptionTimer) {
      clearInterval(this._processSubscriptionTimer);
      this._processSubscriptionTimer = null;
    }
    this._processSubscriptionTimer = setInterval(() => {this.processSubscription()}, 120000)

    const sub = await this.processSubscription()  // TODO: Redundant processSubscription?

    const s = this.session
    let createdAt: string;
      try {
        createdAt = new firebase.firestore.Timestamp((s.createdAt as any).seconds, (s.createdAt as any).nanoseconds).toDate().toISOString()
      } catch {
        //Anonymous case
        createdAt = "2024-01-01T00:00:00.000Z"
      }

    this._sessionTraceService.setEnableGtag(ENV.ga4Enabled)

    // TODO: Replace 'bento' feature with a generic 'userGuides' after MAP-2240 deployment
    this._sessionTraceService.setEnableUserGuiding(ENV.userGuidingEnabled && (f.userFeatures.bento || f.generalFeatures.bento))

      
    this._sessionTraceService.onLogin(s.uid,  {
      domain            : this._wl.baseDomain || '',
      gid               : this.session.gid,
      email             : s.email,
      name              : s.displayName || s.company || s.email,
      created_at        : createdAt,
      isTrial           : sub.status === GROUP_SUBSCRIPTION_TYPE.TRIAL,
      essentialListings : group.freeLocationsCount     || 0,
      basicListings     : group.basicLocationsCount    || 0,
      ultimateListings  : group.ultimateLocationsCount || 0,
      totalListings     : group.totalLocationsCount    || 0,
   }) 

  }

  async forceAuthRefresh() : Promise<string> {
    // console.log('forceRefresh() start, old token', this.accessToken)
    const user = firebase.auth().currentUser
    if (!user)
      throw Error("No user")

    const token = await user.getIdToken(/*forceRefresh =*/ true)
    // onIdTokenChanged will be called here synchronously

    this._setAccessToken(token, 'forceAuthRefresh')
  
    return token
  }

  updateGroup(group: Group) : void {
    this._groupIn$.next(group);
  }

  async processSubscription() : Promise<ISubscription> {
    const gid = this.session?.gid

    try {
      if(!gid)
         throw('No session/gid')

      const sub = await this.getSubscription(gid)

      const trialEndNumericDate: number = (sub.trialEnd['$date'] || sub.trialEnd)

      sub.daysInTrial = Math.round(Math.abs((new Date(trialEndNumericDate).getTime() - Date.now()) / (86400*1000)))

      const group = await this.groupOut$.getValue()
      if (group.dismissModalDatePicker)
        sub.dismissModalDatePicker = group.dismissModalDatePicker;

      this._subscriptionIn$.next(sub)
      return sub 
      
    } catch(err) {
      console.error(`Error loading subscription gid=${gid} err=${err}`)
      this._spinnerService.loading$.next(false)
      await this._modalService.openErrorModal('Loading error', ('Error while loading the subscription. '+
                                                                'Please try again or contact support (error code 5)'))
      await this.signOut(true, true) 
      throw('') // not really throwed as the page is reloaded on signOut
    }

  }

  static getSession(): Session {
    return JSON.parse(localStorage.getItem(SESSION)) as Session;
  }

  setSession(user : User | firebase.User) : void {
    if (user)
      localStorage.setItem(SESSION, JSON.stringify(user));
  }

  async googleLogin() : Promise<void> {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const authState = await this.afAuth.auth.signInWithPopup(provider);
    if (authState?.additionalUserInfo?.isNewUser) {
      await this.afAuth.auth.currentUser.delete()
    }
    await this._afterLogin(authState);
  }

  async signInWithImpersonateToken(impersonate_token : string) : Promise<null | string> {
    let authState : firebase.auth.UserCredential
    try {
      authState = await this.afAuth.auth.signInWithCustomToken(impersonate_token);
    } catch(e) {
      console.debug("error impersonating", e)
      return (e.code + ' ' + e.message)
    }

    localStorage.setItem(IS_IMPERSONATING, 'true');
    await this._afterLogin(authState);
    return null
  }

  async signInWithEmailAndPassword(email : string, password : string) : Promise<void> {
    const authState = await this.afAuth.auth.signInWithEmailAndPassword(email, password)
    await this._afterLogin(authState)
  }
  
  async signOut(redirectToLogin = true, reloadPage=false) : Promise<void> {
    await this._ngZone.run(async () => {
      console.debug(`signOut(): redirectToLogin=${redirectToLogin} reloadPage=${reloadPage}`)
      if(!(redirectToLogin && reloadPage) && !this.afAuth.auth.currentUser) {
        console.debug('signout(): already logged out')
        return
      }

      if (this._processSubscriptionTimer) {
        clearInterval(this._processSubscriptionTimer);
        this._processSubscriptionTimer = null;
      }

      for(const f of [(() => this._sessionTraceService.onLogout()),
                      (() => this.storageRemoveAll()),
                      (() => this.afAuth.auth.signOut())] as const)
        try {
          await f()
        } catch (e) {
          console.error("signOut(): error", e)
        }

      if(redirectToLogin) {
        console.debug('signout(): reload') 
        await this._router.navigate(['/login'], reloadPage ? { replaceUrl: true } : undefined)
        if(reloadPage) {
          // Ensure refresh on logout to mitigate memory leaks and oversubscribed observers
          // If we don't that, some observers still run and try to make http requests
          // without valid authorization headers
          window.location.reload()
        }
      }
    })
  }

  // Maybe this needs its own GMB service?
  // But it means the token we already saved..?
  getGmbToken() {
    const getByGidDocId_1 = this._userService.getByGidDocId(this.session?.gid, this.session?.uid).valueChanges();
    const getByGidDocId_2 = this._userService.getByGidDocId(this.session?.gid, `backup_${this.session?.uid}`).valueChanges();

    return combineLatest(getByGidDocId_1, getByGidDocId_2).pipe(map(value => {
      const user1 = value[0];
      const user2 = value[1];
      let googleAuth: User['googleAuth'] = null;
      if (user1 !== null && user1 !== undefined) {
        this.afAuth.auth.currentUser.getIdToken().then(token => {
          user1.authToken = token;
          this.setSession(user1);
        });
        googleAuth = user1?.googleAuth;
      } else {
        // TODO: Maybe user2 is also null?
        this.afAuth.auth.currentUser.getIdToken().then(token => {
          user2.authToken = token;
          this.setSession(user2);
        });
        googleAuth = user2?.googleAuth;
      }

      return googleAuth;
    }));

  }

  // TODO: Move to subscription.service.ts (without creating a circular dep)
  async getSubscription(gid : string) : Promise<ISubscription> {
    return ((await this._http.get(`${ENV.billingApiUrl}/subscription/${gid}/without-locations`).toPromise()) as any).data as ISubscription
  }

  /**
   * this method with email and password create user account in firebase auth
   */
  async createUserWithEmailAndPassword(email: string, password: string) : Promise<firebase.auth.UserCredential> {
    return await this.afAuth.auth.createUserWithEmailAndPassword(email, password)
  }

  /**
   * Create group$ and user with gid and get of firebase token id and set in localstorage session user
   * @param newUser  Response firestore  User
   * @param displayName  name for User.ts
   * @param company  company for User.ts
   */
  async createUserAndGroup(newUser : firebase.auth.UserCredential, displayName = null, company = '', needsVerification = false) : Promise<User> {
    const authUser = newUser.user;
    const name = authUser.displayName || displayName;
    const user = this._userService.transformUser(authUser.uid, authUser.email, name, company, authUser.photoURL);
    const group = await this._initNewGroup({ users: [user.uid],
                                            admin: user.uid,
                                            domain: this._wl.domainUser,
                                            company})

    user.gid         = group['gid'];
    user.role        = 'admin';
    user.createdAt   = new Date();  // FIXME: Browser local time but must be server UTC time. 
    user.displayName = name;

    if (needsVerification) {
      user.emailVerified         = null
      user.emailVerificationHash = (new Md5()).appendStr(`${authUser.uid}${user.gid}${user.displayName}`).end()
    }

    await this._userService.save(user)

    try {
      this._notificationService.saveNotification(user.gid, 
                                                this._wl.baseDomain, 
                                                string_message(Messages.notifications.USER_ADDED, 
                                                [user.displayName, user.role]), 
                                                NOTIFICATION_GENERAL, 
                                                TYPE_LOG_USER, 
                                                this._notificationService.getMetaTypeLog(TYPE_LOG_USER, user));
    } catch(e) {
        console.error("error sending notification", e)
    }
    ///// TODO: Refactor against session callbacks
    this.setSession(user);

    if(!needsVerification) {
      if (this.session?.gid) {
        this._checkUser(this.session?.gid, this.session?.uid);
      }
      this._setAccessToken(await this.afAuth.auth.currentUser.getIdToken(true), "createUserAndGroup")
    }
    ///// 

    return this.session;
  }

  /**
   * Creates an account on Firebase Auth using a Google account popup
   * Here the UID is created
   */
  async registerWithGoogle() : Promise<void> {

    try {
      // Just in case
      this.storageRemoveAll()
    } catch {
      // pass
    }

    try {
      // Set this flag to disable session initialization triggered by callbacks called by
      // signInWithPopup before the user setup is completely finished
      this._disableSessionCallbacks = true

      // This will open a Google Login Popup and the user will be created on Firebase Auth if it doesn't
      // exists.
      const newUser = await this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      if(!newUser.additionalUserInfo.isNewUser)
        throw 'This account is already registered. Try logging in.'

      // Before refactor this line was not awaited, TODO: Check if is ok to await
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const displayName = (newUser.additionalUserInfo.profile as any)?.name
        if(displayName)
          await this.afAuth.auth.currentUser.updateProfile({ displayName })
      } catch(e) {
        console.error("Error updating new user profile:", e)
      }

      this.gradeExternal = false;
      await this.createUserAndGroup(newUser)
    } finally {
      this._disableSessionCallbacks = false
    }
  }

  async signInAnonymously(gid = null) : Promise<void>{
    this.anonymous = true;
    this.gradeExternal = false;
    await this.afAuth.auth.signInWithEmailAndPassword(MAIL_ANONYMOUS, 'password')
    if (!isRunningEmbedded()) {
      await this.initSession(gid)
      // localStorage.setItem(SESSION, JSON.stringify({ gid }));
    }
  }

  async redirectAfterLogin() : Promise<void> {
    if (this.isMember) {
      await this._router.navigate(['/accounts'])
    } else
      try {
        // TODO: Replace call with AccountService.getAccountPaginate, now is not used because if AccountService is
        // imported from this file then a circular dep will be created.
        const r = await this._http.get(`${ENV.apiUrl}/v2/accounts/${this.session?.gid}/all?page=1&pageSize=25&accountIds=`).toPromise()
        const accounts = r["items"]
        await this._router.navigate([accounts.length > 0 ? `accounts/${accounts[0].accountId}/locations` : '/accounts'])
        await this._alertIfNotPaymentMethod()
      } catch (err) {
        console.error(err)
      }
  }

  async createExternalGradeUser() {
    this.gradeExternal = true;

    try {
      const user = await this.afAuth.auth.signInWithEmailAndPassword(MAIL_EXTERNAL_GRADE, 'external');

      return this._userService.getUserByUid(user.user.uid).pipe(map(async result => {
        if (result.docs.length == 0)
          return this.createUserAndGroup(user, 'external-grade', 'maplabs.com');
        else
          return result.docs[0].data();
      })
      ).toPromise().then();  // TODO: "then" intention is to await? bad

    } catch (e) {
      if (e.code === 'auth/user-not-found') {
        const user = await this.createUserWithEmailAndPassword(MAIL_EXTERNAL_GRADE, 'external');
        return this.createUserAndGroup(user, 'external-grade', 'maplabs.com');
      }
    }
  }

  storageRemoveAll() : void {
    console.debug("localStorage: Removing all")
    for(const k of STORAGE_ALL_KEYS)
      try {
          localStorage.removeItem(k)
      } catch { 
        //pass
      }
  }

  datadogInit(): void {
    const uid   = this.session?.uid
    const email = this.session?.email

    if (ENV.env == 'prod') {
      // Now loading via asycn so need to ensure we 'wait' via onReady
      const DD_RUM = (window as any).DD_RUM
      DD_RUM.onReady(() => {
        DD_RUM.setUser({
          'id': uid,
          'email': email
        });
      });
    }
  }

  private async _afterLogin(user : firebase.auth.UserCredential) {
    return this.afAuth.auth.currentUser.getIdToken().then(token => {
      return this._userService.getUserByUid(user.user.uid).toPromise()
        .then(data => [data.docs[0].data() as User])
        .then(async value => {
          if (!value[0]) {
            this.signOut();
            this._snack.openError(Messages.register.USER_ALREADY_EXIST, 4000);
          } else {
            const isEmailVerified = await this._userService.getEmailIsVerified(value[0]);
            this.setSession(value[0]);
            if (!this.session) 
                return;

            // TODO: Superflous user re-fetch?
            this._userService.getUserByUid(user.user.uid).pipe(map(result => {
                return result.docs.length > 0 ? result.docs[0].data() : null
            })).toPromise().then(u => {
              if (u) {
                this._userService.updateUser(u.gid, u.uid, { lastLogin: firebase.firestore.Timestamp.now() });
              }
            });

            if (isEmailVerified) {
              value[0].authToken = token;
              this.setSession(value[0]);
              if (this.session?.gid) {
                this._checkUser(this.session?.gid, this.session?.uid);
              }
    
              this._userService.domainValidation(location.hostname, this.session?.gid, this.session?.uid, this.session?.domainSurfing).subscribe(
                res => {
                  // The isDevMode flag should be present here to enable impersonation during development.
                  if (!res['allowLogin'] && !isDevMode()) {
                    console.debug("Domain validation FAILED")

                    this._ngZone.run(async () => {
                      await this._modalService.openErrorModal('Heads up', "Sorry, we couldn't find your account. " +
                                                                         "Please check your username and password or contact support.")                    
                      await this.signOut(true, true) 
                    })

                  } else {
                    this.initSession();
                    this.redirectAfterLogin(); // TODO: then() intention is to await?
                  }
                },
                err => console.log(err)
              );
              return value[0];
            } else {
              this._verificationService.getVerification(value[0].uid, value[0].gid).toPromise().then(view => {
                view.forEach(v => {
                  if (!v) {
                    return;
                  }
                  const data = v.data()
                  if (data.emailVerified == null) {
                    this.signOut();
                    this._snack.openError(Messages.register.EMAIL_VERIFIED, 4000);
                  } else {
                    this._userService.getByGidDocId(data.key2, data.key1).get().map(doc => doc.data()).take(1).subscribe(user => {
                      if (user) {
                        // TODO: Should be done server-side!
                        user.emailVerified = firebase.firestore.Timestamp.now()
                        this._userService.altUpdate(user as User)
                      }
                      user.authToken = token;
                      this.setSession(user as any);
                      if (this.session?.gid) {
                        this._checkUser(this.session?.gid, this.session?.uid);
                      }
                      this._groupService.getByDocId(this.session?.gid).toPromise().then(group => {  // FIXME: Intention is to await before initSession()?
                        this.updateGroup(group);
                      });
                      this.initSession();
                      this.redirectAfterLogin(); // TODO: "then()" intention is to await? bad
                      return user;
                    });
                  }
                });
              });
            }
          }
        });
    });

  }

  private _checkUser(gid : string, uid : string) :  void {
    // TODO: this setups a subscription on userIndex, multiple calls to checkUser will
    // register multiple (redundant) subscriptions. Probably an observer leak here.
    this.userIndex$ = this._userService.get(gid, uid).pipe(share(), map((indexUser: User) => {
      this.user$.next(indexUser);
      const user : User = { ...this.session, 
                            ...indexUser }
      this.setSession(user);
      this._userService.updateSession(user);
      return indexUser;

    }));
    this.userIndex$.subscribe();
  }

  private _initNewGroup(group: Group) {
    return this._http.post(`${ENV.apiUrl}/v2/auth/signup`, group, HEADERS_NO_AUTH).toPromise()
  }

  private _setAccessToken(accessToken : string | null, debugStr : string) {
    this._accessToken = accessToken    
    console.debug(`${debugStr}: accessToken changed`, accessToken)
    if(accessToken && !this._authProxyService.initialized)
      this._authProxyService.initialize(() => this.signOut(true, true), 
                                       () => this.headers, 
                                       () => this.forceAuthRefresh())
  }

  /**
   * Alert the user if he has a paid Subscription and no Credit Card configured
   */
  private async _alertIfNotPaymentMethod(): Promise<void> {
    const gid = this.session?.gid
    const sub = await this.getSubscription(gid)
    if (sub.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL &&
        sub.collectionByBillingOverride[sub.billingOverride.toString() as 'true' | 'false'].requiresPaymentMethod) {

      const group = await this._groupService.getByDocId(gid).toPromise()
      if ((group.basicLocationsCount || group.ultimateLocationsCount) &&
          !(await this._paymentsService.hasPaymentMethods(gid))) {

         this._modalService.openModal(AlertComponent, {
            title: 'Heads up',
            content: "You don't have a Credit Card set up. Please add one.",
            closeButtonLabel: 'Close',
            alertType: AlertType.ERROR,
            disableCancelButton : true
          }) 
      }
    }
  }

  private _onStorageEventFromOtherTab(event : StorageEvent) : void {
    // console.debug('storage', event)
    if(event.key === SESSION && (!event.newValue || !JSON.parse(event.newValue))) {
      console.debug('Detected signOut from another tab')
      this.signOut(true, true)
    }
  }

}