import {ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter, OnChanges} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Colors} from 'ng2-charts';
import {COLORS_CHART_FULL, COLORS_CHARTS} from '../../../constants/chart-color';
import {tooltipCustom} from '../../../constants/tooltip';
import {DecimalPipe} from '@angular/common';

@Component({
  selector: 'app-chart-multi',
  templateUrl: './chart-multi.component.html',
  styleUrls: ['./chart-multi.component.scss']
})
export class ChartMultiComponent implements OnInit, OnChanges {
  @Input() dataset: ChartDataSets[];
  @Input() labels: any[];
  @Input() commaNumbers: boolean;
  @Input() type: string;
  @Input() yDecimals: number;
  @Input() noDecimals: boolean;
  @Output('checks') checks = new EventEmitter();

  colors: Colors[];
  public barChartType = 'line';
  public barChartLegend = false;
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
  };

  constructor(private ref: ChangeDetectorRef) {
    this.colors = COLORS_CHARTS;
  }

  ngOnChanges() {
    this.ngOnInit()
  }

  ngOnInit() {
    this.barChartOptions.tooltips.custom = tooltipCustom;
    this.colors = (this.type !== 'COLORS-FULL') ? COLORS_CHARTS : COLORS_CHART_FULL;

    if (this.commaNumbers) {
      this.barChartOptions.scales = {
        yAxes: [{
          id: 'left',
          type: 'linear',
          position: 'left',
          ticks: {
            beginAtZero: true,
            // Include a dollar sign in the ticks
            callback: (value, index, values) => {
              let v = value;
              const i = Math.pow(10, this.yDecimals);
              if (this.yDecimals > 0) {
                v = Math.round(value * i) / i;
              }
              if (this.commaNumbers) {
                v = this.numberWithCommas(v);
              }
              if (this.noDecimals) {
                if (value % 1 !== 0) return;
              }
              return v;
            }
          }
        },{
          id: 'right',
          type: 'linear',
          position: 'right',
          display: 'auto',
          ticks: {
            beginAtZero: true,
            callback: (value: any) => {
              if (value !== 0) {
                if (value % 1 === 0) {
                  return value;
                }
              }

            }
          }
        }]
      };
    }
  }

  numberWithCommas(x) {
    const pipe = new DecimalPipe('EN-US');
    return pipe.transform(x, '1.0-0');
  }

  clearChecks(){
    this.checks.emit(true);
  }

}
