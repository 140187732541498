// dep
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, ReplaySubject, Subscription as subs, Observable } from 'rxjs';
import moment from 'moment';

// app
import { ReviewsService } from '../../services/reviews.service';
import { DataPicker } from '../../constants/data-picker';
import { LocationService } from '../../services/location.service';
import { Pagination } from '../../constants/api-response';
import { ReportService } from '../../services/report.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from 'src/app/services/auth.service';
import { ISubscription } from '../../constants/subscription';
import { EventMessageService } from 'src/app/services/event-message.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { LoadingService } from '../../services/loading.service';
import { LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { FeaturedDatePickerComponent } from "../../components/featured-datepicker.component";


export interface ReviewKeywords {
  id: number;
  text: string;
  weight: number;
  score: number;
}

@Component({
  selector: 'app-dashboard-reviews',
  templateUrl: './dashboard-reviews.component.html',
  styleUrls:  ['./dashboard-reviews.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardReviewsComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input('dataPicker') reportDataPicker: DataPicker;
  @Input() reportName: string;
  @Input() isShared = false;
  @Input() lockDates = false;
  @Input() reportType = 'review';
  @Input() viewModeChecked = 'legacy';
  @Input() reportId: string;
  @Input() report
  @Input() minDate = null;
  @Input() maxDate = null;

  public exporting = false;
  locationId: string;
  reviewsStats: any = null;
  public dataPicker: DataPicker;
  public isReportListAccordionOpen = false;
  refreshFilter = new ReplaySubject<DataPicker>(1)
  private resume = new Subject<any>();
  // progress events

  isProgressResume = true;
  isProgressDistribution = true;
  // isProgressResponses = true;

  loadingMuchData = false;
  public loadingAvereageAndReview = false; 

  // responses: { answered: number, notAnswered: number };
  pagination: Pagination;
  noData: boolean;

  rating: any = null;
  isReport: boolean;
  // noDataGoogle: boolean;
  // noDataDistribution: boolean;
  differenceTotal: number;
  differenceAverage: number;
  private first = true;

  public reviewsData = {};
  public averageData = {};

  // subscription by close when OnDestroy
  private googleReviewsWithLastMonth$: subs;
  private ratingDistribution$: subs;
  // private aggregationResponses$: subs;

  private keywordsByStat$: subs;
  private reviewResume$: subs;
  selectDate;
  location;
  // isFailedResume: boolean;
  isProgressRating: boolean;
  total: any;
  accountId: string;
  locations: { accountId: string; locationId: string }[];
  gid: string;
  subscription$: Observable<ISubscription>;
  subscription: ISubscription;
  isProgressCSV: boolean;

  constructor(
    private route: ActivatedRoute,
    private reviewsS: ReviewsService,
    private locationS: LocationService,
    private auth: AuthService,
    private reportS: ReportService,
    private cdRef: ChangeDetectorRef,
    private snackS: SnackbarService,
    private em: EventMessageService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
    private loadingService: LoadingService
  ) {
    this.locationId = this.route.snapshot.parent.params.locationId;
    this.accountId = this.route.parent.snapshot.params.accountId;
    this.locations = [{ accountId: this.accountId, locationId: this.locationId }];
    this.subscription$ = this.auth.subscription$;
    this.subscription$.subscribe(subscription => this.subscription = subscription);
  }

  async ngOnInit() {
    if (this.reportDataPicker) {
      this.gid = this.route.snapshot.params.gid;
      this.isReport = true;
      this.locations = this.reportDataPicker.locations;
      this.reportDataPicker.aggregation = this.reportS.correctDateAggregate(this.reportDataPicker);
      this.dataPicker = this.reportDataPicker;
      this.selectDate = this.reportS.selectDatesFromDataPicker(this.dataPicker);
    } else {
      this.gid = this.auth?.session?.gid;
      const dateValidations = await this.locationS.getDateValidations('review', [this.accountId], [this.gid], [this.locationId]).toPromise();
      const dates = this.locationS.dateValidation(dateValidations);
      this.minDate = dates.minDate;
      this.maxDate = dates.maxDate;
      let locationData: any = {};
      await this.locationS.getRef(this.gid, this.accountId, this.locationId).toPromise().then(location => locationData = location);;
      this.selectDate = (
        this.minDate && this.maxDate && (locationData.subscriptionType != LOCATION_SUBSCRIPTION_TYPE.FREE && 
                                         locationData.subscriptionType != LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) ? 
        { start: this.minDate, end: this.maxDate } :
        this.locationS.buildDatepickerDate(this.reportType, this.maxDate)
      );
    }
    this.em.getEmitter().subscribe(res => {
      if (res.name != "DATEPICKER SUBSCRIPTION") {
        return;
      }

      let dateDismiss = null;
      if(this.subscription.dismissModalDatePicker != undefined){
        if(this.subscription.dismissModalDatePicker instanceof moment){
          dateDismiss = this.subscription.dismissModalDatePicker.toDate() 
        }else{
          dateDismiss = new Date(this.subscription.dismissModalDatePicker as unknown as string)
        }
      } else {
        dateDismiss = new Date();
      }

      if (new Date() >= dateDismiss) {
        this.modalService.openModal(FeaturedDatePickerComponent,
          { 
            accountId: this.accountId,
            locationId: this.locationId
          },
          { onClose : res => {
            if (!res)
              return;
            console.log('DashboardReviewsComponent upgrade suscription');
            this.subscriptionService.flowChangeLocationsPlan(this.subscription, [this.locations[0]])              
        }})
      }

    });
  }

  /**
   * this method handle event change in date picker and get data
   */
  handleChange($event) {
    if (this.isReport && this.first) {
      this.getReviewData(this.dataPicker);
      this.first = false;
    } else {
      this.dataPicker = $event;
      this.getReviewData(this.dataPicker);  
    }

    this.refreshFilter.next(this.dataPicker);
  }

  /**
   * this method get all data of charts when change date picker
   */
  getReviewData(dataPicker: DataPicker, refresh?: boolean) {
    this.isProgressResume = true;
    this.isProgressDistribution = true;
    // this.isProgressResponses = true;

    if (!this.locations || !this.dataPicker) {
      return;
    }
    this.reviewResume$ = this.locationS.review_summary(this.gid, this.locations).take(2).subscribe(resume => {
      if (resume) {
        // this.isFailedResume = false;
        this.isProgressResume = false;
        if (resume?.length > 1) {
          this.resumeManyLocations(resume);
        } else {
          this.reviewsStats      = resume
          this.differenceAverage = resume.difference.average;
          this.differenceTotal   = resume.difference.total;
        }
        this.resume.next(this.reviewsStats);

      } else {
        this.isProgressResume = false;
        // this.isFailedResume = true;
      }
      this.loadingService.reportAdvanced(1, 'Review Summary')
      refresh && this.snackS.openSuccess('Finished refreshing! ', 2000);
    });


    this.ratingDistribution$ = this.reviewsS.ratingDistribution(this.locations).take(2).subscribe(result => {
      if (result.data) {
        this.isProgressDistribution = false;
        this.rating = result.data;
        this.loadingService.reportAdvanced(1, 'Rating Distribution')
      }
    }, () => {
      this.isProgressDistribution = false;
    });

    this.getAverageAndReviews();
  }

  getAverageAndReviews() {
    this.loadingAvereageAndReview = true;
    this.dataPicker.aggregation = this.report?.aggregation ? this.report?.aggregation : this.dataPicker?.aggregation;
    const url = this.isReport ? this.reviewsS.getRatingsAndReviewsReportData(this.reportId, this.dataPicker).take(2) : this.reviewsS.getRatingsAndReviewsData(this.accountId, this.gid, this.locationId, this.dataPicker).take(2);
    url.subscribe(
      res => {
        this.reviewsData = {
          data: res.reviews,
          labels: res.dates,
          total: res.total_reviews
        };
        this.averageData = {
          data: res.avg_rating,
          labels: res.dates,
          total: res.total_avg
        };
        this.loadingAvereageAndReview = false;
      }, err => {
        this.loadingAvereageAndReview = false;
      }
    );
  }

  // TODO: Refactor against protocol-report.component.ts / resumeManyLocations
  resumeManyLocations(resumes: any[]) {
      const stats = { averageRating    : 0, 
                      totalReviewCount : 0, 
                      answered         : 0, 
                      notAnswered      : 0, 
                      googleResume     : { totalReviewCount : 0 }
                    }
      const difference = { average : 0,
                           total   : 0 }
      let length = resumes.length
      for(const r of resumes) {
          if (!r || r.totalReviewCount === 0) {
              if (length > 0) 
                  length--
              continue
          }
          
          difference.total       += r.difference.total
          difference.average     += r.difference.average
          stats.averageRating    += r.averageRating
          stats.totalReviewCount += r.totalReviewCount
          stats.answered         += r.answered
          stats.notAnswered      += r.notAnswered
          stats.googleResume.totalReviewCount += r.googleResume?.totalReviewCount || 0
      }

      stats.averageRating   /= (length || 1)
      difference.average    /= (length || 1)
      this.differenceTotal   = difference.total
      this.differenceAverage = difference.average
      this.reviewsStats      = stats
      this.isProgressResume  = false
  }


  ngAfterViewChecked() {
    this.detectChanges();
  }

  detectChanges () : void{
    if ( this.cdRef !== null &&
        this.cdRef !== undefined &&
        ! (this.cdRef as ViewRef).destroyed
    ) {
            this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.ratingDistribution$?.unsubscribe();
    // this.aggregationResponses$?.unsubscribe();
    this.keywordsByStat$?.unsubscribe();
    this.googleReviewsWithLastMonth$?.unsubscribe();
    this.reviewResume$?.unsubscribe();
    this.resume.unsubscribe();
  }

  handleRefresh(): void {
    this.getReviewData(this.dataPicker, true);
  }

  isExporting(value) {
    this.exporting = value;
  }

  async exportCSV(event) {
    if (event != 'csv' && event != 'csv-review') return;

    const reportId = this.isReport ? this.reportId : null;
    const accountId = !this.isReport ? this.accountId : null
    const locationId = !this.isReport ? this.locationId : null
    this.isProgressCSV = true;
    const type = event === 'csv' ? 'review' : 'review_list';

    await this.reportS.handleExportCsvMongo(
      reportId,
      this.gid,
      accountId,
      locationId, 
      this.dataPicker.range.start,
      this.dataPicker.range.end,
      this.dataPicker.aggregation,
      type
    );

    this.isProgressCSV = false;
  }
}
