export const WHITE_COLOR = {
  opacity: 'rgba(255, 255, 255, 0.1)',
  full: 'rgb(255, 255, 255)'
};

export const BLUE_COLOR = {
  opacity: 'rgba(66, 133, 244, 0.1)',
  full: 'rgb(66, 133, 244)'
};

export const YELLOW_COLOR = {
  opacity: 'rgba(251, 188, 5, 0.1)',
  full: 'rgb(251, 188, 5)',
};

export const GREEN_COLOR = {
  opacity: 'rgba(52, 168, 83, 0.1)',
  full: 'rgb(52, 168, 83)',
};


export const RED_COLOR = {
  opacity: 'rgba(234, 67, 53, 0.1)',
  full: 'rgb(234, 67, 53)',
};

export const CHART_BLUE_COLOR = {
  backgroundColor: BLUE_COLOR.opacity,
  borderColor: BLUE_COLOR.full,
};
export const CHART_YELLOW_COLOR = {
  backgroundColor: YELLOW_COLOR.opacity,
  borderColor: YELLOW_COLOR.full,
};
export const CHART_GREEN_COLOR = {
  backgroundColor: GREEN_COLOR.opacity,
  borderColor: GREEN_COLOR.full,
};

export const CHART_RED_COLOR = {
  backgroundColor: RED_COLOR.opacity,
  borderColor: RED_COLOR.full,
};

export const COLORS_CHARTS = [
  CHART_BLUE_COLOR,
  CHART_YELLOW_COLOR,
  CHART_GREEN_COLOR,
  CHART_RED_COLOR,
  CHART_BLUE_COLOR,
  CHART_YELLOW_COLOR,
  CHART_GREEN_COLOR,
  CHART_RED_COLOR,
  CHART_BLUE_COLOR,
  CHART_YELLOW_COLOR,
  CHART_GREEN_COLOR,
  CHART_RED_COLOR,
  CHART_BLUE_COLOR,
  CHART_YELLOW_COLOR,
  CHART_GREEN_COLOR,
  CHART_RED_COLOR,
];

export const CHART_BLUE_COLOR_SAME = {
  backgroundColor: BLUE_COLOR.full,
  borderColor: BLUE_COLOR.full,
};
export const CHART_YELLOW_COLOR_SAME = {
  backgroundColor: YELLOW_COLOR.full,
  borderColor: YELLOW_COLOR.full,
};
export const CHART_GREEN_COLOR_SAME = {
  backgroundColor: GREEN_COLOR.full,
  borderColor: GREEN_COLOR.full,
};

export const CHART_RED_COLOR_SAME = {
  backgroundColor: RED_COLOR.full,
  borderColor: RED_COLOR.full,
};

export const COLORS_CHART_FULL = [
  CHART_BLUE_COLOR_SAME,
  CHART_YELLOW_COLOR_SAME,
  CHART_GREEN_COLOR_SAME,
  CHART_RED_COLOR_SAME
];

export const FULL_COLORS_CHARTS = [
  CHART_BLUE_COLOR_SAME,
  CHART_YELLOW_COLOR_SAME,
  CHART_GREEN_COLOR_SAME,
  CHART_RED_COLOR_SAME,
  CHART_BLUE_COLOR_SAME,
  CHART_YELLOW_COLOR_SAME,
  CHART_GREEN_COLOR_SAME,
  CHART_RED_COLOR_SAME,
  CHART_BLUE_COLOR_SAME,
  CHART_YELLOW_COLOR_SAME,
  CHART_GREEN_COLOR_SAME,
  CHART_RED_COLOR_SAME,
  CHART_BLUE_COLOR_SAME,
  CHART_YELLOW_COLOR_SAME,
  CHART_GREEN_COLOR_SAME,
  CHART_RED_COLOR_SAME,
];

export const COLORS_CHART_DOUGHNUT = [BLUE_COLOR.full, YELLOW_COLOR.full, GREEN_COLOR.full, RED_COLOR.full];
export const BORDERS_CHART_DOUGHNUT = [BLUE_COLOR.full, YELLOW_COLOR.full, GREEN_COLOR.full, RED_COLOR.full];

export const COLORS_CHART_DOUGHNUT_REVENUE = [YELLOW_COLOR.full, GREEN_COLOR.full];
export const BORDERS_CHART_DOUGHNUT_REVENUE = [YELLOW_COLOR.full, GREEN_COLOR.full];

export const COLORS_CHART_PROGRESS_REVENUE = [YELLOW_COLOR.opacity, GREEN_COLOR.opacity];

