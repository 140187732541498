import {Component, Input, OnInit, OnChanges, ChangeDetectorRef, AfterViewInit} from '@angular/core';

@Component({
  selector: 'app-chart-progress',
  templateUrl: './chart-progress.component.html',
  styleUrls: ['./chart-progress.component.scss']
})
export class ChartProgressComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() total;
  @Input() label: string;
  @Input() bgColor;
  @Input() type: string;
  @Input() showPercent = true;
  @Input() tooltip: string = null;
  progressColorClass: string;
  progressBgColorClass: string;


  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.showPercent = !this.label?.toLowerCase().includes('post');
    this.progressColorClass = (this.total?.percent > 55) ? 'txt--white' : 'txt--black';
  }

  ngOnChanges() {
    switch (this.bgColor) {
      case 'rgba(66, 133, 244, 0.1)': {
        this.progressBgColorClass = 'progress--blue';
        break;
      }
      case 'rgba(251, 188, 5, 0.1)': {
        this.progressBgColorClass = 'progress--yellow';
        break;
      }
      case 'rgba(52, 168, 83, 0.1)': {
        this.progressBgColorClass = 'progress--green';
        break;
      }
      case 'rgba(234, 67, 53, 0.1)': {
        this.progressBgColorClass = 'progress--red';
        break;
      }
      default: {
        break;
      }
    }
  }

}
