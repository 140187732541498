/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./revenue-reports.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../report-table/report-list.component.ngfactory";
import * as i3 from "../report-table/report-list.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../../services/auth.service";
import * as i7 from "../../services/report.service";
import * as i8 from "../../services/modal.service";
import * as i9 from "../../services/snackbar.service";
import * as i10 from "../../services/location.service";
import * as i11 from "../../services/group.service";
import * as i12 from "../../services/subscription.service";
import * as i13 from "../../services/dates.service";
import * as i14 from "../../services/spinner.service";
import * as i15 from "./revenue-reports.component";
var styles_RevenueReportsComponent = [i0.styles];
var RenderType_RevenueReportsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RevenueReportsComponent, data: {} });
export { RenderType_RevenueReportsComponent as RenderType_RevenueReportsComponent };
export function View_RevenueReportsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report-list", [], null, null, null, i2.View_ReportListComponent_0, i2.RenderType_ReportListComponent)), i1.ɵdid(1, 245760, null, 0, i3.ReportListComponent, [i4.Router, i5.MatDialog, i6.AuthService, i7.ReportService, i8.ModalService, i9.SnackbarService, i10.LocationService, i11.GroupService, i12.SubscriptionService, i13.DatesService, i14.SpinnerService, i1.ChangeDetectorRef], { reportType: [0, "reportType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RevenueReportsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-revenue-reports", [], null, null, null, View_RevenueReportsComponent_0, RenderType_RevenueReportsComponent)), i1.ɵdid(1, 114688, null, 0, i15.RevenueReportsComponent, [i4.Router, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RevenueReportsComponentNgFactory = i1.ɵccf("app-revenue-reports", i15.RevenueReportsComponent, View_RevenueReportsComponent_Host_0, {}, {}, []);
export { RevenueReportsComponentNgFactory as RevenueReportsComponentNgFactory };
