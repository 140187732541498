// dep
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, zip, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import moment from 'moment';
import { environment as ENV} from '@environment';

// app
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { ACCOUNTS, GROUPS, LOCATIONS, POST_MANAGEMENT_GROUP, REPORTS, PROTOCOLS, WIDGET_INFO } from '../constants/firestore/collections';
import { Queue } from '../constants/firestore/enqueue';
import LocationObject, { LocationRef } from '../constants/firestore/location-object';
import SavedLocation from '../constants/firestore/saved-location';
import User from '../constants/firestore/user';
import { WeekDays, WEEK_DAYS } from '../constants/google/week-days';
import { IServiceArea } from './../constants/service-area';
import { HoursAmPmPipe } from '../pipes/hours-am-pm.pipe';
import { AuthService } from './auth.service';
import { InsightsService } from './insights.service';
import { ReviewsService } from './reviews.service';
import { ApiResponse, Pagination } from '../constants/api-response';
import { PostService } from './post.service';
import { ObservationService } from './observation.service';
import { Pageable } from '../constants/pageable';
import { GoogleService } from './google.service';
import { ServiceData, ServiceList, Service } from '../constants/google/service-list';
import { DatesService } from "../services/dates.service";

type ArrayItemType<T extends any[]> = T extends (infer U)[] ? U : never;

@Injectable({
  providedIn: 'root'
})
// This refers to Saved GMB Location.
export class LocationService {
  public loading = false;

  private _dataStore: {
    locations: SavedLocation[]
    location: SavedLocation
  };
  private _locations: BehaviorSubject<SavedLocation[]> = null;
  private _location: BehaviorSubject<SavedLocation> = null;
  private _paginate = new BehaviorSubject({ size: 10, page: 1 });

  /**
   * Will trigger when some location changed (e.g. by an upgrade), to signal
   * that locations should be refreshed. 
   */
  public someLocationChanged = new BehaviorSubject<null>(null)

  constructor(
    private _auth: AuthService,
    private _afs: AngularFirestore,
    private _http: HttpClient,
    private _googleS: GoogleService,
    private _reviewsService: ReviewsService,
    private _insightsService: InsightsService,
    private _postService: PostService,
    private _observationS: ObservationService,
    private _dateS: DatesService
  ) {
    this._dataStore = { locations: [], location: null };
    this.reset();
  }

  get locations(): Observable<SavedLocation[]> {
    return this._locations.asObservable();
  }

  get location(): Observable<SavedLocation> {
    return this._location.asObservable();
  }

  getRegionCode(result: any): string {
    return (result?.location?.address?.regionCode || 
            result?.location?.serviceArea?.regionCode || 
            navigator.language.split('-')[1])
  }

  getLocationPaginate(gid: string, accountId: string, pageable: Pageable, locationIds?: string[]): Observable<any> {
    
    const params = new HttpParams()
      .set('page', pageable.page.toString())
      .set('pageSize', pageable.size.toString())
      .set('locationIds', locationIds.join(','));

    return this._http.get(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/all`, { params });
  }

  getLocationsIdsByStringQuery(gid: string, queryString: string): Observable<any> {
    return this._http.post(`${ENV.apiUrl}/v2/search/gid/${gid}/account-locations?query=${queryString}`, {'query': queryString})
  }


  fetchLocation(gid : string, accountId : string, locationId : string): Promise<SavedLocation> {
    return this.getRef(gid, accountId, locationId).toPromise()
  }

  // TODO: move all .getRef(...).toPromise() calls to .fetchLocation(), also change multiple calls to getRef 
  // to a single getMultipleLocations
  getRef(gid : string, accountId : string, locationId : string): Observable<SavedLocation> {
    return this._http.get<SavedLocation>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}`)
  }


  /**
   * Fetchs all gid locations or only a subset of them (if locationRefs is specified)
   * If fields is specified, only those fields will be fetched, if not, all fields should be.
   */
  // async fetchMultipleLocations<R extends SavedLocation[]>(gid : string, locationRefs? : LocationRef[]) : Promise<R>
  async fetchMultipleLocations<F extends (keyof SavedLocation)[] | undefined, 
                               R extends (F extends undefined ? SavedLocation : Pick<SavedLocation, ArrayItemType<F>>[])>
                              (gid : string, locationRefs? : LocationRef[], fields? : F) : Promise<R> {
    return await this._http.post<R>(`${ENV.apiUrl}/v2/locations/by-gid/${gid}` + 
                                    (fields ? '?fields=' + fields.join(',') : ''),
                                    (locationRefs ? {'locations' : locationRefs} : undefined)).toPromise()
  }


  getPendingMask(gid: string, accountId: string, locationId: string): Promise<any> {
    return this._http.get<SavedLocation>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}/pendingMask`).toPromise();
  }

  getObservations(gid : string, accountId : string, locationId : string): Observable<any | null> {
    return this.getRef(gid, accountId, locationId).pipe(map(loc => loc?.googleObservations || null));
  }

  isLocked(locationId: string): Observable<any> {
    return this._http.get<SavedLocation>(`${ENV.apiUrl}/v2/locations/${locationId}/locked`);
  }

  reset(): void {
    this._locations = new BehaviorSubject<SavedLocation[]>([])
    this._location  = new BehaviorSubject<SavedLocation>(null)
  }

  loadAll(user: User, accountObjectId: string): void {
    this.loading = true;
    this.byAccount(user.gid, accountObjectId).subscribe(locations => {
      this._dataStore.locations = locations;
      this._locations.next(Object.assign({}, this._dataStore).locations);
      this.loading = false;
    });
  }


  fetchLocationsExistence(locations: (LocationRef & {gid : string})[]): Promise<(LocationRef & { gid : string
                                                                                                 locationName : string
                                                                                                 exist : boolean })[]> { 
    return this._http.post<any>(`${ENV.apiUrl}/v2/locations/byIds`, locations).toPromise()
  }

  getPaginate(): Observable<{size: number, page: number}> {
    return this._paginate.asObservable();
  }

  setPaginate(paginate: {size: number, page: number}): void {
    return this._paginate.next(paginate);
  }

  get(gid: string, accountId: string, locationId: string): void {
    this.loading = true;
    const location$ = this.getRef(gid, accountId, locationId);
    location$.subscribe((data: SavedLocation) => {
      this._dataStore.location = data;
      this._location.next(Object.assign({}, this._dataStore).location);
      this.loading = false;
    });
  }


  async saveAll(locations: LocationObject[], accountObject : string | any): Promise<boolean | boolean[]> {
    const accountId = (typeof accountObject === 'string') ? accountObject: accountObject.accountId

    const requests = locations.map(async (locationObject: LocationObject) => {
      return await this.save(locationObject, accountId);
    });
    return Promise.all(requests);
  }

  /**
   * Deletes a Location
   * TODO: Integrate with deleteReferencesToLocation
   */
  deleteLocation(gid: string, locationId : string, accountId: string): Promise<Object> {
    return this._http.delete(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}`).toPromise();
  }


  async deleteReportLocation(locationsToRemove : LocationRef[], gid: string, reportId: string): Promise<void> {

    const reportRef = this._afs.collection(GROUPS).doc(gid).collection(REPORTS).doc(reportId);
    const reportDocument = await reportRef.get().toPromise()
    const reportData = reportDocument.data()

    const accountIds = locationsToRemove.map(loc => loc['accountId'])
    const notDuplicatedAccountIds = [... new Set(accountIds)]

    const accountsCopy = [...reportData?.accounts]

    for (const acc of notDuplicatedAccountIds){

      const reportAccount = accountsCopy.find(account => account.accountId == acc);
      const reportAccountIndex = accountsCopy.indexOf(reportAccount);
      const locationsAccount = locationsToRemove.filter(loc => loc['accountId'] == acc);
      const locationsIdRemove = locationsAccount.map(loc => loc['locationId'])

      const newReportLocations = []

      for (const loc of reportAccount.locations){
        const isLocationForRemove = !locationsIdRemove.includes(loc['locationId'])
        if(isLocationForRemove) 
           newReportLocations.push(loc)
      }

      if (newReportLocations.length > 0){
        accountsCopy[reportAccountIndex].locations = newReportLocations;
      } else{
        accountsCopy.splice(reportAccountIndex, 1);
      }
    }
    reportRef.update({'accounts': accountsCopy})
  }

  // TODO: This should be done on backend side using a single endpoint. 
  // TODO: Some code awaits an observable emission from there but
  // it will resolve immediately without awaiting for real resolution,
  // probably not the intention.
  // TODO: accountId is not part of the args
  // TODO: Usually called AFTER delete() location, should be done before,
  // integrate with deleteLocation()
  deleteReferencesToLocation(gid : string, locationId : string): void {
    for(const coll of [POST_MANAGEMENT_GROUP, 
                       REPORTS, 
                       PROTOCOLS]) {

      const ref = this._afs.collection(GROUPS).doc(gid).collection(coll);
      const obs = ref.snapshotChanges();

      obs.subscribe(items  => {
        items.map(item => {
          const data = item.payload.doc.data();
          const id   = item.payload.doc.id;
          const newData = Object.assign({}, data)
          this._analyzeAccounts(newData, locationId);
          if (newData.accounts?.length) {
            if (newData.hasOwnProperty('accounts') && newData.accounts.length !== data.accounts.length) {
              ref.doc(id).update(newData)
            }
          }
          else {
            ref.doc(id).delete()
          }
        });
      })
    }
  }

  private _analyzeAccounts(item : firebase.firestore.DocumentData, locationId : string) {
    if (item.hasOwnProperty('accounts')) {
      const accounts = item.accounts;
      const newAccounts = accounts.filter(account => {
        if (account.hasOwnProperty('locations')) {
          const locations = account.locations;
          const newLocations = locations.filter(location => location.locationId !== locationId);
          if (newLocations.length !== locations.length) {
            account.locations = newLocations;
          }
          if (newLocations.length !== 0) {
            return account;
          }
        }
      });
      if (newAccounts.length !== accounts.length) {
        item.accounts = newAccounts;
      }
      if (newAccounts.length !== 0) {
        return item;
      }
    }
    else {
      return item;
    }
  }

  async save(locationObject: LocationObject, accountId: string, gidExternalGrade?: string): Promise<boolean> {
    const gid = gidExternalGrade || this._auth.session.gid;
    const isExternal = !!gidExternalGrade
    const locationId = locationObject.name.split('/')[3];

    const location = {
      'locationName': locationObject.locationName,
      'lockedOn': null,
      'location': locationObject,
      'locationId': locationId,
      'subscriptionType': LOCATION_SUBSCRIPTION_TYPE.FREE,
      'accountId': accountId,
      'gid': gid
    };

    try {
      await this._http.post(`${ENV.apiUrl}/v2/locations/add`, location).toPromise()
      this._locations.next(Object.assign({}, this._dataStore).locations);
      this.refreshAll(accountId, locationId, gid, isExternal)
      return true;
    } catch (err) {
      console.error('Error saving location', err);
      return false
    }
  }

  checkLocation(gid: string, accountId: string, locationId: string): Observable<any> {
    return this._http.get(`${ENV.apiUrl}/v2/locations/check/gid/${gid}/account/${accountId}/location/${locationId}`);
  }

  checkService(gid: string, accountId: string, locationId: string): Observable<ApiResponse> {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/google/account/${accountId}/location/${locationId}/service_list`) 
  }

  saveInsights(accountId: string, locationId : string): Observable<Object> {
    return this._insightsService.saveInsights(accountId, locationId);
  }

  saveReviews(accountId: string, locationId : string, isExternal = false): Observable<Object> {
    return this._reviewsService.saveReviews(accountId, locationId, isExternal);
  }

  saveV3Posts(gid: string, accountId: string, locationId: string): Promise<void> {
    return this._postService.saveV3All(gid, accountId, locationId);
  }

  saveObservations(accountId: string, locationId : string): Observable<Object> {
    return this._observationS.save(accountId, locationId);
  }

  saveServices(accountId: string, locationId : string): Observable<any> {
    return this._googleS.saveServices(accountId, locationId);
  }

  saveMenu(accountId: string, locationId : string): Observable<any> {
    return this._googleS.saveMenu(accountId, locationId);
  }

  update(gid : string, accountId : string, locationId : string, data): Observable<SavedLocation> {
    return this._http.post<SavedLocation>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}/save`, data);
  }

  initLocationEdit(gid : string, accountId : string, locationId : string, locationEdit): Observable<SavedLocation> {
    return this.update(gid, accountId, locationId, { locationEdit });
  }

  organizeServiceList(serviceList: ServiceList[], categories: { categoryId: string, displayName: string, primary: boolean, serviceTypes: { displayName: string, serviceTypeId: string }[] }[]): ServiceData[] {
    const dataSource: ServiceData[] = categories.map(ac => {
      return {
        categoryId: ac.categoryId,
        displayName: ac.displayName,
        principal: ac.primary,
        services: serviceList.reduce((result: Service[], sl, i) => {
          if (sl.freeFormServiceItem) {
            sl.freeFormServiceItem.categoryId = sl.freeFormServiceItem.category;
            if (sl.freeFormServiceItem.categoryId === ac.categoryId) {
              result.push({
                isFreeFormServiceItem: true,
                positionEdit: i,
                isOffered: sl.isOffered,
                serviceTypeId: null,
                description:  sl.freeFormServiceItem.label.description,
                displayName:  sl.freeFormServiceItem.label.displayName,
                languageCode: sl.freeFormServiceItem.label.displayName,
                price: sl.price
              })
            }
          } else if (sl.structuredServiceItem) {
            const serviceType = ac.serviceTypes?.find(st => st.serviceTypeId === sl.structuredServiceItem.serviceTypeId)
            if (serviceType) {
              result.push({
                isFreeFormServiceItem: false,
                positionEdit: i,
                isOffered: sl.isOffered,
                serviceTypeId: serviceType.serviceTypeId,
                description: sl.structuredServiceItem.description,
                displayName: serviceType.displayName,
                languageCode: null,
                price: sl.price
              })
            }
          }
          return result;
        }, [])
      }
    });
    return dataSource;
  }


  convertPriceListType(type: string): string {
    type = type.toLowerCase();
    if (type === 'jobs') {
      type = 'Jobs';
    }

    if (type === 'services') {
      type = 'Services';
    }

    if (type === 'menu') {
      type = 'Menu';
    }

    return type;
  }


  review_summary(gid, locations: any[]): any {
    if (!gid) {
      gid = this._auth.session.gid;
    }

    const normalizeSummary = (rs) => {
        if(!rs || !('googleResume' in rs) || !('difference' in rs))
            // missing or broken location review_summary
            return null

        if ('totalReviewCount' in rs)
            // New format after MAP-172
            return rs

        // Convert old format to new format
        return {
            difference       : rs.difference,
            googleResume     : rs.googleResume,
            answered         : rs.googleResume.answered,
            notAnswered      : rs.googleResume.notAnswered,
            totalReviewCount : rs.googleResume.answered + rs.googleResume.notAnswered,
            averageRating    : rs.googleResume.averageRating
        }
    }

    if (locations.length === 1) {
      return this.getRef(gid, locations[0].accountId, locations[0].locationId)
        .pipe(map(location => normalizeSummary(location?.review_summary)));
    } else if (locations.length > 1) {
      const $resumes = [];
      locations.forEach(p => {
        $resumes.push(
          this.getRef(gid, p.accountId, p.locationId)
            .pipe(map(location => normalizeSummary(location?.review_summary))));
      });
      return combineLatest($resumes);
    }
  }


  async refreshAll(accountId: string, locationId: string, gid: string, isExternal = false): Promise<boolean> {
    let call;
    if (ENV.saveLocationInChain) {
      call = zip(
        this._saveInChain(accountId, locationId)
      );
    } else {
      call = zip(
        // TODO: All of this must be collapsed to a single backend endpoint
        this.checkLocation(gid, accountId, locationId),
        this.checkService(gid, accountId, locationId),
        this.saveInsights(accountId, locationId),
        this.saveReviews(accountId, locationId, isExternal),
        this.saveObservations(accountId, locationId),
        this.saveV3Posts(gid, accountId, locationId)
      );
    }

    try {
      await call.toPromise()
      return true
    } catch (e) {
      console.error(`Error refreshing location locationId=${locationId}`, e);
      return false
    }
  }


  formatAddress(address): string | undefined {
    if (!address) 
      return
    
    return `${address.addressLines !== undefined ? address.addressLines[0] : '--'} ${address.locality || ''}, ${address.administrativeArea || ''} ${address.postalCode || ''}`;
  }

  formatServiceArea(serviceArea: IServiceArea): string | null {
    if (!serviceArea) {
      return null;
    }
    
    const placeNames: string[] = [];

    serviceArea.places?.placeInfos?.forEach(place => {
      placeNames.push(place.placeName || '--')
    });
    
    return placeNames.join(' | ');
  }


  verifyOpen(periods) {
    if (!periods) {
      periods = [];
    }

    periods = JSON.parse(JSON.stringify(periods));
    const periodsResult = [];
    WEEK_DAYS.forEach(day => {
      const currentPeriods = periods.filter(period => period.openDay === day);
      for (const period of currentPeriods) {
        if (period) {
          const hoursPipe = new HoursAmPmPipe();
          const openTime = this._dateS.getStringHours(period.openTime);
          const closeTime = this._dateS.getStringHours(period.closeTime);
          period.openTime = hoursPipe.transform(openTime);
          period.closeTime = hoursPipe.transform(closeTime);
          if (period.open !== false) {
            period.open = true;
          }
          periodsResult.push(period);
        }

        if (period.open === false) {
          return;
        }
      }
      if (currentPeriods.length === 0) {
        periodsResult.push({
          openDay: day,
          closeDay: day,
          closeTime: '',
          openTime: '',
          open: false
        });
      }
    });
    return periodsResult;
  }

  sortPeriodsByDay(periods: WeekDays[]) {
    return periods.reduce((r, a) => {
      r[a.openDay] = r[a.openDay] || [];
      r[a.openDay].push(a);
      return r;
    }, {});
  }

  joinByDay(periods: any[]): any {
    let data = {};

    periods.forEach(el => {
      const date = `${el.startDate.day}/${el.startDate.month}/${el.startDate.year}`;
      
      if (Object.keys(data).includes(date)) {
        data[date].push(el);
      } else {
        data[date] = [el];
      }
    })

    return this._getContinuousSpecialHours(data)
  }

  getContinuousHours(periods: any): any[] {
    const data = [];
    
    periods.forEach((p, i) => {
      const day = {...p};
      const isOpen24 = (day.openTime === '12:00 AM' && day.closeTime === '12:00 AM');
      i = (day.openDay == 'SATURDAY' ? -1 : i)
      const nextDay = periods[i + 1];
      const nextDayIsOpen24 = (nextDay.openTime === '12:00 AM' && nextDay.closeTime === '12:00 AM');


      if (day.closeTime != '' && 
          p.closeTime == nextDay?.openTime && 
          !isOpen24 && 
          !nextDayIsOpen24 &&
          this.isBefore1230PM(nextDay.closeTime)
      ) {
        day.closeTime = nextDay?.closeTime;
        if(day.openDay == nextDay.openDay || nextDay.openDay == periods[i + 2]?.openDay || day.openDay == 'SATURDAY') {
          periods.splice(periods[i+1], 1);
        } else {
          nextDay.openTime = '';
          nextDay.closeTime = '';
          nextDay.open = false;
        }
        if (day.openDay == 'SATURDAY' && p.closeTime == nextDay.openTime) {
          if (data[1].openDay == 'SUNDAY') {
            data.splice(0, 1)
          } else {
            data[0].openTime = '';
            data[0].closeTime = '';
            data[0].open = false;
          }
        }
      }

      data.push(day);
    })
    return data;
  }

  private isBefore1230PM(time: string): boolean {
    return time && (time === '12:00 PM' || time.includes('AM'))
  }

  byAccount(gid : string, accountId : string): Observable<SavedLocation[]> {
    return this._http.get<SavedLocation[]>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}`)
  }

  byAccountGetLocations(gid : string, accountId : string, data = {}): Observable<SavedLocation[]> {
    return this._http.post<SavedLocation[]>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/getLocations`, data)
  }

  async isAllLocationsUltimate(locations): Promise<boolean> {
    const response = await this._http.post(
      `${ENV.apiUrl}/v2/locations/is-all-ultimate`,
      { locationPaths: locations }
    ).toPromise();
    return (response as any)?.data?.isAllLocationsUltimate;
  }

  async basicLocations(list: any[]): Promise<void> {
    const accountsLocations = [];
    await Promise.all(list.map(
      async (i) => {
        if (i.accounts) {
          if (i.accounts.length > 0) {
            await Promise.all(i.accounts.map(async (account) => {
              account.locationsBasics = [];
              const exists = accountsLocations.filter(al => al.accountId === account.accountId);
              if (exists.length === 0) {

                const locations = this.getBasicLocations(i.gid || this._auth.session.gid, account.accountId);
                const asyncData = await Promise.all([locations]);

                if (asyncData[0]) {
                  await new Promise(resolve =>
                    asyncData[0]
                      .subscribe(basicLocations => {
                        accountsLocations.push({ [account.accountId]: basicLocations });
                        account.locations.forEach(location => {
                          basicLocations.forEach(bl => {
                            if (bl.locationId === location.locationId) {
                              account.locationsBasics.push(bl);
                            }
                          });
                        });
                        resolve(basicLocations);
                      }));
                }
              } else {
                return;
              }
            }));
          } else if (i.gid && i.accountId && i.placeId) {
            const location = await this.getRef(i.gid, i.accountId, i.placeId);
            const asyncData = await Promise.all([location]);
            if (asyncData[0]) {
              await new Promise(resolve =>
                asyncData[0]
                  .subscribe(async (loc) => {
                    i.location = loc.data();
                    const asyncAccount = await loc.ref.parent.parent.get();
                    i.account = asyncAccount.data();
                    resolve(loc);
                  }));
            }
          }
        }
      }
    ));
  }

  async getBasicLocations(gid: string, accountId: string): Promise<Observable<SavedLocation[]>> {
    return await this.byAccount(gid, accountId);
  }

  getByPrimaryCategory(gid: string, accountId: string, locationId: string): Observable<any> {
    return this._http.get<ApiResponse>(`${ENV.apiUrl}/v2/locations/${gid}/${accountId}/${locationId}/category`)
  }

  saveWidget(gid: string, accountId: string, locationId: string, data: any): Promise<void> {
    return this._afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId)
      .collection(LOCATIONS).doc(locationId).collection(WIDGET_INFO).doc(locationId)
      .set({ ...data }, { merge: true });
  }


  deleteWidget(gid: string, accountId: string, locationId: string): Promise<void> {
    return this._afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(accountId)
      .collection(LOCATIONS).doc(locationId).collection(WIDGET_INFO).doc(locationId).ref.delete();
  }

  async getAccountLocation(groupId: string, account: any): Promise<void> {
    const locations = this.byAccount(groupId, account.accountId).pipe(take(1));
    const asyncData = await Promise.all([locations]);
    if (asyncData[0]) {
      await new Promise(resolve =>
        asyncData[0]
          .pipe(take(1))
          .subscribe(result => {
            account.locations = result;
            resolve(result);
          }));
    }
  }

  getLocationsPaginate(count, pageable, actions): Pagination {
    return this.formatPagination(count, pageable, actions);
  }

  formatPagination(count: number, pageable, actions): Pagination {
    const pages = Math.ceil(count / pageable.size);
    let hasPrev = true;
    let hasNext = true;
    if (pages === pageable.page && pages > 1) {
      hasNext = false;
      hasPrev = true;
    } else if (pages === pageable.page && pages === 1) {
      hasNext = false;
      hasPrev = false;
    } else if (pageable.page === 1 && pages !== 0) {
      hasPrev = false;
      hasNext = true;
    } else if (pageable.page > 1 && pageable.page < pages) {
      hasPrev = true;
      hasNext = true;
    } else {
      hasPrev = false;
      hasNext = false;
    }

    return {
      items: actions,
      total: count,
      per_page: pageable.size,
      page:     pageable.page,
      pages,
      hasPrev,
      hasNext,
    }
  }

  formatDates(date : string) : string {
    return date?.includes('T') ? date?.split('T')[0] : date?.split(' ')[0];
  }

  getDateValidations(reportType : string, accountIds : string[], gids : string[], locationIds : string[]): Observable<any> {
    const data = {
      "locationIds": locationIds,
      "accountIds": accountIds,
      "gids": gids,
      "type": reportType
      
     }
    return this._http.post(`${ENV.apiUrl}/v2/locations/date-validation`, data)
  }
  
  dateValidation(dates: any = {}): { minDate: any, maxDate: any } {
    const data = {minDate: null, maxDate: null};
    if(dates?.maxDate) {
      dates.maxDate = `${dates.maxDate.split(' ')[0]}T23:59:59`;
      data.maxDate = moment(dates.maxDate);
    }
    
    if(dates?.minDate) {  
      dates.minDate = `${dates.minDate.split(' ')[0]}T23:59:59`;
      data.minDate = moment(dates.minDate);
    }
    return data;
  }

  buildDatepickerDate(reportType: string, maxDate = null) {
    const today = moment().subtract(7, 'days');
    const todayStr = today.format('YYYY-MM-DD 23:59:59')
    let endOfMonth = moment().endOf('month').format('YYYY-MM-DD 23:59:59');
    let isFullMonth = todayStr == endOfMonth;

    //Case 1: when the moth is complete
    let start = isFullMonth ? today.clone().subtract(1, 'year') : today.clone().subtract({ months: 1, years: 1 });
    let end = isFullMonth ? today.clone() : today.clone().subtract({ months: 1 });

    //Case 2: if the moth is incomplete
    if (!isFullMonth && (reportType?.includes('rollup') || reportType == 'performance-comparison') && maxDate) {
      // we create a clone of maxDate to prevent mutating the original date which caused SO many issues...
      const maxDateClone = maxDate.clone();
      const maxDateString = maxDateClone?.format('YYYY-MM-DD 23:59:59')
      endOfMonth = maxDateClone?.endOf('month').format('YYYY-MM-DD 23:59:59');
      isFullMonth = maxDateString == endOfMonth;

      start = isFullMonth ? maxDateClone?.clone().subtract(1, 'year') : maxDateClone?.clone().subtract({ months: 1, years: 1 });
      end = isFullMonth ? maxDateClone : maxDateClone?.clone().subtract({ months: 1 });
    }
    return {
      start: start?.startOf('month'),
      end: end?.endOf('month')
    };
  }

  deleteServiceArea(accounts) {
    accounts?.forEach(acc => {
      acc?.locations.forEach(l => {
        if (Object.keys(l)?.includes('serviceArea')) {
          delete l.serviceArea;
        }
      })
    })

    return accounts;
  }

  deleteAddress(accounts) {
    accounts?.forEach(acc => {
      acc?.locations.forEach(l => {
        if (Object.keys(l)?.includes('address')) {
          delete l.address;
        }
      })
    })

    return accounts;
  }


  private _getContinuousSpecialHours(hours: any): any {
    const keys = Object.keys(hours);
    
    keys.forEach(d => {
      hours[d].forEach((h, i) => {
        const nextHour = hours[d][i+1];
        if(h.closeTime == nextHour?.openTime) {
          h.closeTime = nextHour?.closeTime;
          hours[d].splice(i+1, 1)
        }
      })
    })
    return hours;
  }

  private _saveInChain(accountId : string, locationId : string) {
    let params = new HttpParams();
    if (ENV.queuesEnabled) {
      params = params.append('enqueue', Queue.COMBINED_EXPRESS);
    }

    return this._http.post(`${ENV.apiUrl}/v2/locations/${accountId}/${locationId}/save`, {}, { params });
  }
}
