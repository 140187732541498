// TODO: Refactor against location-info-list.component.ts
import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { COMPONENTS_CORE, COMPONENTS_MISC } from "../../constants/location-info";
import { AuthService } from '../../services/auth.service';
import { BulkLocationsEditService } from '../../services/bulk-locations-edit.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { BehaviorSubject, of } from 'rxjs';
import { GoogleService } from '../../services/google.service';
import * as _ from 'lodash';
import { startWith, map } from 'rxjs/operators';
import { defaultMenu } from '../../constants/google/menuList';
import { ModalService } from '../../services/modal.service';
import { AlertType } from '../../components/alert.component';
var ModalBulkLocationInfoComponent = /** @class */ (function () {
    function ModalBulkLocationInfoComponent(_dialogRef, _auth, _bulkLocationService, _snackS, _googleServices, _locationService, _modalService) {
        this._dialogRef = _dialogRef;
        this._auth = _auth;
        this._bulkLocationService = _bulkLocationService;
        this._snackS = _snackS;
        this._googleServices = _googleServices;
        this._locationService = _locationService;
        this._modalService = _modalService;
        this.listCoreComponents = COMPONENTS_CORE;
        this.listMiscComponents = COMPONENTS_MISC;
        this.listActionLink = [];
        this.step = 0;
        this.stepTotal = 0;
        this.accountsReport = [];
        this.componentToEdit = new FormControl({}, [Validators.required]);
        this.validResult = false;
        this.regionCodes = null;
        this.locationsInfo = [];
        this.notSameCategory$ = new BehaviorSubject(false);
        this.notAllowedMenu$ = new BehaviorSubject(false);
        this.notAllowServices$ = new BehaviorSubject(true);
        this.loadingAttributes$ = new BehaviorSubject(true);
        this.notSameRegionCode$ = new BehaviorSubject(false);
        this.ListTemplate = [];
        this.ListMenuTemplate = [];
        this.serviceListTemplate = [];
        this.menuListTemplate = [];
        this.primaryCategories = [];
        this.additionalCategories = [];
        this.commonAdditionalCategories = [];
        this.gid = this._auth.session.gid;
        this.groupAttributes = [];
        this.attributesUrl = null;
        this.actionLinks = [];
        this.serviceActive = [];
        this.menuActive = [];
        this.filteredOptions = of([]);
        this.locationsTemplate = new FormControl();
        this.locationsSelection = [];
        this.Replace = true;
        this.regionCodesdViewDetails = false;
        this.categoriesViewDetails = false;
    }
    ModalBulkLocationInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filteredOptions = this.locationsTemplate.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value); }));
    };
    ModalBulkLocationInfoComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        if (filterValue !== '') {
            var locations = Object.assign([], this.locationsSelection);
            return locations.filter(function (option) { return option.address.addressLines[0].toLowerCase().indexOf(filterValue) === 0 || option.locationName.toLowerCase().indexOf(filterValue) === 0; });
        }
        else {
            return this.locationsSelection;
        }
    };
    ModalBulkLocationInfoComponent.prototype.selectedLocations = function ($event) {
        this.accountsReport = $event;
    };
    ModalBulkLocationInfoComponent.prototype.completeSteeper = function () {
        var _this = this;
        this._modalService.openConfirmModal('Bulk Update Confirmation', 'Are you sure you want to update our local copy of the location? If they are locked, they will remain locked', function (res) {
            if (!res)
                return;
            var component = _this.resultComponent.getResult();
            if (!_this.validResult) {
                _this._snackS.openInfo('You must add some information', 2000);
                return;
            }
            _this.save(component);
        }, AlertType.INFO);
    };
    ModalBulkLocationInfoComponent.prototype.loadCategories = function () {
        var _this = this;
        var promises = [];
        this.locationsSelection = [];
        this.accountsReport.forEach(function (a) {
            a.locations.forEach(function (l) {
                var result = _this._locationService.getRef(_this._auth.session.gid, a.accountId, l.locationId);
                _this.locationsSelection.push(__assign({ gid: _this._auth.session.gid, accountId: a.accountId, locationId: l.locationId }, l));
                promises.push(result.toPromise());
            });
        });
        return promises;
    };
    ModalBulkLocationInfoComponent.prototype.verification = function ($event) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var promises, primaryC_1, pivot, search_1, value, pivot;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!($event === 0)) return [3 /*break*/, 1];
                        this.componentToEdit.setValue({});
                        return [3 /*break*/, 11];
                    case 1:
                        if (!($event === 1)) return [3 /*break*/, 10];
                        this.componentToEdit.setValue({});
                        this.componentToEdit.markAsUntouched();
                        this.loadingAttributes$.next(true);
                        this.primaryCategories = [];
                        this.additionalCategories = [];
                        this.serviceActive = [];
                        this.menuActive = [];
                        this.ListTemplate = [];
                        this.ListMenuTemplate = [];
                        this.serviceListTemplate = [];
                        this.locationsInfo = [];
                        this.actionLinks = [];
                        this.listActionLink = [];
                        return [4 /*yield*/, this.addActionLinks()];
                    case 2:
                        _c.sent();
                        promises = this.loadCategories();
                        return [4 /*yield*/, Promise.all(promises).then(function (results) {
                                var _a;
                                var regionCodes = results.map(function (el) { return _this._locationService.getRegionCode(el); });
                                regionCodes = __spread(new Set(regionCodes));
                                _this.regionCodes = (_a = regionCodes) === null || _a === void 0 ? void 0 : _a.join(', ');
                                var value = regionCodes.every(function (el) { return el == regionCodes[0]; });
                                _this.notSameRegionCode$.next(!value);
                                results.forEach(function (el) {
                                    var _a, _b, _c, _d;
                                    return _this.locationsInfo.push({ locationName: el.locationName,
                                        locationAddress: el.location.address,
                                        serviceArea: (_d = (_c = (_b = (_a = el) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.serviceArea) === null || _c === void 0 ? void 0 : _c.places) === null || _d === void 0 ? void 0 : _d.placeInfos });
                                });
                                results.forEach(function (data) { return __awaiter(_this, void 0, void 0, function () {
                                    var loc, canService, canMenu;
                                    var _a, _b;
                                    return __generator(this, function (_c) {
                                        if (data['serviceList'])
                                            this.ListTemplate.push({ source: this._auth.session.gid + "/" + data['accountId'] + "/" + data['locationId'], serviceList: data['serviceList'] });
                                        if (data['menuList'])
                                            this.ListMenuTemplate.push({ source: this._auth.session.gid + "/" + data['accountId'] + "/" + data['locationId'], menuList: data['menuList'] });
                                        loc = data['location'];
                                        if (loc) {
                                            this.primaryCategories.push(loc['primaryCategory'] || null);
                                            this.additionalCategories.push(loc['additionalCategories'] || null);
                                            canService = ((_a = loc['locationState']) === null || _a === void 0 ? void 0 : _a.canModifyServiceList) || false;
                                            this.serviceActive.push(canService);
                                            canMenu = ((_b = loc['locationState']) === null || _b === void 0 ? void 0 : _b.canHaveFoodMenus) || false;
                                            this.menuActive.push(canMenu);
                                        }
                                        return [2 /*return*/];
                                    });
                                }); });
                            })
                                .catch(function () {
                                _this.primaryCategories.push(null);
                                _this.additionalCategories.push(null);
                            })];
                    case 3:
                        _c.sent();
                        this.primaryCategories[0]['primary'] = true;
                        this.notAllowedMenu$.next(!this.menuActive.every(function (v) { return v === true; }));
                        this.notAllowServices$.next(!this.serviceActive.every(function (v) { return v === true; }));
                        if (!(this.primaryCategories.length > 1)) return [3 /*break*/, 6];
                        primaryC_1 = this.primaryCategories[0].categoryId;
                        pivot = this.additionalCategories[0] || [];
                        search_1 = this.additionalCategories.slice(1).filter(Boolean);
                        this.commonAdditionalCategories = pivot.reduce(function (ArrayResult, pivotCategory) {
                            if (search_1.every(function (s) {
                                var finding = s.find(function (categorySearch) { return categorySearch.categoryId === pivotCategory.categoryId; });
                                if (finding)
                                    return true;
                                else
                                    return false;
                            })) {
                                ArrayResult.push(pivotCategory);
                            }
                            return ArrayResult;
                        }, []);
                        if (!primaryC_1) return [3 /*break*/, 5];
                        value = !this.primaryCategories.every(function (c) { var _a; return ((_a = c) === null || _a === void 0 ? void 0 : _a.categoryId) === primaryC_1; });
                        this.notSameCategory$.next(value);
                        if (!!value) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.setInfo()];
                    case 4:
                        _c.sent();
                        _c.label = 5;
                    case 5: return [3 /*break*/, 9];
                    case 6:
                        if (!(this.primaryCategories.length == 1)) return [3 /*break*/, 8];
                        pivot = this.additionalCategories[0] || [];
                        this.commonAdditionalCategories = pivot;
                        this.notSameCategory$.next(false);
                        return [4 /*yield*/, this.setInfo()];
                    case 7:
                        _c.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        this.notSameCategory$.next(true);
                        _c.label = 9;
                    case 9:
                        this.loadingAttributes$.next(false);
                        return [3 /*break*/, 11];
                    case 10:
                        if ($event === 2) {
                            if (((_b = (_a = this.componentToEdit) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.key) === 'service') {
                                this.resultComponent.setDataService(this.serviceListTemplate, this.primaryCategories[0], this.commonAdditionalCategories);
                            }
                        }
                        _c.label = 11;
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    ModalBulkLocationInfoComponent.prototype.addActionLinks = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var accountIds, locationIds, res, err_1;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        accountIds = this.accountsReport.map(function (el) { return el.accountId; });
                        locationIds = [];
                        this.accountsReport.forEach(function (acc) { return acc.locations.map(function (el) { return locationIds.push(el.locationId); }); });
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._bulkLocationService.getBulkPlaceActionLinks(this._auth.session.gid, accountIds, locationIds).toPromise()];
                    case 2:
                        res = _e.sent();
                        if ((_a = res) === null || _a === void 0 ? void 0 : _a.data) {
                            this.showLoader = false;
                            this.actionLinks = (_c = (_b = res) === null || _b === void 0 ? void 0 : _b.data[0]) === null || _c === void 0 ? void 0 : _c.actions;
                            (_d = this.actionLinks) === null || _d === void 0 ? void 0 : _d.forEach(function (el) {
                                var _a, _b;
                                _this.listActionLink.push({
                                    key: 'placeActionLinks',
                                    value: (_a = el) === null || _a === void 0 ? void 0 : _a.displayName,
                                    placeActionType: (_b = el) === null || _b === void 0 ? void 0 : _b.placeActionType
                                });
                            });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _e.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ModalBulkLocationInfoComponent.prototype.setInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var accountIds, locationIds, groups;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accountIds = this.accountsReport.map(function (el) { return el.accountId; });
                        locationIds = [];
                        this.accountsReport.forEach(function (acc) { return acc.locations.map(function (el) { return locationIds.push(el.locationId); }); });
                        return [4 /*yield*/, this._googleServices.groupsWithAttribute(accountIds, [this._auth.session.gid], locationIds)];
                    case 1: return [4 /*yield*/, (_a.sent())];
                    case 2:
                        groups = (_a.sent()).groups;
                        this.groupAttributes = groups;
                        this.attributesUrl = groups.reduce(function (c, p) {
                            var items = p.items.filter(function (i) {
                                return _.startsWith(i.attributeId, 'url');
                            });
                            if (items.length > 0)
                                Object.assign(c, items);
                            return c;
                        }, []);
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalBulkLocationInfoComponent.prototype.changeReplace = function (Replace) {
        this.Replace = Replace.checked;
    };
    Object.defineProperty(ModalBulkLocationInfoComponent.prototype, "completeStep1", {
        get: function () {
            var _a;
            return ((_a = this.accountsReport) === null || _a === void 0 ? void 0 : _a.length) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalBulkLocationInfoComponent.prototype, "completeStep2", {
        get: function () {
            this.validResult = false;
            return this.componentToEdit.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalBulkLocationInfoComponent.prototype, "completeStep3", {
        get: function () {
            return this.validResult;
        },
        enumerable: true,
        configurable: true
    });
    ModalBulkLocationInfoComponent.prototype.GetNameProperty = function (component) {
        var _a, _b;
        if (!!Object.keys(component).length) {
            var key = typeof (component) === 'string' ? component : (_a = component) === null || _a === void 0 ? void 0 : _a.key;
            if (!((_b = key) === null || _b === void 0 ? void 0 : _b.includes("Secondary-"))) {
                return component.value;
            }
            else {
                var name_1 = component.split("-");
                name_1.splice(0, 1);
                var displayName = name_1.join(" ");
                return "Secondary Website URLs (" + displayName + ")";
            }
        }
        return '';
    };
    ModalBulkLocationInfoComponent.prototype.save = function (component) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var key, bulkEdit, bulkSaved, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.showLoader = true;
                        key = (_b = (_a = this.componentToEdit) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.key;
                        bulkEdit = {
                            accounts: this.accountsReport,
                            component: key,
                            gid: this._auth.session.gid,
                            status: 'PENDING',
                            change: key !== 'placeActionLinks' ? component : this.getActionLinksData(component),
                            replace: this.Replace
                        };
                        if (key === 'photos')
                            delete bulkEdit['change'];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._bulkLocationService.create(this._auth.session.gid, bulkEdit)];
                    case 2:
                        bulkSaved = _d.sent();
                        this._dialogRef.close({ location_edit: bulkSaved,
                            updates: component });
                        return [3 /*break*/, 4];
                    case 3:
                        _c = _d.sent();
                        this._snackS.openError("There was an error during the bulk edit. Please try again then contact support.", 2500);
                        return [3 /*break*/, 4];
                    case 4:
                        this.showLoader = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalBulkLocationInfoComponent.prototype.getActionLinksData = function (component) {
        var _this = this;
        var data = this.actionLinks.find(function (el) { return el.placeActionType === _this.componentToEdit.value.placeActionType; });
        data.placeActionLinks = component;
        return data;
    };
    ModalBulkLocationInfoComponent.prototype.getAttributes = function () {
        return this._googleServices.attributesToGMB(this.groupAttributes);
    };
    ModalBulkLocationInfoComponent.prototype.getAttribute = function () {
        var _a, _b, _c;
        var attributeId = (_c = (_b = (_a = this.componentToEdit) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.key) === null || _c === void 0 ? void 0 : _c.split('Secondary-')[1];
        return this.attributesUrl.find(function (a) { return a.attributeId === attributeId; });
    };
    ModalBulkLocationInfoComponent.prototype.changeSourceService = function (source) {
        var _a;
        var search = this.ListTemplate.find(function (sl) { return sl.source === source.value; });
        this.serviceListTemplate = ((_a = search) === null || _a === void 0 ? void 0 : _a.serviceList) || [];
        this.resultComponent.setDataService(this.serviceListTemplate, this.primaryCategories[0], this.commonAdditionalCategories);
    };
    ModalBulkLocationInfoComponent.prototype.changeAutoCompleteSourceService = function (source) {
        var _a;
        var search = this.ListTemplate.find(function (sl) { return sl.source === source; });
        this.serviceListTemplate = ((_a = search) === null || _a === void 0 ? void 0 : _a.serviceList) || [];
        this.resultComponent.setDataService(this.serviceListTemplate, this.primaryCategories[0], this.commonAdditionalCategories);
    };
    ModalBulkLocationInfoComponent.prototype.changeAutoCompleteSourceMenu = function (source) {
        var _a;
        var search = this.ListMenuTemplate.find(function (sl) { return sl.source === source; });
        this.menuListTemplate = Object.assign([], ((_a = search) === null || _a === void 0 ? void 0 : _a.menuList) || defaultMenu);
        this.resultComponent.setDataMenu(this.menuListTemplate);
    };
    ModalBulkLocationInfoComponent.prototype.formatAddress = function (address) {
        return address ? this._locationService.formatAddress(address) : '';
    };
    return ModalBulkLocationInfoComponent;
}());
export { ModalBulkLocationInfoComponent };
