import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalActionLinksComponent } from '../../modal-action-links/modal-action-links.component';

@Component({
  selector: 'app-location-action-links',
  templateUrl: './location-action-links.component.html',
  styleUrls: ['./location-action-links.component.scss']
})
export class LocationActionLinksComponent implements OnInit {
  @Input() actionLinks = [];
  @Input() isEdit = true;
  @Input() isUnderReview = false;

  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private _dialogS: MatDialog,
  ) {}

  ngOnInit() {
  }

  openActionLinksDialog(element): void {
    const dialogRef = this._dialogS.open(ModalActionLinksComponent, {
      width: '680px',
      data: {
        title: element?.displayName,
        actionLinks: element
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onUpdate.emit(true);
      }
    });
  }

}
