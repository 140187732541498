<div class="dialog__header modal-getaddon-header">
    <h2 mat-dialog-title class="txt--xl m--0"><strong>{{data.title}}</strong></h2>
    <button mat-icon-button mat-dialog-close tabIndex="-1" class="txt--lg"><i class="fal fa-times"></i></button>
</div>

<div class="dialog__content" mat-dialog-content>
    <div class="row m-b-15">
        <div class="col-10">
            <strong><h2 class="txt--xl m--0">Bulk Actions</h2></strong>
            <p class="txt--lg">An essential add-on for businesses with multiple locations.</p>
        </div>
        <div class="col-2 txt-align-rigth">
            <div *ngIf="price">
                <strong><h2 class="txt-size-25 m--0">${{price}}/mo</h2></strong>
                <p class="txt-size-16 txt-color-gray">Starting from</p>
            </div>
        </div>
    </div>
    <div>
        <div class="dialog__content__message">
            <strong><h2 class="txt--lg m--0">Roll-Up Reports</h2></strong>
            <p class="txt--lg m--0">Aggregate insights and performance data from multiple locations.</p>
        </div>
        <div class="dialog__content__message">
            <strong><h2 class="txt--lg m--0">Bulk Updates</h2></strong>
            <p class="txt--lg m--0">Change hours, URLs, attributes, menus, services, photos, and more in bulk.</p>
        </div>
        <div class="dialog__content__message">
            <strong><h2 class="txt--lg m--0">Bulk Posting & Scheduling</h2></strong>
            <p class="txt--lg m--0">Schedule posts in advance and publish across multiple locations with one click.</p>
        </div>
    </div>
</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">{{data.buttons['cancel']}}</button>
    </span>
    <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--action" (click)="getAddOn()" >{{data.buttons['accept']}}</button>
</div>