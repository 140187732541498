// dep
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { takeUntil, take, switchMap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject, of, merge, timer } from 'rxjs';
import * as _ from 'lodash';

// app
import { AuthService } from '../../services/auth.service';
import { LocationService } from '../../services/location.service';
import SavedLocation from '../../constants/firestore/saved-location';
import { SubscriptionService } from '../../services/subscription.service';
import { AccountService } from '../../services/account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { ObservationService } from '../../services/observation.service';
import { ModalService } from '../../services/modal.service';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import User from '../../constants/firestore/user';
import { ISubscription } from '../../constants/subscription';
import * as constants_locations from '../../constants/firestore/account-location'
import * as constants_plans from '../../constants/firestore/plan'
import { WhiteLabelService } from "../../services/white-label.service";
import { PaginatorComponent } from '../charts/paginator/paginator.component';
import { NotificationService } from 'src/app/services/notification.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from 'src/app/constants/notifications';
import { Messages, string_message } from 'src/app/constants/messages';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AlertType } from 'src/app/components/alert.component';
import { LocationRef } from 'src/app/constants/firestore/location-object';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: [ './locations.component.scss']
})
export class LocationsComponent implements OnInit, AfterViewInit, OnDestroy {

  public STATUS_CHANGE_PLAN         = constants_plans.STATUS_CHANGE_PLAN;
  public LOCATION_SUBSCRIPTION_TYPE = LOCATION_SUBSCRIPTION_TYPE;
  public GROUP_SUBSCRIPTION_TYPE    = GROUP_SUBSCRIPTION_TYPE;
  // public authOk           = constants_locations.VERIFICATION_OK;
  public authUnauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
  public authNotFound     = constants_locations.VERIFICATION_NOT_FOUND;
  public accountError     = constants_locations.VERIFICATION_ACCOUNT_ERROR;
  public verifRequired    = constants_locations.VERIFICATION_REQUIRED;

  public user: User;
  public totalLocations: any[] = [];
  public filteredLocationIds: string[] = [];
  public filteredOptions: Observable<any>;
  public countLocations: number;
  public locations: any[] = [];
  public accountsOptions = [];
  public selectedAccount: object
  public labelAccountsFiltered: string;
  public filteredAccounts: any[] = []
  public filterAccountsControl = new FormControl('');
  public accountObjectId: string;
  public message: object;
  public displayedColumns: string[];
  public dataSource: any;
  public allLocationCheck = false;
  public allChecked: boolean;
  public checkedLocations;
  public listCheckLocations: any[] = [];
  public upgradeCheckSelect = false;
  public downgradeCheckSelect = false;
  public manualPage: number;
  public errorMessage: boolean;
  public locationsUser = [];
  public textSearch = null;
  public size = 10;
  public page = 1;
  public pagination: Pagination = {
    items: [],
    per_page: this.size,
    page: this.page,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public selectedSearch = new FormControl();
  public filtered = false;
  public searchText: string;
  public subscription: ISubscription;
  public loadingTable$   = new BehaviorSubject(true);
  public NotFoundSearch$ = new BehaviorSubject(false);
  public timeout: ReturnType<typeof setTimeout> | null = null
  public errorAccount = false;

  private _hasAccountsFilter = false
  private _refreshTimerStarted = false
  private _paginate : Pageable
  private _destroy$ = new Subject<boolean>()
  // private _previousPageable: Pageable = null;

  constructor(
    public auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _detectStrategy: ChangeDetectorRef,
    private _spinnerService: SpinnerService,
    private _snack: SnackbarService,
    private _locationService: LocationService,
    private _accountService: AccountService,
    private _apiSubscription: SubscriptionService,
    private _obsService: ObservationService,
    private _modalService: ModalService,
    private _location: Location,
    private _notificationService: NotificationService,
    private _wl: WhiteLabelService,
  ) {
    this.user = this.auth.session;
    this._untilDestroy(this.auth.subscription$).subscribe(sub => {
      this.subscription = sub
      this._updateTable()
    });

    // Reload the locations table when we trigger a location plan change from this
    // component or the plan change is externally started by TrialMessageComponent
    let is_first_value = true
    this._untilDestroy(this._locationService.someLocationChanged).subscribe(
      _ => {
        if(is_first_value)
          is_first_value = false
        else
          this._init()
      }
    )
  }

  private _untilDestroy<T>(o : Observable<T>) : Observable<T> {
    return o.pipe(takeUntil(this._destroy$))
  }

  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('paginator', { static: false }) paginatorComponent: PaginatorComponent;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  ngOnInit(): void {
    this._untilDestroy(this._locationService.getPaginate()).subscribe(
      res => this._paginate = res
    )

    this._init();
    this._untilDestroy(this._router.events).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loadingTable$.next(true);
        this.accountObjectId = event.url.split('/')[2]
        this.filterAccountsByAccountId();
        this._init();
      }
    });
  }

  ngAfterViewInit(): void {
    this.manualPage = 1;
    this.errorMessage = false;
    this._detectStrategy.detectChanges();
  }

  ngOnDestroy(): void {
    this._locationService.reset();
    this._destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this._destroy$.unsubscribe();
  }

  resetListSelect(): void {
    this.listCheckLocations = []
  }

  
   async onAddAccount(): Promise<void> {
     await this._router.navigate(['accounts'])
     this._obsService.sendAddAccount()
   }

  openDeleteDialog(location : SavedLocation): void {
    this._modalService.openConfirmModal(`Are you sure you want to delete ${location.locationName} ?`, 
                                        'This action cannot be reverted.', (res) => {
      if (!res) {
        return;
      }
      //Notification informative delete location
      this._spinnerService.loading$.next(true);
      location.formatAddress = !location.address ? '' : this._locationService.formatAddress(location.address)

      const delete$ = of(this._locationService.deleteReferencesToLocation(this.user.gid, location.locationId));
      delete$.pipe(
        switchMap(async () => await this._locationService.deleteLocation(this.user.gid, location.locationId, this.accountObjectId))
      ).subscribe(() => {
      }, err => {
        this._spinnerService.loading$.next(false);
        console.error('Error deleting locations', err)
      }, () => {
        location = { accountId: this.accountObjectId, address: location.formatAddress, ...location }
        const meta = this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, location);
        const notify$ = merge(this._notificationService.saveNotification(this.auth.session.gid, this._wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [location.locationName, location.address, DELETE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta))
        notify$.subscribe();
        const locations = this.locations.filter(l => l.locationId !== location.locationId)
        if (locations.length !== 0 || this.filtered) {
          this.listCheckLocations = [];
          this._init()
          return;
        }
        // Delete the account itself and navigate to /accounts
        this._accountService.delete(this.user.gid, this.accountObjectId).then(
          () => {
            this._router.navigate(['/accounts']).then();
          }, err => console.error(`Error deleting master AOID: ${this.accountObjectId}`, err)
        );
        this._spinnerService.loading$.next(false);
      })
    }, 2);
  }

  private _initPaginator(): void {
    this._paginate = { page: 1, size: 10 };
    this.paginatorComponent.reset();
    // this._previousPageable = null;
  }

  // apply filter from search
  applyFilter($event: string): void {
    this.NotFoundSearch$.next(false);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

       if (!$event) {
         this.filtered = false;
         this.filteredLocationIds = [];
         this.loadingTable$.next(true);
         this._initPaginator();
         this._getData(this._paginate);
       } else {
         if ($event[$event.length - 1] !== ' ') {
           if (!this.filtered) 
              this._initPaginator();
           this.filtered = true;
           this.loadingTable$.next(true);
           const text = $event.toLowerCase();
           this.searchText = text;
           this.updateData(this._paginate, text)
         }
       }

     }, 350);
  }

  private _getData($event: Pageable, locationsIds : string[] = []): void {
    if (!this.auth.isAdmin) {
      locationsIds = this.locationsUser;
    }
    
    // FIXME: Subscription leak? multiple handlers will be run in paralell as they are all
    // subscribed?
    this._untilDestroy(this._locationService.getLocationPaginate(this.user.gid, this.accountObjectId, $event, locationsIds))
    .subscribe(r => {
        // this._previousPageable = { size: r['perPage'], 
        //                            page: r['currentPage'] };
        this.pagination = {
          items:    r['items'],
          per_page: r['perPage'],
          page:     r['currentPage'],
          hasNext:  r['currentPage'] < r['totalPages'],
          hasPrev:  r['currentPage'] > 1,
          pages:    r['totalPages'],
          total:    r['totalItems']
        };
        this.locations = this._formatData(r['items'], this.subscription);
        this.countLocations = r['totalItems'];
        this._updateTable();
        this._spinnerService.loading$.next(false);
      
    },
    err => {
      this._spinnerService.loading$.next(false);
      console.log(err);
    });
  }

  updateData($event: Pageable, text: string): void {
    this._locationService.getLocationsIdsByStringQuery(this.user.gid, text)
    .pipe(map(response => {
      const filteredAccount = response?.accounts?.find(account => account.accountId === this.accountObjectId) || null
      this.filteredLocationIds = filteredAccount?.locations?.map(location => location.locationId) || []
      return this.filteredLocationIds
    }))
    .subscribe((locationIds: string[]) => {
      if (locationIds.length > 0) {
        this._getData($event, locationIds)
      } else {
        this.NotFoundSearch$.next(true)
        this.loadingTable$.next(false)
      }
    });
  }

  setElementSwitch(element): string {
    if (element?.location?.locationState?.isVerified == false)
      return this.verifRequired
    else if (element?.lastCheck?.status && element.lastCheck.status !== 'OK') 
      return element?.lastCheck?.status
    else if (element?.errorLog) 
      return 'errorLog'
    else
      return ''
  }

  // check for nested objects
  private _nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this._nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }


  toggleCheckAll(check: MatCheckboxChange) : void {
    this.allChecked = check.checked;
    this._getTotalLocations();
    this.locations.forEach(location => {
      if (this.allChecked) {
        const result = this.listCheckLocations.find(l => l.locationId == location.locationId);
        if (!result) {
          this.listCheckLocations.push({
            locationId: location.locationId,
            subscriptionType: location.subscriptionType,
            pendingChange: location.pendingChange,
            locationName: location.locationName,
            location: location.location,
            checked: this.allChecked
          });
        }
      } else {
        this.listCheckLocations = [];
        this.allLocationCheck = false;
        const result = this.listCheckLocations.find(l => l.locationId == location.locationId);
        if (result) {
          this.listCheckLocations = this.listCheckLocations.filter(l => l.locationId !== location.locationId);
        }
      }
      location.isChecked = this.allChecked
    });
    this.isUpgradeDowngradeActions();
  }

  private _getTotalLocations() : void {
    this._locationService.locations.pipe(take(1)).subscribe(locations => {
      if (this.filteredLocationIds.length > 0)
        this.totalLocations = locations.filter(l => this.filteredLocationIds.includes(l.locationId))  
      else
        this.totalLocations = locations
    });
  }

  selectLocation(location : SavedLocation, check: MatCheckboxChange) : void {
    if (this.listCheckLocations.length > 0) {
      const result = this.listCheckLocations.find(l => l.locationId == location.locationId);

      if (result) {
        this.listCheckLocations = this.listCheckLocations.filter(l => l.locationId !== location.locationId);
      } else {
        this.listCheckLocations.push({
          locationId: location.locationId,
          pendingChange: location.pendingChange,
          subscriptionType: location.subscriptionType,
          locationName: location.locationName,
          location: location.location,
          checked: check.checked
        });
      }
    } else {
      this.listCheckLocations.push({
        locationId: location.locationId,
        pendingChange: location.pendingChange,
        subscriptionType: location.subscriptionType,
        locationName: location.locationName,
        location: location.location,
        checked: check.checked
      });
    }

    this.isUpgradeDowngradeActions();
  }

  private isUpgradeDowngradeActions() : void {
    if (this.listCheckLocations.length > 0) {
      this.upgradeCheckSelect   = !!this.listCheckLocations.find(l => l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE)
      this.downgradeCheckSelect = !!this.listCheckLocations.find(l => l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC || 
                                                                      l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE)
    } else {
      this.upgradeCheckSelect   = false
      this.downgradeCheckSelect = false
    }
  }

  private _generateBulkSubscriptionErrorMessage(unverifiedLocations, locationsChange): string {
    let message = `<div class='txt--left'>
    The following location(s) need to be verified in your Google Business Profile before you can update the subscription(s).
      <div>
      <br>
        <ul>`;    
    unverifiedLocations.forEach((location) => {
      message += `<div>${location?.locationName}</div><li>${this._locationService.formatAddress(location?.location?.address)}</li>`;
    });
  
    message += `</ul>
      </div>`;

    if (locationsChange.length >= 1){
      message += "Would you like to proceed with updating the other locations?"
    }
    message += "</div>"
  
    return message;
  }

  // Function change to plan locations
  async changePlan(locations : SavedLocation | SavedLocation[]) : Promise<void> {
    if (this.auth.isMember) {
      this._modalService.openWarningModal('Contact your administrator',
                                          'Your user doesn’t have permissions to do this. Contact your account administrator to change your role.')
      return;
    }
    let locationsChange : LocationRef[]= []
    const unverifiedLocations = []
    if (!_.isArray(locations)) {
      if (!this._validateVerifiedStatus(locations as SavedLocation, true))
        return
    
      locationsChange = [{ locationId: (locations as SavedLocation).locationId, 
                           accountId:  this.accountObjectId }];
    }
    else {
      locations.forEach(location => {
        if (location?.location?.locationState?.isVerified == false){
          unverifiedLocations.push(location)
        }else {
          locationsChange.push({ locationId: location, 
                                 accountId:  this.accountObjectId })
        }
      });
    }

    if (unverifiedLocations.length >= 1 ){
      const ret = await this._modalService.openAlertModal("Verification required in Google Business Profile",
        this._generateBulkSubscriptionErrorMessage(unverifiedLocations, locationsChange),
        AlertType.ERROR,
        null,
        'Ok',
        () => { return true }
      )

      if (!ret || locationsChange.length === 0 ) {
         return
      }
    }

    await this._apiSubscription.flowChangeLocationsPlan(this.subscription, locationsChange)
    // locationService.someLocationsChanged will fire if a plan change was made
  }

  async refreshLocation(locationId: string): Promise<void> {
    if (this.filtered) 
      this.applyFilter(this.searchText);
    try {
      await this._locationService.refreshAll(this.accountObjectId, locationId, this.user.gid)
    } finally {
      if (this.filtered) 
        this.applyFilter(this.searchText);
    }
  }

  refreshSelected(): void {
    this._snack.openInfo("The selected locations are being refreshed. Please wait while this is completed.", 1800);
    let count_refreshed = 0;
    this.listCheckLocations.forEach(async (l) => {
      await this._locationService.refreshAll(this.accountObjectId, l.locationId, this.user.gid);
      count_refreshed++;
    });

    if (count_refreshed === this.listCheckLocations.length) {
      this._snack.openSuccess("The selected locations was updated", 1800);
      this.resetListSelect()
    }

    if (this.filtered) 
      this.applyFilter(this.searchText);
  }

  deleteSelected() {
    let locationsDeletedCount = 0;
    if (this.listCheckLocations.length === 1) {
      this.openDeleteDialog(_.head(this.listCheckLocations));
    } else {
      this._modalService.openConfirmModal(`Are you sure you want to delete these places?`, 'This action cannot be reverted.', (res) => {
        if (!res) {
          return;
        }
        this._spinnerService.loading$.next(true);
        this.listCheckLocations.forEach(async (location) => {
          //Notification informative delete location
          const locationAddress = location.location.address ? this._locationService.formatAddress(location.location.address) : '';
          location = { gid:       this.auth.session.gid, 
                       accountId: this.accountObjectId, 
                       address:   locationAddress, 
                       ...location }
          const meta = this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, location);
          this._notificationService.saveNotification(this.auth.session.gid, this._wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [location.locationName, location.address, DELETE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta)

          await this._locationService.deleteLocation(this.user.gid, location.locationId, this.accountObjectId).then(() => {
            this.countLocations = this.countLocations - 1;
            this.locations = _.reject(this.locations, { locationId: location.locationId });
            locationsDeletedCount += 1;

            // Delete the account itself and navigate to /accounts
            if (this.listCheckLocations.length === locationsDeletedCount) {
              this.listCheckLocations = [];
              this.allChecked = false;
              if (this.countLocations == 0) {
                
                this._accountService.delete(this.user.gid, this.accountObjectId).then(
                  () => {
                    this._router.navigate(['/accounts']).then();
                    this._spinnerService.loading$.next(false);
                  }, err => {
                    console.error(`Error deleting master AOID: ${this.accountObjectId}`, err);
                    this._spinnerService.loading$.next(false);
                    if (!this.filtered || this.allChecked) { this._router.navigate(['/accounts']).then(); }
                  }
                );
              } else {
                // this.filtered = false;
                // this.getData(this.paginate);
                this._init()
              }
            }
          });
        })
      }, 2);
    }
  }

  async getAccountsFilter() : Promise<void> {
    try {
      const r = await this._accountService.getAccountPaginate(this.user.gid, {page: 1, size: 1000}, [])
      this.accountsOptions = [...r.items]
      this.filterAccountsByAccountId()
      this._hasAccountsFilter = true
    } catch (e) {
      console.error(e)
    } finally {
      this._spinnerService.loading$.next(false)
    }
  }

  selectAll(): void {
    if (!this.allLocationCheck) {
      this.allLocationCheck = true;
      this.totalLocations.forEach(location => {
        const result = this.listCheckLocations.find(l => l.locationId == location.locationId);
        if (!result) {
          this.listCheckLocations.push({
            locationId: location.locationId,
            pendingChange: location.pendingChange,
            subscriptionType: location.subscriptionType,
            locationName: location.locationName,
            checked: this.allChecked,
            location: location.location,
          });
        }
      });

      this.locations.forEach(location => {
        this.allChecked = true;
        location.isChecked = this.allChecked
      });
    } else {
      this.listCheckLocations = [];
      this.allLocationCheck = false;
      this.locations.forEach(location => {
        this.allChecked = false;
        location.isChecked = this.allChecked
      });
    }
  }

  private _formatData(locations: SavedLocation[], subscription : ISubscription): SavedLocation[] {
    const existSelected = this.listCheckLocations.length > 0;
    locations = locations.filter(Boolean);
    for (const location of locations) {
      if (location && subscription) {
        let status = '';
        if (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) {
          status = (subscription.status === GROUP_SUBSCRIPTION_TYPE.TRIAL ? 
                    LOCATION_SUBSCRIPTION_TYPE.ULTIMATE_TRIAL : 
                    LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)
        } else {
          status = location.subscriptionType
        }
        
        location['subscriptionStatus'] = status;

        if(existSelected)
          location.isChecked = !!this.listCheckLocations.find(l => l.locationId === location.locationId)
        
      }
    }
    this.loadingTable$.next(false);

    return locations;
  }

  handleReload($event: Pageable): void {
    this.loadingTable$.next(true);
    this._locationService.setPaginate($event);
    this._paginate = $event;
    if (this.filtered) {
      this.applyFilter(this.searchText);
    } else {
      this._getData($event);
    }
  }

  goLocation(accountId: string, location: SavedLocation): void {
    if (!this._validateVerifiedStatus(location))
      return
    this._router.navigate(['/account', accountId, 'location', location.locationId, 'insights'])
  }

  private _validateVerifiedStatus(location: SavedLocation, typeIsSubscription = false) : boolean {
    if (location?.location?.locationState?.isVerified == false){

      this._modalService.openErrorModal(
        "Verification required in Google Business Profile",
        `<div class='txt--left'>
        ${typeIsSubscription ? 
          'In order to update your profile subscription, you need to verify your location.' :
          'This location requires verification, which prevents access to your dashboard.' }
        <div>
          <br>Step 1: Log in to your Google Business Profile and verify your location.
          <br>Step 2: Once verified, return and click the refresh button.
        </div>
      </div>`
      )

      return false
    }
    return true
  }

  goNewTab(accountId: string, location: SavedLocation, event?: Event): void {
    if (event) 
      event.stopPropagation()
    
    if (!this._validateVerifiedStatus(location))
      return
    
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/account', accountId, 'location', location.locationId, 'insights'])
    );
    window.open(url, '_blank');
  }

  goNewTabSelected(): void {
    for (const location of this.listCheckLocations) 
      this.goNewTab(this.accountObjectId, location)
  }

  getNextDueDate(): string {
    let data = '-';
     if(this.subscription?.nextDueDate) {
      let nextDueDate = this._locationService.formatDates(this.subscription.nextDueDate);
      let nextDueDateArray = nextDueDate.split('-');
      data = `${nextDueDateArray[1]}/${nextDueDateArray[2]}/${nextDueDateArray[0]}`
    }
     return data;
  }

  cancelChangePlan(location : SavedLocation): void {
    this._modalService.openConfirmModal(
      'Cancel Listing Plan Change',
      `This location is scheduled to change subscription plan to ${location.pendingChange.nextPlan} `+
      `on ${this.getNextDueDate()}. `+
      `Do you want to cancel this scheduled change and keep the location as ${location.subscriptionType}?`,
      res => {
        if (!res) {
          return;
        }
        const locationCancel = { locationId: location.locationId, 
                                  accountId: this.accountObjectId }
        this._spinnerService.loading$.next(true)
        this._apiSubscription.applyChangePlan([locationCancel], location.subscriptionType).then(res => {
          if (!res) {
            return;
          }

          this._getData(this._paginate);
          this._locationService.loadAll(this.user, this.accountObjectId);
        }).catch(err => {
          console.error(err);
          this._spinnerService.loading$.next(false)
        })
      });
  }

  filterChanged(account): void {
    this.listCheckLocations = [];
    this.allLocationCheck = true;
    this.selectAll();
    this.loadingTable$.next(true);
    this.accountObjectId = account[0].accountId;
    this._router.navigate([`accounts/${this.accountObjectId}/locations`]);

  } 

  filterAccountsByAccountId(): void {
    const matchingAccount = this.accountsOptions?.find((account) => account?.accountId === this.accountObjectId);
    this.selectedAccount = matchingAccount || null
    this.labelAccountsFiltered = matchingAccount?.account?.accountName || null
  }

  private _updateTable(): void {
    // data for table
    this.displayedColumns = ['id', 'company']
    
    if (this.subscription?.pricingVersion < 3) {
      this.displayedColumns.push('subscription')
    }
    if (this.auth.isAdmin) {
      this.displayedColumns.push('actions')
    }


    // if (this.auth.isAdmin) {
    //   this.displayedColumns = ['id', 'company', 'subscription', 'actions'];
    // } else {
    //   this.displayedColumns = ['id', 'company', 'subscription'];
    // }

    this.dataSource = new MatTableDataSource<SavedLocation[]>(this.locations);
    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this._nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  
  private _init() {
    this.filtered = false;
    this.textSearch = '';
    const errorAccount = this._route.snapshot.queryParamMap.get("errorAccount")
    this.errorAccount = errorAccount ? errorAccount.toLowerCase() == "true" : false;

    if (this._route.snapshot.queryParamMap.get("errorAccount") !== null) {
      const url = this._router.url.split('?')[0]
      this._location.go(url)
    }
    
    this.accountObjectId = this._route.snapshot.paramMap.get('accountObjectId');

    this.locationsUser = ((this.user.accounts || []).length > 0 ? 
                           this.user.accounts.find(it => it.accountId === this.accountObjectId) ? 
                           this.user.accounts.find(it => it.accountId === this.accountObjectId).locations.map(it => it.locationId) : [] : [])

    // TODO: 5s forced delay, why? (single shot)
    if(!this._refreshTimerStarted) {
      this._refreshTimerStarted = true
      this._untilDestroy(timer(5000)).subscribe(() => {
        try {
          this._getData(this._paginate)
          this._locationService.loadAll(this.user, this.accountObjectId)
          this._updateTable()
        } finally {
          this._refreshTimerStarted = false
        }
      })
    }

    if (!this._hasAccountsFilter)
      this.getAccountsFilter()
  }
}
