<div class="sidebar d-print-none" [ngStyle]="{'background-color': (domainService.domain$ | async)?.branding?.secondaryColor || ''}">
	<div class="sidebar__group p--0 sidebar__location-header bg--darkerblue">
		<div class="sidebar__title">
			<ng-container *ngIf="!(location | async)?.locationName">
				<ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px', 'display': 'block', 'background-color': '#1a2333', 'opacity': '.15' }"></ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '35px', 'margin-top': '5px', 'background-color': '#1a2333', 'opacity': '.15' }"></ngx-skeleton-loader>
			</ng-container>
			<ng-container *ngIf="(location | async)?.locationName">
				<h2 gmbGLI [gid]="auth.session.gid" [accountId]="accountId" [locationId]="locationId" class="txt--white m--0">
					<strong>{{(location | async)?.locationName}} </strong>

					<ng-container [ngSwitch]="(location | async)?.lastCheck?.status">
						<em *ngSwitchCase="c_auth_unauthorized" matTooltip="The authentication for this location failed. Go to accounts and click Authenticate to reconnect" matTooltipPosition="right" matTooltipClass="tooltip tooltip--white" class="fas fa-exclamation-triangle text-warning"></em>
						<em *ngSwitchCase="c_auth_not_found" matTooltip="This location was not found in GBP. Did you move it or delete it from GBP?" matTooltipPosition="right" matTooltipClass="tooltip tooltip--white" class="fas fa-exclamation-triangle text-warning"></em>
						<span *ngSwitchDefault></span>
					</ng-container>
				</h2>
				<gmb-address [address]="currentLocation?.location?.address" [serviceAreas]="currentLocation?.location?.serviceArea?.places?.placeInfos" [hasInfoModal]="true"></gmb-address>
			</ng-container>
			<div *ngIf="isRevealPath">{{auth.session.gid}}/{{(location | async)?.locationName}}</div>
		</div>
	</div>
	<div>
			<div class="sidebar__group">
				<a [routerLink]="['insights']" class="sidebar__item" routerLinkActive="active">
					<div class="sidebar__menu-icon"><em class="fas fa-chart-bar"></em></div> Performance Insights
				</a>
				<a [routerLink]="['keywords']" class="sidebar__item" routerLinkActive="active" [appUserFeature]="'searchTermsLocationView'">
					<div class="sidebar__menu-icon"><em class="fa fa-arrow-down-arrow-up"></em></div> 
					<span class="title-dark-launch">Keywords</span>
				</a>
				<a [routerLink]="['reviews']" class="sidebar__item" routerLinkActive="active">
					<div class="sidebar__menu-icon"><em class="fas fa-pen-square"></em></div> Reviews
				</a>
				<a [routerLink]="['location-info']" class="sidebar__item" routerLinkActive="active">
					<div class="sidebar__menu-icon"><em class="fas fa-store-alt"></em></div> Location Info
				</a>
				<ng-container *ngIf="(location | async) as locationData">
					<ng-container *ngIf="locationData.location as location">
						<a [routerLink]="['posts']" class="sidebar__item d-none" routerLinkActive="active" [appUserFeature]="'legacyPost'">
							<div class="sidebar__menu-icon"><em class="fab fa-stack-exchange"></em></div>
							Posts
						</a>
						<a [routerLink]="['post-management']" class="sidebar__item d-none" routerLinkActive="active" [appUserFeature]="'postManagement'">
							<div class="sidebar__menu-icon"><em class="fab fa-stack-exchange"></em></div>
							<span class="title-dark-launch">Posts</span>
						</a>
						<a [routerLink]="['services']" class="sidebar__item" routerLinkActive="active" *ngIf="location.locationState?.canModifyServiceList">
							<div class="sidebar__menu-icon"><em class="fas fa-list-ul"></em></div> Services
						</a>
						<a [routerLink]="['menu']" class="sidebar__item" routerLinkActive="active" *ngIf="location.locationState?.canHaveFoodMenus">
							<div class="sidebar__menu-icon"><em class="fas fa-utensils"></em></div> Menu
						</a>
					</ng-container>
				</ng-container>
				<a [routerLink]="['photos']" class="sidebar__item" routerLinkActive="active" >
					<div class="sidebar__menu-icon"><em class="far fa-images"></em></div> Photos
				</a>
				<a [routerLink]="['qanda']" class="sidebar__item" routerLinkActive="active" [appUserFeature]="'qandaLocationView'">
					<div class="sidebar__menu-icon"><em class="fa fa-map-marker-question"></em></div>
					<span class="title-dark-launch">Questions & Answers</span>
				</a>
				<a [routerLink]="['grade']" class="sidebar__item" routerLinkActive="active">
					<div class="sidebar__menu-icon"><em class="fas fa-check"></em></div> Grader
				</a>
			</div>
			<div class="sidebar__group">
				<a [routerLink]="['widget']" class="sidebar__item" routerLinkActive="active">
					<div class="sidebar__menu-icon"><em class="fas fa-code"></em></div> Widget Generator
				</a>
				<a (click)="handleReviewLinkGenerator()" [routerLink]="" class="sidebar__item">
					<div class="sidebar__menu-icon"><em class="fas fa-link"></em></div> Review Link Generator
				</a>
			</div>
			<div class="sidebar__group">
				<a [href]="(location | async)?.location.metadata?.mapsUrl" target="_blank" class="sidebar__item"
				routerLinkActive="active" rel="noopener">
					<div class="sidebar__menu-icon"><em class="fas fa-map-marker-alt"></em></div> View on Maps
				</a>
				<a [href]="(location | async)?.search_url" target="_blank" class="sidebar__item"
				routerLinkActive="active" rel="noopener">
					<div class="sidebar__menu-icon"><em class="fas fa-search"></em></div> View on Search
				</a>
			</div>

			<div class="sidebar__group sidebar-edit-location" *ngIf="currentLocation && subscription?.pricingVersion < 3">
				<a class="sidebar__item change-plan" (click)="changePlan()">
					<div class="sidebar__menu-icon"><em class="far fa-pen-alt"></em></div>
					<div>
						Change Subscription
					</div>
					<span *ngIf="currentLocation?.subscriptionStatus">({{currentLocation?.subscriptionStatus}})</span>
				</a>
			</div>

			<div class="sidebar__group sidebar-edit-location" *ngIf="currentLocation">
				<a class="sidebar__item" (click)="openDeleteDialog()">
						<div class="sidebar__menu-icon"><em class="far fa-trash-alt"></em></div> Delete Location
					</a>
			</div>
	</div>
</div>
