<!--average star rating-->
<div class="box box--rounded box--shadow bg--white">
  <div class="box__header">
    <h2 class="txt--capitalize d-flex align-items-center">
      <strong>average star rating:
        <ngx-skeleton-loader count="1"
                             [theme]="{ width: '25px',height: '25px','margin-bottom': '0', 'transform':'translateY(5px)'}"
                             *ngIf="isProgressRating"></ngx-skeleton-loader>
        <span class="txt--green" *ngIf="!isProgressRating">{{avgRating | number: '1.0-1'}}</span>
        <span *ngIf="tooltip"
          [matTooltip]="tooltip"
          matTooltipPosition="above"
          matTooltipClass="mat-tooltip--override"
          class="mat-tooltip tooltip tooltip--info">
          <em class="fas fa-info"></em>
        </span>
      </strong>
    </h2>
  </div>
  <div class="box__content box--padding-xxs">
    <app-skeleton-reviews type="AVERAGE-STAR-RATING" *ngIf="isProgressRating"></app-skeleton-reviews>
    <app-chart-line
      [max]="5"
      [yDecimals]="1"
      [showLegend]="false"
      [labels]="labelsLineRating"
      [dataset]="datasetLineRating"
      [dataPicker]="dataPicker"
      *ngIf="!isProgressRating"
    ></app-chart-line>
  </div>
</div>
