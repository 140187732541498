<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="updatingData">
  <mat-spinner></mat-spinner>
</div>

<!-- Report filter -->
<app-reports-filter 
  *ngIf="!loading" 
  [accountId]="accountId" 
  [locations]="isReport ? locations : location"
  [isShared]="isShared" 
  (export)="handleExport($event)" 
  [selectedDate]="selectDate" 
  [hasPeriodSelect]="true" 
  [hasComparison]="isComparisonVisible" 
  [compareToValue]="dataRange"
  [isComparisonVisible]="isComparisonVisible" 
  [type]="'keyword'" 
  (dataPicker)="handleDataPicker($event)" 
  [minDate]="minDate" 
  [maxDate]="maxDate"
  (selectRange)="handleSelectRange($event)" 
  (changedViewComparison)="changedViewComparison($event)">
</app-reports-filter>

<mat-progress-bar mode="indeterminate" [class.mt--30]="!isReport" *ngIf="isProgressCSV"></mat-progress-bar><!-- //NOSONAR -->

<div [ngClass]="isReport || isShared ? 'ml--35 mr--35 pt--5' : ''">

  <app-skeleton-search-keywords *ngIf="loading"></app-skeleton-search-keywords>

  <section
    *ngIf="!loading && (searchKeywordsData?.keywordsCount > 0 || totalKeywordsData?.total > 0 || totalImpressionsData?.total > 0 || searchImpressionsData?.total > 0)">

    <div class="row box--height" [ngClass]="isReport || isShared ? 'mt--25' : 'mt--60'">
      <!-- Total Keywords -->
      <h2 class="col-12" *ngIf='report?.reportName'><strong>{{report?.reportName|uppercase}}</strong></h2>
      <div class="col-12 col-sm-6 col-lg-4 mb--30">
        <div class="box box--shadow box--rounded bg--white pb--20 pt--20">
          <section class="pl--20 pr--20">
            <h2 class="d--inl-block txt--md">
              <strong>Total Page One Keywords</strong>
              <span class="mat-tooltip tooltip tooltip--info" (click)="getTooltip('Total Page One Keywords')">
                <em class="fas fa-info"></em>
              </span>
            </h2>
            <div class="summary-graph d--inl-block" *ngIf="isComparisonVisible" [ngClass]="{
              'box--success': searchKeywordsData?.keywordsCountComparisonPercentage > 0,
              'box--fail': searchKeywordsData?.keywordsCountComparisonPercentage < 0,
              'box--info': searchKeywordsData?.keywordsCountComparisonPercentage == 0
            }">
              <mat-chip-list>
                <svg-icon class="m--0" src="/assets/images/icons/graph-rise.svg"></svg-icon>
                <mat-chip class="m--0 mr--5 ml--5 txt--xs line--height--14 fw--medium" disabled>
                  {{searchKeywordsData?.keywordsCountComparisonPercentage | buildPercentageFormat: false}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </section>
          <div class="d-flex justify-content-between flex-column pl--20 pr--20">
            <div class="d-flex align-items-center justify-content-start mb--25">
              <p class="txt--price txt--lightblue fw--medium m--0">
                {{searchKeywordsData?.keywordsCount | number}}
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="pl--20 pr--20 pt--40">
            <div class="txt--align--center" *ngIf="updatingData">
              <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '150px', height: '150px'}">
              </ngx-skeleton-loader>
            </div>
            <app-chart-donut *ngIf="!updatingData" [dataset]="totalKeywordsData?.items"></app-chart-donut>
            <div class="pt--40">
              <app-chart-progress *ngFor="let data of totalKeywordsData?.items" [total]="data?.total"
                [label]="data?.label" [bgColor]="data?.backgroundColor" [tooltip]="data?.tooltip">
              </app-chart-progress>
            </div>
          </div>
        </div>
      </div>

      <!-- Est. Total impressions -->
      <div class="col-12 col-sm-6 col-lg-8 mb--30">
        <div class="box box--shadow box--rounded bg--white pb--20 pt--20">
          <section class="pl--20 pr--20">
            <h2 class="d--inl-block txt--md">
              <strong>Impressions</strong>
              <span class="mat-tooltip tooltip tooltip--info" (click)="getTooltip('Impressions')">
                <em class="fas fa-info"></em>
              </span>
            </h2>
            <div class="summary-graph d--inl-block" *ngIf="isComparisonVisible" [ngClass]="{
              'box--success': searchKeywordsData?.keywordsImpressionsComparisonPercentage > 0,
              'box--fail': searchKeywordsData?.keywordsImpressionsComparisonPercentage < 0,
              'box--info': searchKeywordsData?.keywordsImpressionsComparisonPercentage == 0
            }">
              <mat-chip-list>
                <svg-icon class="m--0" src="/assets/images/icons/graph-rise.svg"></svg-icon>
                <mat-chip class="m--0 mr--5 ml--5 txt--xs line--height--14 fw--medium" disabled>
                  {{searchKeywordsData?.keywordsImpressionsComparisonPercentage | buildPercentageFormat: false}} 
                </mat-chip>
              </mat-chip-list>
            </div>
          </section>
          <div class="d-flex justify-content-between flex-column pl--20 pr--20">
            <div class="d-flex align-items-center justify-content-start mb--25">
              <p class="txt--price txt--lightblue fw--medium m--0 row">
                <span>
                  {{totalImpressionsData?.total_actual_period_low | number}}
                  <span class="display--block txt--black txt--sm">Minimum</span>
                </span> 
                <span class="ml--10 mr--10">-</span>
                <span>
                  {{totalImpressionsData?.total_actual_period_high | number}}
                  <span class="display--block txt--black txt--sm">Maximum</span>
                </span>
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="pl--20 pr--20 pt--40">
            <app-chart-line
              [commaNumbers]="true"
              [dataset]="totalImpressionsData?.items"
              [labels]="totalImpressionsData?.labels"
              [noDecimals]="true"
              [disabledLengend]="true"
              chartType="horizontalBar"
            >
            </app-chart-line>
          </div>
        </div>
      </div>
    </div>

    <div class="row box--height">
      <!-- Topics -->
      <div class="col-12 col-sm-6 mb--30" [ngClass]="isReport ? 'col-lg-4' : 'col-lg-5'">
        <div class="box box--shadow box--rounded bg--white pt--20">
          <section class="pl--20 pr--20">
            <h2 class="d--inl-block txt--md">
              <strong>Direct vs. Discovery</strong>
              <span class="mat-tooltip tooltip tooltip--info" (click)="getTooltip('Direct vs. Discovery')">
                <em class="fas fa-info"></em>
              </span>
            </h2>
          </section>
          <div class="box__content box--padding-xxs-tb box--padding-lr pt--0 txt--center mt--75 mb--50">
            <ng-container *ngFor="let graph of topicsGraph?.items">
                <div class="topic-progress progress-label progress-label--normal {{ graph?.progressBgColorClass }}">
                  <mat-progress-bar class="topic-progress-bar" mode="determinate" [value]="graph?.total?.percent"></mat-progress-bar>
                  <div class="txt--center w--130-px topic-progress-value">
                    <span class="txt--sm fw--light {{ graph?.progressColorClass}}">{{graph?.total?.numeric | number:  '1.0-0'}} - ({{graph?.total?.percent | number:  '1.0-0'}}%)</span>
                    <p class="txt--black fw--medium mb--5">{{graph?.label}}</p>
                  </div>
                </div>
            </ng-container>  
          </div>
          
          <div class="txt--align--center mt--75 mb--40">
            <p class="txt--dark--gray txt--14 fw--extrabold mb--5">
              Total: 
              <span class="fw--medium txt--price-sup txt--lightblue m--0 txt--align--end d--inl-grid">
                {{topicsGraph?.total | number}}
                <span class="display--block txt--black txt--sm mt--5">Impressions</span>
              </span>
            </p>
          </div>

          <mat-divider></mat-divider>
    
          <h2 class="p--20 pb--0">
            <strong>Keyword topics</strong>
            <span class="mat-tooltip tooltip tooltip--info" (click)="getTooltip('Keyword topics')">
              <em class="fas fa-info"></em>
            </span>
          </h2>
    
          <div class="row box__content box--padding-xxs-tb box--padding-lr pt--0">
            <table class="table table--bordered" mat-table [dataSource]="dataSourceTopics" aria-describedby="topic-keywords">
              <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef class="txt--black txt--center p--0 w--10"><span>No.</span></th>
                <td mat-cell *matCellDef="let row" class="p--0 txt--center">
                  <div class="cell__content txt--center p--0">
                    <p class="m--0 txt--md">{{ row?.id }}</p>
                  </div>
                </td>
              </ng-container>
      
              <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef class="txt--black pl--10"><span>search terms</span></th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content flex-column align-items-start justify-content-center">
                    <p class="m--0 txt--md txt--black txt--underline w--100">
                      {{ row?.topics | titlecase }}
                      <button mat-icon-button tabIndex="-1" class="btn btn-icon btn-icon--txt pull--right" (click)="handleExportCsvTopic(row)" matTooltip="Download CSV">
                        <svg-icon src="/assets/images/icons/csv.svg" class="filter__item__icon"></svg-icon>
                      </button>
                    </p>
                    <p class="m--0 txt--md">{{ row?.low_estimate | number }} - {{ row?.high_estimate | number }} Impressions ({{row?.impressions_percentage}}%)</p>
                    <p class="m--0 txt--md">{{ row?.total_keywords | number }} Keywords ({{row?.keywords_percentage}}%)</p>
                  </div>
                </td>
              </ng-container>
      
              <tr mat-header-row *matHeaderRowDef="fieldsColumnsTopics"></tr>
              <tr mat-row *matRowDef="let row; columns: fieldsColumnsTopics"></tr>
            </table>
          </div>
      
          <div class="p--15 pl--30" *ngIf="dataSourceTopics?.data?.length === 0"> No data available.</div>
          <app-paginator class="topics-paginator" [pagination]="topicPagination" (reload)="topicsHandleReload($event)" [options]="[10, 25,50,100,200,500]"></app-paginator>
        </div>
      </div>

      <!-- Keywords -->
      <div class="col-12 col-sm-6 mb--30" [ngClass]="isReport ? 'col-lg-8' : 'col-lg-7'">
        <div class="box box--shadow box--rounded bg--white pb--20 pt--20">
          <ng-container>
            <div class="row box__content box--padding-xxs-tb box--padding-lr">
              <div class="col-12 col-lg-6 col-xl-12">
                <section>
                  <!-- Keywords Atlas Search -->
                  <div class="row d-none justify-content-between" [appUserFeature]="'searchKeywordsAtlas'">
                    <div>
                      <span
                        class="input-field input-field--search input-field--header border--solid border--gray keyword-search">
                        <input type="text" [formControl]='keywordSearchInput' matInput id="keywordsSearch"
                          placeholder="Search By Keyword" />
                      </span>
                    </div>
                    <div *ngIf="!!keywordSearchInput.value">
                      <button mat-flat-button color="accent" (click)="handleExportCsvKeywordsSearch()">Export CSV</button>
                    </div>
                  </div>
    
                </section>
              </div>
    
              <div class="w--100">
                <table class="table table--bordered" mat-table [dataSource]="keywordsDatasource" matSort [matSortActive]="sort.sortBy" [matSortDirection]="sort.sortDirection" (matSortChange)="sortChanged($event)">
                  <ng-container matColumnDef="keywords">
                    <th mat-header-cell *matHeaderCellDef class="txt--black w--50">
                      <span>Keywords</span>
                      <span class="mat-tooltip tooltip tooltip--info" (click)="getTooltip('Keywords')">
                        <em class="fas fa-info"></em>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div class="cell__content flex-column align-items-start justify-content-center">
                        <p class="m--0 txt--md">{{ row?.keyword | titlecase }}</p>
                      </div>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="impressions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="txt--center txt--black"><span>Number of
                        Impressions</span></th>
                    <td mat-cell *matCellDef="let row">
                      <div class="flex-column align-items-start justify-content-center">
                        <p class="m--0 txt--md txt--center">{{ getImpressionsValue(row?.numberOfImpressions) }}</p>
                      </div>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="differencePercentage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="txt--center txt--black"><span>% Change vs
                        {{dataRange.displayName}} </span></th>
                    <td mat-cell *matCellDef="let row" class="txt--center">
                      <div class="flex-column align-items-start justify-content-center"
                        *ngIf="row?.prevComparison?.percentage === 0">
                        <p class="cursor--pointer" matTooltip="There is no comparison available"
                          matTooltipPosition="above" matTooltipClass="tooltip tooltip--black"> - </p>
                      </div>
                      <div class="flex-column align-items-start justify-content-center"
                        *ngIf="row?.prevComparison?.percentage != 0">
                        <div [ngClass]="{
                          'box--flex box--success': row?.prevComparison?.percentage > 0,
                          'box--flex box--fail': row?.prevComparison?.percentage < 0,
                          'box--flex box--info': row?.prevComparison?.percentage == 0}">
                            <mat-chip-list>
                              <svg-icon class="m--0 statistics-arrow" src="/assets/images/icons/graph-rise.svg"></svg-icon>
                              <mat-chip class="m--0 mr--5 ml--5 txt--xs line--height--14 fw--medium" disabled>{{
                                getPercentage(row?.prevComparison?.percentage)  | number: '1.0-1' | buildPercentageFormat: false }}
                                <span *ngIf="row?.prevComparison?.percentage >= 1000">+</span>
                            </mat-chip>
                            </mat-chip-list>
                        </div>
                      </div>
                    </td>
                  </ng-container>
    
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsKeywords"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsKeywords"></tr>
                </table>
    
                <!-- No data when filter -->
                <div class="p--15" *ngIf="keywordsDatasource?.data?.length === 0 && keywordSearchInput?.value"> No data matching the filter. </div>
                
                <app-paginator [pagination]="pagination" (reload)="handleReload($event)" [options]="[10, 25,50,100,200,500]"></app-paginator>
              </div>
            </div>
          </ng-container>
    
          <div *ngIf="searchKeywordsData?.keywordsCount == 0" class="box box--rounded box--shadow bg--white">
            <div class="box__header d-flex align-items-center justify-content-center">
              <h2 class="txt--capitalize"><strong>No keywords data for the {{dataRange?.displayName}}</strong></h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Multilocations table -->
    <app-multilocations-info-table *ngIf="isReport && report?.reportType != 'keyword-location'" [isLoading]="tableLoading"
      [reportType]="'keywords'" [reportName]="report?.reportName" [dataPicker]="dataPicker" [reportId]="reportId" [title]="'Multi Locations Table'" [isShared]="isShared"
      [hasExportPDF]="true" [hasExportCSV]="true" [displayedColumns]="displayedColumnsMultLoc" [sort]="sortMultiloc"
      [fieldsColumns]="fieldsColumnsMultiLoc" [dataSource]="dataSourceMultiLoc" [pagination]="paginationMultiloc"
      (handleMultilocSort)="handleMultilocSort($event)" (handleMultilocPaginate)="handleMultilocPaginate($event)"
      [appUserFeature]="'searchTermsMultilocationTable'">
    </app-multilocations-info-table>
  </section>

  <section
    *ngIf="!loading && searchKeywordsData?.keywordsCount == 0 && totalKeywordsData?.total == 0 && totalImpressionsData?.total == 0 && searchImpressionsData?.total == 0"
    class="box--height pt--30 mb--30">
    <div class="box box--rounded box--shadow bg--white" style="margin-top: 50px;">
      <div class="box__header d-flex align-items-center justify-content-center">
        <h2 class="txt--capitalize"><strong>No keywords data for the {{dataRange?.displayName}}</strong></h2>
      </div>
    </div>
  </section>

</div>