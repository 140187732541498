// dep
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation, 
         OnChanges, SimpleChanges, ChangeDetectorRef, ViewRef } from '@angular/core'
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take, debounceTime, map, switchMap, startWith } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject, Subscription as RxjsSubscription } from 'rxjs';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars'
import moment from 'moment'
import * as _ from 'lodash'

// app
import { SelectedDatePicker } from '../date-picker/date-picker.component';
import { LocationService } from '../../services/location.service';
import { ReportService } from '../../services/report.service';
import { ModalService } from '../../services/modal.service';
import { ModalShareComponent } from '../modal-share/modal-share.component';
import { REPORT_TYPE } from '../../constants/firestore/report';
import { periodDaysQanda } from '../../constants/periodDaysQanda';
import { PdfService } from '../../services/pdf.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationRef } from '../../constants/firestore/location-object';
import { AuthService } from '../../services/auth.service';
import { ISubscription } from '../../constants/subscription';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { IS_IMPERSONATING } from '../../constants/session';
import { MULTIKEY_METRIC, MULTIKEY_METRIC_INSIGHTS } from '../../constants/google/location-stats';
import { alwaysShowCalendar } from '../../helpers/syncfusion.helpers';
import { VAR_MONTH_PICKER_YEAR_AGO } from '../../constants/datePicker';
import { DatesService } from '../../services/dates.service'
import { QuestionsAnswerService } from '../../services/questions-answer.service'
import { PromisedObservable, makeOpenPromise } from '../../helpers/utils.helpers';
import SavedLocation from '../../constants/firestore/saved-location';

@Component({
  selector: 'app-reports-filter',
  templateUrl: './reports-filter.component.html',
  styleUrls:  ['./reports-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsFilterComponent implements OnDestroy, OnInit, OnChanges {
  public isImpersonating = false;
  
  @Input() reportName = '';
  @Input() locations: LocationRef[];
  @Input('type') reportType: REPORT_TYPE = 'keyword'
  @Input() isShared: boolean;
  @Input() selectedDateFromDashboard: SelectedDatePicker; // monitored by ngOnChanges
  
  @Input() set selectedDate(selectedDate: SelectedDatePicker) {
    if (this._firstLoad)
      this.selectedDateA$.next(selectedDate);
  };
  @Input() selectedDateB: SelectedDatePicker;
  @Input() clearChecks: boolean;
  @Input() lockDates = false;
  @Input() accountId: string; // Only for go back to locations
  @Input() hasPeriodSelect = false;
  @Input() hasToggle = false;
  @Input() isComparisonVisible = false;
  @Input() hasFoodOrdering = true;
  @Input() maxDate = null;
  @Input() minDate = null;
  @Input() subscribeToDatepicker = false;
  @Input() showTooltipsOnDisabledDates = false;
  @Input() hasDateLimitsInfo = false;
  @Input() aggregation = null;
  @Input() comparisonMetrics: any[] = [];
  @Input() stats: any [] = [];
  @Input() multiChartSelected: any [] = [];
  @Input() hasComparison = false;
  @Input() questionsTypeReport = null;
  @Input() compareToValue = null;
  @Input() periodReportValue = null
  @Input() isReport = false;
  @Input() accounts = [];
  @Input() hasClearValues = false;
  @Input() hasSaveValues = false;

  @Output() questionsType = new EventEmitter();
  @Output() questionsPeriod = new EventEmitter();
  @Output() locationsChanged = new EventEmitter();
  @Output() keywordSearch = new EventEmitter<string>();
  @Output('dataPicker') change = new EventEmitter();
  @Output() selectRange = new EventEmitter();
  @Output() shared = new EventEmitter(); // TODO: Not used?
  @Output() export = new EventEmitter<'csv' | 'csv-review'>();
  @Output() isExporting = new EventEmitter<boolean>();
  @Output() statusCheck = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter<void>()
  @Output() expand = new EventEmitter<boolean>();
  @Output() changedViewComparison = new EventEmitter<boolean>()
  @Output() clearValuesEmit = new EventEmitter();
  @Output() saveValuesEmit = new EventEmitter();

  @ViewChild('datePicker', { static: true, read: true }) 
  datePicker: DaterangepickerComponent;
  @ViewChild('metricsTrigger', { static: false }) 
  trigger: MatMenuTrigger;
  @ViewChild('dateRangePickerMonthly',{ static: false }) 
  
  public dateRange: DateRangePickerComponent
  public montPicker = VAR_MONTH_PICKER_YEAR_AGO;
  public viewModeChecked = 'new';
  public metrics: any[];
  public allChecked: boolean;
  public checkItem: boolean;
  public checkedList = [];
  public progress: boolean;
  public selectedDateA: SelectedDatePicker;
  public selectedDateA$ = new BehaviorSubject<SelectedDatePicker | null>(null);
  public dataPicker : any
  public locationP = makeOpenPromise<SavedLocation>()
  public locationWasSent$ = new Subject<boolean>()
  public isSharedUrl: boolean;
  public isLocationReport: boolean;
  /**  Must limit the start date of the date picker? */
  public minRange = false; 
  public topBanner = false;
  public subscription$: Observable<ISubscription>;
  public subscription: ISubscription;
  public subscriptionOut$ : PromisedObservable<ISubscription>
  public isLegacyInsights = false;
  public monthSelected = '';
  public GROUP_SUBSCRIPTION_TYPE = GROUP_SUBSCRIPTION_TYPE;
  public selectRangeOptions = [
    { value: 'prevPeriodComparison', displayName: 'Previous Period', disabled: false},
    { value: 'prevMonthComparison', displayName: 'Previous Month', disabled: false},
    { value: 'prevYearComparison', displayName: 'Previous Year', disabled: false}
  ];
  public selectRangeValue = this.selectRangeOptions[2];
  public ejsStartDate: Date;
  public ejsEndDate: Date;
  public metricsCustomChart: any[]
  public locationsFiltered = [];
  public readonly questionsTypeOptions = [
    { value: null, displayName: 'All'},
    { value: 'withAnswerFromOwner', displayName: 'With Answer'},
    { value: 'withoutAnswerFromOwner', displayName: 'Without Answer'},
  ]
  public questionsTypeValue = this.questionsTypeOptions[0];
  public periodDaysOptions = periodDaysQanda;
  public periodDaysValue = this.periodDaysOptions[3];
  public filterLocationControl = new FormControl('');
  public locationsOptions = [];
  public labelsLocationsFiltered = null;
  public filteredOptions: Observable<any>;
  public keywordSearchInput = new FormControl();
  public keywordSubject = new Subject<string>();
  public capturing = false;
  
  private _subscriptionsToCleanup: RxjsSubscription[] = []
  private _firstLoad = true;
  
  constructor(
    public reportS: ReportService,
    private _cdRef:ChangeDetectorRef,
    private _locationService: LocationService,
    private _modalS: ModalService,
    private _route: ActivatedRoute,
    private _snackS: SnackbarService,
    private _pdfS: PdfService,
    private _auth: AuthService,
    private _router: Router,
    private _location: Location,
    private _dateS: DatesService,
    private _questionAnswerS: QuestionsAnswerService
  ) {
    this.isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
    this.subscriptionOut$ = this._auth.subscription$

    this._subscriptionsToCleanup.push(
      this.subscriptionOut$.subscribe(sub => {
      if (sub) 
        this._checkPlanLimits()
    }))

    this.isLegacyInsights = !(this._route?.snapshot?.routeConfig?.path == 'insights' || 
                              this._route?.snapshot?.routeConfig?.path == 'reviews' || 
                              this._route?.snapshot?.params?.reportType == 'review' || 
                              this._route?.snapshot?.params?.reportType?.includes('performance'))
                              
    this.maxDate = this.isLegacyInsights ? moment('12-31-2022', 'MM-DD-YYYY') : null;
    
    // checkbox
    this.allChecked = false;
    this.checkItem = true;

    this.metrics = this.isLegacyInsights ? MULTIKEY_METRIC : MULTIKEY_METRIC_INSIGHTS
  }

  ngOnInit(): void {
    if (this.reportType != 'keyword') {
      this.maxDate = this.maxDate ? moment(this.maxDate, 'MM-DD-YYYY') : (this.isLegacyInsights ? moment('12-31-2022', 'MM-DD-YYYY') : null);
      this.minDate = this.minDate ? moment(this.minDate, 'MM-DD-YYYY') : null;
    } else {
      this.maxDate = this.maxDate?.toDate() || this.montPicker.lastEnd
      this.minDate = this.minDate?.toDate() || null

      this.selectedDateA = {
        start: this.selectedDateA?.start.isBefore(moment(this.minDate)) ? moment(this.minDate) : this.selectedDateA?.start,
        end:   this.selectedDateA?.end.isAfter(moment(this.maxDate))    ? moment(this.maxDate) : this.selectedDateA?.end
      }

      if(this.compareToValue) 
        this.selectRangeValue = this.compareToValue;
      
      if (this.reportType?.includes('rollup'))
        this.getMetricsCustomChart();
    }

    this.locationsOptions = [...this.accounts];
    this.locationsOptions.forEach(acc => acc.locations.forEach(loc => loc.checked = false));
    
    this.isLocationReport = !!this._route.snapshot?.data?.isReport

    if(this.reportType.includes('qanda') && this.isReport && this.questionsTypeReport)
      this.questionsTypeValue = this.questionsTypeOptions.find(el => el.value == this.questionsTypeReport);

    const loc = this.locations[0] 
    if(loc && loc.accountId && loc.locationId) 
      this._fetchLocation(this._auth.session?.gid || this._route.snapshot.params.gid, 
                          loc.accountId, 
                          loc.locationId)

    this.isSharedUrl = _.endsWith(this._router.url, 'shared');

    this.selectedDateA$
    .pipe(take(2))
    .subscribe(selectedDateA => {
      const dateToStr = (date : moment.Moment | string) =>
         (moment.isMoment(date) ? this._dateS.getStringDateYMD(date) : this.createDateFromString(date).toISOString())

      this.selectedDateA = selectedDateA;

      if (selectedDateA) {
        this.dataPicker = {
          aggregation: 'Day', 
          range: {
            start: dateToStr(selectedDateA.start),
            end:   dateToStr(selectedDateA.end),
          }
        }
        
        if (this.selectedDateB)
          this.dataPicker.rangeB = {
            start: dateToStr(this.selectedDateB.start),
            end:   dateToStr(this.selectedDateB.end)
          };
      
        
        this.ejsStartDate = moment.isMoment(selectedDateA?.start) ? selectedDateA?.start?.toDate() : this.createDateFromString(selectedDateA?.start);
        this.ejsEndDate   = moment.isMoment(selectedDateA?.end)   ? selectedDateA?.end?.toDate()   : this.createDateFromString(selectedDateA?.end);

        this.change.emit(this.dataPicker);
        this._firstLoad = false;
        this.getEnabledOptionsFromRange();
        this.detectChanges();
        return;
      }
    }, error  => {
      console.error(error);
      const now = moment().utc(true)
      this.dataPicker = {
        aggregation: 'Day', 
        range: {
          start: now.clone().subtract('3', 'months').toISOString(), // for future reference, this is the default to 3 months
          end:   now.clone().toISOString()          
        }, 
        multiChart: []
      };
      this._firstLoad = false;
      this.getEnabledOptionsFromRange();
      this.detectChanges();

    })

    if(this.isReport)     
      this.periodDaysValue = this.periodDaysOptions.find(el => el.value == this.periodReportValue);

    this._subscriptionsToCleanup.push(
      this.keywordSearchInput.valueChanges.subscribe(keyword => { this.keywordSubject.next(keyword || '') })
    )

    this.keywordSubject.pipe(
      debounceTime(650),
      switchMap( keyword => {
        this.keywordSearch.next(keyword || '')
        return keyword;
      }))

    // TODO: Redundant, remove or finish
    // this.accounts = this.buildFilterOptions();

    this.filteredOptions = this.filterLocationControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );


  }
  
  private async _checkPlanLimits(): Promise<void> {
    const loc = await this.locationP

    const loc_is_free = (loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE || 
                         loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)

    const sub_is_trial = ((await this.subscriptionOut$.getValue()).status === GROUP_SUBSCRIPTION_TYPE.TRIAL)

    this.topBanner = !!(this._route.snapshot.parent.params.locationId && (sub_is_trial || loc_is_free))

    // Restrict the DatePicker minimum range?
    this.minRange  = (!sub_is_trial && loc_is_free)
  }

  createDateFromString(date: string): Date {
    const [year, month, day] = date.split(date.includes('T') ? 'T' : ' ')[0].split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  detectChanges(): void{
    if (this._cdRef && !(this._cdRef as ViewRef).destroyed) 
      this._cdRef.detectChanges()
  }

  // TODO: Dummy method, remove
  // buildFilterOptions(options = this.accounts) {
  //   const data = options
  //   return data;
  // }

  getMetricsCustomChart(): void {
    let data = this.metrics.filter(el => el.name != 'Average Star Rating' && el.name != 'Number of New Reviews');
    const statsMetrics = [];
   
    if(this.stats?.length) {
      this.stats.forEach(s => Object.keys(s).filter(el => {
        if (el != 'title' && el != 'totals' && el != 'labels') {
          statsMetrics.push(el?.toUpperCase())
        }
      }))

      if (!this.hasFoodOrdering) {
        const index = data.findIndex(el => el.key == "FOOD_ORDERING");
        data.splice(index, 1);
      }
      
      data = data.filter(item => statsMetrics.includes(item.key));
    }

    this.metricsCustomChart = data;
    this.initMultiChart();
  }

  initMultiChart(): void {
    const metricsSelected = this.multiChartSelected?.map(m => m.key)
   
    this.metricsCustomChart?.forEach(m => {
      if(metricsSelected?.includes(m?.key)) {
        m.checked = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('hasFoodOrdering' in changes) {
      this.getMetricsCustomChart()
    }

    if (changes.stats) {
      this.detectChanges();
    }

    if (changes.selectedDateFromDashboard) {
      const date = changes.selectedDateFromDashboard.currentValue;
      if (date) {
        const start = this._dateS.getStringDateYMD(date.start);
        const end   = this._dateS.getStringDateYMD(date.end);
        this.dataPicker = {
          aggregation: 'Day', 
          range: {
            start: moment.isMoment(date.start) ? start : this.createDateFromString(date.start).toISOString(),
            end:   moment.isMoment(date.end)   ? end   : this.createDateFromString(date.end).toISOString()            
          }
        };
        this.selectedDateA$.next(date);
        this.change.emit(this.dataPicker)
      }
    }
  }

  monthlyRangeSelected(event): void {
    if (this.monthSelected === event.text)
      return

    this.monthSelected = event.text;
    if(this.isLocationReport){
      if(this.reportType === 'keyword') {
        this.dataPicker.isReport = true;
        this.dataPicker.range.start = moment(event?.startDate);
        this.dataPicker.range.end   = moment(event?.endDate);
        this.getEnabledOptionsFromRange();
        this.change.emit(this.dataPicker);
        this.detectChanges();
      }
    } 
    else { // not report
      this.dataPicker = {
        aggregation: event.daySpan <= 365 ? 'month' : 'year',
        range: {
          start: moment(event.startDate).utc(true),
          end:   moment(event.endDate).utc(true)
        }
      };

      if(this.reportType == 'keyword'){
        this.dataPicker.range.start = moment(event?.startDate)
        this.dataPicker.range.end   = moment(event?.endDate)
      }
      this.getEnabledOptionsFromRange();
      this.selectRange.emit(this.selectRangeValue);
      this.change.emit(this.dataPicker);
      this.detectChanges();
    }
  }

  daterangepickerOpen(): void{
    alwaysShowCalendar();
  }

  getEnabledOptionsFromRange(): void {
    if(!this.hasComparison) 
      return

    const start = moment(this.dataPicker.range.start)
    const end   = moment(this.dataPicker.range.end)
    const diff = end.diff(start, 'month');

    if (diff > 0) {
      this.selectRangeOptions[1].disabled = true;
      this.selectRangeValue = this.selectRangeValue.value !== "prevMonthComparison" ? this.selectRangeValue : this.selectRangeOptions[0];
    } else {
      this.selectRangeOptions[1].disabled = false;
    }

    if (diff > 11) {
      this.selectRangeOptions[2].disabled = true;
      this.selectRangeValue = this.selectRangeValue.value !== "prevYearComparison" ? this.selectRangeValue : this.selectRangeOptions[0];
    } else {
      this.selectRangeOptions[2].disabled = false;
    }

    this.detectChanges();
  }

  GoBack(reportType: string): void {
    if (reportType==='comparison') {
      this._router.navigate(['/comparison-report'])
    } else if (reportType==='performance-comparison') {
      this._router.navigate(['/performance-comparison-report'])
    } else if (reportType==='rollup-report') {
      this._router.navigate(['/rollup-report'])
    } else if (reportType==='performance-rollup') {
        this._router.navigate(['/performance-rollup-report'])
    } else if (reportType==='Review Assistant'){
      this._router.navigate(['/review-assistant'], { queryParams: { tab: 2 }})
    } else {
      this._location.back();
    }
  }

  GoLocations(): void {
    this._router.navigate(['/accounts', this.accountId,  'locations'])
  }

  ngOnDestroy(): void {
    for(const s of this._subscriptionsToCleanup)
      s.unsubscribe()
    this.keywordSubject.complete()
    this.selectedDateA$.complete()
  }

  // check/uncheck all checkbox
  toggleCheckAll(): void {
    for (const item of this.metrics) 
      item.checked = this.allChecked
    
    this.getCheckedItemList();
  }

  // if every item is checked, check the Select All checkbox

  isAllChecked(): void {
    this.allChecked = !this.metricsCustomChart.find(m => !m.checked)
    this.getCheckedItemList();
  }

  unCheckedAll(): void {
    this.metrics.forEach(item => {
      item.checked = false;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList(): void {
    this.checkedList = this.metrics.filter(item => item.checked)
  }

  changeText(aggregation: string): void {
    this.dataPicker.aggregation = aggregation;
    this.change.emit(this.dataPicker);
    this.detectChanges();
  }

  changeSelectRangeValue(event): void {
    this.selectRangeValue = event;
    this.selectRange.emit(event);
  }

  getDate(date : string) : string {
    return date.split('T')[0];
  }

  datesUpdated($event): void {
    const start = moment($event.range.start).utc(true);
    const end   = moment($event.range.end).utc(true);
    const diff = end.diff(start, 'days') + 1;

    this.dataPicker.aggregation = (this.aggregation || this.reportS.correctDateAggregate($event))
    this.change.emit(this.dataPicker);
    this.detectChanges();
  }

  handleMultiChart(): void {
    this.dataPicker.multiChart = this.checkedList;
    this.trigger.closeMenu();
    this.statusCheck.emit(false);
    this.change.emit(this.dataPicker);
    this.detectChanges();
  }


  isComparisonReportMore25Locations(): boolean {
    const charts = document.getElementById('charts-container')
    const chartArray: any = charts?.childNodes[5]?.childNodes[1]?.childNodes[2];
    
    return (this.reportType === 'performance-comparison' && chartArray?.childElementCount > 25)
  }

  disableScrollingAndPointerEvents() : void {
    document.body.classList.add('disable-scroll');
    document.body.classList.add('disable-pointer-events');
    this.capturing = true
  }
  
  enableScrollingAndPointerEvents() : void {
    document.body.classList.remove('disable-scroll');
    document.body.classList.remove('disable-pointer-events');
    this.capturing = false
  }

  async handleExport(format: string): Promise<void> {
    try {
      this.disableScrollingAndPointerEvents();

      if (format === 'pdf') {
        this.scrollTop();
        this.isExporting.emit(true);
        this.expand.emit(true);
        try {
          await this.exportPdf()
        } catch(e) {
          console.error("Error exporting PDF", e)
        }
      } else if (format === 'csv' || format === 'csv-review')
        this.export.emit(format);

    } finally {
      this.enableScrollingAndPointerEvents();
    }
  }

  async exportPdf(): Promise<void> {
    try {
      this.progress = true;
      const params = this._route.snapshot.params;
      let filename : string

      if (params.id) {
        const report = (await this.reportS.getById(params.gid, params.id).get().toPromise()).data()
        const name = report?.reportName ? report.reportName : this.reportName;
        filename = `${name.toLowerCase()} - ${this.reportType.toUpperCase()}`;
      } else {
        const loc = this.locations[0]
        const location = await this._locationService.fetchLocation(this._auth.session.gid, loc.accountId, loc.locationId)
        const address = this._locationService.formatAddress(location.location.address);
        filename = `${location.locationName}` + (address ? ` - ${address}` : '');
      }
      // const branding = await this._wlService.branding
      await this._pdfS.base64AndSave('charts-container', filename, '')
    } finally {
      this.isExporting.emit(false);
      this.progress = false;   
      this.enableScrollingAndPointerEvents();
    }
  }

  handleShared(): void {
    this.dataPicker.locations = this.locations;
    let start;
    let end;
    const reportType = (
      this.reportType?.includes('rollup') && !this.isLegacyInsights ? 'performance-insights' : 
      !this._router.url.includes('report')  ? `${this.reportType}-location` : 
      this.reportType?.includes('revenue') && this._router.url.includes('performance') ? 'performance-revenue-report' : `${this.reportType}-report`
    );

    if(this.reportType.includes('qanda')) {
      const dates = this._questionAnswerS.buildPeriodDate(this.periodDaysValue.value);
      start = dates.startDatetime;
      end = dates.endDatetime;
    } else {
      start = moment.isMoment(this.dataPicker?.range?.start) ? this._dateS.getStringDateYMD(this.dataPicker?.range?.start) : this.dataPicker?.range?.start;
      end = moment.isMoment(this.dataPicker?.range?.end) ? this._dateS.getStringDateYMD(this.dataPicker?.range?.end) : this.dataPicker?.range?.end;
    }

    this.dataPicker.range.start = this.reportS.getFormattedStringDate(start);
    this.dataPicker.range.end   = this.reportS.getFormattedStringDate(end);

    if(this.reportType.includes('comparison')) {  
      const startB = moment.isMoment(this.dataPicker?.rangeB?.start) ? this._dateS.getStringDateYMD(this.dataPicker?.rangeB?.start) : this.dataPicker?.rangeB?.start;
      const endB = moment.isMoment(this.dataPicker?.rangeB?.end) ? this._dateS.getStringDateYMD(this.dataPicker?.rangeB?.end) : this.dataPicker?.rangeB?.end;
      
      this.dataPicker.rangeB = {start: null, end: null};
      this.dataPicker.rangeB.start = this.reportS.getFormattedStringDate(startB);
      this.dataPicker.rangeB.end   = this.reportS.getFormattedStringDate(endB);
    }

    this._modalS.openGenericModal(ModalShareComponent, {
      createNew: true,
      dataPicker: this.dataPicker,
      reportType: reportType,
      comparisonMetrics: this.comparisonMetrics,
      showComparison: this.reportType == 'keyword' ? this.hasComparison : null,
      compareToValue: this.reportType == 'keyword' ? this.selectRangeValue : null,
      questionsType: this.reportType == 'qanda' ? this.questionsTypeValue?.value : null,
      periodDaysValue: this.reportType == 'qanda' ? this.periodDaysValue?.value : null,
      reportName: this.reportName,
      route: this._route
    }, () => {
      this.detectChanges();
    });
  }

  handleRefresh(): void {
    this.locations.forEach( async (l) => {
      this._snackS.openSuccess('Refresh request sent! ', 2000);
      await this._locationService.checkLocation(this._auth.session.gid, l.accountId, l.locationId).toPromise()
      switch (this.reportType) {
        case 'rollup':
          await this._locationService.saveInsights(l.accountId, l.locationId).toPromise()
          await this._locationService.saveReviews(l.accountId, l.locationId).toPromise()
          this.refresh.emit();
          break;
        case 'review':
          await this._locationService.saveReviews(l.accountId, l.locationId).toPromise()
          this.refresh.emit();
          break;
      }
      // this.locationService.saveAllData(l.accountId, l.locationId, Queue.COMBINED_EXPRESS).subscribe();
    });
  }

  scrollTop(): void {
    (function goUp(){
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      if(currentScroll > 0)
      {
        window.requestAnimationFrame(goUp);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  goPerformanceInsights(): void {
    const p = this._route.parent.snapshot.params
    this._router.navigateByUrl(`/account/${p.accountId}/location/${p.locationId}/insights`)
  }

  changeQuestionsTypeValue(option): void {
    this.questionsTypeValue = option;
    this.questionsType.emit(option);
  }

  changeQuestionsPeriodValue(option): void {
    this.periodDaysValue = option;
    this.questionsPeriod.emit(option);
  }

  locationsFilterdChange(menu, clear): void {
    if (clear) {
      this.locationsFiltered = []
      this.accounts.forEach(acc => acc.locations.forEach(l => l.checked = false));
      this.labelsLocationsFiltered = null;
    }
    this.locationsChanged.emit(this.locationsFiltered);
    this.filterLocationControl.setValue(null);
    menu.close.next(false);
  }

  getLocationsFiltered(event, account, location): void {
    const value = [];
    this.labelsLocationsFiltered = null;
    this.accounts.forEach(acc => {
      acc.locations.forEach(loc => {
        loc.checked = (loc.locationId == location.locationId ? event.checked : loc.checked)
        if (loc.checked) {
          value.push({location: loc, gid: acc.gid});
          this.labelsLocationsFiltered = this.labelsLocationsFiltered ? `${this.labelsLocationsFiltered} - ${loc.locationName}`: loc.locationName; 
        }
      })
    });
    this.locationsFiltered = this.buildLocationsFiltered(value);
  }

  buildLocationsFiltered(options): any[] {
    const data = []
    options.forEach(el =>{
      const indexAcc = data.findIndex(locfiltered => locfiltered.accountId == el?.location?.accountId);
      if (indexAcc > -1) {
        data[indexAcc].locations.push({
          accountId: el?.location?.accountId,
          address: el?.location?.address,
          locationId: el?.location?.locationId,
          locationName: el?.location?.locationName,
          checked: el?.location?.checked,
          serviceArea: el?.location?.serviceArea,
        })
      } else {
        data.push({
          accountId: el?.location?.accountId,
          gid: el?.gid,
          locations: [{
            accountId: el?.location?.accountId,
            address: el?.location?.address,
            locationId: el?.location?.locationId,
            locationName: el?.location?.locationName,
            checked: el?.location?.checked,
            serviceArea: el?.location?.serviceArea,
          }]
        });
      }
    });
    return data;
  }

  changeComparison(event): void {
    this.hasComparison = event;
    this.changedViewComparison.emit(this.hasComparison);
  }

  getHasComparison(): string {
    return this.hasComparison ? 'Yes' : 'No';
  }

  _filter(value: string): void {
    const options = [];
    const filterValue = value?.toLowerCase();
    if(value != ''){
      this.accounts?.forEach(acc => {
        acc?.locations?.forEach(loc => { 
          if (
            loc?.locationName.toLowerCase().includes(filterValue) || 
            loc?.address?.administrativeArea.toLowerCase().includes(filterValue) || 
            loc?.address?.languageCode.toLowerCase().includes(filterValue) || 
            loc?.address?.locality.toLowerCase().includes(filterValue) || 
            loc?.address?.postalCode.toLowerCase().includes(filterValue) || 
            loc?.address?.regionCode.toLowerCase().includes(filterValue) ||
            loc?.address?.addressLines?.join('').toLowerCase().includes(filterValue)
          ) {
            options.push({
              gid: acc?.gid,
              location: loc
            });
          }
        })
      });
      this.locationsOptions = this.buildLocationsFiltered(options);
    } else {
      this.locationsOptions = [...this.accounts];
    }
  }

  hasLastMonth(): boolean {
    return (!this.maxDate || moment(this.maxDate).isAfter(moment(this.montPicker?.lastEnd)))
  }

  clearValues(): void {
    this.clearValuesEmit.emit();
  }

  saveValues(): void {
    this.saveValuesEmit.emit();
  }


  private async _fetchLocation(gid : string, accountId : string, locationId : string) : Promise<void> {
    try {
      const loc = await this._locationService.fetchLocation(gid, accountId, locationId)
      this.locationP.resolve(loc)
      this.locationWasSent$.next(loc['lastImpressions-fastTask']?.status === 'SENT' && 
                                 loc['lastKeywords-fastTask']?.status === 'SENT')
      this._checkPlanLimits()
    } catch(e) {
      this.locationP.reject(e)
      this.locationWasSent$.next(false)
    }  
  }
                                                    


}
