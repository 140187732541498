import { AlertType } from './../../../components/posts-errors';
import { Component, Input, OnInit, OnDestroy, OnChanges, EventEmitter, Output, ViewChild, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators, } from '@angular/forms';
import { AngularFireStorageReference } from '@angular/fire/storage';
import { MatDrawer } from '@angular/material';

import { Observable, Subject } from 'rxjs';
import { map, takeUntil, startWith } from 'rxjs/operators';
import moment from 'moment';

import { PostService } from 'src/app/services/post.service';
import { AuthService } from 'src/app/services/auth.service';
import { AccountService } from 'src/app/services/account.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ModalService } from './../../../services/modal.service';
import { StorageImageService } from './../../../services/storage-image.service';
import { IMediaUrl } from './../../../constants/media-url';
import { DatesService } from 'src/app/services/dates.service';
import { FormInputUploadComponent } from '../../form-input-upload/form-input-upload.component';

export interface IDataForLastStep {
  postType?: any;
  cadence?: any;
  timeOfPublish?: any;
  timeOfUnpublish?: any;
  schedulePublish?: any;
  scheduledDetele?: any;
  startDate?: any;
  endDate?: any;
  startTime?: any;
  endTime?: any;
  couponCode?: any;
  linkToRedeem?: any;
  termsAndCondition?: any;
  publishesEvery?: any;
  endsOn?: any;
  firstPublish?: any;
  repeatsOn?: any;
  dailyDate?: any;
}

@Component({
  selector: 'app-post-manegement-slider',
  templateUrl: './post-manegement-slider.component.html',
  styleUrls: ['./post-manegement-slider.component.scss']
})
export class PostManegementSliderComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('inputUpload', {static: false}) inputUpload: FormInputUploadComponent;
  
  @Input() drawer: MatDrawer ;
  @Input() isSliderOpened = false;
  @Input() postToEdit: any = {};
  @Input() isBulk = true;
  @Input() location = {};
  
  @Output() closeSlider = new EventEmitter();

  public isEdit = false;
  public form: FormGroup;
  public step = 0;
  public accountsSelected: any = [];
  public locationsSelected: any = [];
  public isDraft = false;
  public cancelText = {
    title: 'You have unsaved changes',
    message: 'If you close and leave this view, you will lose any unsaved changes.'
  }
  public stepTitle = [
    'Select Locations',
    'Post Schedule and Settings',
    'Add Details',
    'Confirmation'
  ];
  public nextButtonLabels = ['Post Schedule', 'Add Details', 'Preview Post', 'Publish'];
  public hoursList = [];
  public daysList = [];
  public readonly nameOfDaysOptions = [
    { value: [0], displayName: 'Sunday' },
    { value: [1], displayName: 'Monday' },
    { value: [2], displayName: 'Tuesday' },
    { value: [3], displayName: 'Wednesday' },
    { value: [4], displayName: 'Thursday' },
    { value: [5], displayName: 'Friday' },
    { value: [6], displayName: 'Saturday' }
  ]
  public imageSelectedUrl: string = null;
  public imageRequirements = {
    type: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
    min_width: 400,
    min_height: 300,
    min_size: 10240,
    max_size: 5000000
  };
  public firestorRef: AngularFireStorageReference;
  public isLoading = false;
  public actionsUrl = [];
  public actionOptions = [
    {value: 'NONE', displayName: 'None'},
    {value: 'BOOK', displayName: 'Book'},
    {value: 'ORDER', displayName: 'Order online'},
    {value: 'SHOP', displayName: 'Buy'},
    {value: 'LEARN_MORE', displayName: 'Learn More'},
    {value: 'SIGN_UP', displayName: 'Sign Up'},
    {value: 'CALL', displayName: 'Call Now'}
  ];
  public dataForLastStep: IDataForLastStep = {};
  public mediaUrlArray: IMediaUrl[] = [];
  public selectedUrl = '';
  public selectedFileIndex = 0;
  public swiperConfig: any = {
    slidesPerView: 4,
    initialSlide: 0,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    simulateTouch: true
  };
  public hoursListOptions: Observable<string[]>;
  private _subsManager$ = new Subject<void>();

  public dataIsBeingVerified = false;
  public isCorrectData = false;
  public fieldsWithError = [];
  public schedulePreviewData = []

  constructor(
    private _dateS: DatesService,
    private _postS: PostService,
    private _authS: AuthService,
    private _accountS: AccountService,
    private _snackS: SnackbarService,
    private _storageImgService: StorageImageService,
    private _modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.initData();
    this._storageImgService.multipleMediaUrl$ // multiple media subscription
      .pipe(takeUntil(this._subsManager$))
      .subscribe((urlsArray: IMediaUrl[]) => {
          if (urlsArray && urlsArray.length > 0) {
              this.mediaUrlArray = [...urlsArray].filter(url => !url.error);
          } else {
              this.mediaUrlArray = [];
          }
          // this.isUploading = false;
      },(e)=>{
          console.error(e);
          this._modalService.openErrorModal(
              'Upload Error',
              "Error uploading the image, try again"
          )
          // this.isUploading = false;
      });
  }

  initData(): void {
    this.hoursList  = this._dateS.loadHoursDropdown(true);
    this.hoursListOptions = this.form?.get('evenTimeStart')!?.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.daysList = Array.from(Array(28), (e, i) => i + 1);
    this.isDraft = this.isEdit && this.postToEdit?.status === 'DRAFT';
    this.initForm();
  }

  initForm(): void {
    //first Step
    if (this.isEdit) {
      const accounts = this._accountS.buildToggleLocationAccounts(this.postToEdit?.locations);
      this.postToEdit.accounts = accounts
      this.accountsSelected = accounts;

      if(this.postToEdit?.post?.media?.length > 0) {
        this.postToEdit?.post?.media.forEach(el => {
          this.mediaUrlArray.push({
            url: el?.sourceUrl,
            category: el?.mediaFormat
          })
        });
        this._storageImgService.setMultipleMediaUrl(this.mediaUrlArray);
      }
    }

    if (!this.isBulk) {
      this.accountsSelected = this.location;
    }

    this.form = new FormGroup({
      //Second Step
      postType: new FormControl(this.postToEdit?.post?.topicType || null, [Validators.required]),
      cadence: new FormControl(this.postToEdit?.scheduleRules?.type || null, [Validators.required]),
      schedulePublish: new FormControl(
        this.postToEdit?.scheduleRules?.type != "INSTANT" ? null : 
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.publish_now ? 'publish_immediately' : 'custom'
      ),
      postStartDate: new FormControl(
        this.postToEdit?.scheduleRules?.type != "INSTANT" ? null : 
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.publish_now ? null : this.getFormDate(this.postToEdit?.scheduleRules?.userInput?.firstPublish?.date)
      ),
      repeatsOnMonthly: new FormControl(
        this.postToEdit?.scheduleRules?.type != 'MONTHLY' || !this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth ? null :
        this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth?.includes(1) || this.postToEdit.scheduleRules.publishRule.daysOfMonth?.includes(15) || this.postToEdit.scheduleRules.publishRule.daysOfMonth?.includes(-1) ? 
        this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth?.[0] : 'custom'
      ),
      repeatsOnDay: new FormControl(
        this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth && !(this.postToEdit.scheduleRules.publishRule.daysOfMonth?.includes(1) || this.postToEdit.scheduleRules.publishRule.daysOfMonth?.includes(15)) ? 
        this.daysList?.find(el => el == this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth?.[0]) : 
        null
      ),
      repeatsOnWeekly: new FormControl(
        this.postToEdit?.scheduleRules?.type == 'WEEKLY' && this.postToEdit?.scheduleRules?.publishRule?.daysOfWeek ? 
        this.nameOfDaysOptions?.find(el => el.value == this.postToEdit.scheduleRules.publishRule.daysOfWeek?.[0]).value : []
      ),
      publishDay: new FormControl(this.postToEdit?.scheduleRules?.type == 'DAILY' ? this.postToEdit?.scheduleRules?.publishRule?.daysOfWeek : []),
      publishTime: new FormControl(this._dateS.hours24To12(this.postToEdit?.scheduleRules?.publishRule?.time) || null),
      // unpublishTime: new FormControl(this._dateS.hours24To12(this.postToEdit?.scheduleRules?.unpublishRule?.time) || null),
      deleteDate: new FormControl(
        this.postToEdit?.scheduleRules?.type != "INSTANT" ? null : 
        !this.postToEdit?.scheduleRules?.userInput?.schedule?.end?.date ? null : this.getFormDate(this.postToEdit.scheduleRules.userInput.schedule.end.date)
      ),
      endsOn: new FormControl(!Object.keys(this.postToEdit)?.length ? null :
        this.postToEdit?.scheduleRules?.interval ? 'custom_occurrences' :
        this.postToEdit?.scheduleRules?.endDate ? 'custom_date' :
        this.postToEdit?.scheduleRules?.userInput?.schedule?.type != "INSTANT" ? 'never' : null
      ),
      endsOnCustomDate: new FormControl(this.getFormDate(this.postToEdit?.scheduleRules?.userInput?.schedule?.end?.date) || null),
      endsOnAfterOcurrences: new FormControl(this.postToEdit?.scheduleRules?.userInput?.schedule?.end?.limit_occurrences || null),
      firstPublish: new FormControl(
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.publish_now ? "post_immediately" :
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.publish_next_occurrence ? "next_occurrence" :
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.date ? "custom" : null
      ),
      firstPublishDate: new FormControl(this.getFormDate(this.postToEdit?.scheduleRules?.userInput?.firstPublish?.date) || null),
    
      //Third step
      title: new FormControl(this.postToEdit?.post?.title),
      summary: new FormControl(this.postToEdit?.post?.summary || null),
      actionType: new FormControl(this.postToEdit?.post?.callToAction?.actionType || 'NONE'),
      actionUrl: new FormControl(this.getActionUrl()),
      instantEventDatesStart: new FormControl(this.getFormDate(this.postToEdit?.post?.eventSchedule?.publishRule?.date) || null),
      instantEventDatesEnd: new FormControl(this.getFormDate(this.postToEdit?.post?.eventSchedule?.deleteRule?.date) || null),
      evenTimeStart: new FormControl(this._dateS.hours24To12(this.postToEdit?.post?.eventSchedule?.publishRule?.time) || null),
      evenTimeEnd: new FormControl(this._dateS.hours24To12(this.postToEdit?.post?.eventSchedule?.deleteRule?.time) || null),
      weeklyEventsDates: new FormControl(
        !this.postToEdit?.post ? null :
        this.postToEdit?.post?.eventSchedule?.publishRule?.matchScheduleDate && this.postToEdit?.post?.eventSchedule?.publishRule?.matchScheduleDate ? 
        'corresponds_publish_date' : 'custom'
      ),
      eventDatesStartWeekly: new FormControl(this.nameOfDaysOptions?.find(el => el.value?.includes(this.postToEdit?.post?.eventSchedule?.publishRule?.daysOfWeek?.[0]))?.value || null),
      eventDatesEndWeekly: new FormControl(this.nameOfDaysOptions?.find(el => el.value?.includes(this.postToEdit?.post?.eventSchedule?.deleteRule?.daysOfWeek?.[0]))?.value || null),
      eventDatesStartMonthly: new FormControl(this.daysList?.find(el => el == this.postToEdit?.post?.eventSchedule?.publishRule?.daysOfMonth?.[0]) || null),
      eventDatesEndMonthly: new FormControl(this.daysList?.find(el => el == this.postToEdit?.post?.eventSchedule?.deleteRule?.daysOfMonth?.[0]) || null),
      couponCode: new FormControl(this.postToEdit?.post?.offer?.couponCode || null),
      linkToRedeem: new FormControl(this.postToEdit?.post?.offer?.redeemOnlineUrl || null),
      termsAndCondition: new FormControl(this.postToEdit?.post?.offer?.termsConditions || null),
    });

    this.stepTitle[2] = this.isEdit ? `Add ${this.postTypeLabel} Details` : `Add Details`;
    this.nextButtonLabels[1] = this.isEdit ? `Add ${this.postTypeLabel} Details` : `Add Details`;
  }

  getActionUrl(): string {
    return (
      !this.postToEdit?.post?.callToAction?.url ? null :
      this.actionsUrl?.find(el => el.key == this.postToEdit?.post?.callToAction?.url)?.value ?
      this.actionsUrl?.find(el => el.key == this.postToEdit?.post?.callToAction?.url)?.value : 
      this.postToEdit.post.callToAction.url
    )
  }

  getActionLinks() {
    this.actionsUrl = [];
    if(!this.actionType?.value || this.actionType?.value === 'CALL' || this.actionType?.value === 'NONE') {
      this.actionUrl.setValue(null)
      return;
    }

    this.isLoading = true;
    const accounts = this.accountsSelected.forEach(acc => {
      acc?.locations.forEach(loc => loc.name = loc.locationName);
    });

    this._postS.getActionLinks(this.accountsSelected).subscribe(
      res => {
        if(res?.data) {
          this.actionsUrl = res?.data;

          if(this.isEdit) {
            this.actionUrl.setValue(this.getActionUrl())
          }
          this.isLoading = false;
        }
      },
      err => {
        this.isLoading = false;
      }
    )
  }

  clearActionUrl(): void {
    this.actionUrl.setValue(null)
  }

  validateInput(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', 'A', 'P', 'M', ':', 'BACKSPACE', ' ', 'TAB', 'ARROWLEFT', 'ARROWRIGHT', 'DELETE'];
    const key = event.key.toUpperCase();

    if (!allowedKeys.includes(key)) {
      event.preventDefault();
    }
  }

  addValidationsStepTwo(): void {
    const label = this.postTypeLabel ? `Add ${this.postTypeLabel} Details` : `Add Details`;
    this.nextButtonLabels[1] = label;
    this.stepTitle[2] = label;

    this.removeAllValidations();

    if (this.cadence?.value) {
      if(this.cadence?.value == 'INSTANT') {
        this.schedulePublish?.setValidators(Validators.required);
        this.form.controls.postStartDate.setValidators(this.isRequired(this.schedulePublish?.value, 'custom') ? [Validators.required] : null);
        this.form.controls.publishTime.setValidators(this.isRequired(this.schedulePublish?.value, 'custom') ? [Validators.required] : null);
      } else {
        //Daily | Monthly | Weekly
        this.form.controls.publishTime.setValidators(Validators.required);
        this.endsOn?.setValidators(Validators.required);
        this.endsOnCustomDate?.setValidators(this.isRequired(this.endsOn?.value, 'custom_date') ? [Validators.required] : null);
        this.endsOnAfterOcurrences?.setValidators(this.isRequired(this.endsOn?.value, 'custom_occurrences') ? [Validators.required] : null);
        this.firstPublish?.setValidators(Validators.required);
        this.firstPublishDate?.setValidators(this.isRequired(this.firstPublish?.value, 'custom') ? [Validators.required] : null);

        switch(this.cadence?.value) {
          case 'DAILY':
            this.publishDay?.setValidators(Validators.required);
            break;
          case 'WEEKLY':
            this.repeatsOnWeekly?.setValidators(Validators.required);
            break;
          case 'MONTHLY':
            this.repeatsOnMonthly?.setValidators(Validators.required);
            this.repeatsOnDay?.setValidators(this.isRequired(this.repeatsOnMonthly?.value, 'custom') ? [Validators.required] : null);
            break;
        }
      }
      this.updateValueAndValidity()
    }
  }

  addFormFieldsStepThree(): void {
    this.removeAllValidations();
    this.form.controls.summary.setValidators([Validators.required]);

    if(this.postType?.value !== 'STANDARD') {
      this.title?.setValidators([Validators.required]);
      //this.evenTimeEnd?.setValidators([this._maxTimeValidator()]);

      if (this.cadence?.value == 'INSTANT') {
        this.instantEventDatesStart?.setValidators([Validators.required]);
        this.instantEventDatesEnd?.setValidators([Validators.required]);
      }

      if (this.cadence?.value == 'WEEKLY') {
        this.eventDatesStartWeekly?.setValidators([Validators.required]);
        this.eventDatesEndWeekly?.setValidators([Validators.required]);
      }

      if(this.cadence?.value == 'MONTHLY') {
        this.weeklyEventsDates?.setValidators([Validators.required]);
        this.eventDatesStartMonthly?.setValidators(this.isRequired(this.weeklyEventsDates?.value, 'custom') ? [Validators.required] : null);
        this.eventDatesEndMonthly?.setValidators(this.isRequired(this.weeklyEventsDates?.value, 'custom') ? [Validators.required] : null);
      }
      
    }
    this.updateValueAndValidity()
  }

  removeAllValidations(): void {
    const keys = Object.keys(this.form.controls);

    keys.forEach(k => {
      if(k != 'postType' && k != 'cadence')
      this.form.controls[k].clearValidators()
    });

    this.updateValueAndValidity();
  }

  updateValueAndValidity(): void {
    const keys = Object.keys(this.form.controls);

    keys.forEach(k => {
      this.form.controls[k].updateValueAndValidity()
    });
  }

  isRequired(field, value): boolean {
    return field === value;
  }

  selectDay(day: number): void {
    if (this.isEdit && !this.isDraft) { return }
    let values = this.publishDay?.value;
    let repeteadElement = this.publishDay.value.findIndex(el => el == day);
    
    if (repeteadElement > -1) {
      values.splice(repeteadElement, 1);
    } else {
      values.push(day);
    }
    
    values = values.sort((a, b) => a - b);
    this.publishDay.setValue(values);
  }

  parseTime(time): string {
    const parsedTime = this._dateS.hours12To24(time);
    const hour = (parsedTime?.hours)?.toString()?.padStart(2, '0');
    const minutes = (parsedTime?.minutes)?.toString()?.padStart(2, '0');

    return `${hour}:${minutes}`;
  }

  getFormDate(date): string {
    return date ? `${date}T00:00:00` : null;
  }

  getServiceDate(date, isYMD = true): string {
    return (
      date && isYMD ?
      this._dateS.getStringDateYMD(moment(date)) :
      date && !isYMD ?
      this._dateS.getStringDateMDY(moment(date)) :
      null
    );
  }

  getUrlsMedia() {
    const urlsArray = [];

    this.mediaUrlArray.forEach(el => {
      const format = !el?.category?.includes('/') ? el?.category : el?.category.split('/')[0] === 'image' ? 'PHOTO' : 'VIDEO';
      
      urlsArray.push(
        {
          "mediaFormat": format, 
          "sourceUrl": el?.url
        }
      );
    })
    
    return urlsArray.length ? urlsArray : null;
  }

  buildServiceData(isDraft = false): any {
    this.accountsSelected.forEach(acc => {
      acc?.locations.forEach(loc => loc.name = loc.locationName);
    });

    return {
      "gid": this._authS.session.gid,
      "accounts": this.accountsSelected,
      "post": {
        "summary": this.summary.value,
        "callToAction": this.postType.value == 'OFFER' || (!this.actionType?.value || this.actionType?.value === 'NONE') ? null :
        {
          "actionType": this.actionType?.value,
          "url": !this.actionUrl?.value ? null : this.actionsUrl.find(el => el.value == this.actionUrl.value)?.key ? this.actionsUrl.find(el => el.value == this.actionUrl.value)?.key : this.actionUrl.value,
        },
        "title": this.postType.value !== 'STANDARD' ? this.title.value : null,
        "eventSchedule": this.postType.value == 'STANDARD' ? null : {
          "publishRule": {
            "daysOfWeek": this.cadence.value == 'WEEKLY' ? this.eventDatesStartWeekly.value : null,
            "daysOfMonth": this.cadence.value == 'MONTHLY' && this.weeklyEventsDates.value == 'custom' ? [this.eventDatesStartMonthly.value] : null,
            "time": this.evenTimeStart?.value ? this.parseTime(this.evenTimeStart.value) : null,
            "date": this.cadence.value == 'INSTANT' ? this.getServiceDate(this.instantEventDatesStart.value) : null,
            "matchScheduleDate": 
            (
              ((this.postType.value == 'EVENT' || this.postType.value == 'OFFER') && this.cadence.value == 'DAILY') || 
              this.weeklyEventsDates?.value == "corresponds_publish_date" ? true : false
            )
          },
          "deleteRule": {
            "daysOfWeek": this.cadence.value == 'WEEKLY' ? this.eventDatesEndWeekly.value : null,
            "daysOfMonth": (
              this.cadence.value == 'MONTHLY' && this.weeklyEventsDates.value == 'custom' ? [this.eventDatesEndMonthly.value] : null
            ),
            "time": this.evenTimeEnd?.value ? this.parseTime(this.evenTimeEnd.value) : null,
            "date": this.cadence.value == 'INSTANT' ? this.getServiceDate(this.instantEventDatesEnd.value) : null,
            "matchScheduleDate": 
            (
              ((this.postType.value == 'EVENT' || this.postType.value == 'OFFER') && this.cadence.value == 'DAILY') || 
              this.weeklyEventsDates?.value == "corresponds_publish_date" ? true : false
            )
          }
        },
        "media": this.getUrlsMedia(),
        "topicType": this.postType?.value,
        "offer": this.postType.value !== 'OFFER' || (!this.form.controls.couponCode?.value && !this.form.controls.linkToRedeem?.value && !this.form.controls.termsAndCondition?.value) ? null :
        {
          "couponCode": this.form.controls.couponCode?.value,
          "redeemOnlineUrl": this.form.controls.linkToRedeem?.value,
          "termsConditions": this.form.controls.termsAndCondition?.value
        }
      },
      "status": isDraft ? "DRAFT" : "ACTIVE",
      "is_bulk": this.isBulk,
      "is_recurring": this.cadence.value != 'INSTANT' ? true : false,
      "is_scheduled": this.cadence.value != 'INSTANT' || (this.cadence.value == 'INSTANT' && this.schedulePublish.value == 'custom') ? true : false,
      "first_publish": {
        "date": (
          this.cadence.value == 'INSTANT' && this.schedulePublish.value == 'custom' ? this.getServiceDate(this.postStartDate.value) : 
          this.firstPublish.value == 'custom' ? this.getServiceDate(this.firstPublishDate.value) : null
        ),
        "publish_now": (this.cadence?.value == 'INSTANT' && this.schedulePublish.value == 'publish_immediately') || this.firstPublish.value == 'post_immediately' ? true : false,
        "publish_next_occurrence": this.firstPublish.value == 'next_occurrence' ? true : false,
      },
      "schedule": {
        "type": this.cadence.value,
        "end":  this.endsOn.value != 'custom_date' && this.endsOn.value != 'custom_occurrences' && !this.deleteDate.value  ? null : {
          "date": (
            this.cadence.value == 'INSTANT' && this.deleteDate.value ? this.getServiceDate(this.deleteDate.value) :
            this.endsOn.value == 'custom_date' ? this.getServiceDate(this.endsOnCustomDate.value) : null
          ),
          "limit_occurrences": this.endsOn.value == 'custom_occurrences' ? this.endsOnAfterOcurrences.value : null,
        },
        "publishRule": this.cadence.value == 'INSTANT' && this.schedulePublish.value != 'custom' ? null : {
          "daysOfWeek": (
            this.cadence.value == 'DAILY' ? this.publishDay.value :
            this.cadence.value == 'WEEKLY' ? this.repeatsOnWeekly.value : null
          ),
          "daysOfMonth": (
            this.cadence.value == 'MONTHLY' && this.repeatsOnMonthly.value != 'custom' ? [this.repeatsOnMonthly.value] :
            this.cadence.value == 'MONTHLY' && this.repeatsOnMonthly.value == 'custom' ? [this.repeatsOnDay.value] : null
          ),
          "time": this.parseTime(this.publishTime.value)
        },
        // "unpublishRule": !this.unpublishTime?.value ? null : {
        //   "daysOfWeek": null, //this.cadence.value == 'WEEKLY' ? this.eventDatesStartWeekly.value : null,
        //   "daysOfMonth": null, // this.cadence.value == 'MONTHLY' && this.weeklyEventsDates.value == 'custom' ? [this.eventDatesStartMonthly.value] : null,
        //   "time": this.parseTime(this.unpublishTime.value),
        // },
      }
    };
  }

  completeSteeper(isDraft = false): void {
    this.isLoading = true;
    this.dataIsBeingVerified = true;
    this.isCorrectData = false;
    this.fieldsWithError = [];
    const data = this.buildServiceData(isDraft);

    if(this.isEdit) {
      this.editPost(data);
    } else {
      this.createPost(data);
    }
  }

  createPost(data):void {
    this._postS.createPost(data).subscribe(
      res => {
        this.isLoading = false;
        this.dataIsBeingVerified = false;
        this.drawer.toggle();
        this.closeSlider.emit(true);
      },
      err => {
        this.handleApiError(err?.error?.errors);
      }
    )
  }

  editPost(data):void {
    const postId = this.postToEdit?._id;

    this._postS.editPost(data, postId).subscribe(
      res => {
        this.isLoading = false;
        this.dataIsBeingVerified = false;
        this.drawer.toggle();
        this.closeSlider.emit(true);
      },
      err => {
        this.handleApiError(err?.error?.errors);
      }
    )
  }

  handleApiError(errors) {
    errors.forEach(
      el => {
        if(el?.parameter) {
          const error = el?.displayable_message?.split('. ')[1];
          const field = el.parameter?.split('->');
          let lastPart = field?.[field?.length - 1];
          lastPart = lastPart.replaceAll('occurrence', 'occurence');
          lastPart = lastPart?.replace(/([A-Z])/g, ' $1')?.replace(/_/g, ' ');
          this.fieldsWithError.push({field: lastPart, error: error})
        }
      }
    );
    this.isLoading = false;
    this.dataIsBeingVerified = false;
    this.isCorrectData = true;
  }

  selectedLocations($event): void {
    this.accountsSelected = $event;
  }

  handleUrl(): void {

  }

  addActionUrl(placeHolder) {
    this.actionUrl.setValue(placeHolder?.value);
  }

  handleRef($event: AngularFireStorageReference): void {
    this.firestorRef = $event;
  }

  onStepChange(event: number): void {
    this.step = event;

    if(this.step === 1) {
      this.buildLocationsNames();
    }

    if(this.step === 2) {
      this.getActionLinks();
      this.addFormFieldsStepThree();
    }

    if(this.step === 3) {
      this.buildDataForLastStep();
      //this.getSchedulePreview();
    }
  }

  backStep(event: number): void {
    this.step = event;
    this.removeAllValidations();
  }

  getActionType(value): string {
    return this.actionOptions.find(el => el?.value === value)?.displayName;
  }

  buildLocationsNames(): void {
    this.locationsSelected = [];
    this.accountsSelected.forEach(
      acc => {
        acc.locations.map(loc => { 
          this.locationsSelected.push(
            {
              'accountName': loc.accountName,
              'locationName': loc.locationName,
              'address': loc.address,
              'serviceArea': loc.serviceArea
            }
          )
        })
      } 
    );
  }

  buildDataForLastStep(): void {
    this.dataForLastStep = {};

    switch(this.cadence?.value) {
      case 'INSTANT':
        this.dataForLastStep = {
          postType: this.postTypeLabel,
          cadence: 'Does Not Repeat',
          timeOfPublish: this.schedulePublish?.value == 'custom' ? this.publishTime?.value : null,
          schedulePublish: this.schedulePublish?.value !== 'custom' ? 'Now' : this.getServiceDate(this.postStartDate?.value, false),
          scheduledDetele: this.getServiceDate(this.deleteDate?.value, false),
          dailyDate: null,
          startDate: this.postType?.value === 'STANDARD' ? null : this.getServiceDate(this.instantEventDatesStart?.value, false),
          endDate: this.postType?.value === 'STANDARD' ? null : this.getServiceDate(this.instantEventDatesEnd?.value, false),
          startTime: this.postType?.value === 'STANDARD' ? null : this.evenTimeStart?.value,
          endTime: this.postType?.value === 'STANDARD' ? null : this.evenTimeEnd?.value,
          couponCode: this.postType?.value !== 'OFFER' ? null : this.form.controls.couponCode?.value,
          linkToRedeem: this.postType?.value !== 'OFFER' ? null : this.form.controls.linkToRedeem?.value,
          termsAndCondition: this.postType?.value !== 'OFFER' ? null : this.form.controls.termsAndCondition?.value,
          publishesEvery: null,
          endsOn: null,
          firstPublish: null,
          // timeOfUnpublish: null,
          repeatsOn: null
        };
        break;
      case 'DAILY':
        this.dataForLastStep = {
          postType: this.postTypeLabel,
          cadence: this.cadence?.value,
          timeOfPublish: this.publishTime?.value ? this.publishTime?.value : null,
          publishesEvery: this.unifiedPublishDayValues,
          endsOn: (
            this.endsOn.value === 'never' ? 'Never' :
            this.endsOn.value === 'custom_date' ? this.getServiceDate(this.endsOnCustomDate?.value, false) :
            `After ${this.endsOnAfterOcurrences?.value} Occurences`
          ),
          firstPublish: (
            this.firstPublish?.value === 'post_immediately' ? 'Post immediately' :
            this.firstPublish?.value === 'custom' ? this.getServiceDate(this.firstPublishDate?.value, false) :
            'Wait for the next occurence'
          ),
          dailyDate: `Daily on ${this.unifiedPublishDayValues}`,
          // timeOfUnpublish: null,
          startDate: null,
          endDate: null,
          startTime: this.postType?.value === 'STANDARD' ? null : this.evenTimeStart?.value,
          endTime: this.postType?.value === 'STANDARD' ? null : this.evenTimeEnd?.value,
          couponCode: this.postType?.value !== 'OFFER' ? null : this.form.controls.couponCode?.value,
          linkToRedeem: this.postType?.value !== 'OFFER' ? null : this.form.controls.linkToRedeem?.value,
          termsAndCondition: this.postType?.value !== 'OFFER' ? null : this.form.controls.termsAndCondition?.value,
          schedulePublish: null,
          scheduledDetele: null,
          repeatsOn: null
        };
        break;
      case 'WEEKLY':
        this.dataForLastStep = {
          postType: this.postTypeLabel,
          cadence: this.cadence?.value,
          timeOfPublish: this.publishTime?.value,
          // timeOfUnpublish: this.unpublishTime?.value,
          publishesEvery: null,
          repeatsOn: this.nameOfDaysOptions.find(el => el.value === this.repeatsOnWeekly.value).displayName,
          endsOn: (
            this.endsOn.value === 'never' ? 'Never' :
            this.endsOn.value === 'custom_date' ? this.getServiceDate(this.endsOnCustomDate?.value, false) :
            `After ${this.endsOnAfterOcurrences?.value} Occurences`
            ),
          firstPublish: (
            this.firstPublish?.value === 'post_immediately' ? 'Post immediately' :
            this.firstPublish?.value === 'custom' ? this.getServiceDate(this.firstPublishDate?.value, false) :
            'Wait for the next occurence'
          ),
          dailyDate: null,
          startDate: this.postType?.value === 'STANDARD' ? null : this.nameOfDaysOptions.find(el => el.value === this.eventDatesStartWeekly.value).displayName,
          endDate: this.postType?.value === 'STANDARD' ? null : this.nameOfDaysOptions.find(el => el.value === this.eventDatesEndWeekly.value).displayName,
          startTime: this.postType?.value === 'STANDARD' ? null : this.evenTimeStart?.value,
          endTime: this.postType?.value === 'STANDARD' ? null : this.evenTimeEnd?.value,
          couponCode: this.postType?.value !== 'OFFER' ? null : this.form.controls.couponCode?.value,
          linkToRedeem: this.postType?.value !== 'OFFER' ? null : this.form.controls.linkToRedeem?.value,
          termsAndCondition: this.postType?.value !== 'OFFER' ? null : this.form.controls.termsAndCondition?.value,
          schedulePublish: null,
          scheduledDetele: null,
        };
        break;
      case 'MONTHLY':
        const repeatsOn = (
          this.repeatsOnMonthly?.value === 'custom' ?
          this.getOrdinalNumbers(this.repeatsOnDay?.value) :
          this.repeatsOnMonthly?.value === -1 ?
          'last day' : 
          this.getOrdinalNumbers(this.repeatsOnMonthly?.value)
        );

        this.dataForLastStep = {
          postType: this.postTypeLabel,
          cadence: this.cadence?.value,
          timeOfPublish: this.publishTime?.value,
          // timeOfUnpublish: this.unpublishTime?.value,
          publishesEvery: null,
          repeatsOn:  `The ${repeatsOn} of the month`,
          endsOn: (
            this.endsOn.value === 'never' ? 'Never' :
            this.endsOn.value === 'custom_date' ? this.getServiceDate(this.endsOnCustomDate?.value, false) :
            `After ${this.endsOnAfterOcurrences?.value} Occurences`
            ),
          firstPublish: (
            this.firstPublish?.value === 'post_immediately' ? 'Post immediately' :
            this.firstPublish?.value === 'custom' ? this.getServiceDate(this.firstPublishDate?.value, false) :
            'Wait for the next occurence'
          ),
          dailyDate: `Monthly on the ${repeatsOn} of the month`,
          startDate: this.postType?.value === 'STANDARD' ? null : this.getOrdinalNumbers(this.eventDatesStartMonthly?.value),
          endDate: this.postType?.value === 'STANDARD' ? null : this.getOrdinalNumbers(this.eventDatesEndMonthly?.value),
          startTime: this.postType?.value === 'STANDARD' ? null : this.evenTimeStart?.value,
          endTime: this.postType?.value === 'STANDARD' ? null : this.evenTimeEnd?.value,
          couponCode: this.postType?.value !== 'OFFER' ? null : this.form.controls.couponCode?.value,
          linkToRedeem: this.postType?.value !== 'OFFER' ? null : this.form.controls.linkToRedeem?.value,
          termsAndCondition: this.postType?.value !== 'OFFER' ? null : this.form.controls.termsAndCondition?.value,
          schedulePublish: null,
          scheduledDetele: null,
        };
        break;
    }
  }

  getOrdinalNumbers(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = number % 100;
    return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  }

  openDelete(item: IMediaUrl): void {
    this._modalService.openAlertModal(
      'Confirmation',
      `Are you sure you want to delete this image? This action cannot be undone.`,
      AlertType.ERROR,
      null,
      'Delete',
      (response) => {
        if (response) {
          this._storageImgService.removeFileFromArray(this.mediaUrlArray, item);
          this._storageImgService.notifyFileDeleted();
        }
      },
      'red-alert')
  }

  getSchedulePreview() {
    this.isLoading = true;
    const data = this.buildServiceData(); 
    this.schedulePreviewData = [];

    this._postS.getSchedulePreview(data).subscribe(
      res => {
        this.schedulePreviewData = res?.data;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  changePreview(file: IMediaUrl): void {
    this.selectedUrl = file.url;
  }

  // custom functions for custom swiper
  
  customSwiperPrev(): void {
    if (this.selectedFileIndex === 0) {
      return;
    }
    this.selectedFileIndex--
  }
  
  customSwiperNext(): void {
    if (this.mediaUrlArray.length - 1 === this.selectedFileIndex) {
      return;
    }
    this.selectedFileIndex++;
  }

  // custom swiper

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.isSliderOpened) {
      this.isEdit = changes?.postToEdit && Object.keys(changes.postToEdit.currentValue).length ? true : false;
      // const content = document.getElementsByClassName('content');
      // if (content.length > 0) {
      //   content[0]['className'] += ' pr--0';
      // }
      
      if(!this.isSliderOpened) {
        this.destroyData();
      } else {
        this.initData();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyData();
  }
  
  destroyData(): void {
    this.accountsSelected = [];
    this.locationsSelected = [];
    this.actionsUrl = [];
    this.dataForLastStep = {};
    this.step = 0;
    this.isEdit = false;
    this.isDraft = false;
    this.postToEdit = null;
    this._subsManager$.next();
    this._subsManager$.complete();
    this._storageImgService.reset();
    this.closeSlider.emit();
  }

  getMinDate(field) {
    const hasValidation = (
      this.postType.value === 'EVENT' && this.cadence.value === 'INSTANT' && this.schedulePublish.value === 'custom' && field === 'instantEventDatesStart' ?
      false :
      true
    );
    return (this.isEdit && !this.isDraft) || !hasValidation ? null : new Date();
  }
  
  getDisabledFieldTooltip(): string | null {
    const message = this.cadence.value === 'INSTANT' ?
    `You can’t edit the schedule for this post. If you’d like to change the schedule, you need to create a new post with the desired schedule change` :
    `You can’t edit the schedule for a recurring post. If you’d like to change the schedule, you need to create a new post with the desired schedule change`;

    return (
      this.isEdit && !this.isDraft ?
      message :
      null
    )
  }

  get formattedSummary(): string {
    const summary = this.summary?.value?.replaceAll(/\n/g, '<br>');
    
    return summary;
  }

  get infoAboutDates(): string {
    let postType = this.postTypeLabel?.replace(/_/g, ' ');
    postType = postType.charAt(0).toUpperCase() + postType.slice(1).toLowerCase();

    return `If the "${postType} End Time” is set, each post will be unpublished at that time instead, and republished at the specified Time of Publish.`;
  }

  get postTypeLabel() {
    return this.postType?.value === 'STANDARD' ? 'Update' : this.postType.value;
  }

  get completeStep1(): boolean {
    let isComplete = false
    if(this.isBulk) {
      let totalLocations = 0;
      this.accountsSelected?.forEach(account => {
        totalLocations += account?.locations?.length;
      });
      isComplete = this.accountsSelected?.length > 0 && totalLocations >= 2
    } else {
      isComplete = this.accountsSelected?.length > 0;
    }
    
    return isComplete;
  }

  get completeStep2(): boolean {
    return this.form?.valid;
  }

  get completeStep3(): boolean {
    return this.form?.valid;
  }

  get postType(): any {
    return this.form?.controls?.postType;
  }

  get cadence(): any {
    return this.form?.controls?.cadence;
  }

  get schedulePublish(): any {
    return this.form?.controls?.schedulePublish;
  }

  get postStartDate(): any {
    return this.form .controls?.postStartDate;
  }

  get deleteDate(): any {
    return this.form?.controls?.deleteDate;
  }

  get publishTime(): any {
    return this.form?.controls?.publishTime;
  }

  // get unpublishTime(): any {
  //   return this.form?.controls?.unpublishTime;
  // }

  get publishDay(): any {
    return this.form?.controls?.publishDay;
  }

  get unifiedPublishDayValues(): string {
    const publishesDay = [];
    this.publishDay.value.forEach(
      day => {
        publishesDay.push(this.nameOfDaysOptions.find(el => el?.value?.includes(day))?.displayName)
      }
    );
    return publishesDay.join(', ');
  }

  get endsOn(): any {
    return this.form?.controls?.endsOn;
  }

  get endsOnCustomDate(): any {
    return this.form?.controls?.endsOnCustomDate;
  }

  get endsOnAfterOcurrences(): any {
    return this.form?.controls?.endsOnAfterOcurrences;
  }
  
  get repeatsOnMonthly(): any {
    return this.form?.controls?.repeatsOnMonthly;
  }
  
  get repeatsOnWeekly(): any {
    return this.form?.controls?.repeatsOnWeekly;
  }

  get repeatsOnDay(): any {
    return this.form?.controls?.repeatsOnDay;
  }

  get firstPublish(): any {
    return this.form?.controls?.firstPublish;
  }

  get firstPublishDate(): any {
    return this.form?.controls?.firstPublishDate;
  }

  get title(): any {
    return this.form?.controls?.title;
  }

  get summary(): any {
    return this.form?.controls?.summary;
  }

  get eventDatesStartWeekly(): any {
    return this.form?.controls?.eventDatesStartWeekly;
  }

  get eventDatesEndWeekly(): any {
    return this.form?.controls?.eventDatesEndWeekly;
  }

  get eventDatesStartMonthly(): any {
    return this.form?.controls?.eventDatesStartMonthly;
  }

  get eventDatesEndMonthly(): any {
    return this.form?.controls?.eventDatesEndMonthly;
  }

  get instantEventDatesStart(): any {
    return this.form?.controls?.instantEventDatesStart;
  }

  get instantEventDatesEnd(): any {
    return this.form?.controls?.instantEventDatesEnd;
  }

  get weeklyEventsDates(): any {
    return this.form?.controls?.weeklyEventsDates;
  }

  get evenTimeStart(): any {
    return this.form?.controls?.evenTimeStart;
  }

  get actionType(): any {
    return this.form?.controls?.actionType;
  }

  get actionUrl(): any {
    return this.form?.controls?.actionUrl;
  }

  get evenTimeEnd(): any {
    return this.form?.controls?.evenTimeEnd;
  }

  get disabledNextStep(): boolean {
    return !(
      this.step === 0 ? this.completeStep1 : 
      this.step === 1 ? this.completeStep2 : 
      this.step === 2 ? this.completeStep3 : true
    );
  }

  // get endsOnTooltip(): string {
  //   return (
  //     this.postType?.value === 'STANDARD' ? 
  //     'Choose whether this recurring post will continue indefinitely or stop after a specific date or number of occurences.' : 
  //     'Choose whether this recurring post will continue indefinitely or stop after a specific date or number of occurences.If your event or offer also has an end date/time, the occurence will be automatically unpublished at the earlier of the two.' 
  //   )
  // }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filtered = this.hoursList.filter(option => option.toLowerCase().includes(filterValue));
    return filtered;
  }

  private _maxTimeValidator() {
    return (control: FormControl): ValidationErrors | null => {
      if (!control?.value) {
        return null
      } else  {
        const currentTime = moment();
        const inputTime = moment(control.value, 'hh:mm A', true);
        if (!inputTime.isValid()) {
          return { invalidTime: true };
        }
        return inputTime.isBefore(currentTime) ? { maxTime: true } : null;
      }
    };
  }
}