import { OnInit, OnChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';
var ChartProgressComponent = /** @class */ (function () {
    function ChartProgressComponent(cdr) {
        this.cdr = cdr;
        this.showPercent = true;
        this.tooltip = null;
    }
    ChartProgressComponent.prototype.ngAfterViewInit = function () {
        this.cdr.detectChanges();
    };
    ChartProgressComponent.prototype.ngOnInit = function () {
        var _a, _b;
        this.showPercent = !((_a = this.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('post'));
        this.progressColorClass = (((_b = this.total) === null || _b === void 0 ? void 0 : _b.percent) > 55) ? 'txt--white' : 'txt--black';
    };
    ChartProgressComponent.prototype.ngOnChanges = function () {
        switch (this.bgColor) {
            case 'rgba(66, 133, 244, 0.1)': {
                this.progressBgColorClass = 'progress--blue';
                break;
            }
            case 'rgba(251, 188, 5, 0.1)': {
                this.progressBgColorClass = 'progress--yellow';
                break;
            }
            case 'rgba(52, 168, 83, 0.1)': {
                this.progressBgColorClass = 'progress--green';
                break;
            }
            case 'rgba(234, 67, 53, 0.1)': {
                this.progressBgColorClass = 'progress--red';
                break;
            }
            default: {
                break;
            }
        }
    };
    return ChartProgressComponent;
}());
export { ChartProgressComponent };
