import { __assign, __awaiter, __generator, __values } from "tslib";
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { GoogleService } from '../../services/google.service';
import { GoogleAccountService } from '../../services/google-account.service';
// import { GoogleLocationService } from '../../services/google-location.service';
import { LocationService } from '../../services/location.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ObservationService } from '../../services/observation.service';
import { WhiteLabelService } from "../../services/white-label.service";
import { NotificationService } from '../../services/notification.service';
import { DELETE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from '../../constants/notifications';
import { Messages, string_message } from '../../constants/messages';
import { SpinnerService } from '../../services/spinner.service';
import { ModalService } from '../../services/modal.service';
import { DialogLocationsComponent } from './dialog-locations.component';
import { AlertType } from '../../components/alert.component';
import { GroupService } from '../../services/group.service';
import { UserService } from '../../services/user.service';
var AccountsComponent = /** @class */ (function () {
    function AccountsComponent(router, accountService, auth, dialog, _googleAccountService, _locationService, _groupService, _google, _cdr, _snack, _obsService, _modalService, _userService) {
        var _this = this;
        this.router = router;
        this.accountService = accountService;
        this.auth = auth;
        this.dialog = dialog;
        this._googleAccountService = _googleAccountService;
        this._locationService = _locationService;
        this._groupService = _groupService;
        this._google = _google;
        this._cdr = _cdr;
        this._snack = _snack;
        this._obsService = _obsService;
        this._modalService = _modalService;
        this._userService = _userService;
        this.subscription$ = this.auth.subscription$;
        this.accounts = null;
        this.displayedColumns = ['name', 'locations', 'actions'];
        this.subsManager$ = new Subject();
        this.loading_refresh_account$ = new BehaviorSubject(false);
        this.size = 25;
        this.page = 1;
        this.pagination = {
            items: [],
            per_page: this.size,
            page: this.page,
            hasNext: false,
            hasPrev: false,
            pages: 0,
            total: 0
        };
        this.accountVerified = false;
        this._paginate = { size: this.size, page: this.page };
        this.dataSource = new MatTableDataSource(null);
        this.user = this.auth.session;
        this.subscription$ = this.auth.subscription$;
        this.subscription$.subscribe(function (subscription) {
            _this.subscription = subscription;
            // if (this.subscription?.status == GROUP_SUBSCRIPTION_TYPE.TRIAL && !this.accountVerified) {
            //   this.showAccountsMessage();
            // }
        });
        if (!this.auth.isAdmin) {
            this.displayedColumns = ['name'];
        }
        this._obsService.getAddAccount()
            .pipe(takeUntil(this.subsManager$))
            .subscribe(function () { return _this.onAddNewAccount(); });
    }
    AccountsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._locationService.setPaginate({ size: 10, page: 1 });
        this._userFeatures = this._userService.getUserFeature(this.user.uid).toPromise();
        this._cdr.detectChanges();
        this._groupService.getByDocId(this.user.gid).subscribe(function (group) {
            _this._group = group;
            _this.getData(_this._paginate);
        });
        this.accounts = this.accountService.accounts;
        this.accountService.pagination = this.pagination;
        this.accountService.previousPageable = this._previousPageable;
        this.dataSource.sort = this.sort;
        this.accounts
            .pipe(takeUntil(this.subsManager$))
            .subscribe(function () {
            _this.pagination = _this.accountService.pagination;
            _this._previousPageable = _this.accountService.previousPageable;
        });
        this.dataSource.filterPredicate = function (data, filter) {
            var accumulator = function (currentTerm, key) {
                return _this.nestedFilterCheck(currentTerm, data, key);
            };
            var dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
            var transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
        };
        this.manualPage = 1;
        this.errorMessage = false;
    };
    AccountsComponent.prototype.showAccountsMessage = function () {
        var _this = this;
        var _a;
        // const paginate: Pageable = { size: 5, page: 1 };
        this.accountVerified = true;
        (_a = this.accounts) === null || _a === void 0 ? void 0 : _a.subscribe(function (acc) {
            // check if no accounts message should be seen
            _this._cdr.detectChanges();
        });
    };
    AccountsComponent.prototype.enableNotifications = function (element) {
        var _this = this;
        this._modalService.openConfirmModal('Enable Notifications', 'Are you sure want you enable notifications?', (function (response) {
            if (response) {
                _this.accountService.enableNotifications(element.accountId).subscribe(function (res) {
                    _this._snack.openSuccess('The notifications was successfully enabled.', 4000);
                }, function (err) {
                    _this._snack.openError(err.message, 4000);
                });
            }
        }));
    };
    AccountsComponent.prototype.ngOnDestroy = function () {
        this.subsManager$.next(true);
        this.subsManager$.complete();
    };
    AccountsComponent.prototype.openReauth = function (account) {
        return __awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!account.hasOwnProperty('googleAuthEmailAddress')) return [3 /*break*/, 1];
                        dialogRef = this.dialog.open(DialogAccountReauthComponent, {
                            width: '680px',
                            data: {
                                account: account
                            }
                        });
                        dialogRef.componentInstance.onAccept
                            .pipe(take(2))
                            .subscribe(function (r) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!r) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.onAddNewAccount(account.accountId)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.onAddNewAccount(account.accountId)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AccountsComponent.prototype.getData = function (_event) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var f, result, response, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this._userFeatures];
                    case 1:
                        f = _b.sent();
                        return [4 /*yield*/, this.accountService.loadAll(this.user, this._paginate)];
                    case 2:
                        result = _b.sent();
                        if (!(result['items'].length === 0 &&
                            !((_a = this._group) === null || _a === void 0 ? void 0 : _a.autoAddLocationsTriggered) &&
                            (f.userFeatures.autoAddLocationsTrigger || f.generalFeatures.autoAddLocationsTrigger))) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._modalService.openConfirmModal('Attention: Redirecting you to Google Business Profile Login', 'After clicking Continue, please ensure you select the account you use for managing your Google Business Profiles', null, AlertType.INFO, 'Continue')];
                    case 3:
                        response = _b.sent();
                        if (response)
                            this.onAddNewAccount();
                        this._groupService.updateByDocId(this.user.gid, { 'autoAddLocationsTriggered': true });
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_1 = _b.sent();
                        console.error(e_1);
                        this._snack.openError('There was an error while loading the data. Please try again or contact support');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AccountsComponent.prototype.onAddNewAccount = function (accountId) {
        if (accountId === void 0) { accountId = null; }
        return __awaiter(this, void 0, void 0, function () {
            var data, oauth_1, popupTick_1, e_2, message;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.dialog.closeAll();
                        this.da = null;
                        this.dl = null;
                        this._googleAccountService.onLoadAll
                            .pipe(takeUntil(this.subsManager$))
                            .subscribe(function (e) {
                            if (e.success !== true) {
                                var msg = '';
                                switch (e.details.status) {
                                    case 901:
                                        msg = 'Oops! There was an error in the application ';
                                        break;
                                    case 401:
                                        msg = 'Google said you are not authorized! - Try again';
                                        break;
                                    default:
                                        msg = 'Some odd error happened. Try again.';
                                }
                                return _this._snack.openError(msg, 4000);
                            }
                            // Everything looks good...
                            // Prevent multiple dialogs from being opened
                            !_this.da && _this.openAddAccountsDialog(accountId);
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this._snack.openInfo("A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings", 2000);
                        return [4 /*yield*/, this._google.authenticate(this.user.gid, this.user.uid, null)];
                    case 2:
                        data = _a.sent();
                        oauth_1 = window.open(data, '_blank');
                        popupTick_1 = setInterval(function () {
                            var _a;
                            if ((_a = oauth_1) === null || _a === void 0 ? void 0 : _a.closed) {
                                clearInterval(popupTick_1);
                                _this._googleAccountService.loadAll();
                            }
                        }, 1000);
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        message = 'There was an error with the GBP Authentication service';
                        console.error(message, e_2);
                        return [2 /*return*/, this._snack.openError(message, 4000)];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AccountsComponent.prototype.onDeleteAccount = function (account) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogDeleteAccountComponent, {
            width: '680px',
            data: {
                account: account
            }
        });
        dialogRef.disableClose = true;
        dialogRef.componentInstance.onDelete
            .pipe(takeUntil(this.subsManager$))
            .subscribe(function () {
            _this._snack.openSuccess('The account was successfully deleted.', 4000);
            _this.ngOnInit();
        });
        dialogRef.afterClosed()
            .pipe(takeUntil(this.subsManager$))
            .subscribe(function () { return null; });
    };
    // apply filter from search
    AccountsComponent.prototype.applyFilter = function (filterValue) {
        this.dataSource.filter = filterValue;
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    };
    // check for nested objects
    AccountsComponent.prototype.nestedFilterCheck = function (search, data, key) {
        if (typeof data[key] === 'object') {
            for (var k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        }
        else {
            search += data[key];
        }
        return search;
    };
    AccountsComponent.prototype.openAddAccountsDialog = function (accountId) {
        var _this = this;
        if (accountId === void 0) { accountId = null; }
        // TODO: Replace for modal service
        this.da = this.dialog.open(DialogAccountsComponent, {
            width: '680px',
            data: { googleAccounts: this._googleAccountService.accounts, accountId: accountId },
            autoFocus: false
        });
        this.da.backdropClick().subscribe(function () {
            _this.dialog.closeAll();
            _this.da = null;
        });
        this.da.afterClosed()
            .pipe(takeUntil(this.subsManager$))
            .subscribe(function (selectedAccountId) {
            _this.dialog.closeAll();
            //this.getData(this.paginate);
            if (selectedAccountId === '' || selectedAccountId === undefined) {
                _this.da = null;
                return;
            }
            if (accountId) {
                _this.da = null;
                _this.dialog.closeAll();
                return;
            }
            _this.dl = _this.dialog.open(DialogLocationsComponent, {
                width: '680px',
                data: { selectedAccountId: selectedAccountId, googleAccounts: _this._googleAccountService.accounts }
            });
            // Destroy the dialogs on close to get clean data.
            _this.dl.afterClosed()
                .pipe(takeUntil(_this.subsManager$))
                .subscribe(function (data) {
                var _a;
                _this.dl = null;
                _this.da = null;
                if (data === 'back') {
                    _this.openAddAccountsDialog(null);
                }
                else {
                    if (data !== '') {
                        var accountId_1 = (_a = selectedAccountId) === null || _a === void 0 ? void 0 : _a.split('/')[1];
                        _this.router.navigate(['accounts', accountId_1, 'locations']); //, 'locations'
                    }
                }
                _this.getData(_this._paginate);
            });
        });
    };
    AccountsComponent.prototype.handleReload = function ($event) {
        this._paginate = $event;
        this.getData($event);
    };
    AccountsComponent.prototype.goLocation = function (element) {
        var _a, _b, _c, _d;
        var errorAccount = true;
        if ((_b = (_a = element) === null || _a === void 0 ? void 0 : _a.gauthStatus) === null || _b === void 0 ? void 0 : _b.isValid) {
            errorAccount = !((_d = (_c = element) === null || _c === void 0 ? void 0 : _c.gauthStatus) === null || _d === void 0 ? void 0 : _d.isValid);
        }
        this.router.navigate(['/accounts/', element.id || element.accountId, 'locations'], { queryParams: { errorAccount: errorAccount } });
    };
    AccountsComponent.prototype.refresh = function (element) {
        var _this = this;
        if (!this.loading_refresh_account$.getValue()) {
            this.loading_refresh_account$.next(true);
            var elementHtml_1 = document.getElementById("spin-" + element.accountId);
            elementHtml_1.classList.add('active-spin');
            this._snack.openInfo("Account locations are being refreshed. This may take several minutes", 1800);
            setTimeout(function () {
                elementHtml_1.classList.remove('active-spin');
            }, 2500);
            var destroy_refresh_account$_1 = new Subject();
            this.accountService.refreshAccountLocations(element).pipe(takeUntil(destroy_refresh_account$_1))
                .subscribe(function (r) {
                if (r.data.status === 'FAIL') {
                    _this._snack.openError('The operation has been failed', 2500);
                    destroy_refresh_account$_1.next(true);
                    destroy_refresh_account$_1.unsubscribe();
                }
                else if (r.data.status === 'COMPLETE') {
                    _this._snack.openSuccess(r.data.response, 2500);
                    destroy_refresh_account$_1.next(true);
                    destroy_refresh_account$_1.unsubscribe();
                }
                _this.loading_refresh_account$.next(false);
            });
        }
        else {
            this._snack.openInfo("Wait, We are update your account", 1800);
        }
    };
    return AccountsComponent;
}());
export { AccountsComponent };
// modal accounts
var DialogAccountsComponent = /** @class */ (function () {
    function DialogAccountsComponent(data, dialogRef, _router, _accountService, _auth, _snack) {
        var _this = this;
        this.data = data;
        this.dialogRef = dialogRef;
        this._router = _router;
        this._accountService = _accountService;
        this._auth = _auth;
        this._snack = _snack;
        this.onAccountSelected = new EventEmitter();
        this.subsManager$ = new Subject();
        this.user = this._auth.session;
        this.selectedAccount = null;
        this.data.googleAccounts.pipe(takeUntil(this.subsManager$)).subscribe(function (result) {
            _this.googleAccounts = result;
            _this.resultSearchAccount = result;
            if (_this.data.accountId != null) {
                _this.close();
                _this._accountService.updateGauth(_this._auth.session.gid, _this.data.accountId).then(function (res) {
                    _this._router.navigate(['accounts', _this.data.accountId, 'locations']);
                    _this._snack.openSuccess('Your account has been re-authenticated!', 2000);
                });
            }
        });
    }
    DialogAccountsComponent.prototype.close = function () {
        this.subsManager$.next(true);
        this.subsManager$.unsubscribe();
        this.dialogRef.close();
    };
    DialogAccountsComponent.prototype.addAccount = function () {
        this.subsManager$.next(true);
        this.subsManager$.unsubscribe();
        this.dialogRef.close(this.selectedAccount);
    };
    DialogAccountsComponent.prototype.filterAccount = function () {
        var _this = this;
        this.resultSearchAccount = this.googleAccounts.filter(function (account) { return account.accountName.toLowerCase().includes(_this.search.toLowerCase()); });
    };
    return DialogAccountsComponent;
}());
export { DialogAccountsComponent };
// Delete Dialog
var DialogDeleteAccountComponent = /** @class */ (function () {
    function DialogDeleteAccountComponent(data, dialogRef, _accountService, _locationService, _auth, _spinnerService, _notificationService, _wl) {
        this.data = data;
        this.dialogRef = dialogRef;
        this._accountService = _accountService;
        this._locationService = _locationService;
        this._auth = _auth;
        this._spinnerService = _spinnerService;
        this._notificationService = _notificationService;
        this._wl = _wl;
        this.onDelete = new EventEmitter();
        this.account = data.account;
        this.user = this._auth.session;
    }
    DialogDeleteAccountComponent.prototype.handleDeleteAccount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, accountId, locations, locations_1, locations_1_1, loc, locationAddress, meta, e_3_1, err_1;
            var e_3, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        gid = this.user.gid;
                        accountId = this.account.accountId;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 14, 15, 17]);
                        this._spinnerService.loading$.next(true);
                        return [4 /*yield*/, this._locationService.byAccount(gid, accountId).toPromise()
                            // TODO: Removing account locations should be backend responsibility
                        ];
                    case 2:
                        locations = _b.sent();
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 10, 11, 12]);
                        locations_1 = __values(locations), locations_1_1 = locations_1.next();
                        _b.label = 4;
                    case 4:
                        if (!!locations_1_1.done) return [3 /*break*/, 9];
                        loc = locations_1_1.value;
                        return [4 /*yield*/, this._locationService.deleteLocation(gid, loc.locationId, accountId)];
                    case 5:
                        _b.sent();
                        return [4 /*yield*/, this._locationService.deleteReferencesToLocation(gid, loc.locationId)
                            //Notification informative delete location
                        ];
                    case 6:
                        _b.sent();
                        locationAddress = loc.location.address ? this._locationService.formatAddress(loc.location.address) : '';
                        meta = this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, __assign({ accountId: accountId, address: locationAddress }, loc));
                        return [4 /*yield*/, this._notificationService.saveNotification(gid, this._wl.baseDomain, string_message(Messages.notifications.LOCATION_TOGGLE, [loc.locationName, locationAddress, DELETE_DATA]), NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta)];
                    case 7:
                        _b.sent();
                        _b.label = 8;
                    case 8:
                        locations_1_1 = locations_1.next();
                        return [3 /*break*/, 4];
                    case 9: return [3 /*break*/, 12];
                    case 10:
                        e_3_1 = _b.sent();
                        e_3 = { error: e_3_1 };
                        return [3 /*break*/, 12];
                    case 11:
                        try {
                            if (locations_1_1 && !locations_1_1.done && (_a = locations_1.return)) _a.call(locations_1);
                        }
                        finally { if (e_3) throw e_3.error; }
                        return [7 /*endfinally*/];
                    case 12: 
                    // All Account locations deleted, delete account
                    return [4 /*yield*/, this._accountService.delete(gid, accountId)];
                    case 13:
                        // All Account locations deleted, delete account
                        _b.sent();
                        return [3 /*break*/, 17];
                    case 14:
                        err_1 = _b.sent();
                        console.error('Error deleting account', err_1);
                        return [3 /*break*/, 17];
                    case 15: return [4 /*yield*/, this._auth.processSubscription()];
                    case 16:
                        _b.sent();
                        this.onDelete.emit(true);
                        this.dialogRef.close();
                        this._spinnerService.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    return DialogDeleteAccountComponent;
}());
export { DialogDeleteAccountComponent };
// Delete Dialog
var DialogAccountReauthComponent = /** @class */ (function () {
    function DialogAccountReauthComponent(data, router, dialogRef, _auth) {
        this.data = data;
        this.router = router;
        this.dialogRef = dialogRef;
        this._auth = _auth;
        this.onAccept = new EventEmitter(false);
        this.account = data.account;
        this.user = this._auth.session;
    }
    DialogAccountReauthComponent.prototype.ReAuth = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onAccept.emit(true);
                return [2 /*return*/];
            });
        });
    };
    return DialogAccountReauthComponent;
}());
export { DialogAccountReauthComponent };
