import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Protocol} from '../../constants/firestore/protocol';

@Component({
  selector: 'app-form-create-protocol',
  templateUrl: './form-create-protocol.component.html',
  styleUrls: ['./form-create-protocol.component.scss']
})
export class FormCreateProtocolComponent implements OnInit {
  form: FormGroup;

  @Output() isValid = new EventEmitter();
  @Input() protocolEdit: Protocol;
  starsArray = [1, 2, 3, 4, 5];
  showKeywords = false;
  selectedStars = [];
  starsTouched: boolean;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
  }


  withoutsChange(): void {
    const keywords = this.form.controls['triggers'].get('keywords').value as string[];
    const withOutComment = this.form.controls['withOutComment'].value;
    const withComment = this.form.controls['withComment'].value;
    if ((withComment && withOutComment) || (!withComment && !withOutComment )) {
      keywords.forEach( (a, i) => this.keywords.removeAt(i))
      this.showKeywords = false;
    } else if (withComment) {
      this.showKeywords = true;
    }
  }

  initForm(): void {

    if (this.protocolEdit) {
      const keywords: FormArray = new FormArray([]);
      if (this.protocolEdit.withComment && !this.protocolEdit.withOutComment) this.showKeywords = true;
      this.protocolEdit.triggers.keywords.forEach(k => {
        keywords.push(new FormControl(k));
      });

      const excludeKeywords: FormArray = new FormArray([]);
      if (this.protocolEdit.triggers.excludeKeywords && this.protocolEdit.triggers.excludeKeywords.length > 0) {
        this.protocolEdit.triggers.excludeKeywords.forEach(k => {
          excludeKeywords.push(new FormControl(k));
        });
      }

      this.form = this.fb.group({
        name: [this.protocolEdit.name, [Validators.required, Validators.minLength(3)]],
        withComment: [this.protocolEdit.withComment],
        withOutComment: [this.protocolEdit.withOutComment],
        triggers: this.fb.group({
          delay: [this.protocolEdit.triggers.delay, Validators.required],
          keywords,
          excludeKeywords
        }),
        stars: [[], Validators.required]
      });

      this.protocolEdit.stars.forEach(star => {
        const event = new MatCheckboxChange();
        event.source = null;
        event.checked = true;
        this.checkedStars(event, star - 1);
      });


    } else {
      this.form = this.fb.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        withComment: [false],
        withOutComment: [false],
        triggers: this.fb.group({
          delay: [0, [Validators.min(0)]],
          keywords: new FormArray([]),
          excludeKeywords: new FormArray([]),
        }),
        stars: [[], Validators.required]
      });
    }


    this.form.valueChanges.subscribe( () => {
      if (this.form.valid) {
        const keywords = this.form.controls['triggers'].get('keywords').value as string[];
        if (keywords.length > 0) {
          const some_empty = keywords.some(k => k === "");
          if (some_empty) {
            this.isValid.emit(false);
          }
        }
        this.isValid.emit(this.form.value);
      } else {
        this.isValid.emit(false);
      }
    });

  }


  checkWord(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const word: string = control.value;
      if (word.split(' ').length > 1) return {'notWord': true}
      return
    }
  }

  checkedStars($event: MatCheckboxChange, i: number): void {
    if ($event.checked) {
      this.selectedStars.push(i + 1);
      this.starsTouched = true;
    } else {
      const index = this.selectedStars.findIndex(s => s === i + 1);
      this.selectedStars.splice(index, 1);
    }

    this.stars.setValue(this.selectedStars);

    if (this.form.valid && this.stars.value.length > 0) {
      this.isValid.emit(this.form.value);
    }
  }

  triggerNumberUp($event: any): void {
    const targetParent = $event.target.closest('.input-number-wrapper');
    const input = targetParent.querySelector('input');
    input.stepUp();
    this.delay.setValue(input.value);
  }

  triggerNumberDown($event: any): void {
    const targetParent = $event.target.closest('.input-number-wrapper');
    const input = targetParent.querySelector('input');
    input.stepDown();
    this.delay.setValue(input.value);
  }


  pushKeyword(): void {
    this.keywords.push(new FormControl('', [Validators.required, this.validateCommaa()]));
  }

  pushNegativeKeyword(): void {
    this.excludeKeywords.push(new FormControl('', [Validators.required, this.validateCommaa()]));
  }

  validateCommaa(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const word: string = control.value;
      if (word.includes(',')) {
        return {hasComma: true};
      }
      return;
    };
  }

  removeKeyword(index: number): void {
    this.keywords.removeAt(index);
  }

  removeNegativeKeyword(index: number): void {
    this.excludeKeywords.removeAt(index);
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get withComment(): AbstractControl {
    return this.form.get('withComment');
  }

  get withOutComment(): AbstractControl {
    return this.form.get('withOutComment');
  }


  get triggers(): AbstractControl {
    return this.form.get('triggers');
  }

  get delay(): AbstractControl {
    return this.triggers.get('delay');
  }

  get keywords(): FormArray {
    return this.triggers.get('keywords') as FormArray;
  }

  get excludeKeywords(): FormArray {
    return this.triggers.get('excludeKeywords') as FormArray;
  }

  get stars(): AbstractControl {
    return this.form.get('stars');
  }

  inputKeyword(i: number): ValidationErrors {
    return this.keywords.at(i).errors
  }

  inputNegativeKeyword(i: number): ValidationErrors {
    return this.excludeKeywords.at(i).errors
  }
}


