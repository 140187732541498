<mat-drawer-container [class.z-index--auto]="isSliderOpened" [hasBackdrop]="true">
  <mat-drawer-content [class.z-index--999]="isSliderOpened">
    <div class="content__header d-flex justify-content-between align-items-center pr--35">
      <h1><strong>{{nameReportType | titlecase }} Report</strong></h1>
      <mat-drawer #drawer mode="'over'" position="end"
        [ngClass]="{'top-space': isImpersonating || (!isMember() && (subscription$ | async)?.status == GROUP_SUBSCRIPTION_TYPE.TRIAL) }"
        (keydown.escape)="closeSliderReport()">
        <app-slide-create-report [viewModeChecked]="viewModeChecked" [drawer]="drawer" [isSliderOpened]="isSliderOpened"
          [reportData]="reportData" (closeSliderReport)="closeSliderReport()"></app-slide-create-report>
      </mat-drawer>
      <div>

        <button mat-flat-button color="accent" class="btn btn--icon btn--md" *ngIf="viewModeChecked == 'new'"
          (click)="openSliderReport(drawer, null, false)">
          Create {{nameReportType | titlecase}} Report
        </button>
      </div>
    </div>

    <div class="content__body pr--35">

      <div class="table--shadow">
        <!--search and list/box view buttons-->
        <div
          class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center bg--white">
          <div class="input-field input-field--search" [matTooltipDisabled]="viewModeChecked != 'legacy'"
            matTooltip="Filtering is not available for legacy reports" matTooltipPosition="left">
            <div class="table__icon table__icon--lg">
              <i class="fas fa-search"></i>
            </div>
            <input (input)="applyFilter($event?.target?.value)" placeholder="Search Report Name"
              [(ngModel)]="searchText" [disabled]="viewModeChecked == 'legacy'">
          </div>
        </div>

        <app-skeleton-reports [type]="reportType" *ngIf="progress"></app-skeleton-reports>
        <ng-container *ngIf="!progress">

          <div *ngIf="dataSource?.data?.length > 0; else reportEmpty">
            <table (matSortChange)="sortData($event)" mat-table matSort matSortStart="desc"
              [matSortActive]="sort && sort.sortBy" [matSortDirection]="sort && sort.direction"
              [dataSource]="dataSource" class="table table--md table--last-row-border">

              <ng-container matColumnDef="name">
                <th width="33%" mat-header-cell mat-sort-header="reportName" *matHeaderCellDef><strong>Name</strong>
                </th>
                <td mat-cell *matCellDef="let element" (click)="action(element, drawer)" class="cursor--pointer">
                  <ng-container>
                    <div class="cell__content">
                      <p>{{ element?.reportName }}</p>
                    </div>
                  </ng-container>
                </td>
              </ng-container>

              <!--show if list view enabled-->
              <ng-container matColumnDef="created">
                <th width="15%" mat-header-cell mat-sort-header="createdAt" *matHeaderCellDef><strong>Created
                    On</strong>
                </th>
                <td mat-cell *matCellDef="let element" (click)="action(element, drawer)" class="cursor--pointer">
                  <div class="cell__content">
                    <p *ngIf="hasDateInFire()">{{ element?.createdAt | fireTimestamp }}</p>
                    <p *ngIf="!hasDateInFire()">{{ element?.createdAt | date }}</p>
                  </div>
                </td>
              </ng-container>


              <!--show if list view enabled-->
              <ng-container matColumnDef="modified">
                <th width="15%" mat-header-cell mat-sort-header="updateAt" *matHeaderCellDef><strong>Modified
                    On</strong>
                </th>
                <td mat-cell *matCellDef="let element" (click)="action(element, drawer)" class="cursor--pointer">
                  <div class="cell__content">
                    <p *ngIf="hasDateInFire()">{{ (element?.updateAt ? element?.updateAt : element?.createdAt) |
                      fireTimestamp }}</p>
                    <p *ngIf="!hasDateInFire()">{{ (element?.updatedAt ? element?.updatedAt : element?.createdAt) | date
                      }}</p>
                  </div>
                </td>
              </ng-container>

              <!--show if list view enabled-->
              <ng-container matColumnDef="locations">
                <th width="10%" mat-header-cell *matHeaderCellDef><strong>Locations</strong></th>
                <td mat-cell *matCellDef="let element" class="cursor--pointer">
                  <div class="cell__content">
                    <button mat-flat-button color="accent" [matMenuTriggerFor]="locationMenu"
                      class="d-flex align-items-center btn btn--badge btn--badge--sm txt--uppercase txt--xs">
                      {{countLocation(element)}} locations <i class="fas fa-angle-down ml--10 mr--0"></i>
                    </button>
                    <mat-menu #locationMenu="matMenu" class="mat-menu--lg mat-menu--noheight">
                      <ng-container *ngFor="let account of element?.accounts">
                        <ng-container *ngIf="account?.locations[0]?.locationName; else elseTemplate">
                          <button class="d-flex align-items-center" mat-menu-item
                            *ngFor="let location of account?.locations">
                            <span class="mr--5">{{location?.locationName}} - </span>
                            <gmb-address style="display: inline-block" 
                              [address]="location?.address" 
                              [serviceAreas]="location?.serviceArea?.places?.placeInfos">
                            </gmb-address>
                          </button>
                        </ng-container>
                        <ng-template #elseTemplate>
                          <button class="d-flex align-items-center" mat-menu-item
                            *ngFor="let location of account?.locationsBasics">
                            <span class="mr--5">{{location?.locationName}} - </span>
                            <gmb-address style="display: inline-block"
                              *ngIf="location?.location?.address || location?.location?.serviceArea"
                              [address]="location?.location?.address"
                              [serviceAreas]="location?.location?.serviceArea?.places?.placeInfos">
                            </gmb-address>
                          </button>
                        </ng-template>

                      </ng-container>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th width="15%" mat-header-cell *matHeaderCellDef><strong>Date</strong></th>
                <td mat-cell *matCellDef="let element" (click)="action(element, drawer)"
                  class="cursor--pointer {{ reportType=='comparison' ? 'w--md' : '' }}">
                  <ng-container *ngIf="element?.scheduleReport || element?.dynamic; else staticRange">
                    <div class="cell__content d-flex flex-column align-items-start"
                      *ngIf="reportType!='comparison' && reportType!='performance-comparison'">
                      <p style="font-weight: bolder;">Dynamic range</p>
                      <span [ngSwitch]="element?.dynamicRange">
                        <p *ngSwitchCase="'LastMonth'">
                          Last Month
                        </p>
                        <p *ngSwitchCase="'Last30'">
                          Last 30 Days
                        </p>
                        <p *ngSwitchCase="'Last90'">
                          Last 90 Days
                        </p>
                        <p *ngSwitchCase="'OneMonth'">
                          Last Month
                        </p>
                        <p *ngSwitchCase="'Last3Month'">
                          Last 3 Months
                        </p>
                        <p *ngSwitchCase="'last7Days'">
                          Last 7 Day
                        </p>
                        <p *ngSwitchCase="'ThisMonth'">
                          This Month
                        </p>
                        <p *ngSwitchCase="'allTime'">
                          All Time
                        </p>
                        <p *ngSwitchDefault>
                          Year to Date
                        </p>
                      </span>
                    </div>

                    <div class="table--box__row"
                      *ngIf="reportType=='comparison' || reportType=='performance-comparison'">
                      <div class="table--box__row__item" style="width:146px; height: 93px;">
                        <span class="d-block txt--black txt--uppercase"><strong>Range A</strong></span>
                        <ng-container [ngSwitch]="element?.dynamicRange">
                          <ng-container *ngSwitchCase="'Last90-PrevLast90'">
                            <p class="m--0">Last 90 Days </p>
                            <p class="mb--10"></p>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <p class="m--0">Last Month</p>
                            <p class="mb--10"></p>
                          </ng-container>

                        </ng-container>
                      </div>
                      <div class="table--box__row__item" style="width:146px; height: 93px;">
                        <span class="d-block txt--black txt--uppercase"><strong>Range B</strong></span>
                        <ng-container [ngSwitch]="element?.dynamicRange">
                          <ng-container *ngSwitchCase="'LastMonth-PrevMonth'">
                            <p class="m--0">Previous Month</p>
                            <p class="mb--10"></p>
                          </ng-container>
                          <ng-container *ngSwitchCase="'LastMonth-YearAgoMonth'">
                            <p class="m--0">Same Month Year ago</p>
                            <p class="mb--10"></p>
                          </ng-container>
                          <ng-container *ngSwitchCase="'Last90-PrevLast90'">
                            <p class="m--0">Previous 90 Days</p>
                            <p class="mb--10"></p>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #staticRange>
                    <ng-container>
                      <div class="cell__content d-flex flex-column align-items-start"
                        *ngIf="reportType!='comparison' && reportType!=='performance-comparison'">
                        <p>From: {{ element?.fromDate}}</p>
                        <p>To: {{ element?.toDate}}</p>
                      </div>

                      <div class="table--box__row"
                        *ngIf="reportType=='comparison' || reportType=='performance-comparison'">
                        <div class="table--box__row__item">
                          <span class="d-block txt--black txt--uppercase"><strong>Range A</strong></span>
                          <p class="m--0">Start: {{ element?.startDatetime|zuluToDate }} </p>
                          <p class="mb--10">End: {{ element?.endDatetime|zuluToDate }} </p>
                        </div>
                        <div class="table--box__row__item">
                          <span class="d-block txt--black txt--uppercase"><strong>Range B</strong></span>
                          <p class="m--0">Start: {{ element?.startDatetimeB|zuluToDate }}</p>
                          <p class="mb--10">End: {{ element?.endDatetimeB|zuluToDate }}</p>
                        </div>
                      </div>
                    </ng-container>

                  </ng-template>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="txt--right"><strong>Actions</strong></th>
                <td mat-cell *matCellDef="let element" class="txt--right">
                  <div class="cell__content">
                    <button mat-flat-button color="accent" class="btn btn-icon mr--10" (click)="handleShared(element)">
                      <em class="fas fa-share-alt"></em>
                    </button>
                    <button mat-flat-button color="primary" class="btn btn-icon mr--10"
                      (click)="openSliderReport(drawer, element, false)">
                      <em class="fas fa-pen"></em>
                    </button>
                    <div class="alternate-theme d-inline-block" *ngIf="reportType != 'qanda'">
                      <button mat-flat-button color="warn" class="btn btn-icon mr--10" [matMenuTriggerFor]="copyMenu"
                        *ngIf="reportType !== 'Review Assistant'  &&  viewModeChecked != 'legacy'">
                        <em class="fal fa-copy mr--5"></em> <em class="fal fa-angle-down"></em>
                      </button>
                      <mat-menu #copyMenu="matMenu">
                        <button mat-button color="warn" (click)="copy(element)">Copy</button>
                        <button mat-button color="warn" *ngIf="reportType!='performance-rollup'"
                          (click)="copy(element,'performance-rollup')">Copy
                          to Rollup
                        </button>
                        <button mat-button color="warn" *ngIf="reportType!='review'"
                          (click)="copy(element,'review')">Copy
                          to Review
                        </button>
                        <button mat-button color="warn" *ngIf="reportType!='performance-comparison'"
                          (click)="copy(element,'performance-comparison')">Copy to Comparison
                        </button>
                        <button mat-button color="warn" *ngIf="reportType!='performance-revenue'"
                          (click)="copy(element,'performance-revenue')">Copy
                          to Revenue
                        </button>
                        <button mat-button color="warn" *ngIf="reportType!='keyword'"
                          (click)="copy(element,'keyword')">Copy
                          to Keyword
                        </button>
                      </mat-menu>
                    </div>

                    <div class="alternate-theme d-inline-block" *ngIf="reportType == 'qanda'">
                      <button mat-flat-button color="warn" class="btn btn-icon mr--10" (click)="copy(element)">
                        <em class="fal fa-copy mr--5"></em>
                      </button>
                    </div>

                    <button *ngIf="isAdmin()" mat-flat-button color="primary" class="btn btn-icon"
                      (click)="showDeleteDialog(element)">
                      <em class="far fa-trash-alt"></em>
                    </button>

                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns" [class.no-hover]="reportType=='comparison'"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.no-hover]="reportType=='comparison'">
              </tr>

            </table>
            <app-paginator *ngIf="pagination && !filtered" [pagination]="pagination" [options]="[10, 25,50,100,200,500]"
              (reload)="handleReload($event)">
            </app-paginator>
          </div>

          <ng-template #reportEmpty>
            <app-no-data message="No available reports"></app-no-data>
          </ng-template>
        </ng-container>
      </div>

    </div>

    <!-- view mode toggle -->
    <div class="spinner-wrapper" *ngIf="loadingLegacyFooter">
      <span>Fetching legacy reports data &nbsp;</span>
      <mat-spinner [diameter]="20"></mat-spinner>
    </div>
    <div class="mt--20 mr--25"
      *ngIf="reportType != 'keyword' && reportType != 'qanda' && reportType != 'review' && (hasLegacy || viewModeChecked == 'legacy')">
      <div class="box box--rounded box--shadow bg--white p--15">
        <div class="row">
          <div class="col-8">
            <span class="d-inline-block mt--10 ml--10 txt--normal mr--15 mb--0">As of February 2023, Google deprecated
              the legacy insights on v4.9 via the Google Business Profile API but are still available via the legacy
              view for metrics recorded prior to December 31, 2022.</span>
          </div>
          <div class="col-4 txt--align--end">
            <span class="d-inline-block txt--normal ml--10 mr--15 mb--0">{{reportType | replace : '-' : ' ' | titlecase
              }} View:</span>
            <mat-button-toggle-group [value]="viewModeChecked" appearance="legacy" name="viewMode"
              (change)="toggleModePerformaceLegacy()">
              <mat-button-toggle value="new">New</mat-button-toggle>
              <mat-button-toggle value="legacy">Legacy</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>

  </mat-drawer-content>

</mat-drawer-container>