import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { Router } from '@angular/router';
import { AlertType } from 'src/app/components/alert.component';
import { ModalService } from 'src/app/services/modal.service';
import { PostService } from 'src/app/services/post.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import * as _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'app-post-management-status',
  templateUrl: './post-management-status.component.html',
  styleUrls: ['./post-management-status.component.scss']
})
export class PostManagementStatusComponent implements OnInit {
  @Input() isSliderOpen = false;
  @Input() statusData = [];
  @Input() postTitle = null;
  @Input() postId = null;
  @Input() statusDrawer: MatDrawer; 

  @Output() closeStatusSlider = new EventEmitter();

  public isLoading = false;

  constructor(
    private _modalS: ModalService,
    private _postS: PostService, 
    private _snackS: SnackbarService,
    private _router: Router,
  ) { }

  ngOnInit() {
    
  }

  getStatusClass(status: string): string {
    let statusClass = 'status--gray';

    if(status?.toUpperCase() === 'DRAFT') {
      statusClass = 'status--gray';
    } else if(status?.toUpperCase() === 'PENDING' || status?.toUpperCase() === 'SCHEDULED') {
      statusClass = 'status--blue';
    } else if(status?.toUpperCase() === 'PUBLISHED' || status?.toUpperCase() === 'FINISHED' || status?.toUpperCase() === 'SUCCESS') {
      statusClass = 'status--green';
    } else if(status?.toUpperCase() === 'FLAGGED_FOR_DELETE' || status?.toUpperCase() === 'CONTACT_SUPPORT') {
      statusClass = 'status--yellow';
    } else if(status?.toUpperCase() === 'FAILED' || status?.toUpperCase() === 'REJECTED') {
      statusClass = 'status--red';
    }
    return statusClass;
  }

  statusLabel(status): string {
    return this._postS.getStatusLabel(status);
  }

  getStatus(status): string {
    return status.replaceAll('_', ' ');
  }

  retryAll(statusData: any[]) {
    this.isLoading = true;
    
    return this._postS.retryAllPosts(statusData,() => {
      this.getData(); 
    });
  }
  
  retryPost(element: any) {
    this.isLoading = true;

    return this._postS.retryPost(element,() => {
      this.getData(); 
    });
  }

  getInfoRejected(element):Promise<any> {
    this.isLoading = true;
    return new Promise((resolve, reject) => {
        this._postS.getInfoRejected(element.postId, element?.postInstanceId).subscribe(
            res => {
              const msg = this._postS.getModalText(res);
              this.isLoading = false;
              resolve(msg);
            },
            err => {
              this.isLoading = false;
              reject(err);
            }
        );
    });
  } 

  async infoAboutStatus(element) {
    const title = (
      element?.status === 'CONTACT_SUPPORT' ? 'Contact Us' : 
      element?.status === 'REJECTED' ? 'Rejected' : 'Failed'
    );

    const msg =  (
      element?.status === 'CONTACT_SUPPORT' ? `<p class="txt--md txt--left">This post had failed to publish 3 times. To resolve this issue, please contact support.</p>` :
      element?.status === 'REJECTED' ? 
      ` <p class="txt--md txt--left">This location has cannot be validated in Map Labs. Review the location in your </br> Google Business Profile for any potential issues, then try again.</p> 
        <p class="txt--md txt--left mt--20">If the problem persist, please contact Support.</p>` : 
      await this.getInfoRejected(element)
    );

    if(msg) {
      this._modalS.openInfoModal(
        title,
        msg,
        'Okay',
        true
      );

    } else {
      this.isLoading = false;
      this._snackS.openError('There was an error while loading the data. Please try again or contact support')
    }
  }

  goToLocation(element): void {
    this._router.navigate(['/account', element?.accountId, 'location', element?.location?.locationId, 'post-management'])
  }

  deleteLocationsModal(element, allLocations): void {
    const message = (
      allLocations ? `Are you sure you want to delete posts for all failed locations? This bulk action cannot be undone.` :
      element?.isRecurring ?
      `Are you sure you want to delete this profile from this post series? This action cannot be undone.` :
      `Are you sure you want to delete this profile? This action cannot be undone.`
    );

    this._modalS.openAlertModal(
      `Attention`,
      message,
      AlertType.ERROR,
      null,
      'Delete Post',
      ((response) => {
        if (response) {
          this.deleteLocationsFromPost(element, allLocations);
        }
      }),
      'accent'
    );
  }

  deleteLocationsFromPost(element, allLocations) {
    this.isLoading = true;
    const locationsId = !allLocations ? [element?.postLocationId] : this.statusData?.filter(el => el?.status === "FAILED")?.map(el => el?.postLocationId);
    const date = moment().format('YYYY-MM-DD')
    const time = moment().format('HH:MM')

    this._postS.deleteLocationsFromPost(this.postId, locationsId, date, time).subscribe(
      res => {
        this.getData();
      },
      err => {
        this.destroyData();
        this.isLoading = false;
      }
    )
  }

  getData(): void {
    this._postS.getStatusPost(this.postId).subscribe(
      res => {
        if(res?.data?.length > 0) {
          this.statusData = res?.data;
          this.isLoading = false;
        } else {
          this.destroyData(true);
          this.isLoading = false;
        }
      },
      err => {
        this.isLoading = false;
        this.destroyData();
      }
    )
  }

  destroyData(needsRefresh = false): void {
    this.statusData = null;
    this.postId = null;
    this.isSliderOpen = false;
    this.closeStatusSlider.emit(needsRefresh);
  }

  existFailed(): boolean {
    return this.statusData.find(el => el.status === "FAILED") ? false : true;
  }

}
